import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BillingType } from 'src/app/saferoadsph/interfaces/Payment';
import { PaymentsService } from 'src/app/saferoadsph/services/payments.service';

@Component({
  selector: 'app-billing-payment-type',
  templateUrl: './billing-payment-type.component.html',
  styleUrls: ['./billing-payment-type.component.scss'],
  providers: [MessageService]
})
export class BillingPaymentTypeComponent implements OnInit {
  recordDialog: boolean;
  records:BillingType[];
  record = new BillingType();
  selectedRecords:BillingType[];
  submitted: boolean;
  statuses: any[];
  state: string = "new";

  constructor(private paymentService: PaymentsService,
              private messageService: MessageService) { }

  ngOnInit(): void {
      this.loadPaymentTypes();
  }

  loadPaymentTypes() {
    this.paymentService.getAllPaymentTypes().then((response:any) => {
        this.records = response.result;
        //console.log(this.records)
    }).catch(err => {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Error in Loading Data Please Contact Administrator",
        life: 3000,
    });
    })
  }

  hideDialog() {
    this.recordDialog = false;
    this.submitted = false;
  }

  editRecord(record: any) {
    this.record = { ...record };
    this.recordDialog = true;
    this.state = "edit";
  }

  openNew() {
    this.record = new BillingType();
    //console.log(this.record)
    this.submitted = false;
    this.recordDialog = true;
    this.state = "new";
  }

  compute() {
    this.record.discountAmount = 0 //this.record.amount * (this.record.discountPercent/100);
    this.record.vatAmount = 0;//this.record.amount * (this.record.vatPercent/100);
    this.record.totalAmount = this.record.amount;//(this.record.amount - this.record.discountAmount) + this.record.vatAmount;
  }

  computeTotalVat(value) {
    if(value.checked) {
        this.record.vatInclusive = 1;
        this.record.totalAmount =  this.record.amount;//((this.record.amount) - this.record.discountAmount);
    } else {
        this.record.vatInclusive = 0;
        this.record.totalAmount =  this.record.amount;//((this.record.amount) - this.record.discountAmount) + this.record.vatAmount;
    }
}

  saveProduct() {
    this.submitted = true;
    //console.log(this.record)
    this.paymentService.createPaymentType(this.record).then((response:any) =>{ 
      this.loadPaymentTypes();
      this.submitted = false;
      this.recordDialog = false;
      this.messageService.add({
        severity: "success",
        summary: "Saving was successful.",
        detail: "",
        life: 3000,
    });
    }).catch(err => {
      this.submitted = false;
      this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
      });
    })
  }

  update() {
    //console.log(this.record)
    this.submitted = true;
    this.paymentService.updatePaymentType(this.record).then((response:any)=> {
        this.loadPaymentTypes();
        this.submitted = false;
        this.recordDialog = false;
        this.messageService.add({
          severity: "success",
          summary: "Saving was successful.",
          detail: "",
          life: 3000,
      });
    }).catch(err => {
      this.submitted = false;
      this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
      });
    })
  }

  deleteRecord(record) {
    //console.log(record);
    this.submitted = true;
    this.paymentService
        .deletePaymentType(record.id)
        .then((res) => {
            this.loadPaymentTypes();
            this.submitted = false;
            this.messageService.add({
                severity: "success",
                summary: "Delete was successful.",
                detail: "",
                life: 3000,
            });
        })
        .catch((err) => {
            this.submitted = false;
            this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Error in Loading Data Please Contact Administrator",
                life: 3000,
            });
        });
    }
}
