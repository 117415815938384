import { Component, OnInit } from '@angular/core';
import * as FileSaver from "file-saver";
import { StudentStatusReport } from 'src/app/saferoadsph/interfaces/Report';
import { ReportsService } from 'src/app/saferoadsph/services/reports.service';
import { StorageService } from 'src/app/saferoadsph/services/storage.service';

@Component({
  selector: 'app-report-school-list',
  templateUrl: './report-school-list.component.html',
  styleUrls: ['./report-school-list.component.scss']
})
export class ReportSchoolListComponent implements OnInit {
  loading: boolean = true;
  recordDialog: boolean;
  passwordDialog: boolean;
  pictureDialog: boolean;
  records: StudentStatusReport[];
  record: StudentStatusReport;
  selectedRecords: StudentStatusReport[];
  submitted: boolean;
  dsProfileId;
  accountValidated;

  constructor(private reportService: ReportsService,
              private storageService: StorageService) {}

  ngOnInit(): void {
      this.loadAccountValidated();
      this.storageService.get("dsProfileId").then((response) => {
          this.dsProfileId = response;
          this.getReports();
      });
  }

  loadAccountValidated() {
      this.storageService.get('accountValidated').then((response:any) => {
          this.accountValidated = response;
      })
  }

  getReports() {
      this.reportService.studentListStatusReport().then((res: any) => {
          this.records = res.result;
          //console.log(res.result);
          this.loading = false;
      });
  }

  exportExcel() {
      import("xlsx").then((xlsx) => {
          const worksheet = xlsx.utils.json_to_sheet(this.records);
          const workbook = {
              Sheets: { data: worksheet },
              SheetNames: ["data"],
          };
          const excelBuffer: any = xlsx.write(workbook, {
              bookType: "xlsx",
              type: "array",
          });
          this.saveAsExcelFile(excelBuffer, "student_list_status_report");
      });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
  }

}
