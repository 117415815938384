import { Component, OnInit } from "@angular/core";
import { SchoolAccountPaymentOption, SchoolPaymentTypeOption, SchoolTransaction } from "../../interfaces/Payment";
import { PaymentsService } from "../../services/payments.service";
import _ from "lodash";
import { ConfirmationService, MessageService } from "primeng/api";
import { ValidationService } from "../../services/validation.service";
import { StorageService } from "../../services/storage.service";

@Component({
    selector: "app-payment-subscription",
    templateUrl: "./payment-subscription.component.html",
    styleUrls: ["./payment-subscription.component.scss"],
    providers:[ConfirmationService, MessageService]
})
export class PaymentSubscriptionComponent implements OnInit {
    recordDialog: boolean;
    submitted: boolean;

    schoolTransaction = new SchoolTransaction();
    paymentOptions: SchoolAccountPaymentOption[] = [];
    subscriptionOption: SchoolPaymentTypeOption[]= [];
    record;
    subscriptionType;

    selectedPaymentType:SchoolPaymentTypeOption;
    selectedPaymentMethod;
    paymentMethodReadOnly;
    
    fileToUpload;
    errForm = false;
    
    activatePlan:boolean;
    accountValidated:boolean;

    constructor(private storageService: StorageService,
                private paymentService: PaymentsService,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private validationService: ValidationService) {}

    ngOnInit(): void {
        //this.activatePlan = true;       
        this.loadSubcriptionType();        
        this.schoolTransaction.subscriptionType = "SUBSCRIPTION";        
        this.loadPaymentOptions();
        this.loadPaymentTypes();
    }

    loadSubcriptionType() {
        this.storageService.get('accountValidated').then((response:any) => {
            this.accountValidated = response;
        });
        this.storageService.get('subscriptionType').then((response:any) => {
            this.subscriptionType = response;
            //console.log(this.subscriptionType);
            //if (this.subscriptionType == "SUBSCRIPTION" &&  !this.accountValidated ) {this.activatePlan = true;}
        });
    }

    clearInputs() {
        this.schoolTransaction = new SchoolTransaction();
        this.fileToUpload = null;
    }

    loadPaymentOptions() {
        this.paymentService.getAdminPaymentOption().then((response:any) => {
            this.paymentOptions = response.result;
            this.selectedPaymentMethod = this.paymentOptions[0];
            this.selectPaymentMethod();
            this.paymentOptions = _.map(this.paymentOptions, function (value) {
                return {
                    label: value.name,
                    value: value,
                };
            });
        });
    }

    loadPaymentTypes() {
        this.paymentService.getPaymentTypeOption().then((response:any) => {
            this.subscriptionOption = response.result;

            this.selectedPaymentType = this.subscriptionOption[0];
            this.selectPaymentType();
            this.subscriptionOption = _.map(this.subscriptionOption, function (value) {
                return {
                    label: value.name +" ( " + value.amount +" PHP )",
                    value: value,
                };
            });
        });
    }

    selectPaymentMethod() {
        this.schoolTransaction.paymentMethodCode = this.selectedPaymentMethod.code;
        this.paymentMethodReadOnly = this.selectedPaymentMethod.name;
    }

    selectPaymentType() {
        //console.log(this.selectedPaymentType)
        this.schoolTransaction.paymentTypeCode = this.selectedPaymentType.code;
        this.schoolTransaction.paymentDiscountAmount = this.selectedPaymentType.discountAmount;
    }

    handleFileInput(file) {
        this.fileToUpload = file.item(0);;
    }

    paymentConfirmation(record) {
        this.record = { ...record };
        this.submitted = false;
        this.recordDialog = true;

        if(!this.validationService.validateDrivingSchoolPayment(this.schoolTransaction)) {
            this.errForm = true;
            this.recordDialog = false;
            return;
        }
    }

    sendTransaction() {
        this.submitted = true;
        //console.log(this.schoolTransaction)
        //console.log(this.fileToUpload)

        const formData = new FormData();
        if(this.fileToUpload) {
            formData.append(
                'file',
                JSON.stringify(this.fileToUpload)
              );
              formData.append('object', JSON.stringify(this.schoolTransaction));
              //console.log(JSON.stringify(this.schoolTransaction));
              this.paymentService
              .schoolPaymentTransctionWithAttachment(formData)
              .then((response: any) => {
                  this.submitted = false;
                  this.recordDialog = false;
                  this.clearInputs();
                  this.messageService.add({
                      severity: "success",
                      summary: "Payment has been successfully submitted.",
                      detail: "",
                      life: 3000,
                  });
              })
              .catch((err) => {
                  this.submitted = false;
                  this.messageService.add({
                      severity: "error",
                      summary: "Error",
                      detail: "Error in Loading Data Please Contact Administrator",
                      life: 3000,
                  });
              });
        } else {
              formData.append('object', JSON.stringify(this.schoolTransaction));
              //console.log(JSON.stringify(this.schoolTransaction));
              this.paymentService
              .schoolPaymentTransction(formData)
              .then((response: any) => {
                  this.submitted = false;
                  this.recordDialog = false;
                  this.clearInputs();
                  this.messageService.add({
                      severity: "success",
                      summary: "Payment has been successfully submitted.",
                      detail: "",
                      life: 3000,
                  });
              })
              .catch((err) => {
                  this.submitted = false;
                  this.messageService.add({
                      severity: "error",
                      summary: "Error",
                      detail: "Error in Loading Data Please Contact Administrator",
                      life: 3000,
                  });
              });
        }
       

      
    }

    hideDialog() {
        this.recordDialog = false;
        this.submitted = false;
    }
}
