// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
    production: false,
    cmsServiceURL:
        "https://saferoadsph-reports-service-legacy.azurewebsites.net/api/v1",
    userServiceURL: "https://saferoadsph-user-service-legacy.azurewebsites.net/api/v1",
    courseServiceURL:
        "https://saferoadsph-module-service-legacy.azurewebsites.net/api/v1",
    paymentServiceURL:
        "https://saferoadsph-payment-service-legacy.azurewebsites.net/api/v1",
    reportServiceURL:
        "https://saferoadsph-reports-service-legacy.azurewebsites.net/api/v1", 
    luxandToken: "df16be834f434cd787f8064b2d41a930", // saferoad account
    //qrCodeCreateAccountURL: "https://saferoads.ph/create-account-with-dscode/",
    //branchQRCodeCreateAccountURL: "https://saferoads.ph/create-account-with-branchcode/",
    //url: 'https://saferoads.ph',
    qrCodeCreateAccountURL: "https://saferoads.ph/create-account-with-dscode/",
    branchQRCodeCreateAccountURL: "https://saferoads.ph/create-account-with-branchcode/",
    url: 'https://saferoads.ph',
};
