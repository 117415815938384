import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { PaymentsService } from "src/app/saferoadsph/services/payments.service";
import { UsersService } from "src/app/saferoadsph/services/users.service";

@Component({
    selector: "app-billing-ds-student",
    templateUrl: "./billing-ds-student.component.html",
    styleUrls: ["./billing-ds-student.component.scss"],
    providers: [MessageService] 
})
export class BillingDsStudentComponent implements OnInit {
    billing;    
    selectedBill;
    selectedBills: any[] = [];
    students =[];
    submitted = false;

    constructor(private paymentService: PaymentsService,
                private userService: UsersService,
                private messageService: MessageService) {}

    ngOnInit(): void {
        this.loadAllInvoices();
    }

    loadAllInvoices() {
        this.paymentService.getAllInvoiceForBilling().then((response:any)=> {

            this.billing = response.result;
            //console.log(this.billing)
        }).catch(err => {
            this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Error in Loading Data Please Contact Administrator",
                life: 3000,
            });
        })
    }

    createInvoice(school) {
        this.selectedBill = school;
    }

    cancel() {
        this.selectedBill = null;
        this.selectedBills = [];
    }

    save() {        
        var data = this.prepareData();
        //console.log(data)
        this.submitted = true;
        this.paymentService.createBillingInvoice(data).then((response:any) => {
            this.submitted = false;
            this.loadAllInvoices();
            this.selectedBill = null;
            this.selectedBills = [];
            this.messageService.add({
                severity: "success",
                summary: "Saving was successful.",
                detail: "",
                life: 3000,
            });
        }).catch(err => {
            this.submitted = false;
            this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Error in Loading Data Please Contact Administrator",
                life: 3000,
            });
        })
    }

    getTotal(selectedBill: any[]) {
        var price = 0;
        selectedBill.forEach((res) => {
            price = price + res.paymentModuleCourseFee;
        });
        return price;
    }

    prepareData() {
        this.selectedBills.forEach((res) => {
            this.students.push(
                {
                    userPaymentTransId: res.userPaymentTransId,
                    userProfileId: res.userProfileId,
                    courseId: res.courseId,
                    courseName: res.courseName
                }
            )
        });

        var data = {
            dsProfileId: this.selectedBill.dsProfileId,
            students: this.students
        }

        return data;
    }
}
