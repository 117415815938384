import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { UsersService } from "../../services/users.service";
import * as FileSaver from "file-saver";
import * as moment from "moment";
import _ from "lodash";
import { CmsService } from "../../services/cms.service";
import { Address, User } from "../../interfaces/User";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { WebcamImage } from "ngx-webcam";
import { ValidationService } from "../../services/validation.service";
import { Router } from "@angular/router";
import { StorageService } from '../../services/storage.service';

@Component({
    selector: "app-users",
    templateUrl: "./users.component.html",
    styleUrls: ["./users.component.scss"],
    providers: [MessageService, ConfirmationService],
})
export class UsersComponent implements OnInit {
    loading: boolean = true;
    recordDialog: boolean;
    passwordDialog: boolean;
    pictureDialog: boolean;
    records: User[];
    record: User;
    selectedRecords: User[];
    submitted: boolean;
    statuses: any[];
    state: string = "new";
    webcamImage: WebcamImage = null;
    noPrivate: any;
    invalidEmail = false;

    cols: any[];
    exportColumns: any[];
    selectedGender;
    selectedUser;
    isDisabled = false;
    submitting = false;
    existingEmail = false;
    resetting = false;
    imageSrc;
    imageFile;
    uploadingPhoto = false;
    accountValidated;

    genderOptions = [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
    ];

    userTypeOptions = [
        { label: "Student Permit", value: "STUDENT" },
        { label: "Non Professional", value: "NON_PRO" },
        { label: "Professional", value: "PRO" },
    ];

    constructor(
        private userService: UsersService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private cmsService: CmsService,
        private validationService: ValidationService,
        private router: Router,
        private storageService: StorageService
    ) {}

    ngOnInit(): void {
        this.loadAccountValidated();
        this.invalidEmail = false;
        this.getAllUsers();
        this.getAllRegions();

        // Exporting Records
        this.cols = [
            { field: "fullName", header: "Name" },
            { field: "gender", header: "Gender" },
            { field: "mobile", header: "Mobile" },
            { field: "status", header: "Status" },
        ];

        this.exportColumns = this.cols.map((col) => ({
            title: col.header,
            dataKey: col.field,
        }));
    }

    loadAccountValidated() {
        this.storageService.get('accountValidated').then((response:any) => {
            this.accountValidated = response;
        })
    }

    getAllUsers() {
        this.userService.getAllUsersByDrivingSchool().then((res: any) => {
            if (res.statusCode == 200) {
                this.records = res.result;
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            }
            this.loading = false;
        });
    }

    convertDate(date) {
        return moment(new Date(date)).format("YYYY-MM-DD");
    }

    openNew() {
        this.submitted = false;
        this.recordDialog = true;
        this.state = "new";
        this.isDisabled = false;
        this.record = {};
        this.record.address = {};

        // Address
        this.provinces = [];
        this.cities = [];
        this.record.address.province = null;
        this.record.address.city = null;

        // Initial Value
        this.record.gender = "Male";
        this.record.userType = "SCHOOL";
    }

    editRecord(record: any) {
        record.birthDate = new Date(this.convertDate(record.birthDate));
        this.record = record;
        this.recordDialog = true;
        this.state = "edit";
        this.submitted = false;
        this.isDisabled = true;
    }
    payment(record: any) {
        this.router.navigate([
            "/school/payments/students-payment/" + record.id,
        ]);
    }

    validateEMail(email) {
        return this.validationService.validateEmail(email);
    }

    validatePassword(record) {
        return record.password == record.confirmPassword;
    }

    addRecord() {
        this.submitted = true; // para sa validation
        var transformRecord: User = this.record; // transformation kung meron customized value

        if (
            this.validationService.validateUser(transformRecord) &&
            this.validatePassword(transformRecord) &&
            this.validateEMail(transformRecord.email)
        ) {
            this.submitting = true; // para sa loading
            this.userService
                .addStudent(transformRecord)
                .then((res: any) => {
                    if (res.statusCode == 409) {
                        // email is already exists
                        this.existingEmail = true;
                        this.submitting = false;
                    }

                    if (res.statusCode == 200) {
                        this.messageService.add({
                            severity: "success",
                            summary: "Successful",
                            detail: "Student Saved",
                            life: 3000,
                        });
                        this.hideDialog();
                        this.getAllUsers();
                        this.submitting = false;
                    }
                })
                .catch((err) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Error in Saving Student.",
                        life: 3000,
                    });
                    this.submitting = false;
                });
        } else {
            this.submitting = false;
        }
    }

    saveRecord() {
        //console.log(this.record);
        this.submitted = true; // para sa validation
        var transformRecord: User = this.record;

        if (this.validationService.validateUser(transformRecord)) {
            this.submitting = true; // para sa loading

            this.userService
                .editStudentProfile(transformRecord.id, transformRecord)
                .then((res) => {
                    this.submitting = false;
                    this.messageService.add({
                        severity: "success",
                        summary: "Successful",
                        detail: "User Saved",
                        life: 3000,
                    });
                    this.hideDialog();
                    this.getAllUsers();
                })
                .catch((err) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Error in Saving User.",
                        life: 3000,
                    });
                    this.submitting = false;
                });
        }
    }

    resetPassword(record) {
        this.resetting = true;
        this.userService
            .resetpasswordByDrivingSchool({ email: record.email })
            .then((res) => {
                this.messageService.add({
                    severity: "success",
                    summary: "Password has been succesfully Reset",
                    detail: "New Password has been sent to : " + record.email,
                    life: 5000,
                });
                this.resetting = false;
            })
            .catch((err) => {
                this.resetting = false;
            });
    }

    editPassword(record: any) {
        this.record = { ...record };
        this.passwordDialog = true;
        this.state = "password";
    }
    uploadPhoto(record: any) {
        this.record = { ...record };
        this.pictureDialog = true;
        this.state = "photo";
        this.noPrivate = null;
        this.webcamImage = null;
        this.imageSrc = null;
    }

    submitPhoto() {
        var img;
        this.uploadingPhoto = true;
        if (this.noPrivate != null) {
            const imageName = moment(new Date()).format("YYYYMMDDHms") + ".png";
            const imageBlob = this.dataURItoBlob(this.noPrivate.imageAsBase64);
            const imageFile = new File([imageBlob], imageName, {
                type: "image/png",
            });
            img = imageFile;
        } else {
            img = this.imageFile[0];
        }

        const formData = new FormData();
        formData.append("studentUserId", this.record.id.toString());
        formData.append("file", img);

        this.userService
            .uploadStudentPhoto(formData)
            .toPromise()
            .then((res) => {
                this.getAllUsers();
                this.uploadingPhoto = false;
                this.messageService.add({
                    severity: "success",
                    summary: "Successful",
                    detail: "Photo has been Uploaded.",
                    life: 3000,
                });
                this.pictureDialog = false;
            })
            .catch((err) => {
                this.uploadingPhoto = false;
            });
    }

    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: "image/png" });
        return blob;
    }

    onFileChange(event) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            this.imageFile = event.target.files;
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.imageSrc = reader.result as string;
                this.webcamImage = null;
                this.noPrivate = null;
            };
        }
    }

    deleteRecord(record: any) {
        this.confirmationService.confirm({
            message: "Are you sure you want to delete " + record.fullName + "?",
            header: "Confirm",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                this.records = this.records.filter(
                    (val) => val.id !== record.id
                );
                this.record = {};
                this.messageService.add({
                    severity: "success",
                    summary: "Successful",
                    detail: "User Deleted",
                    life: 3000,
                });
            },
        });
    }

    hideDialog() {
        this.recordDialog = false;
        this.submitted = false;
    }

    exportPdf() {
        const doc = new jsPDF();
        //doc.autoTable(this.exportColumns, this.records);
        autoTable(doc, { html: "#my-table" });
        doc.save("users.pdf");
        /*import("jspdf").then((jsPDF) => {
          import("jspdf-autotable").then((x) => {
              const doc = new jsPDF.default(0, 0);
              doc.autoTable(this.exportColumns, this.records);
              doc.save("students.pdf");
          });
      });*/
    }

    exportExcel() {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(this.records);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, "students");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(
            data,
            fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
    }

    // Fucking Webcam
    // latest snapshot

    handleImage(webcamImage: WebcamImage) {
        this.webcamImage = webcamImage;
        this.noPrivate = webcamImage;
    }
    // Get Address Settings
    retake() {
        this.webcamImage = null;
        this.noPrivate = null;
    }
    regions: any[];
    provinces: any[];
    cities: any[];

    getAllRegions() {
        this.cmsService.getRegions().then((res: any) => {
            if (res.statusCode == 200) {
                this.regions = res.result;
                this.regions.push({ regionName: null });
                this.regions = _.map(this.regions, function (value) {
                    return {
                        label: value.regionName ? value.regionName : "",
                        value: value.regionName,
                    };
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            }
        });
    }

    changeRegion($e) {
        this.cmsService.getProvincesByRegionName($e.value).then((res: any) => {
            if (res.statusCode == 200) {
                this.provinces = res.result;
                this.provinces.push({ stateName: null });
                this.provinces = _.map(this.provinces, function (value) {
                    return {
                        label: value.stateName ? value.stateName : "",
                        value: value.stateName,
                    };
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            }
        });
    }

    changeProvince($e) {
        this.cmsService.getCitiesByProvinceName($e.value).then((res: any) => {
            if (res.statusCode == 200) {
                this.cities = res.result;
                this.cities.push({ cityName: null });
                this.cities = _.map(this.cities, function (value) {
                    return {
                        label: value.cityName ? value.cityName : "",
                        value: value.cityName,
                    };
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            }
        });
    }

    loadAddressOfStudent(record) {
        this.cmsService
            .getProvincesByRegionName(record.address.region)
            .then((res: any) => {
                if (res.statusCode == 200) {
                    this.provinces = res.result;
                    this.provinces = _.map(this.provinces, function (value) {
                        return {
                            label: value.stateName,
                            value: value.stateName,
                        };
                    });
                    this.cmsService
                        .getCitiesByProvinceName(record.address.province)
                        .then((res: any) => {
                            if (res.statusCode == 200) {
                                this.cities = res.result;
                                this.cities = _.map(
                                    this.cities,
                                    function (value) {
                                        return {
                                            label: value.cityName,
                                            value: value.cityName,
                                        };
                                    }
                                );
                                record.address.city = record.address.city;
                            }
                        });
                }
            });
    }
}
