import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { SchoolAccountPaymentOption } from "../../interfaces/Payment";
import { PaymentsService } from "../../services/payments.service";
import { StorageService } from "../../services/storage.service";

@Component({
    selector: "app-payment-options",
    templateUrl: "./payment-options.component.html",
    styleUrls: ["./payment-options.component.scss"],
    providers: [MessageService, ConfirmationService],
})
export class PaymentOptionsComponent implements OnInit {
    loading: boolean = true;
    recordDialog: boolean;
    photoDialog: boolean;
    records: SchoolAccountPaymentOption[];
    record: SchoolAccountPaymentOption;
    systemPaymentOption;
    selectedRecords: SchoolAccountPaymentOption[];
    submitted: boolean;
    statuses: any[];
    state: string = "new";
    dsProfileId;
    accountValidated;
    fileToUpload;

    constructor(
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private paymentService: PaymentsService,
        private storageService: StorageService
    ) {}

    ngOnInit(): void {
        this.loadAccountValidated();
        this.loadPaymentOptions();
        this.getSystemPaymentMethod();
        this.storageService.get("dsProfileId").then((response) => {
            this.dsProfileId = response;
        });
    }

    loadAccountValidated() {
        this.storageService.get('accountValidated').then((response:any) => {
            this.accountValidated = response;
        })
    }

    loadPaymentOptions() {
        this.paymentService.getSchoolPaymentOption().then((response: any) => {
            this.records = response.result;
            this.loading = false;
        });
    }

    openNew() {
        this.record = new SchoolAccountPaymentOption();
        this.submitted = false;
        this.recordDialog = true;
        this.state = "new";
        this.record.dsProfileId = this.dsProfileId;
    }

    editRecord(record: any) {
        this.record = { ...record };
        this.recordDialog = true;
        this.state = "edit";
    }

    photoRecord(record: any) {
        this.record = { ...record };
        this.photoDialog = true;
        this.state = "photo";
    }

    updatePaymentOption() {
        this.submitted = true;
        this.paymentService
            .editSchoolPaymentOption(this.record)
            .then((response: any) => {
                this.submitted = false;
                this.recordDialog = false;
                this.loadPaymentOptions();
                this.messageService.add({
                    severity: "success",
                    summary: "Saving was successful.",
                    detail: "",
                    life: 3000,
                });
                this.photoDialog = false;
            })
            .catch((err) => {
                this.submitted = false;
                this.recordDialog = false;
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            });
    }

    handleFileInput(file) {
        this.fileToUpload = file.item(0);
    }

    updatePaymentOptionWithPhoto() {
        this.submitted = true;
        const formData = new FormData();
      
        if(this.fileToUpload) {
            formData.append("file", this.fileToUpload);
            formData.append("object", JSON.stringify(this.record));
            //console.log(this.record.id)
            //console.log(this.fileToUpload);
             this.paymentService
            .updateSchoolPaymentOptionWithPhoto(formData, this.record.id)
            .then((response: any) => {
                //console.log(this.record.id)
                //console.log(this.fileToUpload);
                this.submitted = false;
                this.recordDialog = false;
                this.messageService.add({
                    severity: "success",
                    summary: "Payment Option Photo has been successfully submitted.",
                    detail: "",
                    life: 3000,
                });
            })
            .catch((err) => {
                this.submitted = false;
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            }); 
        } else {

                this.submitted = false;
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
        }

    }

    addPaymentOption() {
        this.submitted = true;
        
        this.record.code = this.record.name.replace(" ", "_");

        this.paymentService
            .addSchoolPaymentOption(this.record)
            .then((response: any) => {
                this.submitted = false;
                this.recordDialog = false;
                this.loadPaymentOptions();
                this.messageService.add({
                    severity: "success",
                    summary: "Saving was successful.",
                    detail: "",
                    life: 3000,
                });
                this.recordDialog = false;
            })
            .catch((err) => {
                this.submitted = false;
                this.recordDialog = false;
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            });
    }

    deleteRecord(record) {
        //console.log(record);
        this.paymentService
            .deletePaymentOption(record.id)
            .then((res) => {
                this.loadPaymentOptions();
                this.messageService.add({
                    severity: "success",
                    summary: "Delete was successful.",
                    detail: "",
                    life: 3000,
                });
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            });
    }

    getSystemPaymentMethod() {
        this.paymentService.getSysPaymentOption().then((response: any) => {
            //console.log(response.result);
            this.systemPaymentOption = response.result;
        });
    }

    hideDialog() {
        this.recordDialog = false;
        this.submitted = false;
        this.loadPaymentOptions();
    }

    hidePhotoDialog() {
        this.photoDialog = false;
        this.submitted = false;
        this.loadPaymentOptions();
    }
}
