export class UserName {
    studentId?: Number;
    email?: string;
    lastName?: string;
    firstName?: string;
    middleName?: string;
    extensionName?: string;
}

export class UserEmail {
    studentId?: Number;
    oldEmail?: string;
    newEmail?: string;
}

export class UserDelete {
    studentId?: Number;
}

export class UserArchived {
    studentId?: Number;
}

export class User {
    accessName?: string;
    address?: Address;
    birthDate?: string;
    birthPlace?: string;
    citizenship?: string;
    civilStatus?: string;
    createdAt?: string;
    createdBy?: string;
    customId?: string;
    deletedAt?: string;
    deletedBy?: string;
    dsCustomId?: string;
    dsProfileId?: Number;
    email?: string;
    emailConfirmed?: string;
    extensionName?: string;
    firstName?: string;
    fullName?: string;
    gender?: string;
    id?: Number;
    luxandId?: Number;
    lastName?: string;
    middleName?: string;
    mobile?: string;
    password?: string;
    photo?: string;
    race?: string;
    referralCode?: string;
    school?: School;
    status?: string;
    token?: string;
    tokenReset?: string;
    tokenValidate?: string;
    updatedAt?: string;
    updatedBy?: string;
    userAccessId?: string;
    userType?: string;
    subscriptionType?: string;
    confirmPassword?: string;
    moduleStatus?: string;
    courseId?:Number;
}

export class Address {
    barangay?: string;
    block?: string;
    city?: string;
    country?: string;
    latitude?: string;
    longitude?: string;
    postalCode?: string;
    province?: string;
    region?: string;
    street?: string;
}

export class School {
    companyEmail?: string;
    companyName?: string;
    contactPerson?: string;
    customId?: string;
    instructorFullName?: string;
    instructorLTOAccreditationNo?: string;
    latitude?: string;
    logo?: string;
    longitude?: string;
    ltoAccreditationNo?: string;
    mobile?: string;
    telephone?: string;
    address?: Address;
    referralCode?: string;
    fullAddress?: string;
}

export class Login {
    email?: string;
    password?: string;
}

export class ChangePassword {
    oldPassword?: string;
    newPassword?: string;
}

export interface UserPhoto {
    filepath: string;
    webviewPath: string;
}

export interface TransferStudentAccount {
    email: string;
    dsProfileId: Number;
}
