import { Component, OnInit } from '@angular/core';
import { CmsService } from "../../services/cms.service";
import { File } from "../../interfaces/Document";
import { ConfirmationService, MessageService } from "primeng/api";
import { UsersService } from "../../services/users.service";
import { StorageService } from "../../services/storage.service";

@Component({
  selector: 'app-document-approval',
  templateUrl: './document-approval.component.html',
  styleUrls: ['./document-approval.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DocumentApprovalComponent implements OnInit {
  recordDialog: boolean;
  records: File[];
  record: File;
  selectedRecords: File[];
  selectedSchools:File[];
  submitted: boolean;
  statuses: any[];
  state: string = "new";
  dsProfileId;
  fileToUpload;
  documentName;
  schools;
  loading: boolean;
  schoolCompanyName;
  documentDialog:boolean;

  optionStatus = [
    { label: "APPROVED", value: "APPROVED" }];

  constructor(        
    private cmsService: CmsService,
    private userService: UsersService,
    private confirmationService: ConfirmationService,
    private storageService: StorageService,
    private messageService: MessageService) { }

  ngOnInit(): void {
      this.getAllDrivingSchools();
  }

  getAllDrivingSchools() {
    this.loading = true;
    this.userService.getAllDrivingSchools().then((res: any) => {
        this.schools = res.result;
        //console.log(this.schools);
        this.loading = false;
    });
}

    loadSchoolDocs(profileId) {
        this.userService
            .getSchoolDocs(profileId)
            .then((response: any) => {
                if (response.statusCode == 200) {
                    this.records = response.result;
                    //console.log(this.records)
                } else {
                    this.records = [];
                }
            });
    }

  getUploadedDocuments() {
      this.cmsService.getUploadedDocuments().then((res) => {
          this.records = res;
      });
  }

  editRecord(record: any) {
    this.record = record;
    this.documentDialog = true;
    this.state = "edit";
}

  selectRecord(school: any) {
      //console.log(school.id);
      this.dsProfileId = school.id;
      this.schoolCompanyName = school.companyName;
    this.loadSchoolDocs(school.id);
   
}

  openNew() {
      this.record = new File();
      this.submitted = false;
      this.recordDialog = true;
      this.state = "new";
  }

  hideDialog() {
      this.recordDialog = false;
      this.submitted = false;
  }

  hideDocumentDialog() {
    this.documentDialog = false;
}

  deleteRecord(record) {
    this.confirmationService.confirm({
        message: "Are you sure you want to delete " + record.fullName + "?",
        header: "Confirm",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
            this.records = this.records.filter(
                (val) => val.id !== record.id
            );

      this.userService
          .deleteDocs(record.id)
          .then((response: any) => {
              this.messageService.add({
                  severity: "success",
                  summary: "Delete record was successful.",
                  detail: "",
                  life: 3000,
              });
              this.loadSchoolDocs(this.dsProfileId);
          })
          .catch((err) => {
              this.submitted = false;
              this.messageService.add({
                  severity: "error",
                  summary: "Error",
                  detail: "Error in Loading Data Please Contact Administrator",
                  life: 3000,
              });
          });
        },
    });
  }

  approveRecord(record: any) {
    this.confirmationService.confirm({
        message: "Are you sure you want to Approve " + record.fileDesc + "?",
        header: "Confirm",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
            this.records = this.records.filter(
                (val) => val.id !== record.id
            );

    const formData = new FormData();
      this.userService
          .approveDocs(formData,record.id)
          .then((response: any) => {
              this.messageService.add({
                  severity: "success",
                  summary: "Approved record was successful.",
                  detail: "",
                  life: 3000,
              });
              this.documentDialog = false;
              this.loadSchoolDocs(this.dsProfileId);
          })
          .catch((err) => {
              this.submitted = false;
              this.messageService.add({
                  severity: "error",
                  summary: "Error",
                  detail: "Error in Loading Data Please Contact Administrator",
                  life: 3000,
              });
          });  
        },
    });    
  }

  handleFileInput(file) {
      this.fileToUpload = file.item(0);
  }

  uploadDocument() {
      const formData = new FormData();
      formData.append("file", this.fileToUpload);
      formData.append("documentName", this.documentName);
      this.submitted = true;
      this.userService
          .uploadSchoolDocs(formData)
          .then((response: any) => {
              this.submitted = false;
              this.recordDialog = false;
              this.clearInputs();
              this.loadSchoolDocs(4);
              this.messageService.add({
                  severity: "success",
                  summary: "Upload file was successful.",
                  detail: "",
                  life: 3000,
              });
          })
          .catch((err) => {
              this.submitted = false;
              this.messageService.add({
                  severity: "error",
                  summary: "Error",
                  detail: "Error in Loading Data Please Contact Administrator",
                  life: 3000,
              });
          });
  }

  clearInputs() {
      this.fileToUpload = null;
  }
}
