import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import {
    PaymentMatrix,
    SchoolAccountPaymentOption,
    Verification,
} from "../interfaces/Payment";

@Injectable({
    providedIn: "root",
})
export class PaymentsService {
    constructor(private httpClient: HttpClient) {}

    getAllInvoices(dsProfileId) {
        return this.httpClient
            .get(
                environment.paymentServiceURL +
                    "/invoice-header/list?dsProfileId=" +
                    dsProfileId
            )
            .toPromise();
    }

    getAllReceipts() {
        return this.httpClient
            .get("assets//saferoadsph/receipt.json")
            .toPromise();
    }

    getAllBill() {
        return this.httpClient
            .get("assets//saferoadsph/billing.json")
            .toPromise();
    }

    // School
    getSchoolTransactions() {
        return this.httpClient
        .get(
            environment.paymentServiceURL +
                "/payment/school-payment-list"
        )
        .toPromise();
    }

    addSchoolMatrix(matrix) {
        return this.httpClient
            .post(
                environment.paymentServiceURL +
                    "/price-matrix/school-price-matrix",
                matrix
            )
            .toPromise();
    }

    editSchoolMatrix(matrix, id) {
        return this.httpClient
            .put(
                environment.paymentServiceURL +
                    "/price-matrix/school-price-matrix/" +
                    id,
                matrix
            )
            .toPromise();
    }

    deleteSchoolMatrix(id) {
        return this.httpClient
        .delete(
            environment.paymentServiceURL +
                "/price-matrix/school-price-matrix/" +
                id,
        )
        .toPromise();
    }

    getSchoolMatrix() {
        return this.httpClient
            .get(
                environment.paymentServiceURL +
                    "/price-matrix/school-price-matrix"
            )
            .toPromise();
    }

    getSchoolPaymentOption() {
        return this.httpClient
            .get(environment.paymentServiceURL + "/payment-method/list")
            .toPromise();
    }

    getSysPaymentOption() {
        return this.httpClient
            .get(
                environment.paymentServiceURL +
                    "/payment-method/getList-Admin-Payment-Method"
            )
            .toPromise();
    }
    addSysPaymentOption(data) {
        return this.httpClient
            .post(
                environment.paymentServiceURL +
                    "/payment-method/create-Admin-Payment-Method",
                data
            )
            .toPromise();
    }

    updateSchoolPaymentOptionWithPhoto(formData: FormData,id) {
        return this.httpClient
            .post(
                environment.paymentServiceURL +
                    "/payment-method/upload-DrivingSchool-Payment-Method-Image/"+id,
                formData
            )
            .toPromise();
    }

    editSysPaymentOption(data) {
        return this.httpClient
            .put(
                environment.paymentServiceURL +
                    "/payment-method/update-Admin-Payment-Method/" +
                    data.id,
                data
            )
            .toPromise();
    }

    deleteSysPaymentOption(id) {
        return this.httpClient
            .delete(
                environment.paymentServiceURL +
                    "/payment-method/delete-Admin-Payment-Method/" +
                    id
            )
            .toPromise();
    }

    addSchoolPaymentOption(data: SchoolAccountPaymentOption) {
        return this.httpClient
            .post(
                environment.paymentServiceURL +
                    "/payment-method/create-DrivingSchool-Payment-Method",
                data
            )
            .toPromise();
    }

    editSchoolPaymentOption(data: SchoolAccountPaymentOption) {
        return this.httpClient
            .put(
                environment.paymentServiceURL +
                    "/payment-method/update-DrivingSchool-Payment-Method/" +
                    data.id,
                data
            )
            .toPromise();
    }

    deletePaymentOption(id) {
        return this.httpClient
            .delete(
                environment.paymentServiceURL +
                    "/payment-method/delete-DrivingSchool-Payment-Method/" +
                    id
            )
            .toPromise();
    }

    getSchoolStudentVerification() {
        return this.httpClient
            .get(
                environment.paymentServiceURL + "/validate/student-transactions"
            )
            .toPromise();
    }

    getSchoolStudentVerificationPending() {
        return this.httpClient
            .get(
                environment.paymentServiceURL + "/validate/student-transactions-pending"
            )
            .toPromise();
    }

    getSchoolStudentVerificationApproved() {
        return this.httpClient
            .get(
                environment.paymentServiceURL + "/validate/student-transactions-approved"
            )
            .toPromise();
    }

    studentPaymentCashTransction(formData: FormData) {
        return this.httpClient
            .post(
                environment.paymentServiceURL +
                    "/validate/student-payment-cash",
                formData
            )
            .toPromise();
    }

    studentPaymentCashTransctionWithAttachment(formData: FormData) {
        return this.httpClient
            .post(
                environment.paymentServiceURL +
                    "/validate/student-payment-cash-with-attachment",
                formData
            )
            .toPromise();
    }

    validatePayment(validation) {
        return this.httpClient
            .post(
                environment.paymentServiceURL +
                    "/validate/validate-student-payment",
                validation
            )
            .toPromise();
    }

    // Admin
    getAllInvoiceForBilling() {
        return this.httpClient
        .get(
            environment.paymentServiceURL +
                "/payment/due-for-billing"
        )
        .toPromise();
    }

    getInvoiceDetails(invocieId) {
        return this.httpClient
        .get(
            environment.paymentServiceURL +
                "/payment/get-invoice-details/"+invocieId
        )
        .toPromise();
    }

    createBillingInvoice(invoice) {
        return this.httpClient
        .post(
            environment.paymentServiceURL +
                "/payment/create-invoice-billing", invoice
        )
        .toPromise();
    }

    getAllStudentTransactions(schoolId) {
        return this.httpClient
        .get(
            environment.paymentServiceURL +
                "/validate/student-transactions?drivingSchoolId="+schoolId
        )
        .toPromise();
    }

    getCourseFees() {
        return this.httpClient
            .get(environment.paymentServiceURL + "/price-matrix/course-fees")
            .toPromise();
    }

    getAllCourseFees() {
        return this.httpClient
            .get(environment.paymentServiceURL + "/course-fee/getList-Course-Fee")
            .toPromise();
    }

    createCourseFee(data) {
        return this.httpClient
        .post(
            environment.paymentServiceURL +
                "/course-fee/create-Course-Fee",
                data
        )
        .toPromise();
    }

    updateCourseFee(data) {
        return this.httpClient
        .put(
            environment.paymentServiceURL +
                "/course-fee/update-Course-Fee/"+data.id,
                data
        )
        .toPromise();
    }

    deleteCourseFee(id) {
        return this.httpClient
        .delete(
            environment.paymentServiceURL +
                "/course-fee/delete-Course-Fee/" +
                id
        )
        .toPromise();
    }

    getAdminPaymentOption() {
        return this.httpClient
            .get(environment.paymentServiceURL + "/payment-method/admin-list")
            .toPromise();
    }

    getPaymentTypeOption() {
        return this.httpClient
            .get(environment.paymentServiceURL + "/price-matrix/payment-types")
            .toPromise();
    }

    schoolPaymentTransctionWithAttachment(formData: FormData) {
        return this.httpClient
            .post(
                environment.paymentServiceURL +
                    "/payment/driving-school-payment-with-attachment",
                formData
            )
            .toPromise();
    }

    schoolPaymentTransction(formData: FormData) {
        return this.httpClient
            .post(
                environment.paymentServiceURL +
                    "/payment/driving-school-payment",
                formData
            )
            .toPromise();
    }

    schoolPaymentBillingTransction(formData: FormData) {
        return this.httpClient
            .post(
                environment.paymentServiceURL +
                    "/payment/driving-school-payment-billing",
                formData
            )
            .toPromise();
    }

    schoolPaymentBillingTransctionWithAttachment(formData: FormData) {
        return this.httpClient
            .post(
                environment.paymentServiceURL +
                    "/payment/driving-school-payment-billing-with-attachment",
                formData
            )
            .toPromise();
    }

    getAdminTransactions() {
        return this.httpClient
        .get(
            environment.paymentServiceURL +
                "/payment/admin-payment-list"
        )
        .toPromise();
    }

    getAdminTransactionsStatusPaymentType(status, paymentType) {
        return this.httpClient
        .get(
            environment.paymentServiceURL +
                "/payment/admin-payment-list-by-status-payment-type/"+status+"/"+paymentType
        )
        .toPromise();
    }

    getAdminTransactionsStatusPaymentTypeCode(status, paymentTypeCode) {
        return this.httpClient
        .get(
            environment.paymentServiceURL +
                "/payment/admin-payment-list-by-status-payment-type-code/"+status+"/"+paymentTypeCode
        )
        .toPromise();
    }

    validateSchoolTransaction(payment) {
        return this.httpClient
        .post(
            environment.paymentServiceURL +
                "/validate/admin-validate-ds-payment",
                payment
        )
        .toPromise();
    }

    getAllInvoiceHeaders() {
        return this.httpClient
        .get(
            environment.paymentServiceURL +
                "/invoice-header/list-all"
        )
        .toPromise();
    }

    getAllStudentWithInvoiceHeaders(id) {
        return this.httpClient
        .get(
            environment.paymentServiceURL +
                "/validate/student-transactions-billing?invoiceId="+id
        )
        .toPromise();
    }

    getAllPaymentTypes() {
        return this.httpClient
        .get(
            environment.paymentServiceURL +
                "/payment-type/getList-Payment-Type"
        )
        .toPromise();
    }

    getStudentPaymentTransactions(id) {
        return this.httpClient
        .get(
            environment.paymentServiceURL +
                "/validate/view-transaction/"+id
        )
        .toPromise();
    }

    getStudentPaymentTransactionsAdmin(id) {
        return this.httpClient
        .get(
            environment.paymentServiceURL +
                "/payment/student-payment-trans-admin/"+id
        )
        .toPromise();
    }

    createPaymentType(data) {
        return this.httpClient
        .post(
            environment.paymentServiceURL +
                "/payment-type/create-Payment-Type",
                data
        )
        .toPromise();
    }

    updatePaymentType(data) {
        return this.httpClient
        .put(
            environment.paymentServiceURL +
                "/payment-type/update-Payment-Type/"+data.id,
                data
        )
        .toPromise();
    }

    deletePaymentType(id) {
        return this.httpClient
        .delete(
            environment.paymentServiceURL +
                "/payment-type/delete-Payment-Type/" +
                id
        )
        .toPromise();
    }
}
