import { Component, OnInit } from "@angular/core";
import { File } from "../../interfaces/Document";
import { ConfirmationService, MessageService } from "primeng/api";
import { PaymentsService } from "../../services/payments.service";
import { StorageService } from "../../services/storage.service";
import * as FileSaver from "file-saver";

@Component({
    selector: "app-payment-receipts",
    templateUrl: "./payment-receipts.component.html",
    styleUrls: ["./payment-receipts.component.scss"],
    providers: [MessageService, ConfirmationService],
})
export class PaymentReceiptsComponent implements OnInit {
    loading: boolean = true;
    recordDialog: boolean;
    records: File[];
    record: File;
    selectedRecords: File[];
    submitted: boolean;
    statuses: any[];
    state: string = "new";
    dsProfileId;
    accountValidated;

    constructor(
        private paymentService: PaymentsService,
        private storageService: StorageService
    ) {}

    ngOnInit(): void {
        this.loadAccountValidated();
        this.storageService.get("dsProfileId").then((response: any) => {
            this.dsProfileId = response;
            this.getInvoiceHeaders();
        });
    }

    loadAccountValidated() {
        this.storageService.get('accountValidated').then((response:any) => {
            this.accountValidated = response;
        })
    }

    getInvoiceHeaders() {
        this.paymentService
            .getAllInvoices(this.dsProfileId)
            .then((response: any) => {
                this.records = response.result;
                this.loading = false;
                //console.log(this.records)
            });
    }

    openNew() {
        this.record = new File();
        this.submitted = false;
        this.recordDialog = true;
        this.state = "new";
    }

    exportExcel() {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(this.records);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, "student_receipts");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(
            data,
            fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
    }
}
