import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AdminPaymentTypeOption, CourseFee } from 'src/app/saferoadsph/interfaces/Payment';
import { PaymentsService } from 'src/app/saferoadsph/services/payments.service';

@Component({
  selector: 'app-billing-course-fee',
  templateUrl: './billing-course-fee.component.html',
  styleUrls: ['./billing-course-fee.component.scss'],
  providers: [MessageService]
})
export class BillingCourseFeeComponent implements OnInit {
  recordDialog: boolean;
  records:CourseFee[];
  record = new CourseFee();
  selectedRecords:CourseFee[];
  submitted: boolean;
  statuses: any[];
  state: string = "new";

  constructor(private paymentService: PaymentsService,
              private messageService: MessageService
              ) { }

  ngOnInit(): void {
    this.loadCourseFees();
  }

  loadCourseFees() {
    this.paymentService.getAllCourseFees().then((response:any) => {
        this.records = response.result;
        //console.log(this.records)
    }).catch(err => {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Error in Loading Data Please Contact Administrator",
        life: 3000,
    });
    })
  }

  hideDialog() {
    this.recordDialog = false;
    this.submitted = false;
  }

  editRecord(record: any) {
    this.record = { ...record };
    this.recordDialog = true;
    this.state = "edit";
  }

  openNew() {
    this.record = new CourseFee();
    //console.log(this.record)
    this.submitted = false;
    this.recordDialog = true;
    this.state = "new";
  }

  compute() {
    this.record.discountAmount = this.record.amount * (this.record.discountPercent/100);
    this.record.vatAmount = this.record.amount * (this.record.vatPercent/100);
    this.record.totalAmount = (this.record.amount - this.record.discountAmount) + this.record.vatAmount;
  }

  saveProduct() {
    this.submitted = true;
    //console.log(this.record)
    this.paymentService.createCourseFee(this.record).then((response:any) =>{ 
      this.loadCourseFees();
      this.submitted = false;
      this.recordDialog = false;
      this.messageService.add({
        severity: "success",
        summary: "Saving was successful.",
        detail: "",
        life: 3000,
    });
    }).catch(err => {
      this.submitted = false;
      this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
      });
    })
  }

  update() {
    //console.log(this.record)
    this.submitted = true;
    this.paymentService.updateCourseFee(this.record).then((response:any)=> {
        this.loadCourseFees();
        this.submitted = false;
        this.recordDialog = false;
        this.messageService.add({
          severity: "success",
          summary: "Saving was successful.",
          detail: "",
          life: 3000,
      });
    }).catch(err => {
      this.submitted = false;
      this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
      });
    })
  }

  deleteRecord(record) {
    //console.log(record);
    this.submitted = true;
    this.paymentService
        .deleteCourseFee(record.id)
        .then((res) => {
            this.loadCourseFees();
            this.submitted = false;
            this.messageService.add({
                severity: "success",
                summary: "Delete was successful.",
                detail: "",
                life: 3000,
            });
        })
        .catch((err) => {
            this.submitted = false;
            this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Error in Loading Data Please Contact Administrator",
                life: 3000,
            });
        });
    }
}
