import { Component, OnInit } from "@angular/core";
import { CourseService } from "src/app/saferoadsph/services/course.service";
import { DialogService } from "primeng/dynamicdialog";
import { CourseModalComponent } from "../../modals/course-modal/course-modal.component";
import { ModuleModalComponent } from "../../modals/module-modal/module-modal.component";
import { Course, Module, Topic } from "src/app/saferoadsph/interfaces/Course";
import { TopicModalComponent } from "../../modals/topic-modal/topic-modal.component";

@Component({
    selector: "app-courses-list",
    templateUrl: "./courses-list.component.html",
    styleUrls: ["./courses-list.component.scss"],
    providers: [DialogService],
})
export class CoursesListComponent implements OnInit {
    selectedCourse;
    courses;
    course;
    module;
    topic;
    loading: boolean;

    constructor(
        private courseService: CourseService,
        public dialogService: DialogService
    ) {}

    ngOnInit(): void {
        this.course = new Course();
        this.module = new Module();
        this.topic = new Topic();
        this.getAllCourses();
    }

    getAllCourses() {
        this.loading = true;
        this.courseService.getAllCourses().then((res: any) => {
            this.courses = res.result;
            this.loading = false;
        });
    }

    showCourseModal(course) {
        const ref = this.dialogService.open(CourseModalComponent, {
            header: "Course",
            width: "70%",
            data: {
                course: course,
            },
        });
    }

    showModuleModal(module) {
        const ref = this.dialogService.open(ModuleModalComponent, {
            header: "Module",
            width: "70%",
            data: {
                module: module,
            },
        });
    }

    showTopicModal(topic, topics) {
        const ref = this.dialogService.open(TopicModalComponent, {
            header: "Topic",
            width: "70%",
            data: {
                topic: topic,
                topics: topics,
            },
        });
    }
}
