import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
} from "@angular/common/http";
import { from, Observable, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { StorageService } from "../services/storage.service";

@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {
    constructor(private storageService: StorageService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return from(this.storageService.get("token")).pipe(
            switchMap((token) => {
                // if Not Login
                if (request.url.endsWith("login") == false) {
                    if (token) {
                        request = request.clone({
                            setHeaders: {
                                Authorization: `${token}`,
                            },
                        });
                    }
                }

                // this.showLoader();
                return next.handle(request).pipe(
                    map((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            if (request.url.endsWith("login") == true) {
                                if (event.headers.get("Authorization")) {
                                    this.storageService.set(
                                        "token",
                                        event.headers.get("Authorization")
                                    );
                                }
                            }
                        }
                        //  this.dismiss();
                        return event;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        if (error.status == 401) {
                        }

                        //this.dismiss();
                        return throwError(error);
                    })
                );
            })
        );
    }
}
