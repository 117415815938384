import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class CourseService {
    constructor(private httpClient: HttpClient) {}

    getAllCourses() {
        return this.httpClient
            .get(environment.courseServiceURL + "/course/courseList")
            .toPromise();
    }

    getAllQuestionaires() {
        return this.httpClient
            .get(environment.courseServiceURL + "/question/getAllQuestion")
            .toPromise();
    }

    getAllTopics() {
        return this.httpClient
            .get(environment.courseServiceURL + "/topic/getAllTopic")
            .toPromise();
    }

    getAllLanguages() {
        return this.httpClient
            .get(environment.courseServiceURL + "/language/getAllLanguage")
            .toPromise();
    }

    getStudentCertificate(courseId,profileId) {
        return this.httpClient
          .get(`${environment.courseServiceURL}/student/certificateByProfileId/` + courseId+'/'+profileId)
          .toPromise();
      }

    getStudentCourseProgessSummaryDetails(profileId,courseId) {
        return this.httpClient
          .get(`${environment.courseServiceURL}/student/courseProgressProgressSummaryDetails?userId=`+profileId+'&courseId=' + courseId)
          .toPromise();
      }

      getStudentCourseDetailsProgess(profileId) {
        return this.httpClient
          .get(`${environment.courseServiceURL}/student/student-course-details-progress-admin/`+ profileId)
          .toPromise();
      }  

      getStudentQuizzes(profileId,courseId, topicId) {
        return this.httpClient
          .get(`${environment.courseServiceURL}/student/student-quizzes-profileId/`+ profileId+'/'+courseId+'/'+topicId)
          .toPromise();
      }  
      
      getStudentModuleStatusDetailsByCouseId(profileId,courseId) {
        return this.httpClient
          .get(`${environment.courseServiceURL}/student/getModuleStatusDetailsByCourseId?studentId=`+ profileId+'&courseId='+courseId)
          .toPromise();
      } 

      getStudentModuleStatusDetailsByTopicId(profileId,topicId) {
        return this.httpClient
          .get(`${environment.courseServiceURL}/student/getModuleStatusDetailsByTopicId?studentId=`+ profileId+'&topicId='+topicId)
          .toPromise();
      } 
}
