import { Component, OnInit } from "@angular/core";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";

@Component({
    selector: "app-course-modal",
    templateUrl: "./course-modal.component.html",
    styleUrls: ["./course-modal.component.scss"],
})
export class CourseModalComponent implements OnInit {
    course;
    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig
    ) {}

    ngOnInit(): void {
        this.course = this.config.data.course;
    }
}
