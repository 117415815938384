import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { StorageService } from "../services/storage.service";
import { UsersService } from "../services/users.service";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate {
    schoolProfile;

    constructor(
        private userService: UsersService,
        private router: Router,
        private storageService: StorageService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.userService
            .getProfile()
            .then((res: any) => {
                //console.log(res);
                if (res.statusCode == 500) {
                    this.router.navigate(["/error"]);
                    return false;
                }

                if (res.statusCode == 409) {
                    this.router.navigate(["/access"]);
                    return false;
                }
                if (res.statusCode == 200) {
                    this.storageService.set(
                        "dsProfileId",
                        res.result[0].dsProfileId
                    ); 
                    this.storageService.set(
                        "region",
                        res.result[0].address.region
                    ); 
                    if (res.result[0].userType === "SCHOOL" || res.result[0].userType === "SCHOOL-ADMIN") {
                        this.getDrivingSchoolProfile();
                        //console.log(res.result[0])                       
                    }
                    this.storageService.set(
                        "profile_photo",
                        res.result[0].photo
                    );
                    this.storageService.set(
                        "name",
                        res.result[0].firstName + " " + res.result[0].lastName
                    );
                    this.storageService.set("userType", res.result[0].userType);
                    this.storageService.set("player", res.result[0].id);
                    return true;
                } else {
                    // this.router.navigate(["/login"]);
                    return false;
                }
            })
            .catch((err) => {
                this.storageService.clear();
                //location.reload();
                this.router.navigate(["/login"]);
                return false;
            });
    }

    getDrivingSchoolProfile() {
        this.userService.getDrivingSchoolProfile().then((response: any) => {
            this.schoolProfile = response.result[0];
            //console.log(this.schoolProfile);
            this.storageService.set(
                "branchCode",
                this.schoolProfile.customId
            );
            this.storageService.set(
                "subscriptionType",
                this.schoolProfile.subscriptionType
            );
            this.storageService.set(
                "companyName",
                this.schoolProfile.companyName
            );
            this.storageService.set(
                "referralCode",
                this.schoolProfile.referralCode
            );
            this.storageService.set(
                "accountValidated",
                this.schoolProfile.isProfileValidated == 1 ? true : false
            );
        });
    }
}
