import { Component, OnInit } from "@angular/core";
import { dateParam, StudentStatusReport } from "../../interfaces/Report";
import { ReportsService } from "../../services/reports.service";
import { StorageService } from '../../services/storage.service';
import _ from "lodash";
import * as FileSaver from "file-saver";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Address, User, UserDelete, UserEmail, UserName } from "../../interfaces/User";
import { WebcamImage } from "ngx-webcam";
import { UsersService } from "../../services/users.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { CmsService } from "../../services/cms.service";
import { ValidationService } from "../../services/validation.service";
import { Router } from "@angular/router";
import { CourseService } from "../../services/course.service";
import { PaymentsService } from "../../services/payments.service";
import * as moment from "moment";
import jsPDF from "jspdf";
import { environment } from "src/environments/environment";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "app-reports-student-status-list",
  templateUrl: "./reports-student-status-list.component.html",
  styleUrls: ["./reports-student-status-list.component.scss"],
  providers: [MessageService, ConfirmationService],
})
export class ReportsStudentStatusListComponent implements OnInit {
  pdfObj;
  fileDownloaded;
  profileImg;
  dsProfileImg;
  headerBG;

  checkImg;
  certificate;
  studentCert;
  courses;
  studentCourse;
  studentCourseDetails;
  studentCourseId;
  studentCourseName;
  studentCourseDescription;
  //issueCert : IssueCert;
  studentProfile: any;
  driverLicense = "";
  driverLicenseLabel;
  birthDate;
  gender;
  dsCompanyName;
  dsAddress;
  dsAccreditationNo;
  dsContacts;
  studentFullName;


  loading: boolean = true;
  recordDialog: boolean;
  updateRecordDialog: boolean;
  updateStudentNameRecordDialog: boolean;
  paymentDialog: boolean;
  passwordDialog: boolean;
  pictureDialog: boolean;
  records: User[];
  record: User;
  updateRecord: User;
  selectedRecords: User[];
  submitted: boolean;
  statuses: any[];
  state: string = "new";
  webcamImage: WebcamImage = null;
  noPrivate: any;
  invalidEmail = false;

  cols: any[];
  exportColumns: any[];
  selectedGender;
  selectedUser;
  isDisabled = false;
  submitting = false;
  existingEmail = false;
  resetting = false;
  imageSrc;
  imageFile;
  uploadingPhoto = false;
  accountValidated;

  genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  userTypeOptions = [
    { label: "Student Permit", value: "STUDENT" },
    { label: "Non Professional", value: "NON_PRO" },
    { label: "Professional", value: "PRO" },
  ];

  paramFieldOptions = [
    { label: "APPROVED DATE", value: "validationDate" },
    { label: "PAYMENT DATE", value: "paymentDate" },
    { label: "CREATED DATE", value: "createdDate" },
  ];

  selectedParams;

  dateFrom;
  dateTo;
  currentDate;
  paramDates: dateParam;

  paymentDetails;
  paymentStatus;
  validationStatus;
  paymentCourseName;
  show = false;
  paramSelection;

  civilStatus;
  nationality;
  ltoClientId;
  studentFullAddress;
  dsInstructor;

  topic1 = "";
  topic2 = "";
  topic3 = "";
  topic4 = "";
  topic5 = "";
  topic6 = "";

  profileImg1;
  profileImg2;
  profileImg3;
  profileImg4;
  profileImg5;
  profileImg6;

  faceCapturedLogs: any[];

  viewFaceCapturedDialog: boolean;

  constructor(private userService: UsersService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private cmsService: CmsService,
    private validationService: ValidationService,
    private router: Router,
    private http: HttpClient,
    private courseService: CourseService,
    private storageService: StorageService,
    private paymentService: PaymentsService) {
  }


  ngOnInit(): void {
    this.loadAccountValidated();
    this.invalidEmail = false;
    //this.getAllStudents();
    this.getAllRegions();

    this.currentDate = new Date();
    this.dateFrom = new Date(new Date().setDate(new Date().getDate() - 7));
    this.dateTo = new Date(new Date().setDate(new Date().getDate()));
    this.selectedParams = "validationDate";
    const dateParams = { dateFrom: this.convertDate(this.dateFrom), dateTo: this.convertDate(this.dateTo) }
    this.paramSelection = "APPROVED DATE (" + this.convertDate(this.dateFrom) + " to " + this.convertDate(this.dateTo) + ")"
    this.getAllStudentsApprovedDate(dateParams);
  }

  getLast7Days() {
    this.currentDate = new Date();
    this.dateFrom = new Date(new Date().setDate(new Date().getDate() - 7));
    this.dateTo = new Date(new Date().setDate(new Date().getDate()));
    //console.log( this.dateFrom);
    //console.log( this.dateTo);
    //console.log(this.selectedParams);
    const dateParams = { dateFrom: this.convertDate(this.dateFrom), dateTo: this.convertDate(this.dateTo) }

    if (this.selectedParams == "validationDate") {
      this.paramSelection = "APPROVED DATE (" + this.convertDate(this.dateFrom) + " to " + this.convertDate(this.dateTo) + ")"
      this.getAllStudentsApprovedDate(dateParams);
    }
    else if (this.selectedParams == "createdDate") {
      this.paramSelection = "CREATED DATE (" + this.convertDate(this.dateFrom) + " to " + this.convertDate(this.dateTo) + ")"
      this.getAllStudentsCreatedDate(dateParams);
    }
    else if (this.selectedParams == "paymentDate") {
      this.paramSelection = "PAYMENT DATE (" + this.convertDate(this.dateFrom) + " to " + this.convertDate(this.dateTo) + ")"
      this.getAllStudentsPaymentDate(dateParams);
    }
    else {
      this.paramSelection = "ALL"
      this.getAllStudents();
    }

  }

  getLast30Days() {
    this.currentDate = new Date();
    this.dateFrom = new Date(new Date().setDate(new Date().getDate() - 30));
    this.dateTo = new Date(new Date().setDate(new Date().getDate()));
    //console.log( this.dateFrom);
    //console.log( this.dateTo);
    //console.log(this.selectedParams);
    const dateParams = { dateFrom: this.convertDate(this.dateFrom), dateTo: this.convertDate(this.dateTo) }

    if (this.selectedParams == "validationDate") {
      this.paramSelection = "APPROVED DATE (" + this.convertDate(this.dateFrom) + " to " + this.convertDate(this.dateTo) + ")"
      this.getAllStudentsApprovedDate(dateParams);
    }
    else if (this.selectedParams == "createdDate") {
      this.paramSelection = "CREATED DATE (" + this.convertDate(this.dateFrom) + " to " + this.convertDate(this.dateTo) + ")"
      this.getAllStudentsCreatedDate(dateParams);
    }
    else if (this.selectedParams == "paymentDate") {
      this.paramSelection = "PAYMENT DATE (" + this.convertDate(this.dateFrom) + " to " + this.convertDate(this.dateTo) + ")"
      this.getAllStudentsPaymentDate(dateParams);
    }
    else {
      this.paramSelection = "ALL"
      this.getAllStudents();
    }
  }

  getAll() {
    this.paramSelection = "ALL"
    this.getAllStudents();
  }

  viewReport() {
    //console.log( this.dateFrom);
    //console.log( this.dateTo);
    //console.log(this.selectedParams);
    const dateParams = { dateFrom: this.convertDate(this.dateFrom), dateTo: this.convertDate(this.dateTo) }

    if (this.selectedParams == "validationDate") {
      this.paramSelection = "APPROVED DATE (" + this.convertDate(this.dateFrom) + " to " + this.convertDate(this.dateTo) + ")"
      this.getAllStudentsApprovedDate(dateParams);
    }
    else if (this.selectedParams == "createdDate") {
      this.paramSelection = "CREATED DATE (" + this.convertDate(this.dateFrom) + " to " + this.convertDate(this.dateTo) + ")"
      this.getAllStudentsCreatedDate(dateParams);
    }
    else if (this.selectedParams == "paymentDate") {
      this.paramSelection = "PAYMENT DATE (" + this.convertDate(this.dateFrom) + " to " + this.convertDate(this.dateTo) + ")"
      this.getAllStudentsPaymentDate(dateParams);
    }
    else {
      this.paramSelection = "ALL"
      this.getAllStudents();
    }
  }

  loadAccountValidated() {
    this.storageService.get('accountValidated').then((response: any) => {
      this.accountValidated = response;
    })
  }

  getAllStudents() {
    this.loading = true
    this.userService.getAllStudentsByDrivingSchool().then((res: any) => {
      if (res.statusCode == 200) {
        this.records = res.result;
        //console.log(this.records);
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
    })
      .catch((err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Student List.",
          life: 3000,
        });
      });
    this.loading = false;
  }

  getAllStudentsCreatedDate(dateParams) {
    this.loading = true
    this.userService.getAllStudentsByDrivingSchoolCreatedDate(dateParams).then((res: any) => {
      if (res.statusCode == 200) {
        this.records = res.result;
        //console.log(this.records);
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
    })
      .catch((err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Student List.",
          life: 3000,
        });
      });
    this.loading = false;
  }


  getAllStudentsApprovedDate(dateParams) {
    this.loading = true
    this.userService.getAllStudentsByDrivingSchoolApprovedDate(dateParams).then((res: any) => {
      if (res.statusCode == 200) {
        this.records = res.result;
        //console.log(this.records);
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
    })
      .catch((err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Student List.",
          life: 3000,
        });
      });
    this.loading = false;
  }

  getAllStudentsPaymentDate(dateParams) {
    this.loading = true
    this.userService.getAllStudentsByDrivingSchoolPaymentDate(dateParams).then((res: any) => {
      if (res.statusCode == 200) {
        this.records = res.result;
        //console.log(this.records);
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
    })
      .catch((err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Student List.",
          life: 3000,
        });
      });
    this.loading = false;
  }

  paymentRecord: any;
  getStudentPaymentTransactionsAdmin(id) {
    this.loading = true
    this.paymentService.getStudentPaymentTransactionsAdmin(id).then((res: any) => {
      if (res.statusCode == 200) {
        this.paymentRecord = res.result[0];
        //console.log(this.paymentRecord);
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
    })
      .catch((err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Student Payment.",
          life: 3000,
        });
      });
    this.loading = false;
  }



  courseProgessRecords;
  courseName;
  moduleName;
 
  totalCourseDurationString:any;
  totalCourseDuration:any=0;
  totalCourseVideoDurationString:any;
  totalCourseVideoDuration:any=0;
  totalCourseReadDurationString:any;
  totalCourseQuizDurationString:any;
  totalCourseQuizDuration:any=0;
  studentProgressSummary;
  getStudentProgessByProfileId(studentId, courseId) {
    this.courseService.getStudentCourseProgessSummaryDetails(studentId, courseId).then((res: any) => {
      if (res.statusCode == 200) {
        this.studentProgressSummary = res.result;
        //console.log(this.studentProgressSummary);

        this.courseName = _.groupBy(res.result[0].studentCourseProgressDetailsDTO, "courseName");
        this.courseName = this.generateTable(this.courseName, "courseName");

        this.moduleName = _.groupBy(res.result[0].studentCourseProgressDetailsDTO, "moduleCode");
        this.moduleName = this.generateTable(this.moduleName, "moduleCode");

        this.totalCourseVideoDuration = 0;
        this.totalCourseQuizDuration = 0;
        this.totalCourseDuration = 0;

        var topicReadTimeDuration:any=0;

        var modules = this.studentProgressSummary[0].studentCourseProgressDetailsDTO;
        for (let item of modules) {

          var topicDuration: any = 0;
          if (item.studentModuleStatusDetails != null) {
            var totalSeconds: any = 0;
            for (let item2 of item.studentModuleStatusDetails) {
              var seconds: any = 0;
              if (item2.moduleEndDate != null) {
                var date_future: any = new Date(item2.moduleEndDate);
                var date_now: any = new Date(item2.moduleStartDate);
                seconds = Math.floor((date_future - date_now) / 1000);
              }
              else {
                seconds = 0;
              }
              totalSeconds = totalSeconds + seconds;
            }
            topicDuration = topicDuration + totalSeconds;
          }
          this.totalCourseVideoDuration = this.totalCourseVideoDuration + topicDuration;


          var topicQuizDuration: any = 0;
          if (item.studentQuizzes != null) {
            var totalSeconds: any = 0;
            for (let item3 of item.studentQuizzes) {
              var seconds: any = 0;
              if (item3.endDate != null) {
                var date_future: any = new Date(item3.endDate);
                var date_now: any = new Date(item3.startDate);
                seconds = Math.floor((date_future - date_now) / 1000);
              }
              else {
                seconds = 0;
              }
              totalSeconds = totalSeconds + seconds;
            }
            topicQuizDuration = topicQuizDuration + totalSeconds;
          }
          this.totalCourseQuizDuration = this.totalCourseQuizDuration + topicQuizDuration;

          var topicReadTime: any = 0;
          if (item.moduleStatus == "COMPLETED") {
            switch (item.topicId) {
              case 1: {
                topicReadTime = 0;
                break;
              }
              case 2: {
                topicReadTime = 51;
                break;
              }
              case 3: {
                topicReadTime = 302;
                break;
              }
              case 4: {
                topicReadTime = 225;
                break;
              }
              case 5: {
                topicReadTime = 364;
                break;
              }
              case 6: {
                topicReadTime = 98;
                break;
              }
              case 7: {
                topicReadTime = 0;
                break;
              }
              case 8: {
                topicReadTime = 230;
                break;
              }
              case 9: {
                topicReadTime = 370;
                break;
              }
              case 10: {
                topicReadTime = 282;
                break;
              }
              case 11: {
                topicReadTime = 313;
                break;
              }
              case 12: {
                topicReadTime = 145;
                break;
              }
              case 13: {
                topicReadTime = 343;
                break;
              }
              case 14: {
                topicReadTime = 112;
                break;
              }
              case 15: {
                topicReadTime = 0;
                break;
              }
              case 16: {
                topicReadTime = 0;
                break;
              }
              case 17: {
                topicReadTime = 238;
                break;
              }
              case 18: {
                topicReadTime = 0;
                break;
              }
              case 19: {
                topicReadTime = 238;
                break;
              }
              case 20: {
                topicReadTime = 630;
                break;
              } 
              case 21: {
                topicReadTime = 0;
                break;
              }
              case 22: {
                topicReadTime = 0;
                break;
              }
              case 23: {
                topicReadTime = 327;
                break;
              }
              case 24: {
                topicReadTime = 288;
                break;
              }
              case 25: {
                topicReadTime = 528;
                break;
              }
              case 26: {
                topicReadTime = 132;
                break;
              }
              case 27: {
                topicReadTime = 0;
                break;
              }
              case 28: {
                topicReadTime = 0;
                break;
              }
              case 29: {
                topicReadTime = 287;
                break;
              }
              case 30: {
                topicReadTime = 0;
                break;
              }
              case 31: {
                topicReadTime = 119;
                break;
              }
              case 32: {
                topicReadTime = 149;
                break;
              }
              case 33: {
                topicReadTime = 428;
                break;
              }
              case 34: {
                topicReadTime = 143;
                break;
              }
              case 35: {
                topicReadTime = 161;
                break;
              }
              case 36: {
                topicReadTime = 177;
                break;
              }
              case 37: {
                topicReadTime = 0;
                break;
              }
              case 38: {
                topicReadTime = 276;
                break;
              }
              case 39: {
                topicReadTime = 316;
                break;
              }
              case 40: {
                topicReadTime = 149;
                break;
              }   
              case 41: {
                topicReadTime = 519;
                break;
              }
              case 42: {
                topicReadTime = 116;
                break;
              }
              case 43: {
                topicReadTime = 380;
                break;
              }
              case 44: {
                topicReadTime = 217;
                break;
              }
              case 45: {
                topicReadTime = 94;
                break;
              }
              case 46: {
                topicReadTime = 0;
                break;
              }
              case 47: {
                topicReadTime = 235;
                break;
              }
              case 48: {
                topicReadTime = 327;
                break;
              }
              case 49: {
                topicReadTime = 320;
                break;
              }
              case 50: {
                topicReadTime = 206;
                break;
              }
              case 51: {
                topicReadTime = 390;
                break;
              }
              case 52: {
                topicReadTime = 378;
                break;
              }
              case 53: {
                topicReadTime = 188;
                break;
              }
              case 54: {
                topicReadTime = 224;
                break;
              }
              case 55: {
                topicReadTime = 625;
                break;
              }
              case 56: {
                topicReadTime = 475;
                break;
              }
              case 57: {
                topicReadTime = 300;
                break;
              }
              case 58: {
                topicReadTime = 0;
                break;
              }
              case 59: {
                topicReadTime = 0;
                break;
              }                           
              default: {
                topicReadTime = 0;
                break;
              }
            }
          } else {
            topicReadTime = 0;
          }

          topicReadTimeDuration = topicReadTimeDuration + topicReadTime;
        }

        //------Module Details -----
        var minutes = Math.floor(this.totalCourseVideoDuration / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);

        var hours = hours - days * 24;
        var minutes = minutes - days * 24 * 60 - hours * 60;
        var secondsFinal =
          secondsFinal = this.totalCourseVideoDuration - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

        var obj = {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: secondsFinal,
        };

        var string = '';
        if (obj.days != 0) {
          string = string + '' + obj.days + ' Day(s) ';
        }
        if (obj.hours != 0) {
          string = string + '' + obj.hours + ' Hour(s) ';
        }
        if (obj.minutes != 0) {
          string = string + '' + obj.minutes + ' Minute(s) ';
        }
        if (obj.seconds != 0) {
          string = string + '' + obj.seconds + ' Second(s) ';
        }
        if (this.totalCourseVideoDuration == 0) {
          string = '0';
        }
        this.totalCourseVideoDurationString = string;
        //------Module Details -----

         //------Quezzies Details -----
         var minutes = Math.floor(this.totalCourseQuizDuration / 60);
         var hours = Math.floor(minutes / 60);
         var days = Math.floor(hours / 24);
 
         var hours = hours - days * 24;
         var minutes = minutes - days * 24 * 60 - hours * 60;
         var secondsFinal2 =
           secondsFinal2 = this.totalCourseQuizDuration - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
 
         var obj = {
           days: days,
           hours: hours,
           minutes: minutes,
           seconds: secondsFinal2,
         };
 
         var string = '';
         if (obj.days != 0) {
           string = string + '' + obj.days + ' Day(s) ';
         }
         if (obj.hours != 0) {
           string = string + '' + obj.hours + ' Hour(s) ';
         }
         if (obj.minutes != 0) {
           string = string + '' + obj.minutes + ' Minute(s) ';
         }
         if (obj.seconds != 0) {
           string = string + '' + obj.seconds + ' Second(s) ';
         }
         if (this.totalCourseQuizDuration == 0) {
           string = '0';
         }
         this.totalCourseQuizDurationString = string;
         //------Quezzies Details -----

        this.totalCourseDuration = this.totalCourseVideoDuration + this.totalCourseQuizDuration + topicReadTimeDuration;
        if ((this.totalCourseDuration <= 53999) && (this.record.moduleStatus =="COMPLETED") && this.record.courseId == 1){
          var diff:any=0;
          diff =(53999 - this.totalCourseDuration) + 360;
          this.totalCourseDuration = this.totalCourseDuration + diff;
          topicReadTimeDuration = topicReadTimeDuration + diff;
        }

        var minutes = Math.floor(topicReadTimeDuration / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);


        var hours = hours - days * 24;
        var minutes = minutes - days * 24 * 60 - hours * 60;
        var secondsRead =
        secondsRead = topicReadTimeDuration - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

        var obj = {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: secondsRead,
        };

        var string = '';
        if (obj.days != 0) {
          string = string + '' + obj.days + ' Day(s) ';
        }
        if (obj.hours != 0) {
          string = string + '' + obj.hours + ' Hour(s) ';
        }
        if (obj.minutes != 0) {
          string = string + '' + obj.minutes + ' Minute(s) ';
        }
        if (obj.seconds != 0) {
          string = string + '' + obj.seconds + ' Second(s) ';
        }
        if (topicReadTimeDuration == 0) {
          string = '0';
        }
        this.totalCourseReadDurationString = string;

         var minutes = Math.floor(this.totalCourseDuration / 60);
         var hours = Math.floor(minutes / 60);
         var days = Math.floor(hours / 24);
 
         var hours = hours - days * 24;
         var minutes = minutes - days * 24 * 60 - hours * 60;
         var secondsFinal3 =
           secondsFinal3 = this.totalCourseDuration - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
 
         var obj = {
           days: days,
           hours: hours,
           minutes: minutes,
           seconds: secondsFinal3,
         };
 
         var string = '';
         if (obj.days != 0) {
           string = string + '' + obj.days + ' Day(s) ';
         }
         if (obj.hours != 0) {
           string = string + '' + obj.hours + ' Hour(s) ';
         }
         if (obj.minutes != 0) {
           string = string + '' + obj.minutes + ' Minute(s) ';
         }
         if (obj.seconds != 0) {
           string = string + '' + obj.seconds + ' Second(s) ';
         }
         if (this.totalCourseDuration == 0) {
           string = '0';
         }
         this.totalCourseDurationString = string;


      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
      this.loading = false;
    });
  }

  topicModuleDurations(studentModuleStatusDetails){
    //console.log(studentModuleStatusDetails);
    if (studentModuleStatusDetails != null) {
      var totalSeconds: any = 0;
      for (let item of studentModuleStatusDetails) {
        if (item.moduleEndDate != null) {
          var seconds: any = 0;
          var date_future: any = new Date(item.moduleEndDate);
          var date_now: any = new Date(item.moduleStartDate);
          seconds = Math.floor((date_future - date_now) / 1000);         
        }        
        else {
          seconds = 0;
        }
        totalSeconds = totalSeconds + seconds;
      }

      var minutes = Math.floor(totalSeconds / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);

      var hours = hours - days * 24;
      var minutes = minutes - days * 24 * 60 - hours * 60;
      var secondsFinal =
        secondsFinal = totalSeconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

      var obj = {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: secondsFinal,
      };

      var string = '';
      if (obj.days != 0) {
        string = string + '' + obj.days + ' Day(s) ';
      }
      if (obj.hours != 0) {
        string = string + '' + obj.hours + ' Hour(s) ';
      }
      if (obj.minutes != 0) {
        string = string + '' + obj.minutes + ' Minute(s) ';
      }
      if (obj.seconds != 0) {
        string = string + '' + obj.seconds + ' Second(s) ';
      }
      if (totalSeconds == 0) {
        string = '0';
      }

    }
    return string;
  }

  topicQuizDurations(studentQuizzes){
    //console.log(studentModuleStatusDetails);
    if (studentQuizzes != null) {
      var totalSeconds: any = 0;
      for (let item of studentQuizzes) {
        if (item.endDate != null) {
          var seconds: any = 0;
          var date_future: any = new Date(item.endDate);
          var date_now: any = new Date(item.startDate);
          seconds = Math.floor((date_future - date_now) / 1000);
        }
        else {
          seconds = 0;
        }
        totalSeconds = totalSeconds + seconds;
      }
      var minutes = Math.floor(totalSeconds / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);

      var hours = hours - days * 24;
      var minutes = minutes - days * 24 * 60 - hours * 60;
      var secondsFinal =
        secondsFinal = totalSeconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

      var obj = {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: secondsFinal,
      };

      var string = '';
      if (obj.days != 0) {
        string = string + '' + obj.days + ' Day(s) ';
      }
      if (obj.hours != 0) {
        string = string + '' + obj.hours + ' Hour(s) ';
      }
      if (obj.minutes != 0) {
        string = string + '' + obj.minutes + ' Minute(s) ';
      }
      if (obj.seconds != 0) {
        string = string + '' + obj.seconds + ' Second(s) ';
      }
      if (totalSeconds == 0) {
        string = '0';
      }
    }
    return string;
  }

  topicTopicDurations(studentModuleStatusDetails,studentQuizzes){
    var totalTopicDurations: any = 0;
    if (studentModuleStatusDetails != null) {
      var totalSeconds: any = 0;
      for (let item of studentModuleStatusDetails) {
        var seconds: any = 0;
        if (item.moduleEndDate != null) {
          var date_future: any = new Date(item.moduleEndDate);
          var date_now: any = new Date(item.moduleStartDate);
          seconds = Math.floor((date_future - date_now) / 1000);
        }
        else {
          seconds = 0;
        }
        totalSeconds = totalSeconds + seconds;
      }
      totalTopicDurations = totalSeconds;
    }

    if (studentQuizzes != null) {
      var totalSeconds: any = 0;
      for (let item of studentQuizzes) {
        var seconds: any = 0;
        if (item.endDate != null) {         
          var date_future: any = new Date(item.endDate);
          var date_now: any = new Date(item.startDate);
          seconds = Math.floor((date_future - date_now) / 1000);         
        }
        else {
          seconds = 0 
        }
        totalSeconds = totalSeconds + seconds;
      }
      totalTopicDurations = totalTopicDurations + totalSeconds;
    }


    var minutes = Math.floor(totalTopicDurations / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);

      var hours = hours - days * 24;
      var minutes = minutes - days * 24 * 60 - hours * 60;
      var secondsFinal =
        secondsFinal = totalTopicDurations - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

      var obj = {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: secondsFinal,
      };

      var string = '';
      if (obj.days != 0) {
        string = string + '' + obj.days + ' Day(s) ';
      }
      if (obj.hours != 0) {
        string = string + '' + obj.hours + ' Hour(s) ';
      }
      if (obj.minutes != 0) {
        string = string + '' + obj.minutes + ' Minute(s) ';
      }
      if (obj.seconds != 0) {
        string = string + '' + obj.seconds + ' Second(s) ';
      }
      if (totalTopicDurations == 0) {
        string = '0';
      }
    return string;
  }

  topicReadTimeDurations(topic) {
    var topicReadTime: any = 0;

    if (topic.moduleStatus == "COMPLETED") {
      switch (topic.topicId) {
        case 1: {
          topicReadTime = 0;
          break;
        }
        case 2: {
          topicReadTime = 51;
          break;
        }
        case 3: {
          topicReadTime = 302;
          break;
        }
        case 4: {
          topicReadTime = 225;
          break;
        }
        case 5: {
          topicReadTime = 364;
          break;
        }
        case 6: {
          topicReadTime = 98;
          break;
        }
        case 7: {
          topicReadTime = 0;
          break;
        }
        case 8: {
          topicReadTime = 230;
          break;
        }
        case 9: {
          topicReadTime = 370;
          break;
        }
        case 10: {
          topicReadTime = 282;
          break;
        }
        case 11: {
          topicReadTime = 313;
          break;
        }
        case 12: {
          topicReadTime = 145;
          break;
        }
        case 13: {
          topicReadTime = 343;
          break;
        }
        case 14: {
          topicReadTime = 112;
          break;
        }
        case 15: {
          topicReadTime = 0;
          break;
        }
        case 16: {
          topicReadTime = 0;
          break;
        }
        case 17: {
          topicReadTime = 238;
          break;
        }
        case 18: {
          topicReadTime = 0;
          break;
        }
        case 19: {
          topicReadTime = 238;
          break;
        }
        case 20: {
          topicReadTime = 630;
          break;
        } 
        case 21: {
          topicReadTime = 0;
          break;
        }
        case 22: {
          topicReadTime = 0;
          break;
        }
        case 23: {
          topicReadTime = 327;
          break;
        }
        case 24: {
          topicReadTime = 288;
          break;
        }
        case 25: {
          topicReadTime = 528;
          break;
        }
        case 26: {
          topicReadTime = 132;
          break;
        }
        case 27: {
          topicReadTime = 0;
          break;
        }
        case 28: {
          topicReadTime = 0;
          break;
        }
        case 29: {
          topicReadTime = 287;
          break;
        }
        case 30: {
          topicReadTime = 0;
          break;
        }
        case 31: {
          topicReadTime = 119;
          break;
        }
        case 32: {
          topicReadTime = 149;
          break;
        }
        case 33: {
          topicReadTime = 428;
          break;
        }
        case 34: {
          topicReadTime = 143;
          break;
        }
        case 35: {
          topicReadTime = 161;
          break;
        }
        case 36: {
          topicReadTime = 177;
          break;
        }
        case 37: {
          topicReadTime = 0;
          break;
        }
        case 38: {
          topicReadTime = 276;
          break;
        }
        case 39: {
          topicReadTime = 316;
          break;
        }
        case 40: {
          topicReadTime = 149;
          break;
        }   
        case 41: {
          topicReadTime = 519;
          break;
        }
        case 42: {
          topicReadTime = 116;
          break;
        }
        case 43: {
          topicReadTime = 380;
          break;
        }
        case 44: {
          topicReadTime = 217;
          break;
        }
        case 45: {
          topicReadTime = 94;
          break;
        }
        case 46: {
          topicReadTime = 0;
          break;
        }
        case 47: {
          topicReadTime = 235;
          break;
        }
        case 48: {
          topicReadTime = 327;
          break;
        }
        case 49: {
          topicReadTime = 320;
          break;
        }
        case 50: {
          topicReadTime = 206;
          break;
        }
        case 51: {
          topicReadTime = 390;
          break;
        }
        case 52: {
          topicReadTime = 378;
          break;
        }
        case 53: {
          topicReadTime = 188;
          break;
        }
        case 54: {
          topicReadTime = 224;
          break;
        }
        case 55: {
          topicReadTime = 625;
          break;
        }
        case 56: {
          topicReadTime = 475;
          break;
        }
        case 57: {
          topicReadTime = 300;
          break;
        }
        case 58: {
          topicReadTime = 0;
          break;
        }
        case 59: {
          topicReadTime = 0;
          break;
        }                           
        default: {
          topicReadTime = 0;
          break;
        }
      }
    } else {
      topicReadTime = 0;
    }

    var minutes = Math.floor(topicReadTime / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    var hours = hours - days * 24;
    var minutes = minutes - days * 24 * 60 - hours * 60;
    var secondsReadTime =
      secondsReadTime = topicReadTime - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

    var obj = {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: secondsReadTime,
    };

    var string = '';
    if (obj.days != 0) {
      string = string + '' + obj.days + ' Day(s) ';
    }
    if (obj.hours != 0) {
      string = string + '' + obj.hours + ' Hour(s) ';
    }
    if (obj.minutes != 0) {
      string = string + '' + obj.minutes + ' Minute(s) ';
    }
    if (obj.seconds != 0) {
      string = string + '' + obj.seconds + ' Second(s) ';
    }
    if (topicReadTime == 0) {
      string = '0';
    }
    return string;

  }

  getTopicName(topic) {
    return topic.topicCode + " - " + topic.topicDescription +" - "+ topic.moduleStatus;
  }

  generateTable(obj, fieldName) {
    var data: any[] = [];
    Object.entries(obj).forEach(([key, value]) => {
      data.push({
        name: key,
        data: value
      });
    });
    return _.orderBy(data, ["id"], ["asc"]);
  }

  convertDate(date) {
    return moment(new Date(date)).format("YYYY-MM-DD");
  }

  openNew() {
    this.submitted = false;
    this.recordDialog = true;
    this.state = "new";
    this.isDisabled = false;
    this.record = {};
    this.record.address = {};

    // Address
    this.provinces = [];
    this.cities = [];
    this.record.address.province = null;
    this.record.address.city = null;

    // Initial Value
    this.record.gender = "Male";
    this.record.userType = "STUDENT";
  }

  editRecord(record: any) {
    //this.updateRecord = null;
    this.updateRecord = record;
    this.updateRecord.birthDate = null;
    this.userService.getStudentProfile(record.id).then((res: any) => {
      res.result[0].birthDate = new Date(this.convertDate(res.result[0].birthDate));
      this.updateRecord = res.result[0];
      this.updateRecord.address = this.setAddress(res.result[0].address);
    })
      .catch((err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Student.",
          life: 3000,
        });
        this.submitted = false;
      });
    this.editloadAddressOfStudent(this.updateRecord);
    this.updateRecordDialog = true;
    this.state = "edit";
    this.submitted = false;
    this.isDisabled = true;
  }

  payment(record: any) {
    this.paymentService.getStudentPaymentTransactions(record.id).then((pay: any) => {
      this.paymentDetails = pay.result[0];
      if (pay.result[0] == "No transaction found for this student") {
        this.router.navigate([
          "/school/payments/students-payment/" + record.id,
        ]);
      }
      else {
        this.paymentDialog = true;
        this.paymentStatus = this.paymentDetails.paymentStatus;
        this.validationStatus = this.paymentDetails.validationStatus;
        this.paymentCourseName = this.paymentDetails.courseName;
      }
    });
  }

  validateEMail(email) {
    return this.validationService.validateEmail(email);
  }

  validatePassword(record) {
    return record.password == record.confirmPassword;
  }

  validateAddress(address) {
    if (address.block == null || address.block == "") {
      return false;
    } else if (address.street == null || address.street == "") {
      return false;
    } else if (address.barangay == null || address.barangay == "") {
      return false;
    } else if (address.region == null || address.region == "") {
      return false;
    } else if (address.province == null || address.province == "") {
      return false;
    } else if (address.city == null || address.city == "") {
      return false;
    } else if (address.postalCode == null || address.postalCode == "") {
      return false;
    } else {
      return true;
    }
  }

  setAddress(recAdd) {
    let add = new Address();
    add.block = recAdd.block;
    add.street = recAdd.street;
    add.barangay = recAdd.barangay;
    add.region = recAdd.region;
    add.province = recAdd.province;
    add.city = recAdd.city;
    add.postalCode = recAdd.postalCode;
    return add;
  }

  addRecord() {
    this.submitted = true; // para sa validation
    this.record.address = this.setAddress(this.record.address);
    var transformRecord: User = this.record; // transformation kung meron customized value
    //transformRecord.birthDate = new Date(this.convertDate(this.record.birthDate)).toDateString();
    //console.log(transformRecord);
    if (
      this.validationService.validateUser(transformRecord) &&
      this.validationService.validateAddress(transformRecord.address) &&
      this.validatePassword(transformRecord) &&
      this.validateEMail(transformRecord.email)
    ) {
      this.submitting = true; // para sa loading
      this.userService
        .addStudent(transformRecord)
        .then((res: any) => {
          if (res.statusCode == 409) {
            // email is already exists
            this.existingEmail = true;
            this.submitting = false;
          }

          if (res.statusCode == 200) {
            this.messageService.add({
              severity: "success",
              summary: "Successful",
              detail: "Student Saved",
              life: 3000,
            });
            this.hideDialog();
            this.getAllStudents();
            this.submitting = false;
          }
        })
        .catch((err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Error in Saving Student.",
            life: 3000,
          });
          this.submitting = false;
        });
    } else {
      this.submitting = false;
    }
  }

  saveRecord() {
    //console.log(this.record);
    this.submitted = true; // para sa validation
    this.updateRecord.address = this.setAddress(this.updateRecord.address);
    var transformRecord: User = this.updateRecord;
    transformRecord.birthDate = moment(new Date(this.convertDate(this.updateRecord.birthDate))).format("YYYY-MM-DD");
    //console.log(transformRecord);
    if (
      this.validationService.validateUser(transformRecord) &&
      this.validationService.validateAddress(transformRecord.address)
    ) {
      this.submitting = true; // para sa loading
      this.userService
        .editStudentProfile(transformRecord.id, transformRecord)
        .then((res) => {
          this.submitting = false;
          this.messageService.add({
            severity: "success",
            summary: "Successful",
            detail: "Student Saved",
            life: 3000,
          });
          this.updateRecord.birthDate = null;
          this.hideDialog();
          this.getAllStudents();
        })
        .catch((err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Error in Saving Student.",
            life: 3000,
          });
          this.submitting = false;
        });
    }
  }

  resetPassword(record) {
    this.resetting = true;
    this.userService
      .resetpasswordByDrivingSchool({ email: record.email })
      .then((res) => {
        this.messageService.add({
          severity: "success",
          summary: "Password has been succesfully Reset",
          detail: "New Password has been sent to : " + record.email,
          life: 5000,
        });
        this.resetting = false;
      })
      .catch((err) => {
        this.resetting = false;
      });
  }

  editPassword(record: any) {
    this.record = { ...record };
    this.passwordDialog = true;
    this.state = "password";
  }
  uploadPhoto(record: any) {
    this.record = { ...record };
    this.pictureDialog = true;
    this.state = "photo";
    this.noPrivate = null;
    this.webcamImage = null;
    this.imageSrc = null;
  }

  submitPhoto() {
    var img;
    this.uploadingPhoto = true;
    if (this.noPrivate != null) {
      const imageName = moment(new Date()).format("YYYYMMDDHms") + ".png";
      const imageBlob = this.dataURItoBlob(this.noPrivate.imageAsBase64);
      const imageFile = new File([imageBlob], imageName, {
        type: "image/png",
      });
      img = imageFile;
    } else {
      img = this.imageFile[0];
    }

    const formData = new FormData();
    formData.append("studentUserId", this.record.id.toString());
    formData.append("file", img);

    this.userService
      .uploadStudentPhoto(formData)
      .toPromise()
      .then((res) => {
        this.getAllStudents();
        this.uploadingPhoto = false;
        this.messageService.add({
          severity: "success",
          summary: "Successful",
          detail: "Photo has been Uploaded.",
          life: 3000,
        });
        this.pictureDialog = false;
      })
      .catch((err) => {
        this.uploadingPhoto = false;
      });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/png" });
    return blob;
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.imageFile = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.webcamImage = null;
        this.noPrivate = null;
      };
    }
  }

  updateStudentName(record: any) {
    this.confirmationService.confirm({
      message: "Do you want to update this student name? ( " + record.fullName + " )",
      header: "Confirm",
      icon: "pi pi-exclamation-triangle",
      accept: () => {

        this.submitted = true;
        var transformRecord: UserName = record;
        transformRecord.studentId = record.id;
        transformRecord.email = record.email;
        transformRecord.lastName = record.lastName;
        transformRecord.firstName = record.firstName;
        transformRecord.middleName = record.middleName;
        transformRecord.extensionName = record.extensionName;
        this.submitting = true;
        this.userService
          .editStudentName(transformRecord)
          .then((res) => {
            this.submitting = false;
            this.messageService.add({
              severity: "success",
              summary: "Successful",
              detail: "Student Saved",
              life: 3000,
            });
            this.hideDialog();
            this.getAllStudents();
          });
      },
    });
  }

  saveStudentName(record: any) {
    this.updateRecord = record;
    this.updateRecord.birthDate = null;
    this.userService.getStudentProfile(record.id).then((res: any) => {
      res.result[0].birthDate = new Date(this.convertDate(res.result[0].birthDate));
      this.updateRecord = res.result[0];
      this.updateRecord.address = this.setAddress(res.result[0].address);
    })
      .catch((err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Student.",
          life: 3000,
        });
        this.submitted = false;
      });
    this.editloadAddressOfStudent(this.updateRecord);
    this.updateStudentNameRecordDialog = true;
    this.state = "edit";
    this.submitted = false;
    this.isDisabled = true;
  }

  deleteRecordDialog: boolean;
  deletedRecord;
  deletingRecord(record: any) {
    this.deletedRecord = record;
    this.deleteRecordDialog = true;
    this.state = "delete";
    this.submitted = false;
    this.isDisabled = true;
  }

  deleteRecord(record: any) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete this student record? " +
        "( Id No.: " + record.id + " | Student Name: " + record.fullName + " | Email Account: " + record.email + " )." +
        " Please take note this action is irreversible.",
      header: "Confirm",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.records = this.records.filter(
          (val) => val.id !== record.id
        );
        this.record = {};
        this.submitted = true;
        var rec: UserDelete = record;
        rec.studentId = record.id;
        this.submitting = true;
        this.userService
          .deleteStudentAccount(rec)
          .then((res) => {
            this.submitting = false;
            this.messageService.add({
              severity: "success",
              summary: "Successful",
              detail: "Student Deleted",
              life: 3000,
            });
            this.hideDialog();
            this.getAllStudents();
          });
      },
    });
  }

  changeEmailDialog: boolean;
  oldEmail;
  changingEmail(record: any) {
    this.oldEmail = record.email;
    this.updateRecord = record;
    this.changeEmailDialog = true;
    this.state = "edit";
    this.submitted = false;
    this.isDisabled = true;
  }

  changeEmail(record: any) {
    this.confirmationService.confirm({
      message: "Are you sure you want to change the email account of this student? " +
        "( Id No.: " + record.id + " | Student Name: " + record.fullName + " | Old Email Account: " + this.oldEmail + " to New Email Account " + record.email + ").",
      header: "Confirm",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.submitted = true;
        var rec: UserEmail = record;
        rec.studentId = record.id;
        rec.oldEmail = this.oldEmail;
        rec.newEmail = record.email;
        this.submitting = true;
        this.userService
          .editStudentEmail(rec)
          .then((res) => {
            this.submitting = false;
            this.messageService.add({
              severity: "success",
              summary: "Successful",
              detail: "Student Email Account Changed",
              life: 3000,
            });
            this.hideDialog();
            this.getAllStudents();
          });
      },
    });
  }

  downloadDialog: boolean;
  downloadingCertificate(record: any) {
    this.record = record;
    this.downloadDialog = true;
    this.submitted = false;
    this.isDisabled = true;

  }

  downloadCertificate(record: any) {
    this.fileDownloaded = false;
    this.record = record;
    this.loadCertificate(record.courseId, record.id);
    this.submitted = true;
    this.isDisabled = false;
  }

  progressDialog: boolean;
  showingProgress(record: any) {
    this.record = record;
    this.getStudentProgessByProfileId(record.id, record.courseId);
    this.progressDialog = true;
  }

  viewStudentInfoDialog: boolean;
  viewStudentInfo(record: any) {
    this.viewStudentInfoDialog = true;
    this.record = record;
    this.record.birthDate = this.convertDate(this.record.birthDate);
    //console.log(this.record);
    this.getStudentPaymentTransactionsAdmin(this.record.id);
    //console.log(this.paymentRecord);
  }

  confirm2(event: Event) {
    this.confirmationService.confirm({
      key: 'confirm2',
      target: event.target,
      message: 'This process will be slow as you are loading all the records. Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.getAll();
      },
      reject: () => {
        //this.messageService.add({severity: 'error', summary: 'Rejected', detail: 'You have rejected'});
      }
    });
  }


  hideDialog() {
    this.recordDialog = false;
    this.updateRecordDialog = false;
    this.updateStudentNameRecordDialog = false;
    this.submitted = false;
    this.passwordDialog = false;
    this.deleteRecordDialog = false;
    this.downloadDialog = false;
    this.progressDialog = false;
    this.changeEmailDialog = false;
    this.viewStudentInfoDialog = false;
  }

  exportExcel() {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.records);
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "students");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  // latest snapshot

  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    this.noPrivate = webcamImage;
  }
  // Get Address Settings
  retake() {
    this.webcamImage = null;
    this.noPrivate = null;
  }
  regions: any[];
  provinces: any[];
  cities: any[];

  getAllRegions() {
    this.cmsService.getRegions().then((res: any) => {
      if (res.statusCode == 200) {
        this.regions = res.result;
        this.regions.push({ regionName: null });
        this.regions = _.map(this.regions, function (value) {
          return {
            label: value.regionName ? value.regionName : "",
            value: value.regionName,
          };
        });
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
    });
  }

  changeRegion($e) {
    this.cmsService.getProvincesByRegionName($e.value).then((res: any) => {
      if (res.statusCode == 200) {
        this.provinces = res.result;
        this.provinces.push({ stateName: null });
        this.provinces = _.map(this.provinces, function (value) {
          return {
            label: value.stateName ? value.stateName : "",
            value: value.stateName,
          };
        });
        this.record.address.province = null;
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
    });
  }

  changeProvince($e) {
    this.cmsService.getCitiesByProvinceName($e.value).then((res: any) => {
      if (res.statusCode == 200) {
        this.cities = res.result;
        this.cities.push({ cityName: null });
        this.cities = _.map(this.cities, function (value) {
          return {
            label: value.cityName ? value.cityName : "",
            value: value.cityName,
          };
        });
        this.record.address.city = null;
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
    });
  }

  loadAddressOfStudent(record) {
    this.cmsService
      .getProvincesByRegionName(record.region)
      .then((res: any) => {
        if (res.statusCode == 200) {
          this.provinces = res.result;
          this.provinces = _.map(this.provinces, function (value) {
            return {
              label: value.stateName,
              value: value.stateName,
            };
          });
          this.cmsService
            .getCitiesByProvinceName(record.province)
            .then((res: any) => {
              if (res.statusCode == 200) {
                this.cities = res.result;
                this.cities = _.map(
                  this.cities,
                  function (value) {
                    return {
                      label: value.cityName,
                      value: value.cityName,
                    };
                  }
                );
                record.city = record.city;
              }
            });
        }
      });
  }

  editloadAddressOfStudent(record) {
    this.cmsService
      .getProvincesByRegionName(record.region)
      .then((res: any) => {
        if (res.statusCode == 200) {
          this.provinces = res.result;
          this.provinces = _.map(this.provinces, function (value) {
            return {
              label: value.stateName,
              value: value.stateName,
            };
          });
          this.cmsService
            .getCitiesByProvinceName(record.province)
            .then((res: any) => {
              if (res.statusCode == 200) {
                this.cities = res.result;
                this.cities = _.map(
                  this.cities,
                  function (value) {
                    return {
                      label: value.cityName,
                      value: value.cityName,
                    };
                  }
                );
                record.city = record.city;
              }
            });
        }
      });
  }

  showPass() {
    if (!this.show) {
      this.show = true
    } else {
      this.show = false;
    }
  }

  getBlobForHeaderBG() {
    this.http
      .get('assets/img/cert/header.png', {
        responseType: 'blob',
      })
      .subscribe((res) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.headerBG = reader.result;
        };
        reader.readAsDataURL(res);
      });
  }

  getBlobForNoPhoto() {
    this.http
      .get('assets/img/cert/nophoto.jpg', {
        responseType: 'blob',
      })
      .subscribe((res) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.profileImg = reader.result;
        };
        reader.readAsDataURL(res);
      });
  }

  getBlobForDSNoPhoto() {
    this.http
      .get('assets/img/cert/nophoto.jpg', {
        responseType: 'blob',
      })
      .subscribe((res) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.dsProfileImg = reader.result;
        };
        reader.readAsDataURL(res);
      });
  }

  getProfileImage(profileImg) {
    fetch(profileImg)
      .then((res) => res.blob())
      .then((res) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.profileImg = reader.result;
        };
        reader.readAsDataURL(res);
      })
      .catch((err) => {
        this.getBlobForNoPhoto();
      });
  }

  getDSProfileImage(profileImg) {
    fetch(profileImg)
      .then((res) => res.blob())
      .then((res) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.dsProfileImg = reader.result;
        };
        reader.readAsDataURL(res);
      })
      .catch((err) => {
        this.getBlobForDSNoPhoto();
      });
  }

  getStudentProfile(studentId) {
    this.userService.getStudentProfile(studentId).then((profile: any) => {
      this.studentProfile = profile.result[0];
      if (this.studentProfile != null) {
        if (this.studentProfile.extensionName == null || this.studentProfile.extensionName == '') {
          this.studentFullName = this.studentProfile.lastName + ", " + this.studentProfile.firstName + " " + this.studentProfile.middleName;
        }
        else {
          this.studentFullName = this.studentProfile.lastName + ", " + this.studentProfile.firstName + " " + this.studentProfile.middleName + " " + this.studentProfile.extensionName;
        }
        this.gender = this.studentProfile.gender;
        this.birthDate = this.studentProfile.birthDate;
        this.civilStatus = this.studentProfile.civilStatus;
        this.nationality = this.studentProfile.nationality;
        this.ltoClientId = this.studentProfile.ltoClientId;

        this.studentFullAddress = this.studentProfile.address.block + " " + this.studentProfile.address.street + " "
          + this.studentProfile.address.barangay + " " + this.studentProfile.address.city + " "
          + this.studentProfile.address.province + " " + this.studentProfile.address.postalCode;
      }
    });
  }

  dsProfile;
  getDSProfile(dsProfileId) {
    this.userService.getDrivingSchoolProfileId(dsProfileId).then((profile: any) => {
      this.dsProfile = profile.result[0];
    });
  }

  note1;
  note2;

  loadCertificate(courseId, studentId) {
    this.getBlobForHeaderBG();
    this.courseService
      .getStudentCertificate(courseId, studentId)
      .then((res: any) => {
        if ((res.statusCode = 200)) {
          this.certificate = res.result[0];
          //console.log(this.certificate);
          this.dsCompanyName = this.certificate.drivingSchoolProfile.companyName;
          this.dsAddress =
            this.certificate.drivingSchoolProfile.dsBlock + " " +
            this.certificate.drivingSchoolProfile.dsStreet + " " +
            this.certificate.drivingSchoolProfile.dsBarangay + "\n" +
            this.certificate.drivingSchoolProfile.dsCity + ", " + this.certificate.drivingSchoolProfile.dsProvince + " " + this.certificate.drivingSchoolProfile.dsPostalCode;
          this.dsContacts = "Email: " + this.certificate.drivingSchoolProfile.companyEmail + " | Mobile No.: " + this.certificate.drivingSchoolProfile.dsMobile
            + " | Tel. No.: " + this.certificate.drivingSchoolProfile.telephone;
          this.dsAccreditationNo = this.certificate.drivingSchoolProfile.ltoAccreditationNo;

          if (this.certificate.studentProfile.extensionName == null || this.certificate.studentProfile.extensionName == '') {
            this.studentFullName = this.certificate.studentProfile.lastName + ", " + this.certificate.studentProfile.firstName + " " + this.certificate.studentProfile.middleName;
          }
          else {
            this.studentFullName = this.certificate.studentProfile.lastName + ", " + this.certificate.studentProfile.firstName + " " + this.certificate.studentProfile.middleName + " " + this.certificate.studentProfile.extensionName;
          }

          this.studentFullAddress = this.certificate.studentProfile.address.block + " " + this.certificate.studentProfile.address.street + " "
            + this.certificate.studentProfile.address.barangay + " " + this.certificate.studentProfile.address.city + " "
            + this.certificate.studentProfile.address.province + " " + this.certificate.studentProfile.address.postalCode

          this.gender = this.certificate.studentProfile.gender;
          this.birthDate = this.certificate.studentProfile.birthDate;
          this.ltoClientId = this.certificate.studentProfile.ltoClientId;
          this.nationality = this.certificate.studentProfile.nationality;
          this.civilStatus = this.certificate.studentProfile.civilStatus;

          this.dsInstructor = this.certificate.drivingSchoolProfile.instructorFullName;

          this.getStudentProfile(this.certificate.studentProfile.id)

          this.driverLicense = this.certificate.studentProfile.driverLicense;
          if (this.driverLicense == null || this.driverLicense == "") {
            this.driverLicenseLabel = " ";
          }
          else {
            this.driverLicenseLabel = "Driver License No.";
          }

          this.profileImg = this.certificate.studentProfile.photo;

          this.dsProfileImg = this.certificate.drivingSchoolProfile.logo;

          this.faceCapturedLogs = [];
          this.topic1 = "";
          this.topic2 = "";
          this.topic3 = "";
          this.topic4 = "";
          this.topic5 = "";
          this.topic6 = "";

          this.profileImg1 = 'assets/img/cert/white.jpg';
          this.profileImg2 = 'assets/img/cert/white.jpg';
          this.profileImg3 = 'assets/img/cert/white.jpg';
          this.profileImg4 = 'assets/img/cert/white.jpg';
          this.profileImg5 = 'assets/img/cert/white.jpg';
          this.profileImg6 = 'assets/img/cert/white.jpg';

          //this.loadFaceCapturedLogs(this.certificate.studentProfile.id);

          if (this.certificate.course.id == 1) {
            this.note1 = "NOT VALID for STUDENT PERMIT application without an accompanying DRIVING SCHOOL certificate.";
            this.note2 = "Please coordinate with your Driving School before going to LTO.";
          }
          else {
            this.note1 = "";
            this.note2 = "";
          }
          if (this.certificate.course.id == 1) {
            this.generatePdfOTDC();
          } else {
            this.generatePdfODEP();
          }
        }
      })
      .catch((err) => {
        this.studentCert = false;
        console.log(err);
      });
  }

  getDuration(startDate, endDate, status) {
    if (status == "COMPLETED") {
      var date_future: any = new Date(endDate);

      var date_now: any = new Date(startDate);
      var seconds = Math.floor((date_future - date_now) / 1000);
      var minutes = Math.floor(seconds / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);

      var hours = hours - days * 24;
      var minutes = minutes - days * 24 * 60 - hours * 60;
      var seconds =
        seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

      var obj = {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };

      var string = '';

      if (obj.days != 0) {
        string = string + '' + obj.days + ' Day(s) ';
      }

      if (obj.hours != 0) {
        string = string + '' + obj.hours + ' Hour(s) ';
      }

      if (obj.minutes != 0) {
        string = string + '' + obj.minutes + ' Minute(s) ';
      }

      if (obj.seconds != 0) {
        string = string + '' + obj.seconds + ' Second(s) ';
      }

    }
    return string;
  }

  getDurationDetails(startDate, endDate) {

    var date_future: any = new Date(endDate);

    var date_now: any = new Date(startDate);
    var seconds = Math.floor((date_future - date_now) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    var hours = hours - days * 24;
    var minutes = minutes - days * 24 * 60 - hours * 60;
    var seconds =
      seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

    var obj = {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };

    var string = '';

    if (obj.days != 0) {
      string = string + '' + obj.days + ' Day(s) ';
    }

    if (obj.hours != 0) {
      string = string + '' + obj.hours + ' Hour(s) ';
    }

    if (obj.minutes != 0) {
      string = string + '' + obj.minutes + ' Minute(s) ';
    }

    if (obj.seconds != 0) {
      string = string + '' + obj.seconds + ' Second(s) ';
    }

    return string;
  }

  getDifferenceDate(quiz) {
    var date_future: any = new Date(quiz.endDate);

    if (quiz.status != 'COMPLETED' && quiz.status != 'PENDING') {
      var date_future: any = new Date();
    }

    var date_now: any = new Date(quiz.startDate);

    var seconds = Math.floor((date_future - date_now) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    var hours = hours - days * 24;
    var minutes = minutes - days * 24 * 60 - hours * 60;
    var seconds =
      seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

    var obj = {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };

    var string = '';

    if (obj.days != 0) {
      string = string + '' + obj.days + ' Day(s) ';
    }

    if (obj.hours != 0) {
      string = string + '' + obj.hours + ' Hour(s) ';
    }

    if (obj.minutes != 0) {
      string = string + '' + obj.minutes + ' Minute(s) ';
    }

    if (obj.seconds != 0) {
      string = string + '' + obj.seconds + ' Second(s) ';
    }

    return string;
  }

  exportPdf() {
    const doc = new jsPDF();
    //doc.autoTable(this.exportColumns, this.records);
    //  var dd =
    //autoTable(doc, { html: "#my-table" });
    //autoTable(doc, { dd });   
    doc.save(this.studentFullName + '.pdf');
    /*import("jspdf").then((jsPDF) => {
        import("jspdf-autotable").then((x) => {
            const doc = new jsPDF.default(0, 0);
            doc.autoTable(this.exportColumns, this.records);
            doc.save("students.pdf");
        });
    });*/
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  async generatePdf() {
    this.dsProfileImg = await this.getBase64ImageFromURL(this.dsProfileImg);
    this.profileImg = await this.getBase64ImageFromURL(this.profileImg);
    let dd = {
      pageSize: 'LETTER', //pageSize: { width: 612.00, height: 792.00},
      pageMargins: [60.5, 0, 0, 0],
      background: [
        {
          image: this.headerBG,
          width: 612.0,
        },
      ],
      watermark: {
        text: 'saferoads.ph',
        color: '#0038a8',
        opacity: 0.05,
        bold: true,
        italics: false,
      },
      content: [
        [
          {
            image: 'dsLogo',
            alignment: 'left',
            fit: [100, 100],
            margin: [0, 50, 0, 0],
            //text:' ',
          },
          {
            text: this.dsCompanyName,
            style: 'companyName',
          },
          {
            text: this.dsAddress
              + '\n' +
              this.dsContacts,
            style: 'companyDetails',
          },
          {
            text: 'LTO Accreditation No.: ' + this.dsAccreditationNo,
            style: 'companyDetails',
          },
        ],
        {
          text: 'COURSE COMPLETION',
          style: 'headerSmall',
        },
        {
          text: 'CERTIFICATE',
          style: 'header',
        },
        {
          text: this.certificate.studentModuleSummary.courseDescription,
          style: 'forLabel',
        },
        {
          text: ' '
        },
        {
          text: 'Student Drivers Information',
          style: 'studentInformationLabel',
        },

        {
          layout: 'noBorders', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers

            widths: [82, 80, 308],

            body: [
              [
                {
                  rowSpan: 3,
                  fillColor: '#f8f8f8',
                  stack: [
                    {
                      image: 'studentPhoto',
                      alignment: 'center',
                      //width: 100,
                      //height:100,
                      fit: [100, 100],
                      margin: [10, 10, 1, 10],
                      //text:' ',
                    },
                  ],
                },
                {
                  text: 'Name',
                  fillColor: '#f8f8f8',
                  margin: [10, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.studentFullName,
                  fillColor: '#f8f8f8',
                  margin: [10, 5, 0, 0],
                  style: 'field',
                },
              ],
              [
                { text: '', fillColor: '#f8f8f8' },
                { text: 'Address', fillColor: '#f8f8f8', style: 'label', margin: [10, 5, 0, 0] },
                {
                  text: this.certificate.userFullAddress
                    ? this.certificate.userFullAddress
                    : 'N/A',
                  fillColor: '#f8f8f8',
                  margin: [10, 5, 0, 0],
                  style: 'field',
                },
              ],
              [
                { text: '', fillColor: '#f8f8f8' },
                {
                  text: 'Gender', fillColor: '#f8f8f8', style: 'label', margin: [10, 5, 0, 0]
                },
                {
                  text: this.gender,
                  fillColor: '#f8f8f8',
                  margin: [10, 5, 0, 0],
                  style: 'field',
                },
              ],
              [
                { text: '', fillColor: '#f8f8f8' },
                {
                  text: this.driverLicenseLabel,
                  fillColor: '#f8f8f8',
                  style: 'label', margin: [10, 5, 0, 0]
                },
                {
                  text: this.driverLicense
                    ? this.driverLicense
                    : ' ',
                  fillColor: '#f8f8f8',
                  style: 'field', margin: [10, 5, 0, 0]
                },
              ],
              [
                { text: '', fillColor: '#f8f8f8' },
                {
                  text: '',//'Birth Date', 
                  fillColor: '#f8f8f8', style: 'label', margin: [10, 5, 0, 0]
                },
                {
                  text: '',//moment(this.birthDate).format('DD-MMM-YYYY'),
                  fillColor: '#f8f8f8',
                  margin: [10, 5, 0, 0],
                  style: 'field',
                },
              ],
            ],
          },
        },

        {
          text: 'Course Information and Evaluation',
          style: 'studentInformationLabel',
        },

        {
          layout: 'noBorders', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers

            widths: [82, 160, 55, 180],

            body: [
              [
                {
                  text: 'Course Name',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.certificate.course.description
                    ? this.certificate.course.description
                    : 'N/A',
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
                {
                  text: 'Purpose',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.certificate.purpose
                    ? this.certificate.purpose
                    : 'N/A',
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
              ],
              [
                {
                  text: 'Date Started \n Total No. of Hrs ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text:
                    this.certificate.studentModuleSummary.startDateFormatted +
                    '\n' +
                    this.getDifferenceDate(
                      this.certificate.studentModuleSummary
                    ),
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
                {
                  text: 'Date Ended',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.certificate.studentModuleSummary.endDateFormatted,
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
              ],
              [
                {
                  text: 'Exam Result ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.certificate.studentModuleSummary.examResult,
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
                {
                  text: '',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: '',
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
              ],

            ],
          },
        },
        {
          layout: 'noBorders', // optional
          table: {
            body: [
              [
                {
                  text: 'Remarks ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: `${this.certificate.remarks}`,
                  margin: [25, 5, 0, 0],
                  style: 'field',
                },
              ],
              [
                {
                  text: 'Certificate No.',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.certificate.certificateNo,
                  margin: [25, 5, 0, 0],
                  style: 'field',
                },
              ],
              [
                {
                  text: 'Issuance Date',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.certificate.issuanceDate,
                  margin: [25, 5, 0, 0],
                  style: 'field',
                },
              ],
            ]
          },
        },
        {
          text: " ",
          style: 'label',
        },
        {
          text: this.note1,
          style: 'note',
        },
        {
          text: this.note2,
          style: 'note',
        },
        {
          qr: environment.url
            + '/qr/' +
            this.certificate.certificateNo,
          fit: '71',
          margin: [400, 0, 0, 0],
        },
        {
          text: '© ' + moment(new Date()).format('YYYY') + ' saferoads.ph Powered by Reviewmasters Inc. | Email: inquiry@saferoads.ph',
          style: 'footer',
        },
      ],
      images: {
        studentPhoto: this.profileImg,
        dsLogo: this.dsProfileImg
      },
      styles: {
        headerSmall: {
          fontSize: 10,
          bold: true,
          alignment: 'left',
          color: '#333232',
          margin: [0, 15, 0, 0],
        },
        header: {
          fontSize: 18,
          alignment: 'left',
          color: '#0038a8',
          bold: true,
        },
        forLabel: {
          fontSize: 10,
          bold: true,
          alignment: 'left',
          color: '#333232',
          margin: [0, 0, 0, 0],
        },
        studentInformationLabel: {
          fontSize: 9,
          bold: true,
          alignment: 'left',
          color: '#0038a8',
          margin: [0, 3, 0, 11],
        },
        companyName: { fontSize: 9, bold: true, color: '#333232' },
        companyDetails: { fontSize: 8, color: '#333232' },
        label: { fontSize: 9, color: '#333232' },
        field: { fontSize: 9, bold: true, color: '#000000' },
        note: { fontSize: 9, bold: true, color: '#FF0000' },
        footer: { fontSize: 6, color: '#333232' },
      },
    };
    pdfMake.createPdf(dd).download(this.studentFullName + '.pdf');
    //pdfMake.createPdf(dd).open();
    this.fileDownloaded = true;
    //console.log("Done");
    this.downloadDialog = false;
  }

  async generatePdfOTDC() {
    this.dsProfileImg = await this.getBase64ImageFromURL(this.dsProfileImg);
    this.profileImg = await this.getBase64ImageFromURL(this.profileImg);
    this.profileImg1 = await this.getBase64ImageFromURL(this.profileImg1);
    this.profileImg2 = await this.getBase64ImageFromURL(this.profileImg2);
    this.profileImg3 = await this.getBase64ImageFromURL(this.profileImg3);
    this.profileImg4 = await this.getBase64ImageFromURL(this.profileImg4);
    this.profileImg5 = await this.getBase64ImageFromURL(this.profileImg5);
    this.profileImg6 = await this.getBase64ImageFromURL(this.profileImg6);
    var dd = {
      pageSize: 'LETTER', //pageSize: { width: 612.00, height: 792.00},
      pageMargins: [60.5, 0, 0, 0],
      background: [
        {
          image: this.headerBG,
          width: 612.0,
        },
      ],
      watermark: {
        text: 'saferoads.ph',
        color: '#0038a8',
        opacity: 0.05,
        bold: true,
        italics: false,
      },
      content: [
        [
          {
            image: 'dsLogo',
            alignment: 'center',
            //width: 100,
            //height: 100,
            fit: [100, 100],
            margin: [1, 50, 1, 1],
          },
          {
            text: this.dsCompanyName,
            style: 'companyName',
            alignment: 'center',
          },
          {
            text: this.dsAddress + '\n' + this.dsContacts,
            style: 'companyDetails',
            alignment: 'center',
          },
          {
            text: 'LTO Accreditation No.: ' + this.dsAccreditationNo,
            style: 'companyDetails',
            alignment: 'center',
          },
        ],
        {
          text: 'COURSE COMPLETION',
          style: 'headerSmall',
        },
        {
          text: 'NOTIFICATION OF COMPLETION',
          style: 'header',
        },
        /*  {
           text: this.certificate.studentModuleSummary.courseDescription,
           style: 'forLabel',
         }, */
        {
          text: ' '
        },
        {
          text: "Student Driver's Information",
          style: 'studentInformationLabel',
        },
        {
          layout: 'noBorders', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            widths: [82, 80, 250],
            body: [
              [
                {
                  rowSpan: 3,
                  //fillColor: '#f8f8f8',
                  stack: [
                    {
                      image: 'studentPhoto',
                      alignment: 'left',
                      //width: 95,
                      //height:120,
                      fit: [100, 100],
                      margin: [1, 1, 1, 1],
                    },
                  ],
                },
                {
                  text: 'Name: ',
                  //fillColor: '#f8f8f8',
                  margin: [15, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.studentFullName,
                  //fillColor: '#f8f8f8',
                  margin: [10, 5, 0, 0],
                  style: 'field',
                },
              ],
              [
                {
                  text: '',
                  //fillColor: '#f8f8f8' 
                },
                {
                  text: 'Address: ',
                  // fillColor: '#f8f8f8', 
                  style: 'label',
                  margin: [15, 5, 0, 0]
                },
                {
                  text: this.studentFullAddress
                    ? this.studentFullAddress
                    : 'N/A',
                  //fillColor: '#f8f8f8',
                  margin: [10, 5, 0, 0],
                  style: 'field',
                },
              ],
              [
                {
                  text: '',
                  //fillColor: '#f8f8f8' 
                },
                {
                  text: 'Gender: ',
                  // fillColor: '#f8f8f8', 
                  style: 'label',
                  margin: [15, 5, 0, 0]
                },
                {
                  text: this.gender,
                  // fillColor: '#f8f8f8',
                  margin: [10, 5, 0, 0],
                  style: 'field',
                },
              ],
              /*   [
                  { text: '', 
                  //fillColor: '#f8f8f8' 
                },
                  {
                    text: 'Date Of Birth', 
                   // fillColor: '#f8f8f8', 
                    style: 'label',
                    margin: [15, 5, 0, 0]
                  },
                  {
                    text: moment(this.birthDate).format('DD-MMM-YYYY'),
                    //fillColor: '#f8f8f8',
                    margin: [10, 5, 0, 0],
                    style: 'field',
                  },
                 
                ],   */
            ],
          },
        },
        {
          text: ' ',
          style: 'studentInformationLabel',
        },
        {
          text: 'Course Information and Evaluation',
          style: 'studentInformationLabel',
        },
        {
          layout: 'noBorders', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            widths: [82, 160, 55, 180],
            body: [
              [
                {
                  text: 'Course Name: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.certificate.course.description
                    ? this.certificate.course.description
                    : 'N/A',
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
                {
                  text: '',//'Purpose',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: '',//this.certificate.purpose
                  // ? this.certificate.purpose
                  // : 'N/A',
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
              ],
              [
                {
                  text: 'Date Started: ',// \n Total No. of Hrs ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  //text:
                  //  this.certificate.studentModuleSummary.startDateFormatted, //+
                  text: moment(this.certificate.studentModuleSummary.startDateFormatted).format('DD-MMM-YYYY'),
                  //'\n' +
                  //this.getDifferenceDate(
                  //  this.certificate.studentModuleSummary
                  //),
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
                {
                  text: 'Date Ended: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  //text: this.certificate.studentModuleSummary.endDateFormatted,
                  text: moment(this.certificate.studentModuleSummary.endDateFormatted).format('DD-MMM-YYYY'),
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
              ],
              [
                {
                  //text: 'Rating',
                  text: 'Total No. of Hrs: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: '15 Hours',
                  //text: this.certificate.studentModuleSummary.examResult,
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
                {
                  text: 'Exam Result: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.certificate.studentModuleSummary.examResult, // `${this.certificate.studentModuleSummary.examScore}/${this.certificate.studentModuleSummary.examQuestions}`,
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
              ],

            ],
          },
        },
        {
          text: ' ',
          style: 'studentInformationLabel',
        },
        /*         {
                  layout: 'noBorders', // optional
                  table: {
                    body: [
                      [
                        {
                          text: this.topic1,
                          style: 'label',
                        },
                        {
                          text: this.topic2,
                          style: 'label',
                        },
                        {
                          text: this.topic3,
                          style: 'label',
                        },
                        {
                          text: this.topic4,
                          style: 'label',
                        },
                        {
                          text: this.topic5,
                          style: 'label',
                        },
                        {
                          text: this.topic6,
                          style: 'label',
                        },
                      ],
                      [
                        {
                          image: 'studentPhoto1',
                          alignment: 'center',
                          //width: 100,
                          //height:100,
                          fit: [70, 70],
                          margin: [1, 1, 1, 1],
                        },
                        {
                          image: 'studentPhoto2',
                          alignment: 'center',
                          //width: 100,
                          //height:100,
                          fit: [70, 70],
                          margin: [1, 1, 1, 1],
                        },
                        {
                          image: 'studentPhoto3',
                          alignment: 'center',
                          //width: 100,
                          //height:100,
                          fit: [70, 70],
                          margin: [1, 1, 1, 1],
                        },
                        {
                          image: 'studentPhoto4',
                          alignment: 'center',
                          //width: 100,
                          //height:100,
                          fit: [70, 70],
                          margin: [1, 1, 1, 1],
                        },
                        {
                          image: 'studentPhoto5',
                          alignment: 'center',
                          //width: 100,
                          //height:100,
                          fit: [70, 70],
                          margin: [1, 1, 1, 1],
                        },
                        {
                          image: 'studentPhoto6',
                          alignment: 'center',
                          //width: 100,
                          //height:100,
                          fit: [70, 70],
                          margin: [1, 1, 1, 1],
                        },
                      ],
                    ]
                  }
                }, */
        {
          layout: 'noBorders', // optional
          table: {
            body: [
              /*  [
      {
                    text: '',//'Remarks ',
                    margin: [0, 5, 0, 0],
                    style: 'label',
                  },
                  {
                    text: '',//`${this.certificate.remarks}`,
                    margin: [25, 5, 0, 0],
                    style: 'field',
                  }, 
                ],*/
              [
                {
                  text: 'Control No.: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.certificate.certificateNo,
                  margin: [25, 5, 0, 0],
                  style: 'field',
                },
              ],
              [
                {
                  text: 'Issuance Date: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  //text: this.certificate.issuanceDate,
                  text: moment(this.certificate.issuanceDate).format('DD-MMM-YYYY'),
                  margin: [25, 5, 0, 0],
                  style: 'field',
                },
              ],
            ]
          },
        },
        {
          text: " ",
          style: 'label',
        },
        /*         {
                  text: " ",
                  style: 'label',
                },  */
        {
          text: this.note1,
          style: 'note',
        },
        {
          text: this.note2,
          style: 'note',
        },
        {
          qr: environment.url + '/qr/' + this.certificate.certificateNo,
          fit: '71',
          margin: [400, 0, 0, 0],
        },
        {
          text: '© ' + moment(new Date()).format('YYYY') + ' saferoads.ph Powered by Reviewmasters Inc. | Email: inquiry@saferoads.ph',
          style: 'footer',
        },
      ],

      images: {
        studentPhoto: this.profileImg,
        //studentPhoto1: this.profileImg1,
        //studentPhoto2: this.profileImg2,
        //studentPhoto3: this.profileImg3,
        //studentPhoto4: this.profileImg4,
        //studentPhoto5: this.profileImg5,
        //studentPhoto6: this.profileImg6,
        dsLogo: this.dsProfileImg
      },

      styles: {
        headerSmall: {
          fontSize: 10,
          bold: true,
          alignment: 'left',
          color: '#333232',
          margin: [0, 15, 0, 0],
        },
        header: {
          fontSize: 16,
          alignment: 'left',
          color: '#0038a8',
          bold: true,
        },
        forLabel: {
          fontSize: 10,
          bold: true,
          alignment: 'left',
          color: '#333232',
          margin: [0, 0, 0, 0],
        },
        studentInformationLabel: {
          fontSize: 9,
          bold: true,
          alignment: 'left',
          color: '#0038a8',
          margin: [0, 3, 0, 10],
        },
        companyName: { fontSize: 9, bold: true, color: '#333232' },
        companyDetails: { fontSize: 8, color: '#333232' },
        label: { fontSize: 9, color: '#333232' },
        field: { fontSize: 9, bold: true, color: '#000000' },
        note: { fontSize: 9, bold: true, color: '#FF0000' },
        footer: { fontSize: 6, color: '#333232' },
      },
    };

    this.pdfObj = pdfMake.createPdf(dd);

    //console.log(this.pdfObj);

    var qr = "{ qr: 'text in QR', fit: '500' }";

    pdfMake.createPdf(dd).download(this.studentFullName + '.pdf');
    //pdfMake.createPdf(dd).open();
    this.fileDownloaded = true;
    //console.log("Done");
    this.downloadDialog = false;

    /*       if (this.plt.is('hybrid')) {
            this.pdfObj.getBase64(async (data) => {
              try {
                let path = `pdf/certificate_${Date.now()}.pdf`;
                const result = await Filesystem.writeFile({
                  path,
                  data,
                  directory: Directory.Documents,
                  recursive: true,
                });
      
                this.cmsService.successMessageToast('File Uri : ' + result.uri);
                //console.log('error');
      
                this.fileOpener
                  .open(`${result.uri}`, 'application/pdf')
                  .then(() => {
                    this.loadingCtrl.dismiss();
                  })
                  .catch((e) => {
                    this.cmsService.errorMessageToast('Error in File Opener : ' + e);
                    //console.log('error');
                    this.loadingCtrl.dismiss();
                  });
              } catch (e) {
                this.cmsService.errorMessageToast('Unnable to Write' + e);
                console.error('Unnable to write');
                this.loadingCtrl.dismiss();
              }
            });
            this.fileDownloaded=true;
          } else {
            this.fileDownloaded=true;
            this.pdfObj.download();
            this.loadingCtrl.dismiss();
          } */
  }

  async generatePdfODEP() {
    this.dsProfileImg = await this.getBase64ImageFromURL(this.dsProfileImg);
    this.profileImg = await this.getBase64ImageFromURL(this.profileImg);
    this.profileImg1 = await this.getBase64ImageFromURL(this.profileImg1);
    this.profileImg2 = await this.getBase64ImageFromURL(this.profileImg2);
    this.profileImg3 = await this.getBase64ImageFromURL(this.profileImg3);
    this.profileImg4 = await this.getBase64ImageFromURL(this.profileImg4);
    this.profileImg5 = await this.getBase64ImageFromURL(this.profileImg5);
    this.profileImg6 = await this.getBase64ImageFromURL(this.profileImg6);
    var dd = {
      pageSize: 'LETTER', //pageSize: { width: 612.00, height: 792.00},
      pageMargins: [60.5, 0, 0, 0],
      background: [
        {
          image: this.headerBG,
          width: 612.0,
        },
      ],
      watermark: {
        text: 'saferoads.ph',
        color: '#0038a8',
        opacity: 0.05,
        bold: true,
        italics: false,
      },
      content: [
        [
          {
            image: this.dsProfileImg,
            alignment: 'center',
            //width: 100,
            //height: 100,
            fit: [100, 100],
            margin: [0, 50, 0, 0],
          },
          {
            text: this.dsCompanyName.toUpperCase(),
            style: 'companyName',
            alignment: 'center',
          },
          {
            text: this.dsAddress.toUpperCase() + '\n' + this.dsContacts.toUpperCase(),
            style: 'companyDetails',
            alignment: 'center',
          },
          {
            text: 'LTO Accreditation No.: ' + this.dsAccreditationNo.toUpperCase(),
            style: 'companyDetails',
            alignment: 'center',
          },
        ],
        {
          text: ' ',
          style: 'forLabel',
          alignment: 'center',
        },
        {
          text: 'CERTIFICATE OF COMPLETION',
          style: 'header',
          alignment: 'center',
        },
        {
          text: 'DRIVING ENHANCEMENT PROGRAM',
          style: 'header',
          alignment: 'center',
        },
        {
          text: this.certificate.studentModuleSummary.courseDescription,
          style: 'forLabel',
          alignment: 'center',
        },
        {
          text: ' ',
          style: 'forLabel',
          alignment: 'center',
        },
        {
          text: 'Driver Information',
          style: 'studentInformationLabel',
        },
        {
          layout: 'noBorders',
          table: {
            widths: [82, 160, 55, 180],
            body: [
              [
                {
                  text: 'LTO Client ID: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.ltoClientId ? this.ltoClientId : ' ',
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
                {
                  text: 'DL No.: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.driverLicense
                    ? this.driverLicense
                    : ' ',
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
              ]
            ]
          }
        },
        /*         {
                  layout: 'noBorders', // optional
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
        
                    widths: [82, 80, 250],
        
                    body: [
                      [
                         {
                          rowSpan: 2,
                          fillColor: '#f8f8f8',
                          stack: [
                            {
                              image: this.profileImg,
                              alignment: 'right',
                              //width: 100,
                              //height:100,
                              fit: [95, 95],
                              margin: [1, 1, -7, 1],
                            },
                          ],
                        }, 
                        {
                          text: 'Name',
                          fillColor: '#f8f8f8',
                          margin: [10, 5, 0, 0],
                          style: 'label',
                        },
                        {
                          text: this.studentFullName,               
                          fillColor: '#f8f8f8',
                          margin: [10, 5, 0, 0],
                          style: 'field',
                        },
                      ],
                      [
                        { text: '', fillColor: '#f8f8f8' },
                        { text: 'Address', fillColor: '#f8f8f8', style: 'label' ,margin: [10, 5, 0, 0]},
                        {
                          text: this.certificate.userFullAddress
                            ? this.certificate.userFullAddress
                            : 'N/A',
                          fillColor: '#f8f8f8',
                          margin: [10, 5, 0, 0],
                          style: 'field',
                        },
                      ],
                       [
                        { text: '', fillColor: '#f8f8f8' },
                        {
                          text: 'Gender', 
                          fillColor: '#f8f8f8', 
                          style: 'label',
                          margin: [10, 5, 0, 0]
                        },
                        {
                          text: this.gender,
                          fillColor: '#f8f8f8',
                          margin: [10, 5, 0, 0],
                          style: 'field',
                        },
                      ],   
                      [
                        { text: '', fillColor: '#f8f8f8' },
                        {
                          text: this.driverLicenseLabel,
                          fillColor: '#f8f8f8',
                          style: 'label', margin: [10, 5, 0, 0]
                        },
                        {
                          text: this.driverLicense
                            ? this.driverLicense
                            : ' ',
                          fillColor: '#f8f8f8',
                          style: 'field',margin: [10, 5, 0, 0]
                        },
                      ],  
                        [
                        { text: '', fillColor: '#f8f8f8' },
                        {
                          text: 'Date Of Birth', 
                          fillColor: '#f8f8f8', style: 'label',margin: [10, 5, 0, 0]
                        },
                        {
                          text: moment(this.birthDate).format('DD-MMM-YYYY'),
                          fillColor: '#f8f8f8',
                          margin: [10, 5, 0, 0],
                          style: 'field',
                        },
                       
                      ],                                       
                    ],
                  },
                }, */
        {
          layout: 'noBorders',
          table: {
            widths: [82, 160, 55, 180],
            body: [
              [

                {
                  text: 'Name: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.studentFullName.toUpperCase(),
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
                {
                  text: '',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: '',
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
              ]
            ]
          }
        },
        {
          layout: 'noBorders',
          table: {
            widths: [82, 350, 55, 180],
            body: [
              [
                {
                  text: 'Address: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.studentFullAddress.toUpperCase()
                    ? this.studentFullAddress.toUpperCase()
                    : 'N/A',
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
                {
                  text: '',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: '',
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },

              ]
            ]
          }
        },
        {
          layout: 'noBorders',
          table: {
            widths: [82, 160, 55, 180],
            body: [
              [
                {
                  text: 'Date of Birth: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: moment(this.birthDate).format('DD-MMM-YYYY'),
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
                {
                  text: 'Nationality: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.nationality.toUpperCase(),
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
              ]
            ]
          }
        },
        {
          layout: 'noBorders',
          table: {
            widths: [82, 160, 55, 180],
            body: [
              [
                {
                  text: 'Gender: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.gender.toUpperCase(),
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
                {
                  text: 'Civil Status: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.civilStatus.toUpperCase(),
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
              ]
            ]
          }
        },
        {
          text: 'Driving Course Training Information',
          style: 'studentInformationLabel',
        },
        {
          layout: 'noBorders', // optional
          table: {
            widths: [82, 160, 55, 180],
            body: [
              [
                {
                  text: 'Date Started: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  //text:  moment(this.certificate.studentModuleSummary.startDateFormatted).format('DD-MMM-YYYY HH:MM'),
                  text: moment(this.certificate.studentModuleSummary.startDateFormatted).format('DD-MMM-YYYY'),
                  //this.certificate.studentModuleSummary.startDateFormatted ,
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
                {
                  text: 'Date Ended: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  //text: moment(this.certificate.studentModuleSummary.endDateFormatted).format('DD-MMM-YYYY HH:MM'),
                  text: moment(this.certificate.studentModuleSummary.endDateFormatted).format('DD-MMM-YYYY'),
                  //this.certificate.studentModuleSummary.endDateFormatted,
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
              ],
              [
                {
                  text: 'Total No. of Hrs: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  // text: this.getDifferenceDate(
                  //     this.certificate.studentModuleSummary
                  //   ),
                  text: "5 Hours",
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
                {
                  text: 'Exam Result: ',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.certificate.studentModuleSummary.examResult,
                  margin: [0, 5, 0, 0],
                  style: 'field',
                },
              ]
            ]
          }
        },
        /*         {
                  layout: 'noBorders', // optional
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    widths: [82, 160, 55, 180],
                    body: [
                      [
                        {
                          text: '',//'Course Name',
                          margin: [0, 5, 0, 0],
                          style: 'label',
                        },
                        {
                          text: '',//this.certificate.course.description
                            //? this.certificate.course.description
                            //: 'N/A',
                          margin: [0, 5, 0, 0],
                          style: 'field',
                        },
                        {
                          text: '',//'Purpose',
                          margin: [0, 5, 0, 0],
                          style: 'label',
                        },
                        {
                          text: '',//this.certificate.purpose
                           // ? this.certificate.purpose
                           // : 'N/A',
                          margin: [0, 5, 0, 0],
                          style: 'field',
                        },
                      ],
                      [
                        {
                          text: 'Date Started' +'\n Total No. of Hrs ',
                          margin: [0, 5, 0, 0],
                          style: 'label',
                        },
                        {
                          text:
                            this.certificate.studentModuleSummary.startDateFormatted +
                            '\n' +
                            this.getDifferenceDate(
                              this.certificate.studentModuleSummary
                            ),
                          margin: [0, 5, 0, 0],
                          style: 'field',
                        },
                        {
                          text: 'Date Ended',
                          margin: [0, 5, 0, 0],
                          style: 'label',
                        },
                        {
                          text: this.certificate.studentModuleSummary.endDateFormatted,
                          margin: [0, 5, 0, 0],
                          style: 'field',
                        },
                      ],
                      [
                        {
                          text: 'Exam Result ',
                          margin: [0, 5, 0, 0],
                          style: 'label',
                        },
                        {
                          text: this.certificate.studentModuleSummary.examResult, // `${this.certificate.studentModuleSummary.examScore}/${this.certificate.studentModuleSummary.examQuestions}`,
                          margin: [0, 5, 0, 0],
                          style: 'field',
                        },
                        {
                          //text: 'Rating',
                          text: '',
                          margin: [0, 5, 0, 0],
                          style: 'label',
                        },
                        {
                          text: '',
                          //text: this.certificate.studentModuleSummary.examResult,
                          margin: [0, 5, 0, 0],
                          style: 'field',
                        },
                      ],
        
                    ],
                  },
                }, */
        {
          text: ' ',
          style: 'studentInformationLabel',
        },
        {
          text: 'This Certificate with Control Number ',
          style: 'label',
          alignment: 'center'
        },
        {
          text: this.certificate.certificateNo,
          style: 'labelBold',
          alignment: 'center'
        },
        {
          text: " has been issued for the initial renewal application  of the five-year validaty of driver's license \n with the Land Transportation Office" +
            " in complience with Section 27 of the R.A. No. 10930.",
          style: 'label',
          alignment: 'center'
        },
        {
          text: ' ',
          style: 'studentInformationLabel',
        },
        {
          text: '',
          style: 'studentInformationLabel',
        },
        {
          text: this.dsInstructor.toUpperCase() ? this.dsInstructor.toUpperCase() : ' ',
          style: 'field',
          alignment: 'center'
        },
        {
          text: '_________________________________',
          style: 'label',
          alignment: 'center'
        },
        {
          text: 'DEC Lecturer',
          style: 'label',
          alignment: 'center'
        },

        {
          text: ' ',
          style: 'studentInformationLabel',
        },

        {
          text: this.dsCompanyName.toUpperCase() ? this.dsCompanyName.toUpperCase() : ' ',
          style: 'field',
          alignment: 'center'
        },
        {
          text: '_________________________________',
          style: 'label',
          alignment: 'center'
        },
        {
          text: "Driver's Education Center (DEC)",
          style: 'label',
          alignment: 'center'
        },
        {
          text: '',
          style: 'studentInformationLabel',
          alignment: 'center'
        },

        /*         {
                  layout: 'noBorders', // optional
                  table: {
                    body: [
                      [
                        {
                          text: this.topic1,
                          style: 'label',
                        },
                        {
                          text: this.topic2,
                          style: 'label',
                        },
                        {
                          text: this.topic3,
                          style: 'label',
                        },
                        {
                          text: this.topic4,
                          style: 'label',
                        },
                        {
                          text: this.topic5,
                          style: 'label',
                        },
                        {
                          text: this.topic6,
                          style: 'label',
                        },
                      ],
                      [
                        {
                          image: 'studentPhoto1',
                          alignment: 'center',
                          //width: 100,
                          //height:100,
                          fit: [70, 70],
                          margin: [1, 1, 1, 1],
                        },
                        {
                          image: 'studentPhoto2',
                          alignment: 'center',
                          //width: 100,
                          //height:100,
                          fit: [70, 70],
                          margin: [1, 1, 1, 1],
                        },
                        {
                          image: 'studentPhoto3',
                          alignment: 'center',
                          //width: 100,
                          //height:100,
                          fit: [70, 70],
                          margin: [1, 1, 1, 1],
                        },
                        {
                          image: 'studentPhoto4',
                          alignment: 'center',
                          //width: 100,
                          //height:100,
                          fit: [70, 70],
                          margin: [1, 1, 1, 1],
                        },
                        {
                          image: 'studentPhoto5',
                          alignment: 'center',
                          //width: 100,
                          //height:100,
                          fit: [70, 70],
                          margin: [1, 1, 1, 1],
                        },
                        {
                          image: 'studentPhoto6',
                          alignment: 'center',
                          //width: 100,
                          //height:100,
                          fit: [70, 70],
                          margin: [1, 1, 1, 1],
                        },
                      ],
                    ]
                  }
                }, */
        {
          layout: 'noBorders', // optional
          table: {
            body: [
              /*  [
      {
                    text: '',//'Remarks ',
                    margin: [0, 5, 0, 0],
                    style: 'label',
                  },
                  {
                    text: '',//`${this.certificate.remarks}`,
                    margin: [25, 5, 0, 0],
                    style: 'field',
                  }, 
                ],*/
              /*   [
                {
                  text: 'Control No.',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: this.certificate.certificateNo,
                  margin: [25, 5, 0, 0],
                  style: 'field',
                },
                ], */
              [
                {
                  text: 'Issuance Date',
                  margin: [0, 5, 0, 0],
                  style: 'label',
                },
                {
                  text: moment(this.certificate.issuanceDate).format('DD-MMM-YYYY'),
                  //this.certificate.issuanceDate,
                  margin: [25, 5, 0, 0],
                  style: 'field',
                },
              ],
            ]
          },
        },
        /*  {
           text: " ",
           style: 'label',
         },  */
        /*         {
                  text: " ",
                  style: 'label',
                },  */
        /*  {
           text: this.note1,
           style: 'note',
         },  
         {
           text: this.note2,
           style: 'note',
         },  */
        {
          qr: environment.url + '/qr/' + this.certificate.certificateNo,
          fit: '71',
          margin: [400, 0, 0, 0],
        },
        {
          text: '© ' + moment(new Date()).format('YYYY') + ' saferoads.ph Powered by Reviewmasters Inc. | Email: inquiry@saferoads.ph',
          style: 'footer',
        },
      ],
      images: {
        studentPhoto: this.profileImg,
        //studentPhoto1: this.profileImg1,
        //studentPhoto2: this.profileImg2,
        //studentPhoto3: this.profileImg3,
        //studentPhoto4: this.profileImg4,
        //studentPhoto5: this.profileImg5,
        //studentPhoto6: this.profileImg6,
        dsLogo: this.dsProfileImg
      },

      styles: {
        headerSmall: {
          fontSize: 10,
          bold: true,
          alignment: 'left',
          color: '#333232',
          margin: [0, 15, 0, 0],
        },
        header: {
          fontSize: 15,
          alignment: 'left',
          color: '#0038a8',
          bold: true,
        },
        forLabel: {
          fontSize: 10,
          bold: true,
          alignment: 'left',
          color: '#333232',
          margin: [0, 0, 0, 0],
        },
        studentInformationLabel: {
          fontSize: 9,
          bold: true,
          alignment: 'left',
          color: '#0038a8',
          margin: [0, 2, 0, 4],
        },
        companyName: { fontSize: 9, bold: true, color: '#333232' },
        companyDetails: { fontSize: 8, color: '#333232' },
        label: { fontSize: 9, color: '#333232' },
        labelBold: { fontSize: 9, color: '#333232', bold: true },
        field: { fontSize: 9, bold: true, color: '#000000' },
        note: { fontSize: 9, bold: true, color: '#FF0000' },
        footer: { fontSize: 6, color: '#333232' },
      },
    };

    this.pdfObj = pdfMake.createPdf(dd);

    //console.log(this.pdfObj);

    var qr = "{ qr: 'text in QR', fit: '500' }";

    pdfMake.createPdf(dd).download(this.studentFullName + '.pdf');
    //pdfMake.createPdf(dd).open();
    this.fileDownloaded = true;
    //console.log("Done");
    this.downloadDialog = false;

    /*       if (this.plt.is('hybrid')) {
            this.pdfObj.getBase64(async (data) => {
              try {
                let path = `pdf/certificate_${Date.now()}.pdf`;
                const result = await Filesystem.writeFile({
                  path,
                  data,
                  directory: Directory.Documents,
                  recursive: true,
                });
      
                this.cmsService.successMessageToast('File Uri : ' + result.uri);
                //console.log('error');
      
                this.fileOpener
                  .open(`${result.uri}`, 'application/pdf')
                  .then(() => {
                    this.loadingCtrl.dismiss();
                  })
                  .catch((e) => {
                    this.cmsService.errorMessageToast('Error in File Opener : ' + e);
                    //console.log('error');
                    this.loadingCtrl.dismiss();
                  });
              } catch (e) {
                this.cmsService.errorMessageToast('Unnable to Write' + e);
                console.error('Unnable to write');
                this.loadingCtrl.dismiss();
              }
            });
            this.fileDownloaded=true;
          } else {
            this.fileDownloaded=true;
            this.pdfObj.download();
            this.loadingCtrl.dismiss();
          } */
  }

  async loadFaceCapturedLogs(studentId: any) {
    await this.userService.getStudentFaceCapturedLogs(studentId).then((res: any) => {
      this.faceCapturedLogs = res.result;
      //console.log( this.faceCapturedLogs) ;      
      let index = 0;
      for (let faceCapturedLog of this.faceCapturedLogs) {

        switch (index) {
          case 0:
            this.topic1 = faceCapturedLog.topicCode;
            this.profileImg1 = faceCapturedLog.photo;
            break;
          case 1:
            this.topic2 = faceCapturedLog.topicCode;
            this.profileImg2 = faceCapturedLog.photo;
            break;
          case 2:
            this.topic3 = faceCapturedLog.topicCode;
            this.profileImg3 = faceCapturedLog.photo;
            break;
          case 3:
            this.topic4 = faceCapturedLog.topicCode;
            this.profileImg4 = faceCapturedLog.photo;
            break;
          case 4:
            this.topic5 = faceCapturedLog.topicCode;
            this.profileImg5 = faceCapturedLog.photo;
            break;
          case 5:
            this.topic6 = faceCapturedLog.topicCode;
            this.profileImg6 = faceCapturedLog.photo;
            break;
        }

        index++;
      }

      if (index == 4) {
        this.topic6 = "";
        this.profileImg6 = 'assets/img/cert/white.jpg';
      } else if (index == 3) {
        this.topic6 = "";
        this.profileImg6 = 'assets/img/cert/white.jpg';
        this.topic5 = "";
        this.profileImg5 = 'assets/img/cert/white.jpg';
      } else if (index == 2) {
        this.topic6 = "";
        this.profileImg6 = 'assets/img/cert/white.jpg';
        this.topic5 = "";
        this.profileImg5 = 'assets/img/cert/white.jpg';
        this.topic4 = "";
        this.profileImg4 = 'assets/img/cert/white.jpg';
      } else if (index == 1) {
        this.topic6 = "";
        this.profileImg6 = 'assets/img/cert/white.jpg';
        this.topic5 = "";
        this.profileImg5 = 'assets/img/cert/white.jpg';
        this.topic4 = "";
        this.profileImg4 = 'assets/img/cert/white.jpg';
        this.topic3 = "";
        this.profileImg3 = 'assets/img/cert/white.jpg';
      } else if (index == 0) {
        this.topic6 = "";
        this.profileImg6 = 'assets/img/cert/white.jpg';
        this.topic5 = "";
        this.profileImg5 = 'assets/img/cert/white.jpg';
        this.topic4 = "";
        this.profileImg4 = 'assets/img/cert/white.jpg';
        this.topic3 = "";
        this.profileImg3 = 'assets/img/cert/white.jpg';
        this.topic2 = "";
        this.profileImg2 = 'assets/img/cert/white.jpg';
      }

    });
  }

  showFaceCaptured(record) {
    this.viewFaceCapturedDialog = true;
    this.loadFaceCapturedLogs(record.id);
  }

  addHoursToDate(date: Date, hours: number): Date {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }
}
