import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import {
    StudentTransaction,
    ValidatePayment,
    Verification,
} from "../../interfaces/Payment";
import { PaymentsService } from "../../services/payments.service";
import { StorageService } from "../../services/storage.service";
import { UsersService } from "../../services/users.service";
import * as moment from "moment";
import * as FileSaver from "file-saver";


@Component({
    selector: "app-verify-student-payments",
    templateUrl: "./verify-student-payments.component.html",
    styleUrls: ["./verify-student-payments.component.scss"],
    providers: [MessageService, ConfirmationService],
})
export class VerifyStudentPaymentsComponent implements OnInit {
    loading: boolean = true;
    studentTransaction = new StudentTransaction();
    validatePayment = new ValidatePayment();
    recordDialog: boolean;
    records;
    record;
    recordInfo;
    selectedRecords: Verification[];
    submitted: boolean;
    statuses: any[];
    state: string = "new";
    accountValidated;
    optionStatus = [
        { label: "PENDING", value: "PENDING" },
        { label: "APPROVED", value: "APPROVED" },
        { label: "INVALID", value: "INVALID" },
    ];

    credits;
    subscriptionType;
    schoolProfile;
    viewStudentInfoDialog:boolean;
    constructor(
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private paymentService: PaymentsService,
        private storageService: StorageService,
        private userService: UsersService
    ) {}

    ngOnInit(): void {
        this.loadAccountValidated();
        this.loadData();
    }

    viewStudentInfo(id){
        this.viewStudentInfoDialog = true;
        this.userService.getStudentProfile(id).then((res: any) => {
            this.recordInfo = res.result[0];
            this.recordInfo.birthDate = this.convertDate(this.recordInfo.birthDate);
            //console.log( this.recordInfo);
          })
          .catch((err) => {
            this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Error in Loading Student.",
                life: 3000,
            });
        });
       
      }

      convertDate(date) {
        return moment(new Date(date)).format("YYYY-MM-DD");
    }

    loadAccountValidated() {
        this.storageService.get('accountValidated').then((response:any) => {
            this.accountValidated = response;
        })
    }

    loadData() {
        this.loadSchoolCredits();
        this.loadVerifications();
        
    }

    loadVerifications() {
        this.paymentService
            .getSchoolStudentVerificationPending()
            .then((response: any) => {
                this.records = response.result;
                this.loading = false;
                //console.log(this.records)
            });
    }

    loadSchoolProfile() {
        this.userService
            .getDrivingSchoolProfile()
            .then((response: any) => {
                this.schoolProfile = response.result[0];
                this.subscriptionType = this.schoolProfile.subscriptionType;
                this.credits = {
                    SP: this.schoolProfile.spQuantity,
                    NP: this.schoolProfile.npQuantity,
                    PR: this.schoolProfile.prQuantity,
                };
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            });
    }

    loadSchoolCredits() {
        this.loadSchoolProfile();
        // this.storageService.get('credits').then((response:any)=
        //     this.credits = JSON.parse(response);
        // });
        // this.storageService.get('subscriptionType').then((response:any) => {
        //     this.subscriptionType = response;
        // })
    }

    editRecord(record: any) {
        this.record = { ...record };
        this.recordDialog = true;
        this.state = "edit";
        this.validatePayment.studentId = record.userProfileId;
    }

    sendTransaction() {
        this.submitted = true;
        //console.log(this.validatePayment);

        if (this.subscriptionType != "null") {
            if (
                this.subscriptionType === "PREPAID" &&
                this.credits[this.record.courseCode] == 0
            ) {
                this.recordDialog = false;
                this.submitted = false;
                this.messageService.add({
                    severity: "error",
                    summary:
                        "Your out of Credits. Kindly Buy/Reload for this course.",
                    detail: "",
                    life: 3000,
                });
                this.loadData();
                return;
            } else if (
                this.schoolProfile.subscriptionType === "SUBSCRIPTION" &&
                moment(this.schoolProfile.subscriptionExpiration).format(
                    "YYYY-MM-DD"
                ) < moment(new Date()).format("YYYY-MM-DD")
            ) {
                this.recordDialog = false;
                this.submitted = false;
                this.messageService.add({
                    severity: "error",
                    summary:
                        "Your subscription is expired. Kindly renew your subscription.",
                    detail: "",
                    life: 3000,
                });
                this.loadData();
                return;
            }
        }

        this.paymentService
            .validatePayment(this.validatePayment)
            .then((response: any) => {
                this.submitted = false;
                this.recordDialog = false;
                this.loadData();
                this.messageService.add({
                    severity: "success",
                    summary: "Validating payment was successful.",
                    detail: "",
                    life: 3000,
                });
            })
            .catch((err) => {
                this.submitted = false;
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            });
    }

    hideDialog() {
        this.recordDialog = false;
        this.submitted = false;
        this.viewStudentInfoDialog=false;
    }

    exportExcel() {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(this.records);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, "students_payments");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(
            data,
            fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
    }
}
