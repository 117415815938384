import { Component, OnInit } from "@angular/core";
import { Address, School, User, Login } from "../saferoadsph/interfaces/User";
import { CmsService } from "../saferoadsph/services/cms.service";
import { ValidationService } from "../saferoadsph/services/validation.service";
import _ from "lodash";
import { UsersService } from "../saferoadsph/services/users.service";
import { StorageService } from "../saferoadsph/services/storage.service";
import { ConfirmationService } from "primeng/api";

@Component({
    selector: "app-register",
    templateUrl: "./app.register.component.html",
    providers: [ConfirmationService],
})
export class AppRegisterComponent implements OnInit {
    display: boolean = true;
    submitted: Boolean;
    registering: Boolean = false;
    user: User = new User();
    school: School = new School();
    address: Address = new Address();
    errors: any[] = [];
    blocks;

    confirmPassword = "";

    genderOption = [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
    ];

    constructor(
        private validationService: ValidationService,
        private cmsService: CmsService,
        private userService: UsersService,
        private storageService: StorageService
    ) {}

    ngOnInit(): void {
        this.getAllRegions();
        this.user.gender = "Male";
        this.loadContent();
    }

    loadContent() {
        this.cmsService.getContent("register").then((res: any) => {
            var blocks = [];
            _.forEach(res.result, function (value, key) {
                blocks[value.block] = value.content;
            });
            this.blocks = blocks;
            //console.log(blocks);
        });
    }

    validateEmail(email) {
        const regularExpression =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regularExpression.test(String(email).toLowerCase());
    }

    register() {
        if (
            this.validationService.validateUser(this.user) &&
            this.validationService.validateAddress(this.address) &&
            this.validationService.validateSchoolDetails(this.school) &&
            this.user.password == this.confirmPassword
        ) {
            this.user.address = this.address;
            this.user.school = this.school;
            this.user.userType = "SCHOOL";
            this.registering = true;
            this.userService
                .register(this.user)
                .then((res: any) => {
                    if (res.statusCode == 200) {
                        var login: Login = new Login();
                        login.email = this.user.email;
                        login.password = this.user.password;
                        this.userService
                            .login(login)
                            .then((res) => {
                                this.storageService
                                    .set("email", this.user.email)
                                    .then((res1) => {
                                        location.reload();
                                    });
                            })
                            .catch((err) => {
                                this.registering = false;
                            });
                    }

                    if (res.statusCode == 409) {
                        this.errors = res.errorMessage;
                        this.registering = false;
                        console.log(this.errors);
                        //console.log(res);
                    }

                    if (res.statusCode == 404) {
                        this.errors = res.errorMessage;
                        this.registering = false;
                        console.log(this.errors);
                    }
                })
                .catch((err) => {
                    this.registering = false;
                    console.log(err);
                });
        }

        this.submitted = true;
    }

    // Get Address Settings

    regions: any[];
    provinces: any[];
    cities: any[];

    getAllRegions() {
        this.cmsService.getRegions().then((res: any) => {
            if (res.statusCode == 200) {
                this.regions = res.result;
                this.regions.push({ regionName: null });
                this.regions = _.map(this.regions, function (value) {
                    return {
                        label: value.regionName ? value.regionName : "",
                        value: value.regionName,
                    };
                });
                //console.log(this.regions);
            } else {
            }
        });
    }

    changeRegion($e) {
        this.cmsService.getProvincesByRegionName($e.value).then((res: any) => {
            if (res.statusCode == 200) {
                this.provinces = res.result;
                this.provinces.push({ stateName: null });
                this.provinces = _.map(this.provinces, function (value) {
                    return {
                        label: value.stateName ? value.stateName : "",
                        value: value.stateName,
                    };
                });
                this.address.province = null;
            } else {
            }
        });
    }

    changeProvince($e) {
        this.cmsService.getCitiesByProvinceName($e.value).then((res: any) => {
            if (res.statusCode == 200) {
                this.cities = res.result;
                this.cities.push({ cityName: null });
                this.cities = _.map(this.cities, function (value) {
                    return {
                        label: value.cityName ? value.cityName : "",
                        value: value.cityName,
                    };
                });
                this.address.city = null;
            } else {
            }
        });
    }
}
