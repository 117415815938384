import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class ReportsService {
    constructor(private httpClient: HttpClient) {}

    studentListStatusReport() {
        return this.httpClient
            .get(environment.reportServiceURL + "/reports/student-list")
            .toPromise();
    }

    studentListStatusReportSchool(id) {
        return this.httpClient
            .get(environment.reportServiceURL + "/reports/student-list-by-driving-school/"+id)
            .toPromise();
    }
}
