import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { Address, School } from "../../interfaces/User";
import { CmsService } from "../../services/cms.service";
import { ValidationService } from "../../services/validation.service";
import * as moment from "moment";
import _ from "lodash";
import { UsersService } from "../../services/users.service";
import { QRCodeModule } from 'angular2-qrcode';
import { environment } from "src/environments/environment";

@Component({
    selector: "app-driving-school",
    templateUrl: "./driving-school.component.html",
    styleUrls: ["./driving-school.component.scss"],
    providers: [MessageService],
})
export class DrivingSchoolComponent implements OnInit {
    school: School = new School();
    address: Address = new Address();
    submitted: Boolean = false;
    regions;
    provinces;
    cities;
    qrCode;
    branchQRCode;
    companyName;
    fullAddress;
    contactDetails1;
    contactDetails2;
    logo;
    constructor(
        private validationService: ValidationService,
        private cmsService: CmsService,
        private messageService: MessageService,
        private userService: UsersService, 
        private QRCode: QRCodeModule,
    ) {}

    ngOnInit(): void {
        this.getAllRegions();
        this.loadSchoolProfile();
    }

    formatDate(d) {
        return moment(new Date(d)).format("YYYY-MM-DD");
      }

    loadSchoolProfile() {
        this.school = null;
        this.userService
            .getDrivingSchoolProfile()
            .then((response: any) => {
                this.school = response.result[0];
                //console.log(response.result[0]);
                this.loadAddressOfProfile(this.school);
                this.address = this.school.address;
                this.qrCode = environment.qrCodeCreateAccountURL + this.school.referralCode;
                this.branchQRCode = environment.branchQRCodeCreateAccountURL +this.school.customId;
                this.companyName = this.school.companyName.toUpperCase();
                this.fullAddress = this.school.fullAddress;
                this.contactDetails1 ="Email: "+ this.school.companyEmail;
                this.contactDetails2 ="Tel. No.: "+this.school.telephone +" | Mobile No.: "+this.school.mobile;
                this.logo = this.school.logo;
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            });
    }

    uploading = false;
    uploadCompanyLogo() {
        this.uploading = true;
        const formData = new FormData();
        formData.append("file", this.imgFile);
        this.userService
            .uploadCompanyLogo(formData)
            .toPromise()
            .then((res) => {
                this.uploading = false;
                this.imgFile = null;
                this.loadSchoolProfile();
            })
            .catch((err) => {
                this.imgFile = null;
                this.uploading = false;
            });
    }

    imgFile;
    onFileChange($e) {
        this.imgFile = $e.target.files[0];
        //console.log(this.imgFile);
    }

    save() {
        this.address.latitude = this.school.latitude;
        this.address.longitude = this.school.longitude;
        this.school.address = this.address;
        this.userService.updateDrivingSchoolProfile(this.school).then((res) => {
            this.messageService.add({
                severity: "success",
                summary: "Success",
                detail: "Driving School Profile Updated.",
                life: 3000,
            });
            this.loadSchoolProfile();
        });
    }

    getAllRegions() {
        this.cmsService.getRegions().then((res: any) => {
            if (res.statusCode == 200) {
                this.regions = res.result;
                this.regions.push({ regionName: null });
                this.regions = _.map(this.regions, function (value) {
                    return {
                        label: value.regionName ? value.regionName : "",
                        value: value.regionName,
                    };
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            }
        });
    }

    changeRegion($e) {
        this.cmsService.getProvincesByRegionName($e.value).then((res: any) => {
            if (res.statusCode == 200) {
                this.provinces = res.result;
                this.provinces.push({ stateName: null });
                this.provinces = _.map(this.provinces, function (value) {
                    return {
                        label: value.stateName ? value.stateName : "",
                        value: value.stateName,
                    };
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            }
        });
    }

    changeProvince($e) {
        this.cmsService.getCitiesByProvinceName($e.value).then((res: any) => {
            if (res.statusCode == 200) {
                this.cities = res.result;
                this.cities.push({ cityName: null });
                this.cities = _.map(this.cities, function (value) {
                    return {
                        label: value.cityName ? value.cityName : "",
                        value: value.cityName,
                    };
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            }
        });
    }

    validateEmail(email) {
        return this.validationService.validateEmail(email);
    }

    loadAddressOfProfile(record) {
        this.cmsService
            .getProvincesByRegionName(record.address.region)
            .then((res: any) => {
                if (res.statusCode == 200) {
                    this.provinces = res.result;
                    this.provinces = _.map(this.provinces, function (value) {
                        return {
                            label: value.stateName,
                            value: value.stateName,
                        };
                    });
                    this.cmsService
                        .getCitiesByProvinceName(record.address.province)
                        .then((res: any) => {
                            if (res.statusCode == 200) {
                                this.cities = res.result;
                                this.cities = _.map(
                                    this.cities,
                                    function (value) {
                                        return {
                                            label: value.cityName,
                                            value: value.cityName,
                                        };
                                    }
                                );
                                record.address.city = record.address.city;
                            }
                        });
                }
            });
    }
}
