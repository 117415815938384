import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { SchoolAccountPaymentOption } from 'src/app/saferoadsph/interfaces/Payment';
import { PaymentsService } from 'src/app/saferoadsph/services/payments.service';

@Component({
  selector: 'app-billing-payment-method',
  templateUrl: './billing-payment-method.component.html',
  styleUrls: ['./billing-payment-method.component.scss'],
  providers: [MessageService, ConfirmationService] 
})
export class BillingPaymentMethodComponent implements OnInit {
  recordDialog: boolean;
  photoDialog: boolean;
  records: SchoolAccountPaymentOption[];
  record: SchoolAccountPaymentOption;
  systemPaymentOption;
  selectedRecords: SchoolAccountPaymentOption[];
  submitted: boolean;
  statuses: any[];
  state: string = "new";
  dsProfileId;

  
  constructor(
      private messageService: MessageService,
      private confirmationService: ConfirmationService,
      private paymentService: PaymentsService
  ) {}

  ngOnInit(): void {
      this.loadPaymentOptions();
  }

  loadPaymentOptions() {
      this.paymentService.getSysPaymentOption().then((response: any) => {
          //console.log(response.result)
          this.records = response.result;
          this.systemPaymentOption = this.records;
      });
  }

  openNew() {
      this.record = new SchoolAccountPaymentOption();
      this.submitted = false;
      this.recordDialog = true;
      this.state = "new";
  }

  editRecord(record: any) {
      this.record = { ...record };
      this.recordDialog = true;
      this.state = "edit";
  }

  photoRecord(record: any) {
      this.record = { ...record };
      this.photoDialog = true;
      this.state = "photo";
  }

  updatePaymentOption() {
      this.submitted = true;
      this.paymentService
          .editSysPaymentOption(this.record)
          .then((response: any) => {
              this.submitted = false;
              this.recordDialog = false;
              this.loadPaymentOptions();
              this.messageService.add({
                  severity: "success",
                  summary: "Saving was successful.",
                  detail: "",
                  life: 3000,
              });
          })
          .catch((err) => {
              this.submitted = false;
              this.recordDialog = false;
              this.messageService.add({
                  severity: "error",
                  summary: "Error",
                  detail: "Error in Loading Data Please Contact Administrator",
                  life: 3000,
              });
          });
  }

  addPaymentOption() {
      this.submitted = true;
      this.record.code = this.record.name.replace(" ", "_");
      this.paymentService
          .addSysPaymentOption(this.record)
          .then((response: any) => {
              this.submitted = false;
              this.recordDialog = false;
              this.loadPaymentOptions();
              this.messageService.add({
                  severity: "success",
                  summary: "Saving was successful.",
                  detail: "",
                  life: 3000,
              });
          })
          .catch((err) => {
              this.submitted = false;
              this.recordDialog = false;
              this.messageService.add({
                  severity: "error",
                  summary: "Error",
                  detail: "Error in Loading Data Please Contact Administrator",
                  life: 3000,
              });
          });
  }

  deleteRecord(record) {
      //console.log(record);
      this.paymentService
          .deleteSysPaymentOption(record.id)
          .then((res) => {
              this.loadPaymentOptions();
              this.messageService.add({
                  severity: "success",
                  summary: "Delete was successful.",
                  detail: "",
                  life: 3000,
              });
          })
          .catch((err) => {
              this.messageService.add({
                  severity: "error",
                  summary: "Error",
                  detail: "Error in Loading Data Please Contact Administrator",
                  life: 3000,
              });
          });
  }


  hideDialog() {
      this.recordDialog = false;
      this.submitted = false;
  }
}
