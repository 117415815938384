import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { ValidatePayment } from "src/app/saferoadsph/interfaces/Payment";
import { PaymentsService } from "src/app/saferoadsph/services/payments.service";
import { StorageService } from "src/app/saferoadsph/services/storage.service";
import * as FileSaver from "file-saver";

@Component({
    selector: "app-billing-invoices",
    templateUrl: "./billing-invoices.component.html",
    styleUrls: ["./billing-invoices.component.scss"],
    providers: [MessageService]
})
export class BillingInvoicesComponent implements OnInit {
    invoices;
    record;
    dsProfileId;
    recordDialog: boolean;
    invoiceDetailsDialog: boolean;
    submitted: boolean;
    validatePayment = new ValidatePayment();
    invoiceHeader;
    invoiceDetails;
    invoiceCompanyname;
    invoiceAmount;

    optionStatus = [
        { label: "PENDING", value: "PENDING" },
        { label: "APPROVED", value: "APPROVED" },
        { label: "INVALID", value: "INVALID" },
    ];

    optionInvoiceStatus = [
        { label: "PENDING", value: "PENDING" },
        { label: "APPROVED", value: "APPROVED" },
        { label: "INVALID", value: "INVALID" },
    ];
    
    status;
    paymentType;
    disableStatus:boolean;
    buttonClick;
    constructor(private paymentService: PaymentsService,
                private storageService: StorageService,
                private messageService: MessageService) {}

    ngOnInit(): void {
        //this.loadAllInvoices();
        this.status = 'PENDING';
        this.paymentType = 'CREDITS'
        this.loadAllInvoicesCreditsByStatus('PENDING');
    }

    onChangeInvoiceStatus(){
        //console.log(this.status);
        if (this.buttonClick == "ALL"){
            this.all();
        }
        else if (this.buttonClick == "CREDITS"){
            this.credits();
        }
        else if (this.buttonClick == "BILLING"){
            this.billing();
        }
        else if (this.buttonClick == "BOND"){
            this.bond();
        }
    }

    all() {
        this.status = 'ALL';
        this.buttonClick = "ALL";
        this.disableStatus = true;
        this.loadAllInvoices();
    }

    credits() {
        this.buttonClick = "CREDITS";
        this.disableStatus = false;
        this.loadAllInvoicesCreditsByStatus(this.status);
    }

    billing() {
        this.buttonClick = "BILLING";
        this.disableStatus = false;
        this.loadAllInvoicesBillingByStatus(this.status);
    }

    bond() {
        this.buttonClick = "BOND";
        this.disableStatus = false;
        this.loadAllInvoicesBondByStatus(this.status);
    }

    loadAllInvoices() {
        this.paymentType = 'ALL'
        this.invoices = null;
        this.paymentService.getAdminTransactions().then((response: any) => {
            this.invoices = response.result;
        });
        //console.log(this.invoices);
    }

    loadAllInvoicesCreditsByStatus(status) {
        this.paymentType = 'CREDITS'
        this.invoices = null;
        this.paymentService.getAdminTransactionsStatusPaymentType(status,"CREDITS").then((response: any) => {
            this.invoices = response.result;
        });
        //console.log(this.invoices);
    }

    loadAllInvoicesBondByStatus(status) {
        this.paymentType = 'BOND'
        this.invoices = null;
        this.paymentService.getAdminTransactionsStatusPaymentTypeCode(status,'BOND').then((response1: any) => {
            if (response1.result.length > 0) {
                this.invoices = response1.result;
            }
        });
    }

    loadAllInvoicesBillingByStatus(status) {
        this.paymentType = 'BILLING'
        this.invoices = null;
        this.paymentService.getAdminTransactionsStatusPaymentType(status,"BILLING").then((response: any) => {
            this.invoices = response.result;
        });
    }

    loadInvoicesDetails(id) {
        this.paymentService.getInvoiceDetails(id).then((response:any)=> {
            this.invoiceHeader = response.result[0];            
            this.invoiceCompanyname = this.invoiceHeader.dsCompanyName;
            this.invoiceAmount = this.invoiceHeader.totalCourseFee;
            this.invoiceDetails = response.result[0].studentDueForInvoices;
        }).catch(err => {
            this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Error in Loading Data Please Contact Administrator",
                life: 3000,
            });
        })
    }

    editRecord(record: any) {
        this.record = { ...record };
        this.recordDialog = true;
    }

    viewInvoiceDetails(id) {
        this.loadInvoicesDetails(id) 
        this.invoiceDetailsDialog = true;
    }

    hideDialog() {
        this.recordDialog = false;
        this.submitted = false;
        this.invoiceDetailsDialog = false;
    }

    sendTransaction() {
        this.validatePayment.dsProfileId = this.record.dsProfileId;
        this.validatePayment.transactionId = this.record.id;
        this.submitted = true;
        //console.log(this.validatePayment)

        this.paymentService.validateSchoolTransaction(this.validatePayment).then((response:any) => {
            this.submitted = false;
            this.recordDialog = false;
            this.clear();
            this.loadAllInvoices();
            this.messageService.add({
                severity: "success",
                summary: "Validating payment was successful.",
                detail: "",
                life: 3000,
            });
        }).catch(err => {
            this.submitted = false;
            this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Error in Loading Data Please Contact Administrator",
                life: 3000,
            });
        });
    }

    clear() {
        this.validatePayment = new ValidatePayment();
    }

    exportExcel() {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(this.invoiceDetails);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, "Invoice Details");
        });
    }
    
    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(
            data,
            fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
    }
}
