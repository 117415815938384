import { Component, OnInit } from "@angular/core";
import { CmsService } from "../../services/cms.service";

@Component({
    selector: "app-cms",
    templateUrl: "./cms.component.html",
    styleUrls: ["./cms.component.scss"],
})
export class CmsComponent implements OnInit {
    cms;
    selectedCms;
    loading: boolean;

    constructor(private cmsService: CmsService) {}

    ngOnInit(): void {
        this.loadAllCms();
    }

    loadAllCms() {
        this.loading = true;
        this.cmsService.getAllCmsData().then((res: any) => {
            this.cms = res.result;
            this.loading = false;
            //console.log(this.cms);
        });
    }
}
