import { Injectable } from "@angular/core";
import { PaymentMatrix, SchoolTransaction, StudentTransaction } from "../interfaces/Payment";
import { User, Address, ChangePassword, School } from "../interfaces/User";

@Injectable({
    providedIn: "root",
})
export class ValidationService {
    constructor() {}

    validateUser(user: User) {
        if (user.firstName == null || user.firstName == "") {
            return false;
        } else if (user.lastName == null || user.lastName == "") {
            return false;
        } else if (user.middleName == null || user.middleName == "") {
            return false;
        } else if (user.birthDate == null || user.birthDate == "") {
            return false;
        } else if (user.birthPlace == null || user.birthPlace == "") {
            return false;
        } else if (user.gender == null || user.gender == "") {
            return false;
        } else if (user.password == null || user.password == "") {
            return false;
        } else if (user.email == null || user.email == "") {
            return false;
        } else if (!this.validateEmail(user.email)) {
            return false;
        } else {
            return true;
        }
    }

    validateAddress(address: Address) {
        if (address.block == null || address.block == "") {
            return false;
        } else if (address.street == null || address.street == "") {
            return false;
        } else if (address.barangay == null || address.barangay == "") {
            return false;
        } else if (address.region == null || address.region == "") {
            return false;
        } else if (address.province == null || address.province == "") {
            return false;
        } else if (address.city == null || address.city == "") {
            return false;
        } else if (address.postalCode == null || address.postalCode == "") {
            return false;
        } else {
            return true;
        }
    }

    validateChangePassword(password: ChangePassword, confirmPassword) {
        if (password.oldPassword == null || password.oldPassword == "") {
            return false;
        } else if (password.newPassword == null || password.newPassword == "") {
            return false;
        } else if (confirmPassword == null || confirmPassword == "") {
            return false;
        } else if (password.newPassword != confirmPassword) {
            return false;
        } else {
            return true;
        }
    }

    validateSchoolDetails(school: School) {
        if (school.companyName == null || school.companyName == "") {
            return false;
        } else if (school.contactPerson == null || school.contactPerson == "") {
            return false;
        } else if (school.companyEmail == null || school.companyEmail == "") {
            return false;
        } else if (!this.validateEmail(school.companyEmail)) {
            return false;
        } else if (
            school.ltoAccreditationNo == null ||
            school.ltoAccreditationNo == ""
        ) {
            return false;
        } else if (
            school.instructorFullName == null ||
            school.instructorFullName == ""
        ) {
            return false;
        } else if (
            school.instructorLTOAccreditationNo == null ||
            school.instructorLTOAccreditationNo == ""
        ) {
            return false;
        } else if (school.mobile == null || school.mobile == "") {
            return false;
        } else if (school.telephone == null || school.telephone == "") {
            return false;
        } else {
            return true;
        }
    }

    validateEmail(email) {
        const regularExpression =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regularExpression.test(String(email).toLowerCase());
    }

    validateStudentPayment(studentTransaction: StudentTransaction) {
        if(studentTransaction.studentId == null || studentTransaction.studentId == 0) {
            return false;
        } 

        if(studentTransaction.courseCode == null || studentTransaction.courseCode == "") {
            return false;
        }

        if(studentTransaction.paymentDate == null || studentTransaction.paymentDate == "") {
            return false;
        }

        if(studentTransaction.paymentMethodCode == null || studentTransaction.paymentMethodCode == "") {
            return false;
        }

        if(studentTransaction.paymentRefNumber == null || studentTransaction.paymentRefNumber == "") {
            return false;
        }

        if(studentTransaction.paymentRemarks == null || studentTransaction.paymentRemarks == "") {
            return false;
        }

        return true;
    }

    validateDrivingSchoolPayment(schoolTransaction: SchoolTransaction) {
        if(schoolTransaction.paymentDate == null || schoolTransaction.paymentDate == "") {
            return false;
        }

        if(schoolTransaction.paymentRefNumber == null || schoolTransaction.paymentRefNumber == "") {
            return false;
        }

        if(schoolTransaction.paymentRemarks == null || schoolTransaction.paymentRemarks == "") {
            return false;
        }
        return true;
    }

    validatePriceMatrix(priceMatrix:PaymentMatrix) {
        if(priceMatrix.discountAmount == null) {
            return false;
        }

        if(priceMatrix.discountPercent == null) {
            return false;
        }

        if(priceMatrix.vatAmount == null) {
            return false;
        }

        if(priceMatrix.vatPercent == null) {
            return false;
        }

        if(priceMatrix.dsRegistrationFee == null || priceMatrix.dsRegistrationFee == 0) {
            return false;
        }

        return true;
    }
}
