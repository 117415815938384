import { Component, OnInit } from "@angular/core";
import {
    PaymentMatrix,
    SchoolAccountPaymentOption,
    SchoolTransaction,
} from "../../interfaces/Payment";
import { PaymentsService } from "../../services/payments.service";
import _ from "lodash";
import { ConfirmationService, MessageService } from "primeng/api";
import { ValidationService } from "../../services/validation.service";
import { StorageService } from "../../services/storage.service";

@Component({
    selector: "app-payment-prepaid",
    templateUrl: "./payment-prepaid.component.html",
    styleUrls: ["./payment-prepaid.component.scss"],
    providers:[ConfirmationService, MessageService]
})
export class PaymentPrepaidComponent implements OnInit {
    recordDialog: boolean;
    submitted: boolean;
    record;

    schoolTransaction = new SchoolTransaction();
    studentAmount=0;
    nonProAmount=0;
    proAmount=0;
    paymentOptions: SchoolAccountPaymentOption[] = [];
    courseFees;
    totalAmount=0;
    vatAmount=0;
    vatPercent=0;
    grandTotal=0;

    fileToUpload;
    paymentMethodReadOnly;
    selectedPaymentMethod;
    errForm = false;
    subscriptionType;
    accountValidated;
    paymentMethodPhoto;

    constructor(private storageService: StorageService,
                private paymentService: PaymentsService,
                private messageService: MessageService,
                private validationService: ValidationService) {}

    ngOnInit(): void {
        this.loadSubcriptionType();
        this.schoolTransaction.subscriptionType = "PREPAID";
        this.loadPaymentOptions();
        this.loadCourseFees();
    }

    loadSubcriptionType() {
        this.storageService.get('subscriptionType').then((response:any) => {
            this.subscriptionType = response;
        })
    }
    loadAccountValidated() {
        this.storageService.get('accountValidated').then((response:any) => {
            this.accountValidated = response;
        })
    }
    

    clearInputs() {
        this.schoolTransaction = new SchoolTransaction();
        this.fileToUpload = null;

        this.studentAmount = 0;
        this.nonProAmount = 0;
        this.proAmount = 0;
        this.schoolTransaction.paymentDiscountAmount = 0;
        this.totalAmount = 0;
        this.vatAmount = 0;
        this.grandTotal = 0;
    }

    loadPaymentOptions() {
        this.paymentService.getAdminPaymentOption().then((response:any) => {
            this.paymentOptions = response.result;
            //console.log(this.paymentOptions);
            this.selectedPaymentMethod = this.paymentOptions[0];
            this.selectPaymentMethod();
            this.paymentOptions = _.map(this.paymentOptions, function (value) {
                return {
                    label: value.name,
                    value: value,
                };            
            });
            this.paymentMethodPhoto =  this.selectedPaymentMethod.photo;
        });
    }

    loadCourseFees() {
        this.paymentService.getCourseFees().then((response:any)=> {
           //console.log(response.result)
            this.courseFees = response.result;
            this.vatPercent = this.courseFees[0].vatPercent;
        });
    }

    selectPaymentMethod() {
        this.schoolTransaction.paymentMethodCode = this.selectedPaymentMethod.code;
        this.paymentMethodPhoto =  this.selectedPaymentMethod.photo;
        this.paymentMethodReadOnly = this.selectedPaymentMethod.name

    }

    handleFileInput(file) {
        this.fileToUpload = file.item(0);
        this.errForm = false;
    }

    computeTotalAmount() {
        var spAmount = 0;
        var npAmount = 0;
        var prAmount = 0;
        var totalDiscount = 0;
    
        this.courseFees.forEach((course) => {
            if(course.code == 'SP') {
              spAmount = (course.amount) * this.schoolTransaction.spQuantity; 
              totalDiscount = totalDiscount + (this.schoolTransaction.spQuantity * course.discountAmount);
            } else if(course.code == 'NP') {
              npAmount = (course.amount) * this.schoolTransaction.npQuantity;   
              totalDiscount = totalDiscount + (this.schoolTransaction.npQuantity * course.discountAmount);
            } else {
              prAmount = (course.amount) * this.schoolTransaction.prQuantity;
              totalDiscount = totalDiscount + (this.schoolTransaction.prQuantity * course.discountAmount);
            }
        });
        this.studentAmount = spAmount;
        this.nonProAmount = npAmount;
        this.proAmount = prAmount;
        this.schoolTransaction.paymentDiscountAmount = totalDiscount;
        this.totalAmount = spAmount + npAmount + prAmount
        this.vatAmount = this.totalAmount;
        this.grandTotal = this.totalAmount;
        this.schoolTransaction.paymentRemarks = "Details: " +
                                                "STUDENT PERMIT - "+ this.schoolTransaction.spQuantity +" Qty ("+spAmount+")" + " | " + 
                                                "NON PROFESSIONAL - "+ this.schoolTransaction.npQuantity +" Qty ("+npAmount+")" + " | " + 
                                                "PROFESSIONAL - "+ this.schoolTransaction.prQuantity +" Qty ("+prAmount+")"
      }

      paymentConfirmation(record) {
        this.record = { ...record };
        this.submitted = false;
        this.recordDialog = true;

        if(!this.validationService.validateDrivingSchoolPayment(this.schoolTransaction)) {
            this.errForm = true;
            this.recordDialog = false;
            return;
        }

        if(this.paymentMethodReadOnly !="CASH" && this.fileToUpload==null ){
            this.errForm = true;
            this.recordDialog = false;
            return;
        }

        if(this.schoolTransaction.spQuantity == 0 && this.schoolTransaction.npQuantity == 0 && this.schoolTransaction.prQuantity == 0) {
            this.recordDialog = false;
            this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Input atleast 1 quantity to any course.",
                life: 3000,
            });
            return;
        }
        //console.log(this.record)
    }

    sendTransaction() {
        this.submitted = true;
        //console.log(this.schoolTransaction)
        //console.log(this.fileToUpload)

        const formData = new FormData();
        formData.append(
            'file',
            this.fileToUpload
        );
        formData.append('object', JSON.stringify(this.schoolTransaction));
        if (this.fileToUpload) {
            this.paymentService
                .schoolPaymentTransctionWithAttachment(formData)
                .then((response: any) => {
                    this.submitted = false;
                    this.recordDialog = false;
                    this.clearInputs();
                    this.messageService.add({
                        severity: "success",
                        summary: "Payment has been successfully submitted.",
                        detail: "",
                        life: 3000,
                    });
                })
                .catch((err) => {
                    this.submitted = false;
                    this.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Error in Loading Data Please Contact Administrator",
                        life: 3000,
                    });
                });
        }
        else {
            this.paymentService
                .schoolPaymentTransction(formData)
                .then((response: any) => {
                    this.submitted = false;
                    this.recordDialog = false;
                    this.clearInputs();
                    this.messageService.add({
                        severity: "success",
                        summary: "Payment has been successfully submitted.",
                        detail: "",
                        life: 3000,
                    });
                })
                .catch((err) => {
                    this.submitted = false;
                    this.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Error in Loading Data Please Contact Administrator",
                        life: 3000,
                    });
                });
        }
    }

    hideDialog() {
        this.recordDialog = false;
        this.submitted = false;
    }
}
