import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { File } from "../interfaces/Document";

@Injectable({
    providedIn: "root",
})
export class CmsService {
    constructor(private httpClient: HttpClient) {}

    getRegions() {
        return this.httpClient
            .get(environment.cmsServiceURL + "/address/allRegion")
            .toPromise();
    }

    getProvinces(regionId) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/address/getStateByRegion/" +
                    regionId
            )
            .toPromise();
    }

    getProvincesByRegionName(regionName) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/address/getStateByRegionName/" +
                    regionName
            )
            .toPromise();
    }

    getCities(provinceId) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/address/getCityByState/" +
                    provinceId
            )
            .toPromise();
    }

    getCitiesByProvinceName(provinceName) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/address/getCityByStateName/" +
                    provinceName
            )
            .toPromise();
    }

    getUploadedDocuments() {
        return this.httpClient
            .get<File[]>("assets//saferoadsph/documents.json")
            .toPromise();
    }

    getContent(page) {
        return this.httpClient
            .get(environment.cmsServiceURL + "/cms/getCMSDataByPage/" + page)
            .toPromise();
    }

    getDashboardData() {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-driving-school-all"
            )
            .toPromise();
    }

    getDashboardDataByAdmin() {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-admin-all-super-admin"
            )
            .toPromise();
    }

    getDashboardDataByBranch(branchCode) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-admin-all-branch/"+branchCode
            )
            .toPromise();
    }

    getDashboardDataByRegion(region) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-admin-all-region/"+region
            )
            .toPromise();
    }

    getDashboardDataByDS(dsProfile) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-admin-all-school/"+dsProfile
            )
            .toPromise();
    }

    getDashboardDataDaily(nYear, nMonth) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-daily/"+nYear+"/"+nMonth
            )
            .toPromise();
    }

    getDashboardDataDailyByDs(nYear, nMonth, dsProfileId) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-daily-ds/"+nYear+"/"+nMonth+"/"+dsProfileId
            )
            .toPromise();
    }

    getDashboardDataDailyByBranch(nYear, nMonth, branchCode) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-daily-branch/"+nYear+"/"+nMonth+"/"+branchCode
            )
            .toPromise();
    }

    getDashboardDataDailyByRegion(nYear, nMonth, region) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-daily-region/"+nYear+"/"+nMonth+"/"+region
            )
            .toPromise();
    }

    getDashboardDataDailyCompleted(nYear, nMonth) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-completed-daily/"+nYear+"/"+nMonth
            )
            .toPromise();
    }

    getDashboardDataDailyCompletedByDs(nYear, nMonth, dsProfileId) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-completed-daily-ds/"+nYear+"/"+nMonth+"/"+dsProfileId
            )
            .toPromise();
    }

    getDashboardDataDailyCompletedByBranch(nYear, nMonth, branchCode) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-completed-daily-branch/"+nYear+"/"+nMonth+"/"+branchCode
            )
            .toPromise();
    }

    getDashboardDataDailyCompletedByRegion(nYear, nMonth, region) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-completed-daily-region/"+nYear+"/"+nMonth+"/"+region
            )
            .toPromise();
    }

    getDashboardDataMonthly(nYear) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-monthly/"+nYear
            )
            .toPromise();
    }

    getDashboardDataMonthlyByDs(nYear, dsProfileId) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-monthly-ds/"+nYear+"/"+dsProfileId
            )
            .toPromise();
    }

    getDashboardDataMonthlyByBranch(nYear, branchCode) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-monthly-branch/"+nYear+"/"+branchCode
            )
            .toPromise();
    }

    getDashboardDataMonthlyByRegion(nYear, region) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-monthly-region/"+nYear+"/"+region
            )
            .toPromise();
    }

    getDashboardDataMonthlyCompleted(nYear) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-completed-monthly/"+nYear
            )
            .toPromise();
    }

    getDashboardDataMonthlyCompletedByDs(nYear, dsProfileId) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-completed-monthly-ds/"+nYear+"/"+dsProfileId
            )
            .toPromise();
    }

    getDashboardDataMonthlyCompletedByBranch(nYear, branchCode) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-completed-monthly-branch/"+nYear+"/"+branchCode
            )
            .toPromise();
    }

    getDashboardDataMonthlyCompletedByRegion(nYear, region) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-completed-monthly-region/"+nYear+"/"+region
            )
            .toPromise();
    }

    getDashboardDataWeekly(nYear) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-weekly/"+nYear
            )
            .toPromise();
    }

    getDashboardDataWeeklyByDs(nYear, dsProfileId) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-weekly-ds/"+nYear+"/"+dsProfileId
            )
            .toPromise();
    }

    getDashboardDataWeeklyByBranch(nYear, branchCode) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-weekly-branch/"+nYear+"/"+branchCode
            )
            .toPromise();
    }

    getDashboardDataWeeklyByRegion(nYear, region) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-count-weekly-region/"+nYear+"/"+region
            )
            .toPromise();
    }

    getDashboardDataWeeklyCompleted(nYear) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-completed-weekly/"+nYear
            )
            .toPromise();
    }   

    getDashboardDataWeeklyCompletedByDs(nYear, dsProfileId) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-completed-weekly-ds/"+nYear+"/"+dsProfileId
            )
            .toPromise();
    }    

    getDashboardDataWeeklyCompletedByBranch(nYear, branchCode) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-completed-weekly-branch/"+nYear+"/"+branchCode
            )
            .toPromise();
    } 

    getDashboardDataWeeklyCompletedByRegion(nYear, region) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/dashboard-student-completed-weekly-region/"+nYear+"/"+region
            )
            .toPromise();
    } 

    getDrivingSchoolProfileById(id) {
        return this.httpClient
            .get(
                environment.cmsServiceURL +
                    "/reports/get-driving-school-profile/"+id
            )
            .toPromise();
    } 

    getAllCmsData() {
        return this.httpClient
            .get(environment.cmsServiceURL + "/cms/allCMSData")
            .toPromise();
    }
}
