import { Component, OnInit } from "@angular/core";
import { CmsService } from "../../services/cms.service";
import { StorageService } from '../../services/storage.service';
import * as moment from 'moment';
import {formatNumber} from '@angular/common';

@Component({
    selector: "app-dashboard-page",
    templateUrl: "./dashboard-page.component.html",
    styleUrls: ["./dashboard-page.component.scss"],
})
export class DashboardPageComponent implements OnInit {
    pieData: any;
    barData:any;
    barData2:any;
    barData3:any;
    barData4:any;
    students = [];
    bills = [];
    accountValidated;
    subscriptionType;
    data;
    dsProfileId;

    dataDaily:any=null;
    date:Date; 
    userType;

    refreshDashboard =[
        { label: "Refreshed Date & Time", value: -1 },
        { label: "Refresh every 30 mins", value: 30 },
        { label: "Refresh every 1 hour", value: 60 },
        { label: "Refresh every 4 hours", value: 240 },
      ];

    selectedValue=1;

    constructor(private cmsService: CmsService,
                private storageService: StorageService) {
                }
    curMin;  
    sec;
    countSec;              
    dsProfile:any;
    region;
    branchCode;

    dataMonthlyCompleted:any=null;
    dataDailyCompleted:any=null;
    dataMonthly:any=null;
    dataWeekly:any=null;  
    dataWeeklyCompleted:any=null;
    nWeek;

    nYear;
    nMonth;
    currentDate;
    sMonth;

    dataLength;
    dataLengthCompleted;
    dataLengthDaily;
    dataLengthDailyCompleted;
    dataLengthWeekly;
    dataLengthWeeklyCompleted;

    totalStudent;
    totalPending;
    totalApproved;
    totalOngoing;

    ngOnInit(): void {
        this.date = new Date();
        this.userType = localStorage.getItem('userType');
        this.dsProfileId = localStorage.getItem('dsProfileId');
        this.region = localStorage.getItem('region');
        //console.log(this.userType);
        //console.log(this.dsProfileId);
        //this.getDSProfile(this.dsProfileId);  
        this.loadAccountValidated();
        this.loadSubcriptionType();
        this.loadDashBoardData();    
    }

    getTimer() {
        //console.log(this.selectedValue);
        if (this.selectedValue > 1) {
            this.sec = 0;
            this.curMin = 0;
            this.countSec = 0;
            setInterval(() => {
                //this.date = new Date()
                this.sec = this.getSeconds(this.date);
                this.countSec = this.countSec + 1;
                if (this.countSec == 60) {
                    this.curMin = this.curMin + 1;
                    //console.log("Minutes: " + this.curMin);
                    this.countSec = 0;
                }
                if (this.curMin == this.selectedValue) {
                    this.date = new Date();
                    //console.log("Refreshed Dashboard");
                    this.loadDashBoardData();    
                    this.curMin = 0;
                }
            }, 1000)
        }
    }

    getMinutes(date) {
        return moment(new Date(date)).format("mm");
    }

    getSeconds(date) {
        return moment(new Date(date)).format("ss");
    }

    getHours(date) {
        return moment(new Date(date)).format("HH");
    }

    loadSubcriptionType() {
        this.storageService.get('subscriptionType').then((response:any) => {
            this.subscriptionType = response;
        })
    }

    loadAccountValidated() {
        this.storageService.get('accountValidated').then((response:any) => {
            this.accountValidated = response;
        })
    }

    loadDashBoardData(){
        if ( this.userType == "SUPER" ){
            this.loadDashboard();
            this.loadDashboardDaily();
            this.loadDashboardMonthly();
            this.loadDashboardWeekly(); 
            //console.log("Load as Super Admin")
        }
        else if ( this.userType == "ADMIN" ){
            this.loadDashboard();
            this.loadDashboardDaily();
            this.loadDashboardMonthly();
            this.loadDashboardWeekly(); 
            //console.log("Load as Admin")
        }
        else if ( this.userType == "SCHOOL-ADMIN" ){
            this.branchCode = localStorage.getItem('branchCode');
            //Branch
            this.loadDashboardByBranch(this.branchCode);
            this.loadDashboardDailyByBranch(this.branchCode);
            this.loadDashboardMonthlyByBranch(this.branchCode);
            this.loadDashboardWeeklyByBranch(this.branchCode); 
            //console.log("Load as School Admin")
        }
        else if ( this.userType == "SCHOOL" ){
            this.branchCode = localStorage.getItem('branchCode');
            this.loadDashboardByDs();
            this.loadDashboardDailyByDs();
            this.loadDashboardMonthlyByDs();
            this.loadDashboardWeeklyByDs(); 
            //console.log("Load as School")
        }
        else if ( this.userType == "LTO" ){
            //Region
            this.loadDashboardByRegion(this.region);
            this.loadDashboardDailyByRegion(this.region);
            this.loadDashboardMonthlyByRegion(this.region);
            this.loadDashboardWeeklyByRegion(this.region);
            //console.log("Load as LTO") 
        }
    }

    getDSProfile(dsProfileId){
        this.cmsService.getDrivingSchoolProfileById(dsProfileId).then((response:any) => {
            this.dsProfile = response.result[0];
            this.branchCode = this.dsProfile.customId;
            this.region = this.dsProfile.region;
        });
    }

    loadDashboardDaily(){
        this.currentDate = new Date();
        this.nYear =  moment(new Date(this.currentDate)).format("YYYY");
        this.nMonth =  moment(new Date(this.currentDate)).format("MM");
        this.sMonth =  moment(new Date(this.currentDate)).format("MMMM");

        this.cmsService.getDashboardDataDailyCompleted(this.nYear, this.nMonth).then((dailyCompletedData:any) => {         
            if (dailyCompletedData.result.length > 0){
                this.dataDailyCompleted = dailyCompletedData.result;  
                //console.log( this.dataDailyCompleted);
                this.dataLengthDailyCompleted = dailyCompletedData.result.length;     
                //console.log(  this.dataLengthDailyCompleted);           
            }
            else{
                this.dataDailyCompleted = {id:1,nYear:this.nYear,nMonth:this.sMonth,nWeek:1,nDay:1,studentCount:0};
                this.dataLengthDailyCompleted = 1;
            }                  
        });

        this.cmsService.getDashboardDataDaily(this.nYear, this.nMonth).then((response:any) => {
            this.dataDaily = response.result;
            this.dataLengthDaily = response.result.length;   

            var labelDaily1 = 1, labelDaily2 = 2, labelDaily3 = 3, labelDaily4 = 4, labelDaily5 = 5, labelDaily6 = 6, labelDaily7 = 7, labelDaily8 = 8, labelDaily9 = 9, labelDaily10 = 10,
            labelDaily11 = 11, labelDaily12 = 12, labelDaily13 = 13, labelDaily14 = 14, labelDaily15 = 15, labelDaily16 = 16, labelDaily17 = 17, labelDaily18 = 18, labelDaily19 = 19, labelDaily20 = 20,
            labelDaily21 = 21, labelDaily22 = 22, labelDaily23 = 23, labelDaily24 = 24, labelDaily25 = 25, labelDaily26 = 26, labelDaily27 = 27, labelDaily28 = 28, labelDaily29 = 29, labelDaily30 = 30,
            labelDaily31 = 31;   
            
            var dataDaily1 = 0, dataDaily2 = 0, dataDaily3 = 0, dataDaily4 = 0, dataDaily5 = 0, dataDaily6 = 0, dataDaily7 = 0, dataDaily8 = 0, dataDaily9 = 0, dataDaily10 = 0,
            dataDaily11 = 0, dataDaily12 = 0, dataDaily13 = 0, dataDaily14 = 0, dataDaily15 = 0, dataDaily16 = 0, dataDaily17 = 0, dataDaily18 = 0, dataDaily19 = 0, dataDaily20 = 0,
            dataDaily21 = 0, dataDaily22 = 0, dataDaily23 = 0, dataDaily24 = 0, dataDaily25 = 0, dataDaily26 = 0, dataDaily27 = 0, dataDaily28 = 0, dataDaily29 = 0, dataDaily30 = 0,
            dataDaily31 = 0;   

            if (this.dataLengthDaily == 31) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
                dataDaily29 = this.dataDaily[28].studentCount;
                dataDaily30 = this.dataDaily[29].studentCount;
                dataDaily31 = this.dataDaily[30].studentCount;
            }

            if (this.dataLengthDaily == 30) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
                dataDaily29 = this.dataDaily[28].studentCount;
                dataDaily30 = this.dataDaily[29].studentCount;
            }

            if (this.dataLengthDaily == 29) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
                dataDaily29 = this.dataDaily[28].studentCount;
            }

            if (this.dataLengthDaily == 28) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
            }

            if (this.dataLengthDaily == 27) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
            }

            if (this.dataLengthDaily == 26) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
            }

            if (this.dataLengthDaily == 25) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
            }

            if (this.dataLengthDaily == 24) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
            }

            if (this.dataLengthDaily == 23) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
            }

            if (this.dataLengthDaily == 22) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
            }

            if (this.dataLengthDaily == 21) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
            }

            if (this.dataLengthDaily == 20) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
            }

            if (this.dataLengthDaily == 19) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
            }

            if (this.dataLengthDaily == 18) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
            }

            if (this.dataLengthDaily == 17) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
            }

            if (this.dataLengthDaily == 16) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
            }

            if (this.dataLengthDaily == 15) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
            }

            if (this.dataLengthDaily == 14) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
            }

            if (this.dataLengthDaily == 13) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
            }

            if (this.dataLengthDaily == 12) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
            }

            if (this.dataLengthDaily == 11) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
            }

            if (this.dataLengthDaily == 10) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
            }

            if (this.dataLengthDaily == 9) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
            }

            if (this.dataLengthDaily == 8) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
            }

            if (this.dataLengthDaily == 7) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
            }

            if (this.dataLengthDaily == 6) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
            }

            if (this.dataLengthDaily == 5) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
            }

            if (this.dataLengthDaily == 4) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
            }

            if (this.dataLengthDaily == 3) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
            }

            if (this.dataLengthDaily == 2) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
            }

            if (this.dataLengthDaily == 1) {
                dataDaily1 = this.dataDaily[0].studentCount;
            }

            var dataDailyCompleted1 = 0, dataDailyCompleted2 = 0, dataDailyCompleted3 = 0, dataDailyCompleted4 = 0, dataDailyCompleted5 = 0, dataDailyCompleted6 = 0, dataDailyCompleted7 = 0, dataDailyCompleted8 = 0, dataDailyCompleted9 = 0, dataDailyCompleted10 = 0,
            dataDailyCompleted11 = 0, dataDailyCompleted12 = 0, dataDailyCompleted13 = 0, dataDailyCompleted14 = 0, dataDailyCompleted15 = 0, dataDailyCompleted16 = 0, dataDailyCompleted17 = 0, dataDailyCompleted18 = 0, dataDailyCompleted19 = 0, dataDailyCompleted20 = 0,
            dataDailyCompleted21 = 0, dataDailyCompleted22 = 0, dataDailyCompleted23 = 0, dataDailyCompleted24 = 0, dataDailyCompleted25 = 0, dataDailyCompleted26 = 0, dataDailyCompleted27 = 0, dataDailyCompleted28 = 0, dataDailyCompleted29 = 0, dataDailyCompleted30 = 0,
            dataDailyCompleted31 = 0;
            
            if (this.dataLengthDailyCompleted == 31) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
                dataDailyCompleted29 = this.dataDailyCompleted[28].studentCount;
                dataDailyCompleted30 = this.dataDailyCompleted[29].studentCount;
                dataDailyCompleted31 = this.dataDailyCompleted[30].studentCount;
            }

            if (this.dataLengthDailyCompleted == 30) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
                dataDailyCompleted29 = this.dataDailyCompleted[28].studentCount;
                dataDailyCompleted30 = this.dataDailyCompleted[29].studentCount;
            }

            if (this.dataLengthDailyCompleted == 29) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
                dataDailyCompleted29 = this.dataDailyCompleted[28].studentCount;
            }

            if (this.dataLengthDailyCompleted == 28) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
            }

            if (this.dataLengthDailyCompleted == 27) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
            }

            if (this.dataLengthDailyCompleted == 26) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
            }

            if (this.dataLengthDailyCompleted == 25) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
            }

            if (this.dataLengthDailyCompleted == 24) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
            }

            if (this.dataLengthDailyCompleted == 23) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
            }

            if (this.dataLengthDailyCompleted == 22) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
            }

            if (this.dataLengthDailyCompleted == 21) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
            }

            if (this.dataLengthDailyCompleted == 20) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
            }

            if (this.dataLengthDailyCompleted == 19) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
            }

            if (this.dataLengthDailyCompleted == 18) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
            }

            if (this.dataLengthDailyCompleted == 17) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
            }

            if (this.dataLengthDailyCompleted == 16) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
            }

            if (this.dataLengthDailyCompleted == 15) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
            }

            if (this.dataLengthDailyCompleted == 14) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
            }

            if (this.dataLengthDailyCompleted == 13) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
            }

            if (this.dataLengthDailyCompleted == 12) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
            }

            if (this.dataLengthDailyCompleted == 11) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
            }

            if (this.dataLengthDailyCompleted == 10) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
            }

            if (this.dataLengthDailyCompleted == 9) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
            }

            if (this.dataLengthDailyCompleted == 8) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
            }

            if (this.dataLengthDailyCompleted == 7) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
            }

            if (this.dataLengthDailyCompleted == 6) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
            }

            if (this.dataLengthDailyCompleted == 5) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
            }

            if (this.dataLengthDailyCompleted == 4) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
            }

            if (this.dataLengthDailyCompleted == 3) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
            }

            if (this.dataLengthDailyCompleted == 2) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
            }

            if (this.dataLengthDailyCompleted == 1) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
            }            
                   
            this.barData2 = {
                labels: [labelDaily1, labelDaily2, labelDaily3, labelDaily4, labelDaily5, labelDaily6, labelDaily7, labelDaily8, labelDaily9, labelDaily10,
                    labelDaily11, labelDaily12, labelDaily13, labelDaily14, labelDaily15, labelDaily16, labelDaily17, labelDaily18, labelDaily19, labelDaily20,
                    labelDaily21, labelDaily22, labelDaily23, labelDaily24, labelDaily25, labelDaily26, labelDaily27, labelDaily28, labelDaily29, labelDaily30,
                    labelDaily31],
                datasets: [
                    {
                        label: 'Approved Students',
                        fill: false,
                        backgroundColor: 'rgb(255, 205, 86)',
                        borderColor: 'rgb(255, 205, 86)',
                        data: [dataDaily1, dataDaily2, dataDaily3, dataDaily4, dataDaily5, dataDaily6, dataDaily7, dataDaily8, dataDaily9, dataDaily10,
                            dataDaily11, dataDaily12, dataDaily13, dataDaily14, dataDaily15, dataDaily16, dataDaily17, dataDaily18, dataDaily19, dataDaily20,
                            dataDaily21, dataDaily22, dataDaily23, dataDaily24, dataDaily25, dataDaily26, dataDaily27, dataDaily28, dataDaily29, dataDaily30,
                            dataDaily31]
                    },
                    {
                        label: 'Completed',
                        fill: false,
                        backgroundColor: 'rgb(75, 192, 192)',
                        borderColor: 'rgb(75, 192, 192)',
                        data: [dataDailyCompleted1, dataDailyCompleted2, dataDailyCompleted3, dataDailyCompleted4, dataDailyCompleted5, dataDailyCompleted6, dataDailyCompleted7, dataDailyCompleted8, dataDailyCompleted9, dataDailyCompleted10,
                            dataDailyCompleted11, dataDailyCompleted12, dataDailyCompleted13, dataDailyCompleted14, dataDailyCompleted15, dataDailyCompleted16, dataDailyCompleted17, dataDailyCompleted18, dataDailyCompleted19, dataDailyCompleted20,
                            dataDailyCompleted21, dataDailyCompleted22, dataDailyCompleted23, dataDailyCompleted24, dataDailyCompleted25, dataDailyCompleted26, dataDailyCompleted27, dataDailyCompleted28, dataDailyCompleted29, dataDailyCompleted30,
                            dataDailyCompleted31]
                    }                   
                ]
            };
        });
    }

    loadDashboardDailyByDs(){
        this.currentDate = new Date();
        this.nYear =  moment(new Date(this.currentDate)).format("YYYY");
        this.nMonth =  moment(new Date(this.currentDate)).format("MM");
        this.sMonth =  moment(new Date(this.currentDate)).format("MMMM");

        this.cmsService.getDashboardDataDailyCompletedByDs(this.nYear, this.nMonth, this.dsProfileId).then((dailyCompletedData:any) => {          
            if (dailyCompletedData.result.length > 0){
                this.dataDailyCompleted = dailyCompletedData.result;  
                //console.log( this.dataDailyCompleted);
                this.dataLengthDailyCompleted = dailyCompletedData.result.length;     
                //console.log(  this.dataLengthDailyCompleted);              
            }
            else{
                this.dataDailyCompleted = {id:1,nYear:this.nYear,nMonth:this.sMonth,nWeek:1,nDay:1,studentCount:0};
                this.dataLengthDailyCompleted = 1;
            }                  
        }); 

        this.cmsService.getDashboardDataDailyByDs(this.nYear, this.nMonth, this.dsProfileId).then((response:any) => {
            this.dataDaily = response.result;
            this.dataLengthDaily = response.result.length;   

            var labelDaily1 = 1, labelDaily2 = 2, labelDaily3 = 3, labelDaily4 = 4, labelDaily5 = 5, labelDaily6 = 6, labelDaily7 = 7, labelDaily8 = 8, labelDaily9 = 9, labelDaily10 = 10,
            labelDaily11 = 11, labelDaily12 = 12, labelDaily13 = 13, labelDaily14 = 14, labelDaily15 = 15, labelDaily16 = 16, labelDaily17 = 17, labelDaily18 = 18, labelDaily19 = 19, labelDaily20 = 20,
            labelDaily21 = 21, labelDaily22 = 22, labelDaily23 = 23, labelDaily24 = 24, labelDaily25 = 25, labelDaily26 = 26, labelDaily27 = 27, labelDaily28 = 28, labelDaily29 = 29, labelDaily30 = 30,
            labelDaily31 = 31;   
            
            var dataDaily1 = 0, dataDaily2 = 0, dataDaily3 = 0, dataDaily4 = 0, dataDaily5 = 0, dataDaily6 = 0, dataDaily7 = 0, dataDaily8 = 0, dataDaily9 = 0, dataDaily10 = 0,
            dataDaily11 = 0, dataDaily12 = 0, dataDaily13 = 0, dataDaily14 = 0, dataDaily15 = 0, dataDaily16 = 0, dataDaily17 = 0, dataDaily18 = 0, dataDaily19 = 0, dataDaily20 = 0,
            dataDaily21 = 0, dataDaily22 = 0, dataDaily23 = 0, dataDaily24 = 0, dataDaily25 = 0, dataDaily26 = 0, dataDaily27 = 0, dataDaily28 = 0, dataDaily29 = 0, dataDaily30 = 0,
            dataDaily31 = 0;   

            if (this.dataLengthDaily == 31) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
                dataDaily29 = this.dataDaily[28].studentCount;
                dataDaily30 = this.dataDaily[29].studentCount;
                dataDaily31 = this.dataDaily[30].studentCount;
            }

            if (this.dataLengthDaily == 30) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
                dataDaily29 = this.dataDaily[28].studentCount;
                dataDaily30 = this.dataDaily[29].studentCount;
            }

            if (this.dataLengthDaily == 29) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
                dataDaily29 = this.dataDaily[28].studentCount;
            }

            if (this.dataLengthDaily == 28) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
            }

            if (this.dataLengthDaily == 27) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
            }

            if (this.dataLengthDaily == 26) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
            }

            if (this.dataLengthDaily == 25) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
            }

            if (this.dataLengthDaily == 24) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
            }

            if (this.dataLengthDaily == 23) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
            }

            if (this.dataLengthDaily == 22) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
            }

            if (this.dataLengthDaily == 21) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
            }

            if (this.dataLengthDaily == 20) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
            }

            if (this.dataLengthDaily == 19) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
            }

            if (this.dataLengthDaily == 18) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
            }

            if (this.dataLengthDaily == 17) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
            }

            if (this.dataLengthDaily == 16) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
            }

            if (this.dataLengthDaily == 15) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
            }

            if (this.dataLengthDaily == 14) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
            }

            if (this.dataLengthDaily == 13) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
            }

            if (this.dataLengthDaily == 12) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
            }

            if (this.dataLengthDaily == 11) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
            }

            if (this.dataLengthDaily == 10) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
            }

            if (this.dataLengthDaily == 9) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
            }

            if (this.dataLengthDaily == 8) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
            }

            if (this.dataLengthDaily == 7) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
            }

            if (this.dataLengthDaily == 6) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
            }

            if (this.dataLengthDaily == 5) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
            }

            if (this.dataLengthDaily == 4) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
            }

            if (this.dataLengthDaily == 3) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
            }

            if (this.dataLengthDaily == 2) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
            }

            if (this.dataLengthDaily == 1) {
                dataDaily1 = this.dataDaily[0].studentCount;
            }

            var dataDailyCompleted1 = 0, dataDailyCompleted2 = 0, dataDailyCompleted3 = 0, dataDailyCompleted4 = 0, dataDailyCompleted5 = 0, dataDailyCompleted6 = 0, dataDailyCompleted7 = 0, dataDailyCompleted8 = 0, dataDailyCompleted9 = 0, dataDailyCompleted10 = 0,
            dataDailyCompleted11 = 0, dataDailyCompleted12 = 0, dataDailyCompleted13 = 0, dataDailyCompleted14 = 0, dataDailyCompleted15 = 0, dataDailyCompleted16 = 0, dataDailyCompleted17 = 0, dataDailyCompleted18 = 0, dataDailyCompleted19 = 0, dataDailyCompleted20 = 0,
            dataDailyCompleted21 = 0, dataDailyCompleted22 = 0, dataDailyCompleted23 = 0, dataDailyCompleted24 = 0, dataDailyCompleted25 = 0, dataDailyCompleted26 = 0, dataDailyCompleted27 = 0, dataDailyCompleted28 = 0, dataDailyCompleted29 = 0, dataDailyCompleted30 = 0,
            dataDailyCompleted31 = 0;
            
            this.dataLengthDailyCompleted = this.dataLengthDailyCompleted;

            if (this.dataLengthDailyCompleted == 31) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
                dataDailyCompleted29 = this.dataDailyCompleted[28].studentCount;
                dataDailyCompleted30 = this.dataDailyCompleted[29].studentCount;
                dataDailyCompleted31 = this.dataDailyCompleted[30].studentCount;
            }

            if (this.dataLengthDailyCompleted == 30) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
                dataDailyCompleted29 = this.dataDailyCompleted[28].studentCount;
                dataDailyCompleted30 = this.dataDailyCompleted[29].studentCount;
            }

            if (this.dataLengthDailyCompleted == 29) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
                dataDailyCompleted29 = this.dataDailyCompleted[28].studentCount;
            }

            if (this.dataLengthDailyCompleted == 28) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
            }

            if (this.dataLengthDailyCompleted == 27) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
            }

            if (this.dataLengthDailyCompleted == 26) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
            }

            if (this.dataLengthDailyCompleted == 25) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
            }

            if (this.dataLengthDailyCompleted == 24) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
            }

            if (this.dataLengthDailyCompleted == 23) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
            }

            if (this.dataLengthDailyCompleted == 22) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
            }

            if (this.dataLengthDailyCompleted == 21) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
            }

            if (this.dataLengthDailyCompleted == 20) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
            }

            if (this.dataLengthDailyCompleted == 19) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
            }

            if (this.dataLengthDailyCompleted == 18) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
            }

            if (this.dataLengthDailyCompleted == 17) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
            }

            if (this.dataLengthDailyCompleted == 16) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
            }

            if (this.dataLengthDailyCompleted == 15) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
            }

            if (this.dataLengthDailyCompleted == 14) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
            }

            if (this.dataLengthDailyCompleted == 13) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
            }

            if (this.dataLengthDailyCompleted == 12) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
            }

            if (this.dataLengthDailyCompleted == 11) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
            }

            if (this.dataLengthDailyCompleted == 10) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
            }

            if (this.dataLengthDailyCompleted == 9) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
            }

            if (this.dataLengthDailyCompleted == 8) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
            }

            if (this.dataLengthDailyCompleted == 7) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
            }

            if (this.dataLengthDailyCompleted == 6) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
            }

            if (this.dataLengthDailyCompleted == 5) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
            }

            if (this.dataLengthDailyCompleted == 4) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
            }

            if (this.dataLengthDailyCompleted == 3) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
            }

            if (this.dataLengthDailyCompleted == 2) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
            }

            if (this.dataLengthDailyCompleted == 1) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
            }            
                   

            this.barData2 = {
                labels: [labelDaily1, labelDaily2, labelDaily3, labelDaily4, labelDaily5, labelDaily6, labelDaily7, labelDaily8, labelDaily9, labelDaily10,
                    labelDaily11, labelDaily12, labelDaily13, labelDaily14, labelDaily15, labelDaily16, labelDaily17, labelDaily18, labelDaily19, labelDaily20,
                    labelDaily21, labelDaily22, labelDaily23, labelDaily24, labelDaily25, labelDaily26, labelDaily27, labelDaily28, labelDaily29, labelDaily30,
                    labelDaily31],
                datasets: [
                    {
                        label: 'Approved Students',
                        fill: false,
                        backgroundColor: 'rgb(255, 205, 86)',
                        borderColor: 'rgb(255, 205, 86)',
                        data: [dataDaily1, dataDaily2, dataDaily3, dataDaily4, dataDaily5, dataDaily6, dataDaily7, dataDaily8, dataDaily9, dataDaily10,
                            dataDaily11, dataDaily12, dataDaily13, dataDaily14, dataDaily15, dataDaily16, dataDaily17, dataDaily18, dataDaily19, dataDaily20,
                            dataDaily21, dataDaily22, dataDaily23, dataDaily24, dataDaily25, dataDaily26, dataDaily27, dataDaily28, dataDaily29, dataDaily30,
                            dataDaily31]
                    },
                     {
                        label: 'Completed',
                        fill: false,
                        backgroundColor: 'rgb(75, 192, 192)',
                        borderColor: 'rgb(75, 192, 192)',
                        data: [dataDailyCompleted1, dataDailyCompleted2, dataDailyCompleted3, dataDailyCompleted4, dataDailyCompleted5, dataDailyCompleted6, dataDailyCompleted7, dataDailyCompleted8, dataDailyCompleted9, dataDailyCompleted10,
                            dataDailyCompleted11, dataDailyCompleted12, dataDailyCompleted13, dataDailyCompleted14, dataDailyCompleted15, dataDailyCompleted16, dataDailyCompleted17, dataDailyCompleted18, dataDailyCompleted19, dataDailyCompleted20,
                            dataDailyCompleted21, dataDailyCompleted22, dataDailyCompleted23, dataDailyCompleted24, dataDailyCompleted25, dataDailyCompleted26, dataDailyCompleted27, dataDailyCompleted28, dataDailyCompleted29, dataDailyCompleted30,
                            dataDailyCompleted31]
                    }                  
                ]
            };
        });
    }

    loadDashboardDailyByBranch(branchCode){
        this.currentDate = new Date();
        this.nYear =  moment(new Date(this.currentDate)).format("YYYY");
        this.nMonth =  moment(new Date(this.currentDate)).format("MM");
        this.sMonth =  moment(new Date(this.currentDate)).format("MMMM");

        this.cmsService.getDashboardDataDailyCompletedByBranch(this.nYear, this.nMonth, branchCode).then((dailyCompletedData:any) => {         
            if (dailyCompletedData.result.length > 0){
                this.dataDailyCompleted = dailyCompletedData.result;  
                //console.log( this.dataDailyCompleted);
                this.dataLengthDailyCompleted = dailyCompletedData.result.length;     
                //console.log(  this.dataLengthDailyCompleted);              
            }
            else{
                this.dataDailyCompleted = {id:1,nYear:this.nYear,nMonth:this.sMonth,nWeek:1,nDay:1,studentCount:0};
                this.dataLengthDailyCompleted = 1;
            }                 
        });


        this.cmsService.getDashboardDataDailyByBranch(this.nYear, this.nMonth, branchCode).then((response:any) => {
            this.dataDaily = response.result;

            this.dataLengthDaily = response.result.length;   

            var labelDaily1 = 1, labelDaily2 = 2, labelDaily3 = 3, labelDaily4 = 4, labelDaily5 = 5, labelDaily6 = 6, labelDaily7 = 7, labelDaily8 = 8, labelDaily9 = 9, labelDaily10 = 10,
            labelDaily11 = 11, labelDaily12 = 12, labelDaily13 = 13, labelDaily14 = 14, labelDaily15 = 15, labelDaily16 = 16, labelDaily17 = 17, labelDaily18 = 18, labelDaily19 = 19, labelDaily20 = 20,
            labelDaily21 = 21, labelDaily22 = 22, labelDaily23 = 23, labelDaily24 = 24, labelDaily25 = 25, labelDaily26 = 26, labelDaily27 = 27, labelDaily28 = 28, labelDaily29 = 29, labelDaily30 = 30,
            labelDaily31 = 31;   
            
            var dataDaily1 = 0, dataDaily2 = 0, dataDaily3 = 0, dataDaily4 = 0, dataDaily5 = 0, dataDaily6 = 0, dataDaily7 = 0, dataDaily8 = 0, dataDaily9 = 0, dataDaily10 = 0,
            dataDaily11 = 0, dataDaily12 = 0, dataDaily13 = 0, dataDaily14 = 0, dataDaily15 = 0, dataDaily16 = 0, dataDaily17 = 0, dataDaily18 = 0, dataDaily19 = 0, dataDaily20 = 0,
            dataDaily21 = 0, dataDaily22 = 0, dataDaily23 = 0, dataDaily24 = 0, dataDaily25 = 0, dataDaily26 = 0, dataDaily27 = 0, dataDaily28 = 0, dataDaily29 = 0, dataDaily30 = 0,
            dataDaily31 = 0;   

            if (this.dataLengthDaily == 31) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
                dataDaily29 = this.dataDaily[28].studentCount;
                dataDaily30 = this.dataDaily[29].studentCount;
                dataDaily31 = this.dataDaily[30].studentCount;
            }

            if (this.dataLengthDaily == 30) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
                dataDaily29 = this.dataDaily[28].studentCount;
                dataDaily30 = this.dataDaily[29].studentCount;
            }

            if (this.dataLengthDaily == 29) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
                dataDaily29 = this.dataDaily[28].studentCount;
            }

            if (this.dataLengthDaily == 28) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
            }

            if (this.dataLengthDaily == 27) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
            }

            if (this.dataLengthDaily == 26) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
            }

            if (this.dataLengthDaily == 25) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
            }

            if (this.dataLengthDaily == 24) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
            }

            if (this.dataLengthDaily == 23) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
            }

            if (this.dataLengthDaily == 22) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
            }

            if (this.dataLengthDaily == 21) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
            }

            if (this.dataLengthDaily == 20) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
            }

            if (this.dataLengthDaily == 19) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
            }

            if (this.dataLengthDaily == 18) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
            }

            if (this.dataLengthDaily == 17) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
            }

            if (this.dataLengthDaily == 16) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
            }

            if (this.dataLengthDaily == 15) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
            }

            if (this.dataLengthDaily == 14) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
            }

            if (this.dataLengthDaily == 13) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
            }

            if (this.dataLengthDaily == 12) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
            }

            if (this.dataLengthDaily == 11) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
            }

            if (this.dataLengthDaily == 10) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
            }

            if (this.dataLengthDaily == 9) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
            }

            if (this.dataLengthDaily == 8) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
            }

            if (this.dataLengthDaily == 7) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
            }

            if (this.dataLengthDaily == 6) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
            }

            if (this.dataLengthDaily == 5) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
            }

            if (this.dataLengthDaily == 4) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
            }

            if (this.dataLengthDaily == 3) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
            }

            if (this.dataLengthDaily == 2) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
            }

            if (this.dataLengthDaily == 1) {
                dataDaily1 = this.dataDaily[0].studentCount;
            }

            var dataDailyCompleted1 = 0, dataDailyCompleted2 = 0, dataDailyCompleted3 = 0, dataDailyCompleted4 = 0, dataDailyCompleted5 = 0, dataDailyCompleted6 = 0, dataDailyCompleted7 = 0, dataDailyCompleted8 = 0, dataDailyCompleted9 = 0, dataDailyCompleted10 = 0,
            dataDailyCompleted11 = 0, dataDailyCompleted12 = 0, dataDailyCompleted13 = 0, dataDailyCompleted14 = 0, dataDailyCompleted15 = 0, dataDailyCompleted16 = 0, dataDailyCompleted17 = 0, dataDailyCompleted18 = 0, dataDailyCompleted19 = 0, dataDailyCompleted20 = 0,
            dataDailyCompleted21 = 0, dataDailyCompleted22 = 0, dataDailyCompleted23 = 0, dataDailyCompleted24 = 0, dataDailyCompleted25 = 0, dataDailyCompleted26 = 0, dataDailyCompleted27 = 0, dataDailyCompleted28 = 0, dataDailyCompleted29 = 0, dataDailyCompleted30 = 0,
            dataDailyCompleted31 = 0;        

            if (this.dataLengthDailyCompleted == 31) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
                dataDailyCompleted29 = this.dataDailyCompleted[28].studentCount;
                dataDailyCompleted30 = this.dataDailyCompleted[29].studentCount;
                dataDailyCompleted31 = this.dataDailyCompleted[30].studentCount;
            }

            if (this.dataLengthDailyCompleted == 30) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
                dataDailyCompleted29 = this.dataDailyCompleted[28].studentCount;
                dataDailyCompleted30 = this.dataDailyCompleted[29].studentCount;
            }

            if (this.dataLengthDailyCompleted == 29) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
                dataDailyCompleted29 = this.dataDailyCompleted[28].studentCount;
            }

            if (this.dataLengthDailyCompleted == 28) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
            }

            if (this.dataLengthDailyCompleted == 27) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
            }

            if (this.dataLengthDailyCompleted == 26) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
            }

            if (this.dataLengthDailyCompleted == 25) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
            }

            if (this.dataLengthDailyCompleted == 24) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
            }

            if (this.dataLengthDailyCompleted == 23) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
            }

            if (this.dataLengthDailyCompleted == 22) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
            }

            if (this.dataLengthDailyCompleted == 21) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
            }

            if (this.dataLengthDailyCompleted == 20) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
            }

            if (this.dataLengthDailyCompleted == 19) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
            }

            if (this.dataLengthDailyCompleted == 18) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
            }

            if (this.dataLengthDailyCompleted == 17) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
            }

            if (this.dataLengthDailyCompleted == 16) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
            }

            if (this.dataLengthDailyCompleted == 15) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
            }

            if (this.dataLengthDailyCompleted == 14) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
            }

            if (this.dataLengthDailyCompleted == 13) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
            }

            if (this.dataLengthDailyCompleted == 12) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
            }

            if (this.dataLengthDailyCompleted == 11) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
            }

            if (this.dataLengthDailyCompleted == 10) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
            }

            if (this.dataLengthDailyCompleted == 9) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
            }

            if (this.dataLengthDailyCompleted == 8) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
            }

            if (this.dataLengthDailyCompleted == 7) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
            }

            if (this.dataLengthDailyCompleted == 6) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
            }

            if (this.dataLengthDailyCompleted == 5) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
            }

            if (this.dataLengthDailyCompleted == 4) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
            }

            if (this.dataLengthDailyCompleted == 3) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
            }

            if (this.dataLengthDailyCompleted == 2) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
            }

            if (this.dataLengthDailyCompleted == 1) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
            }            
                   
            this.barData2 = {
                labels: [labelDaily1, labelDaily2, labelDaily3, labelDaily4, labelDaily5, labelDaily6, labelDaily7, labelDaily8, labelDaily9, labelDaily10,
                    labelDaily11, labelDaily12, labelDaily13, labelDaily14, labelDaily15, labelDaily16, labelDaily17, labelDaily18, labelDaily19, labelDaily20,
                    labelDaily21, labelDaily22, labelDaily23, labelDaily24, labelDaily25, labelDaily26, labelDaily27, labelDaily28, labelDaily29, labelDaily30,
                    labelDaily31],
                datasets: [
                    {
                        label: 'Approved Students',
                        fill: false,
                        backgroundColor: 'rgb(255, 205, 86)',
                        borderColor: 'rgb(255, 205, 86)',
                        data: [dataDaily1, dataDaily2, dataDaily3, dataDaily4, dataDaily5, dataDaily6, dataDaily7, dataDaily8, dataDaily9, dataDaily10,
                            dataDaily11, dataDaily12, dataDaily13, dataDaily14, dataDaily15, dataDaily16, dataDaily17, dataDaily18, dataDaily19, dataDaily20,
                            dataDaily21, dataDaily22, dataDaily23, dataDaily24, dataDaily25, dataDaily26, dataDaily27, dataDaily28, dataDaily29, dataDaily30,
                            dataDaily31]
                    },
                     {
                        label: 'Completed',
                        fill: false,
                        backgroundColor: 'rgb(75, 192, 192)',
                        borderColor: 'rgb(75, 192, 192)',
                        data: [dataDailyCompleted1, dataDailyCompleted2, dataDailyCompleted3, dataDailyCompleted4, dataDailyCompleted5, dataDailyCompleted6, dataDailyCompleted7, dataDailyCompleted8, dataDailyCompleted9, dataDailyCompleted10,
                            dataDailyCompleted11, dataDailyCompleted12, dataDailyCompleted13, dataDailyCompleted14, dataDailyCompleted15, dataDailyCompleted16, dataDailyCompleted17, dataDailyCompleted18, dataDailyCompleted19, dataDailyCompleted20,
                            dataDailyCompleted21, dataDailyCompleted22, dataDailyCompleted23, dataDailyCompleted24, dataDailyCompleted25, dataDailyCompleted26, dataDailyCompleted27, dataDailyCompleted28, dataDailyCompleted29, dataDailyCompleted30,
                            dataDailyCompleted31]
                    }                  
                ]
            };
        });
    }

    loadDashboardDailyByRegion(region){
        this.currentDate = new Date();
        this.nYear =  moment(new Date(this.currentDate)).format("YYYY");
        this.nMonth =  moment(new Date(this.currentDate)).format("MM");
        this.sMonth =  moment(new Date(this.currentDate)).format("MMMM");

        this.cmsService.getDashboardDataDailyCompletedByRegion(this.nYear, this.nMonth, region).then((dailyCompletedData:any) => {   
            if (dailyCompletedData.result.length > 0){
                this.dataDailyCompleted = dailyCompletedData.result;  
                //console.log( this.dataDailyCompleted);
                this.dataLengthDailyCompleted = dailyCompletedData.result.length;     
                //console.log(  this.dataLengthDailyCompleted);              
            }
            else{
                this.dataDailyCompleted = {id:1,nYear:this.nYear,nMonth:this.sMonth,nWeek:1,nDay:1,studentCount:0};
                this.dataLengthDailyCompleted = 1;
            }                  
        });
        //console.log(this.dataDailyCompleted);    
        this.cmsService.getDashboardDataDailyByRegion(this.nYear, this.nMonth, region).then((response:any) => {
            this.dataDaily = response.result;
            this.dataLengthDaily = response.result.length;   

            var labelDaily1 = 1, labelDaily2 = 2, labelDaily3 = 3, labelDaily4 = 4, labelDaily5 = 5, labelDaily6 = 6, labelDaily7 = 7, labelDaily8 = 8, labelDaily9 = 9, labelDaily10 = 10,
            labelDaily11 = 11, labelDaily12 = 12, labelDaily13 = 13, labelDaily14 = 14, labelDaily15 = 15, labelDaily16 = 16, labelDaily17 = 17, labelDaily18 = 18, labelDaily19 = 19, labelDaily20 = 20,
            labelDaily21 = 21, labelDaily22 = 22, labelDaily23 = 23, labelDaily24 = 24, labelDaily25 = 25, labelDaily26 = 26, labelDaily27 = 27, labelDaily28 = 28, labelDaily29 = 29, labelDaily30 = 30,
            labelDaily31 = 31;   
            
            var dataDaily1 = 0, dataDaily2 = 0, dataDaily3 = 0, dataDaily4 = 0, dataDaily5 = 0, dataDaily6 = 0, dataDaily7 = 0, dataDaily8 = 0, dataDaily9 = 0, dataDaily10 = 0,
            dataDaily11 = 0, dataDaily12 = 0, dataDaily13 = 0, dataDaily14 = 0, dataDaily15 = 0, dataDaily16 = 0, dataDaily17 = 0, dataDaily18 = 0, dataDaily19 = 0, dataDaily20 = 0,
            dataDaily21 = 0, dataDaily22 = 0, dataDaily23 = 0, dataDaily24 = 0, dataDaily25 = 0, dataDaily26 = 0, dataDaily27 = 0, dataDaily28 = 0, dataDaily29 = 0, dataDaily30 = 0,
            dataDaily31 = 0;   

            if (this.dataLengthDaily == 31) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
                dataDaily29 = this.dataDaily[28].studentCount;
                dataDaily30 = this.dataDaily[29].studentCount;
                dataDaily31 = this.dataDaily[30].studentCount;
            }

            if (this.dataLengthDaily == 30) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
                dataDaily29 = this.dataDaily[28].studentCount;
                dataDaily30 = this.dataDaily[29].studentCount;
            }

            if (this.dataLengthDaily == 29) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
                dataDaily29 = this.dataDaily[28].studentCount;
            }

            if (this.dataLengthDaily == 28) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
                dataDaily28 = this.dataDaily[27].studentCount;
            }

            if (this.dataLengthDaily == 27) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
                dataDaily27 = this.dataDaily[26].studentCount;
            }

            if (this.dataLengthDaily == 26) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
                dataDaily26 = this.dataDaily[25].studentCount;
            }

            if (this.dataLengthDaily == 25) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
                dataDaily25 = this.dataDaily[24].studentCount;
            }

            if (this.dataLengthDaily == 24) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
                dataDaily24 = this.dataDaily[23].studentCount;
            }

            if (this.dataLengthDaily == 23) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
                dataDaily23 = this.dataDaily[22].studentCount;
            }

            if (this.dataLengthDaily == 22) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
                dataDaily22 = this.dataDaily[21].studentCount;
            }

            if (this.dataLengthDaily == 21) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
                dataDaily21 = this.dataDaily[20].studentCount;
            }

            if (this.dataLengthDaily == 20) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
                dataDaily20 = this.dataDaily[19].studentCount;
            }

            if (this.dataLengthDaily == 19) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
                dataDaily19 = this.dataDaily[18].studentCount;
            }

            if (this.dataLengthDaily == 18) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
                dataDaily18 = this.dataDaily[17].studentCount;
            }

            if (this.dataLengthDaily == 17) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
                dataDaily17 = this.dataDaily[16].studentCount;
            }

            if (this.dataLengthDaily == 16) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
                dataDaily16 = this.dataDaily[15].studentCount;
            }

            if (this.dataLengthDaily == 15) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
                dataDaily15 = this.dataDaily[14].studentCount;
            }

            if (this.dataLengthDaily == 14) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
                dataDaily14 = this.dataDaily[13].studentCount;
            }

            if (this.dataLengthDaily == 13) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
                dataDaily13 = this.dataDaily[12].studentCount;
            }

            if (this.dataLengthDaily == 12) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
                dataDaily12 = this.dataDaily[11].studentCount;
            }

            if (this.dataLengthDaily == 11) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
                dataDaily11 = this.dataDaily[10].studentCount;
            }

            if (this.dataLengthDaily == 10) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
                dataDaily10 = this.dataDaily[9].studentCount;
            }

            if (this.dataLengthDaily == 9) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
                dataDaily9 = this.dataDaily[8].studentCount;
            }

            if (this.dataLengthDaily == 8) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
                dataDaily8 = this.dataDaily[7].studentCount;
            }

            if (this.dataLengthDaily == 7) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
                dataDaily7 = this.dataDaily[6].studentCount;
            }

            if (this.dataLengthDaily == 6) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
                dataDaily6 = this.dataDaily[5].studentCount;
            }

            if (this.dataLengthDaily == 5) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
                dataDaily5 = this.dataDaily[4].studentCount;
            }

            if (this.dataLengthDaily == 4) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
                dataDaily4 = this.dataDaily[3].studentCount;
            }

            if (this.dataLengthDaily == 3) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
                dataDaily3 = this.dataDaily[2].studentCount;
            }

            if (this.dataLengthDaily == 2) {
                dataDaily1 = this.dataDaily[0].studentCount;
                dataDaily2 = this.dataDaily[1].studentCount;
            }

            if (this.dataLengthDaily == 1) {
                dataDaily1 = this.dataDaily[0].studentCount;
            }

            var dataDailyCompleted1 = 0, dataDailyCompleted2 = 0, dataDailyCompleted3 = 0, dataDailyCompleted4 = 0, dataDailyCompleted5 = 0, dataDailyCompleted6 = 0, dataDailyCompleted7 = 0, dataDailyCompleted8 = 0, dataDailyCompleted9 = 0, dataDailyCompleted10 = 0,
            dataDailyCompleted11 = 0, dataDailyCompleted12 = 0, dataDailyCompleted13 = 0, dataDailyCompleted14 = 0, dataDailyCompleted15 = 0, dataDailyCompleted16 = 0, dataDailyCompleted17 = 0, dataDailyCompleted18 = 0, dataDailyCompleted19 = 0, dataDailyCompleted20 = 0,
            dataDailyCompleted21 = 0, dataDailyCompleted22 = 0, dataDailyCompleted23 = 0, dataDailyCompleted24 = 0, dataDailyCompleted25 = 0, dataDailyCompleted26 = 0, dataDailyCompleted27 = 0, dataDailyCompleted28 = 0, dataDailyCompleted29 = 0, dataDailyCompleted30 = 0,
            dataDailyCompleted31 = 0;
            
            if (this.dataLengthDailyCompleted == 31) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
                dataDailyCompleted29 = this.dataDailyCompleted[28].studentCount;
                dataDailyCompleted30 = this.dataDailyCompleted[29].studentCount;
                dataDailyCompleted31 = this.dataDailyCompleted[30].studentCount;
            }

            if (this.dataLengthDailyCompleted == 30) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
                dataDailyCompleted29 = this.dataDailyCompleted[28].studentCount;
                dataDailyCompleted30 = this.dataDailyCompleted[29].studentCount;
            }

            if (this.dataLengthDailyCompleted == 29) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
                dataDailyCompleted29 = this.dataDailyCompleted[28].studentCount;
            }

            if (this.dataLengthDailyCompleted == 28) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
                dataDailyCompleted28 = this.dataDailyCompleted[27].studentCount;
            }

            if (this.dataLengthDailyCompleted == 27) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
                dataDailyCompleted27 = this.dataDailyCompleted[26].studentCount;
            }

            if (this.dataLengthDailyCompleted == 26) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
                dataDailyCompleted26 = this.dataDailyCompleted[25].studentCount;
            }

            if (this.dataLengthDailyCompleted == 25) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
                dataDailyCompleted25 = this.dataDailyCompleted[24].studentCount;
            }

            if (this.dataLengthDailyCompleted == 24) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
                dataDailyCompleted24 = this.dataDailyCompleted[23].studentCount;
            }

            if (this.dataLengthDailyCompleted == 23) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
                dataDailyCompleted23 = this.dataDailyCompleted[22].studentCount;
            }

            if (this.dataLengthDailyCompleted == 22) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
                dataDailyCompleted22 = this.dataDailyCompleted[21].studentCount;
            }

            if (this.dataLengthDailyCompleted == 21) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
                dataDailyCompleted21 = this.dataDailyCompleted[20].studentCount;
            }

            if (this.dataLengthDailyCompleted == 20) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
                dataDailyCompleted20 = this.dataDailyCompleted[19].studentCount;
            }

            if (this.dataLengthDailyCompleted == 19) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
                dataDailyCompleted19 = this.dataDailyCompleted[18].studentCount;
            }

            if (this.dataLengthDailyCompleted == 18) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
                dataDailyCompleted18 = this.dataDailyCompleted[17].studentCount;
            }

            if (this.dataLengthDailyCompleted == 17) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
                dataDailyCompleted17 = this.dataDailyCompleted[16].studentCount;
            }

            if (this.dataLengthDailyCompleted == 16) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
                dataDailyCompleted16 = this.dataDailyCompleted[15].studentCount;
            }

            if (this.dataLengthDailyCompleted == 15) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
                dataDailyCompleted15 = this.dataDailyCompleted[14].studentCount;
            }

            if (this.dataLengthDailyCompleted == 14) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
                dataDailyCompleted14 = this.dataDailyCompleted[13].studentCount;
            }

            if (this.dataLengthDailyCompleted == 13) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
                dataDailyCompleted13 = this.dataDailyCompleted[12].studentCount;
            }

            if (this.dataLengthDailyCompleted == 12) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
                dataDailyCompleted12 = this.dataDailyCompleted[11].studentCount;
            }

            if (this.dataLengthDailyCompleted == 11) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
                dataDailyCompleted11 = this.dataDailyCompleted[10].studentCount;
            }

            if (this.dataLengthDailyCompleted == 10) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
                dataDailyCompleted10 = this.dataDailyCompleted[9].studentCount;
            }

            if (this.dataLengthDailyCompleted == 9) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
                dataDailyCompleted9 = this.dataDailyCompleted[8].studentCount;
            }

            if (this.dataLengthDailyCompleted == 8) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
                dataDailyCompleted8 = this.dataDailyCompleted[7].studentCount;
            }

            if (this.dataLengthDailyCompleted == 7) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
                dataDailyCompleted7 = this.dataDailyCompleted[6].studentCount;
            }

            if (this.dataLengthDailyCompleted == 6) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
                dataDailyCompleted6 = this.dataDailyCompleted[5].studentCount;
            }

            if (this.dataLengthDailyCompleted == 5) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
                dataDailyCompleted5 = this.dataDailyCompleted[4].studentCount;
            }

            if (this.dataLengthDailyCompleted == 4) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
                dataDailyCompleted4 = this.dataDailyCompleted[3].studentCount;
            }

            if (this.dataLengthDailyCompleted == 3) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
                dataDailyCompleted3 = this.dataDailyCompleted[2].studentCount;
            }

            if (this.dataLengthDailyCompleted == 2) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
                dataDailyCompleted2 = this.dataDailyCompleted[1].studentCount;
            }

            if (this.dataLengthDailyCompleted == 1) {
                dataDailyCompleted1 = this.dataDailyCompleted[0].studentCount;
            }            
                   
            this.barData2 = {
                labels: [labelDaily1, labelDaily2, labelDaily3, labelDaily4, labelDaily5, labelDaily6, labelDaily7, labelDaily8, labelDaily9, labelDaily10,
                    labelDaily11, labelDaily12, labelDaily13, labelDaily14, labelDaily15, labelDaily16, labelDaily17, labelDaily18, labelDaily19, labelDaily20,
                    labelDaily21, labelDaily22, labelDaily23, labelDaily24, labelDaily25, labelDaily26, labelDaily27, labelDaily28, labelDaily29, labelDaily30,
                    labelDaily31],
                datasets: [
                    {
                        label: 'Approved Students',
                        fill: false,
                        backgroundColor: 'rgb(255, 205, 86)',
                        borderColor: 'rgb(255, 205, 86)',
                        data: [dataDaily1, dataDaily2, dataDaily3, dataDaily4, dataDaily5, dataDaily6, dataDaily7, dataDaily8, dataDaily9, dataDaily10,
                            dataDaily11, dataDaily12, dataDaily13, dataDaily14, dataDaily15, dataDaily16, dataDaily17, dataDaily18, dataDaily19, dataDaily20,
                            dataDaily21, dataDaily22, dataDaily23, dataDaily24, dataDaily25, dataDaily26, dataDaily27, dataDaily28, dataDaily29, dataDaily30,
                            dataDaily31]
                    },
                     {
                        label: 'Completed',
                        fill: false,
                        backgroundColor: 'rgb(75, 192, 192)',
                        borderColor: 'rgb(75, 192, 192)',
                        data: [dataDailyCompleted1, dataDailyCompleted2, dataDailyCompleted3, dataDailyCompleted4, dataDailyCompleted5, dataDailyCompleted6, dataDailyCompleted7, dataDailyCompleted8, dataDailyCompleted9, dataDailyCompleted10,
                            dataDailyCompleted11, dataDailyCompleted12, dataDailyCompleted13, dataDailyCompleted14, dataDailyCompleted15, dataDailyCompleted16, dataDailyCompleted17, dataDailyCompleted18, dataDailyCompleted19, dataDailyCompleted20,
                            dataDailyCompleted21, dataDailyCompleted22, dataDailyCompleted23, dataDailyCompleted24, dataDailyCompleted25, dataDailyCompleted26, dataDailyCompleted27, dataDailyCompleted28, dataDailyCompleted29, dataDailyCompleted30,
                            dataDailyCompleted31]
                    }                  
                ]
            };
        });
    }

    loadDashboardMonthly(){
        this.currentDate = new Date();
        this.nYear =  moment(new Date(this.currentDate)).format("YYYY");

        this.cmsService.getDashboardDataMonthlyCompleted(this.nYear).then((com:any) => {
            if (com.result.length > 0){
                this.dataMonthlyCompleted = com.result;     
                this.dataLengthCompleted = com.result.length;            
            }
            else{
                this.dataMonthlyCompleted = {id:1,nYear:this.nYear,nMonth:this.sMonth,nWeek:1,nDay:1,studentCount:0};
                this.dataLengthCompleted = 1 ;    
            }  
        });

        this.cmsService.getDashboardDataMonthly(this.nYear).then((response:any) => {
            this.dataMonthly = response.result;
            this.dataLength = this.dataMonthly.length;
            var nMonth1 = 1, nMonth2 = 2, nMonth3 = 3, nMonth4 = 4, nMonth5= 5, 
                nMonth6 = 6, nMonth7 = 7, nMonth8 = 8, nMonth9 = 9, nMonth10 = 10, nMonth11 = 11, nMonth12 = 12;

            var nMonthData1 = 0, nMonthData2 = 0, nMonthData3 = 0, nMonthData4 = 0, nMonthData5 = 0, 
                nMonthData6 = 0, nMonthData7 = 0, nMonthData8 = 0, nMonthData9 = 0, nMonthData10 = 0, nMonthData11 = 0, nMonthData12 = 0;
    
            if (this.dataMonthly.length == 12){
                    nMonthData1 = this.dataMonthly[0].studentCount;
                    nMonthData2 = this.dataMonthly[1].studentCount;
                    nMonthData3 = this.dataMonthly[2].studentCount;
                    nMonthData4 = this.dataMonthly[3].studentCount;
                    nMonthData5 = this.dataMonthly[4].studentCount;
                    nMonthData6 = this.dataMonthly[5].studentCount;
                    nMonthData7 = this.dataMonthly[6].studentCount; 
                    nMonthData8 = this.dataMonthly[7].studentCount;
                    nMonthData9 = this.dataMonthly[8].studentCount;
                    nMonthData10 = this.dataMonthly[9].studentCount; 
                    nMonthData11 = this.dataMonthly[10].studentCount; 
                    nMonthData12 = this.dataMonthly[11].studentCount; 
                }
            if (this.dataMonthly.length == 11){
                nMonthData1 = this.dataMonthly[0].studentCount;
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
                nMonthData9 = this.dataMonthly[8].studentCount;
                nMonthData10 = this.dataMonthly[9].studentCount; 
                nMonthData11 = this.dataMonthly[10].studentCount; 
            }
            if (this.dataMonthly.length == 10){
                nMonthData1 = this.dataMonthly[0].studentCount;
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
                nMonthData9 = this.dataMonthly[8].studentCount;
                nMonthData10 = this.dataMonthly[9].studentCount; 
            }
            if (this.dataMonthly.length == 9){
                nMonthData1 = this.dataMonthly[0].studentCount;
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
                nMonthData9 = this.dataMonthly[8].studentCount;
            }
            if (this.dataMonthly.length == 8){
                nMonthData1 = this.dataMonthly[0].studentCount;
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
            }
            if (this.dataMonthly.length == 7){
                nMonthData1 = this.dataMonthly[0].studentCount;
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
            }
            if (this.dataMonthly.length == 6){
                nMonthData1 = this.dataMonthly[0].studentCount;
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
            }
            if (this.dataMonthly.length == 5){
                nMonthData1 = this.dataMonthly[0].studentCount;
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
            }
            if (this.dataLength == 4){
                nMonthData1 = this.dataMonthly[0].studentCount;
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
            }
            if (this.dataLength == 3){
                nMonthData1 = this.dataMonthly[0].studentCount;
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
            }
            if (this.dataLength == 2){
                nMonthData1 = this.dataMonthly[0].studentCount;
                nMonthData2 = this.dataMonthly[1].studentCount;
            }
            if (this.dataLength == 1){
                nMonthData1 = this.dataMonthly[0].studentCount;
            } 

            var nMonthDataCompleted1 = 0, nMonthDataCompleted2 = 0, nMonthDataCompleted3 = 0, nMonthDataCompleted4 = 0, nMonthDataCompleted5 = 0, 
            nMonthDataCompleted6 = 0, nMonthDataCompleted7 = 0, nMonthDataCompleted8 = 0, nMonthDataCompleted9 = 0, nMonthDataCompleted10 = 0, nMonthDataCompleted11 = 0, nMonthDataCompleted12 = 0;    
    
            if (this.dataLengthCompleted == 12) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
                nMonthDataCompleted10 = this.dataMonthlyCompleted[9].studentCount;
                nMonthDataCompleted11 = this.dataMonthlyCompleted[10].studentCount;
                nMonthDataCompleted12 = this.dataMonthlyCompleted[11].studentCount;
            }
            if (this.dataLengthCompleted == 11) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
                nMonthDataCompleted10 = this.dataMonthlyCompleted[9].studentCount;
                nMonthDataCompleted11 = this.dataMonthlyCompleted[10].studentCount;
            }
            if (this.dataLengthCompleted == 10) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
                nMonthDataCompleted10 = this.dataMonthlyCompleted[9].studentCount;              
            }
            if (this.dataLengthCompleted == 9) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
            }
            if (this.dataLengthCompleted == 8) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
            }
            if (this.dataLengthCompleted == 7) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
            }
            if (this.dataLengthCompleted == 6) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
            }          
            if (this.dataLengthCompleted == 5) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
            }
            if (this.dataLengthCompleted == 4) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
            }
            if (this.dataLengthCompleted == 3) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
            }
            if (this.dataLengthCompleted == 2) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
            }
            if (this.dataLengthCompleted == 1) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
            }

            this.barData3 = {
                labels: [nMonth1, nMonth2, nMonth3,nMonth4, nMonth5, nMonth6, nMonth7, nMonth8, nMonth9, nMonth10, nMonth11, nMonth12],
                datasets: [
                    {
                        label: 'Approved Students',
                        backgroundColor: [
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)"       
                        ],
                        borderColor: [
                            "rgb(0, 255, 255)"],
                        data: [nMonthData1, nMonthData2, nMonthData3,nMonthData4, nMonthData5, 
                            nMonthData6, nMonthData7, nMonthData8, nMonthData9, nMonthData10, nMonthData11, nMonthData12]
                    },
                    {
                        label: 'Completed',
                        backgroundColor: [                         
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)"
                        ],
                        borderColor: [
                            "rgb(255, 99, 132)"],
                        data: [nMonthDataCompleted1, nMonthDataCompleted2, nMonthDataCompleted3,nMonthDataCompleted4, nMonthDataCompleted5, 
                            nMonthDataCompleted6, nMonthDataCompleted7, nMonthDataCompleted8, nMonthDataCompleted9, nMonthDataCompleted10, 
                            nMonthDataCompleted11, nMonthDataCompleted12]
                    },                    
                ]
            };
        });
    }

    loadDashboardMonthlyByDs(){
        this.dataLength = 0;
        this.dataLengthCompleted = 0;
        this.currentDate = new Date();
        this.nYear =  moment(new Date(this.currentDate)).format("YYYY");

        this.cmsService.getDashboardDataMonthlyCompletedByDs(this.nYear, this.dsProfileId).then((com:any) => {
            if (com.result.length > 0){
                this.dataMonthlyCompleted = com.result;    
                this.dataLengthCompleted = com.result.length;        
            }
            else{
                this.dataMonthlyCompleted = {id:1,nYear:this.nYear,nMonth:this.sMonth,nWeek:1,nDay:1,studentCount:0};
                this.dataLengthCompleted = 1;     
            }  
        });
       
        this.cmsService.getDashboardDataMonthlyByDs(this.nYear, this.dsProfileId).then((response:any) => {
            this.dataMonthly = response.result;     

            this.dataLength = this.dataMonthly.length;
            var nMonth1 = 1, nMonth2 = 2, nMonth3 = 3, nMonth4 = 4, nMonth5= 5, 
                nMonth6 = 6, nMonth7 = 7, nMonth8 = 8, nMonth9 = 9, nMonth10 = 10, nMonth11 = 11, nMonth12 = 12;

            var nMonthData1 = 0, nMonthData2 = 0, nMonthData3 = 0, nMonthData4 = 0, nMonthData5 = 0, 
                nMonthData6 = 0, nMonthData7 = 0, nMonthData8 = 0, nMonthData9 = 0, nMonthData10 = 0, nMonthData11 = 0, nMonthData12 = 0;
    
            if (this.dataMonthly.length == 12){
                    nMonthData1 = this.dataMonthly[0].studentCount
                    nMonthData2 = this.dataMonthly[1].studentCount;
                    nMonthData3 = this.dataMonthly[2].studentCount;
                    nMonthData4 = this.dataMonthly[3].studentCount;
                    nMonthData5 = this.dataMonthly[4].studentCount;
                    nMonthData6 = this.dataMonthly[5].studentCount;
                    nMonthData7 = this.dataMonthly[6].studentCount; 
                    nMonthData8 = this.dataMonthly[7].studentCount;
                    nMonthData9 = this.dataMonthly[8].studentCount;
                    nMonthData10 = this.dataMonthly[9].studentCount; 
                    nMonthData11 = this.dataMonthly[10].studentCount; 
                    nMonthData12 = this.dataMonthly[11].studentCount; 
                }
            if (this.dataMonthly.length == 11){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
                nMonthData9 = this.dataMonthly[8].studentCount;
                nMonthData10 = this.dataMonthly[9].studentCount; 
                nMonthData11 = this.dataMonthly[10].studentCount; 
            }
            if (this.dataMonthly.length == 10){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
                nMonthData9 = this.dataMonthly[8].studentCount;
                nMonthData10 = this.dataMonthly[9].studentCount; 
            }
            if (this.dataMonthly.length == 9){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
                nMonthData9 = this.dataMonthly[8].studentCount;
            }
            if (this.dataMonthly.length == 8){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
            }
            if (this.dataMonthly.length == 7){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
            }
            if (this.dataMonthly.length == 6){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
            }
            if (this.dataMonthly.length == 5){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
            }
            if (this.dataLength == 4){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
            }
            if (this.dataLength == 3){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
            }
            if (this.dataLength == 2){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
            }
            if (this.dataLength == 1){
                nMonthData1 = this.dataMonthly[0].studentCount
            } 

            var nMonthDataCompleted1 = 0, nMonthDataCompleted2 = 0, nMonthDataCompleted3 = 0, nMonthDataCompleted4 = 0, nMonthDataCompleted5 = 0, 
            nMonthDataCompleted6 = 0, nMonthDataCompleted7 = 0, nMonthDataCompleted8 = 0, nMonthDataCompleted9 = 0, nMonthDataCompleted10 = 0, nMonthDataCompleted11 = 0, nMonthDataCompleted12 = 0;    
    
    
            if (this.dataLengthCompleted == 12) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
                nMonthDataCompleted10 = this.dataMonthlyCompleted[9].studentCount;
                nMonthDataCompleted11 = this.dataMonthlyCompleted[10].studentCount;
                nMonthDataCompleted12 = this.dataMonthlyCompleted[11].studentCount;
            }
            if (this.dataLengthCompleted == 11) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
                nMonthDataCompleted10 = this.dataMonthlyCompleted[9].studentCount;
                nMonthDataCompleted11 = this.dataMonthlyCompleted[10].studentCount;
            }
            if (this.dataLengthCompleted == 10) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
                nMonthDataCompleted10 = this.dataMonthlyCompleted[9].studentCount;              
            }
            if (this.dataLengthCompleted == 9) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
            }
            if (this.dataLengthCompleted == 8) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
            }
            if (this.dataLengthCompleted == 7) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
            }
            if (this.dataLengthCompleted == 6) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
            }          
            if (this.dataLengthCompleted == 5) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
            }
            if (this.dataLengthCompleted == 4) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
            }
            if (this.dataLengthCompleted == 3) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
            }
            if (this.dataLengthCompleted == 2) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
            }
            if (this.dataLengthCompleted == 1) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
            }

            this.barData3 = {
                labels: [nMonth1, nMonth2, nMonth3,nMonth4, nMonth5, nMonth6, nMonth7, nMonth8, nMonth9, nMonth10, nMonth11, nMonth12],
                datasets: [
                    {
                        label: 'Approved Students',
                        backgroundColor: [
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)"       
                        ],
                        borderColor: [
                            "rgb(0, 255, 255)"],
                        data: [nMonthData1, nMonthData2, nMonthData3,nMonthData4, nMonthData5, 
                            nMonthData6, nMonthData7, nMonthData8, nMonthData9, nMonthData10, nMonthData11, nMonthData12]
                    },
                    {
                        label: 'Completed',
                        backgroundColor: [                         
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)"
                        ],
                        borderColor: [
                            "rgb(255, 99, 132)"],
                        data: [nMonthDataCompleted1, nMonthDataCompleted2, nMonthDataCompleted3,nMonthDataCompleted4, nMonthDataCompleted5, 
                            nMonthDataCompleted6, nMonthDataCompleted7, nMonthDataCompleted8, nMonthDataCompleted9, nMonthDataCompleted10, 
                            nMonthDataCompleted11, nMonthDataCompleted12]
                    },                    
                ]
            };
        });
    }

    loadDashboardMonthlyByBranch(branchCode){
        this.currentDate = new Date();
        this.nYear =  moment(new Date(this.currentDate)).format("YYYY");

        this.cmsService.getDashboardDataMonthlyCompletedByBranch(this.nYear, branchCode).then((com:any) => {            
            if (com.result.length > 0){
                this.dataMonthlyCompleted = com.result;   
                this.dataLengthCompleted = com.result.length ;              
            }
            else{
                this.dataMonthlyCompleted = {id:1,nYear:this.nYear,nMonth:this.sMonth,nWeek:1,nDay:1,studentCount:0};
                this.dataLengthCompleted = 1 ;  
            }  
        });

        this.cmsService.getDashboardDataMonthlyByBranch(this.nYear, branchCode).then((response:any) => {
            this.dataMonthly = response.result;
            this.dataLength = this.dataMonthly.length;
            var nMonth1 = 1, nMonth2 = 2, nMonth3 = 3, nMonth4 = 4, nMonth5= 5, 
                nMonth6 = 6, nMonth7 = 7, nMonth8 = 8, nMonth9 = 9, nMonth10 = 10, nMonth11 = 11, nMonth12 = 12;

            var nMonthData1 = 0, nMonthData2 = 0, nMonthData3 = 0, nMonthData4 = 0, nMonthData5 = 0, 
                nMonthData6 = 0, nMonthData7 = 0, nMonthData8 = 0, nMonthData9 = 0, nMonthData10 = 0, nMonthData11 = 0, nMonthData12 = 0;

            if (this.dataMonthly.length == 12){
                    nMonthData1 = this.dataMonthly[0].studentCount
                    nMonthData2 = this.dataMonthly[1].studentCount;
                    nMonthData3 = this.dataMonthly[2].studentCount;
                    nMonthData4 = this.dataMonthly[3].studentCount;
                    nMonthData5 = this.dataMonthly[4].studentCount;
                    nMonthData6 = this.dataMonthly[5].studentCount;
                    nMonthData7 = this.dataMonthly[6].studentCount; 
                    nMonthData8 = this.dataMonthly[7].studentCount;
                    nMonthData9 = this.dataMonthly[8].studentCount;
                    nMonthData10 = this.dataMonthly[9].studentCount; 
                    nMonthData11 = this.dataMonthly[10].studentCount; 
                    nMonthData12 = this.dataMonthly[11].studentCount; 
                }
            if (this.dataMonthly.length == 11){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
                nMonthData9 = this.dataMonthly[8].studentCount;
                nMonthData10 = this.dataMonthly[9].studentCount; 
                nMonthData11 = this.dataMonthly[10].studentCount; 
            }
            if (this.dataMonthly.length == 10){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
                nMonthData9 = this.dataMonthly[8].studentCount;
                nMonthData10 = this.dataMonthly[9].studentCount; 
            }
            if (this.dataMonthly.length == 9){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
                nMonthData9 = this.dataMonthly[8].studentCount;
            }
            if (this.dataMonthly.length == 8){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
            }
            if (this.dataMonthly.length == 7){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
            }
            if (this.dataMonthly.length == 6){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
            }
            if (this.dataMonthly.length == 5){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
            }
            if (this.dataLength == 4){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
            }
            if (this.dataLength == 3){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
            }
            if (this.dataLength == 2){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
            }
            if (this.dataLength == 1){
                nMonthData1 = this.dataMonthly[0].studentCount
            } 

            var nMonthDataCompleted1 = 0, nMonthDataCompleted2 = 0, nMonthDataCompleted3 = 0, nMonthDataCompleted4 = 0, nMonthDataCompleted5 = 0, 
            nMonthDataCompleted6 = 0, nMonthDataCompleted7 = 0, nMonthDataCompleted8 = 0, nMonthDataCompleted9 = 0, nMonthDataCompleted10 = 0, nMonthDataCompleted11 = 0, nMonthDataCompleted12 = 0;    
    
    
            if (this.dataLengthCompleted == 12) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
                nMonthDataCompleted10 = this.dataMonthlyCompleted[9].studentCount;
                nMonthDataCompleted11 = this.dataMonthlyCompleted[10].studentCount;
                nMonthDataCompleted12 = this.dataMonthlyCompleted[11].studentCount;
            }
            if (this.dataLengthCompleted == 11) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
                nMonthDataCompleted10 = this.dataMonthlyCompleted[9].studentCount;
                nMonthDataCompleted11 = this.dataMonthlyCompleted[10].studentCount;
            }
            if (this.dataLengthCompleted == 10) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
                nMonthDataCompleted10 = this.dataMonthlyCompleted[9].studentCount;              
            }
            if (this.dataLengthCompleted == 9) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
            }
            if (this.dataLengthCompleted == 8) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
            }
            if (this.dataLengthCompleted == 7) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
            }
            if (this.dataLengthCompleted == 6) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
            }          
            if (this.dataLengthCompleted == 5) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
            }
            if (this.dataLengthCompleted == 4) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
            }
            if (this.dataLengthCompleted == 3) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
            }
            if (this.dataLengthCompleted == 2) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
            }
            if (this.dataLengthCompleted == 1) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
            }
    
            this.barData3 = {
                labels: [nMonth1, nMonth2, nMonth3,nMonth4, nMonth5, nMonth6, nMonth7, nMonth8, nMonth9, nMonth10, nMonth11, nMonth12],
                datasets: [
                    {
                        label: 'Approved Students',
                        backgroundColor: [
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)"       
                        ],
                        borderColor: [
                            "rgb(0, 255, 255)"],
                        data: [nMonthData1, nMonthData2, nMonthData3,nMonthData4, nMonthData5, 
                            nMonthData6, nMonthData7, nMonthData8, nMonthData9, nMonthData10, nMonthData11, nMonthData12]
                    },
                    {
                        label: 'Completed',
                        backgroundColor: [                         
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)"
                        ],
                        borderColor: [
                            "rgb(255, 99, 132)"],
                        data: [nMonthDataCompleted1, nMonthDataCompleted2, nMonthDataCompleted3,nMonthDataCompleted4, nMonthDataCompleted5, 
                            nMonthDataCompleted6, nMonthDataCompleted7, nMonthDataCompleted8, nMonthDataCompleted9, nMonthDataCompleted10, 
                            nMonthDataCompleted11, nMonthDataCompleted12]
                    },                    
                ]
            };
        });
    }

    loadDashboardMonthlyByRegion(region){
        this.currentDate = new Date();
        this.nYear =  moment(new Date(this.currentDate)).format("YYYY");

        this.cmsService.getDashboardDataMonthlyCompletedByRegion(this.nYear, region).then((com:any) => {
            if (com.result.length > 0){
                this.dataMonthlyCompleted = com.result;        
                this.dataLengthCompleted = com.result.length ;                     
            }
            else{
                this.dataMonthlyCompleted = {id:1,nYear:this.nYear,nMonth:this.sMonth,nWeek:1,nDay:1,studentCount:0};
                this.dataLengthCompleted = 1;
            }  
        });

        this.cmsService.getDashboardDataMonthlyByRegion(this.nYear, region).then((response:any) => {
            this.dataMonthly = response.result;
            this.dataLength = this.dataMonthly.length;
            var nMonth1 = 1, nMonth2 = 2, nMonth3 = 3, nMonth4 = 4, nMonth5= 5, 
                nMonth6 = 6, nMonth7 = 7, nMonth8 = 8, nMonth9 = 9, nMonth10 = 10, nMonth11 = 11, nMonth12 = 12;

            var nMonthData1 = 0, nMonthData2 = 0, nMonthData3 = 0, nMonthData4 = 0, nMonthData5 = 0, 
                nMonthData6 = 0, nMonthData7 = 0, nMonthData8 = 0, nMonthData9 = 0, nMonthData10 = 0, nMonthData11 = 0, nMonthData12 = 0;

            if (this.dataMonthly.length == 12){
                    nMonthData1 = this.dataMonthly[0].studentCount
                    nMonthData2 = this.dataMonthly[1].studentCount;
                    nMonthData3 = this.dataMonthly[2].studentCount;
                    nMonthData4 = this.dataMonthly[3].studentCount;
                    nMonthData5 = this.dataMonthly[4].studentCount;
                    nMonthData6 = this.dataMonthly[5].studentCount;
                    nMonthData7 = this.dataMonthly[6].studentCount; 
                    nMonthData8 = this.dataMonthly[7].studentCount;
                    nMonthData9 = this.dataMonthly[8].studentCount;
                    nMonthData10 = this.dataMonthly[9].studentCount; 
                    nMonthData11 = this.dataMonthly[10].studentCount; 
                    nMonthData12 = this.dataMonthly[11].studentCount; 
                }
            if (this.dataMonthly.length == 11){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
                nMonthData9 = this.dataMonthly[8].studentCount;
                nMonthData10 = this.dataMonthly[9].studentCount; 
                nMonthData11 = this.dataMonthly[10].studentCount; 
            }
            if (this.dataMonthly.length == 10){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
                nMonthData9 = this.dataMonthly[8].studentCount;
                nMonthData10 = this.dataMonthly[9].studentCount; 
            }
            if (this.dataMonthly.length == 9){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
                nMonthData9 = this.dataMonthly[8].studentCount;
            }
            if (this.dataMonthly.length == 8){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
                nMonthData8 = this.dataMonthly[7].studentCount;
            }
            if (this.dataMonthly.length == 7){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
                nMonthData7 = this.dataMonthly[6].studentCount; 
            }
            if (this.dataMonthly.length == 6){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
                nMonthData6 = this.dataMonthly[5].studentCount;
            }
            if (this.dataMonthly.length == 5){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
                nMonthData5 = this.dataMonthly[4].studentCount;
            }
            if (this.dataLength == 4){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
                nMonthData4 = this.dataMonthly[3].studentCount;
            }
            if (this.dataLength == 3){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
                nMonthData3 = this.dataMonthly[2].studentCount;
            }
            if (this.dataLength == 2){
                nMonthData1 = this.dataMonthly[0].studentCount
                nMonthData2 = this.dataMonthly[1].studentCount;
            }
            if (this.dataLength == 1){
                nMonthData1 = this.dataMonthly[0].studentCount
            } 

            var nMonthDataCompleted1 = 0, nMonthDataCompleted2 = 0, nMonthDataCompleted3 = 0, nMonthDataCompleted4 = 0, nMonthDataCompleted5 = 0, 
            nMonthDataCompleted6 = 0, nMonthDataCompleted7 = 0, nMonthDataCompleted8 = 0, nMonthDataCompleted9 = 0, nMonthDataCompleted10 = 0, nMonthDataCompleted11 = 0, nMonthDataCompleted12 = 0;    
    
    
            if (this.dataLengthCompleted == 12) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
                nMonthDataCompleted10 = this.dataMonthlyCompleted[9].studentCount;
                nMonthDataCompleted11 = this.dataMonthlyCompleted[10].studentCount;
                nMonthDataCompleted12 = this.dataMonthlyCompleted[11].studentCount;
            }
            if (this.dataLengthCompleted == 11) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
                nMonthDataCompleted10 = this.dataMonthlyCompleted[9].studentCount;
                nMonthDataCompleted11 = this.dataMonthlyCompleted[10].studentCount;
            }
            if (this.dataLengthCompleted == 10) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
                nMonthDataCompleted10 = this.dataMonthlyCompleted[9].studentCount;              
            }
            if (this.dataLengthCompleted == 9) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
                nMonthDataCompleted9 = this.dataMonthlyCompleted[8].studentCount;
            }
            if (this.dataLengthCompleted == 8) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
                nMonthDataCompleted8 = this.dataMonthlyCompleted[7].studentCount;
            }
            if (this.dataLengthCompleted == 7) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
                nMonthDataCompleted7 = this.dataMonthlyCompleted[6].studentCount;
            }
            if (this.dataLengthCompleted == 6) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
                nMonthDataCompleted6 = this.dataMonthlyCompleted[5].studentCount;
            }          
            if (this.dataLengthCompleted == 5) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
                nMonthDataCompleted5 = this.dataMonthlyCompleted[4].studentCount;
            }
            if (this.dataLengthCompleted == 4) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
                nMonthDataCompleted4 = this.dataMonthlyCompleted[3].studentCount;
            }
            if (this.dataLengthCompleted == 3) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
                nMonthDataCompleted3 = this.dataMonthlyCompleted[2].studentCount;
            }
            if (this.dataLengthCompleted == 2) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
                nMonthDataCompleted2 = this.dataMonthlyCompleted[1].studentCount;
            }
            if (this.dataLengthCompleted == 1) {
                nMonthDataCompleted1 = this.dataMonthlyCompleted[0].studentCount;
            }
            this.barData3 = {
                labels: [nMonth1, nMonth2, nMonth3,nMonth4, nMonth5, nMonth6, nMonth7, nMonth8, nMonth9, nMonth10, nMonth11, nMonth12],
                datasets: [
                    {
                        label: 'Approved Students',
                        backgroundColor: [
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)",
                            "rgb(0, 255, 255)"       
                        ],
                        borderColor: [
                            "rgb(0, 255, 255)"],
                        data: [nMonthData1, nMonthData2, nMonthData3,nMonthData4, nMonthData5, 
                            nMonthData6, nMonthData7, nMonthData8, nMonthData9, nMonthData10, nMonthData11, nMonthData12]
                    },
                    {
                        label: 'Completed',
                        backgroundColor: [                         
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 99, 132)"
                        ],
                        borderColor: [
                            "rgb(255, 99, 132)"],
                        data: [nMonthDataCompleted1, nMonthDataCompleted2, nMonthDataCompleted3,nMonthDataCompleted4, nMonthDataCompleted5, 
                            nMonthDataCompleted6, nMonthDataCompleted7, nMonthDataCompleted8, nMonthDataCompleted9, nMonthDataCompleted10, 
                            nMonthDataCompleted11, nMonthDataCompleted12]
                    },                    
                ]
            };
        });
    }

    loadDashboardWeekly(){
        this.currentDate = new Date();
        this.nYear =  moment(new Date(this.currentDate)).format("YYYY");

        this.cmsService.getDashboardDataWeeklyCompleted(this.nYear).then((dataCompleted:any) => {
            if (dataCompleted.result.length > 0){
                this.dataWeeklyCompleted = dataCompleted.result;   
                this.dataLengthWeeklyCompleted = dataCompleted.result.length;      
            }
            else{
                this.dataWeeklyCompleted = {id:1,nYear:this.nYear,nMonth:1,nWeek:this.nWeek,nDay:1,studentCount:0};
                this.dataLengthWeeklyCompleted = 1;
                //console.log(this.dataMonthlyCompleted);
            }  
        });

        this.cmsService.getDashboardDataWeekly(this.nYear).then((response:any) => {
            this.dataWeekly = response.result;
            this.dataLengthWeekly = response.result.length;
            //console.log(this.dataWeekly);

            var labelWeekly1 = 1, labelWeekly2 = 2, labelWeekly3 = 3, labelWeekly4 = 4, labelWeekly5 = 5, labelWeekly6 = 6, labelWeekly7 = 7, labelWeekly8 = 8, labelWeekly9 = 9, labelDaily10 = 10,
            labelWeekly11 = 11, labelWeekly12 = 12, labelWeekly13 = 13, labelWeekly14 = 14, labelWeekly15 = 15, labelWeekly16 = 16, labelWeekly17 = 17, labelWeekly18 = 18, labelWeekly19 = 19, labelWeekly20 = 20,
            labelWeekly21 = 21, labelWeekly22 = 22, labelWeekly23 = 23, labelWeekly24 = 24, labelWeekly25 = 25, labelWeekly26 = 26, labelWeekly27 = 27, labelWeekly28 = 28, labelWeekly29 = 29, labelWeekly30 = 30,  
            labelWeekly31 = 31, labelWeekly32 = 32, labelWeekly33 = 33, labelWeekly34 = 34, labelWeekly35 = 35, labelWeekly36 = 36, labelWeekly37 = 37, labelWeekly38 = 38, labelWeekly39 = 39, labelDaily40 = 40,
            labelWeekly41 = 41, labelWeekly42 = 42, labelWeekly43 = 43, labelWeekly44 = 44, labelWeekly45 = 45, labelWeekly46 = 46, labelWeekly47 = 47, labelWeekly48 = 48, labelWeekly49 = 49, labelDaily50 = 50,
            labelWeekly51 = 51, labelWeekly51 = 52;

            var dataWeekly1 = 0, dataWeekly2 = 0, dataWeekly3 = 0, dataWeekly4 = 0, dataWeekly5 = 0, dataWeekly6 = 0, dataWeekly7 = 0, dataWeekly8 = 0, dataWeekly9 = 0, dataWeekly10 = 0,
            dataWeekly11 = 0, dataWeekly12 = 0, dataWeekly13 = 0, dataWeekly14 = 0, dataWeekly15 = 0, dataWeekly16 = 0, dataWeekly17 = 0, dataWeekly18 = 0, dataWeekly19 = 0, dataWeekly20 = 0,
            dataWeekly21 = 0, dataWeekly22 = 0, dataWeekly23 = 0, dataWeekly24 = 0, dataWeekly25 = 0, dataWeekly26 = 0, dataWeekly27 = 0, dataWeekly28 = 0, dataWeekly29 = 0, dataWeekly30 = 0,
            dataWeekly31 = 0, dataWeekly32 = 0, dataWeekly33 = 0, dataWeekly34 = 0, dataWeekly35 = 0, dataWeekly36 = 0, dataWeekly37 = 0, dataWeekly38 = 0, dataWeekly39 = 0, dataWeekly40 = 0,
            dataWeekly41 = 0, dataWeekly42 = 0, dataWeekly43 = 0, dataWeekly44 = 0, dataWeekly45 = 0, dataWeekly46 = 0, dataWeekly47 = 0, dataWeekly48 = 0, dataWeekly49 = 0, dataWeekly50 = 0,
            dataWeekly51 = 0, dataWeekly52 = 0;

            if(this.dataLengthWeekly == 52){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
                dataWeekly50 = this.dataWeekly[49].studentCount;
                dataWeekly51 = this.dataWeekly[50].studentCount;
                dataWeekly52 = this.dataWeekly[51].studentCount;
            }
            if(this.dataLengthWeekly == 51){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
                dataWeekly50 = this.dataWeekly[49].studentCount;
                dataWeekly51 = this.dataWeekly[50].studentCount;
            }
            if(this.dataLengthWeekly == 50){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
                dataWeekly50 = this.dataWeekly[49].studentCount;
            }
            if(this.dataLengthWeekly == 49){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
            }
            if(this.dataLengthWeekly == 48){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
            }
            if(this.dataLengthWeekly == 47){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
            }
            if(this.dataLengthWeekly == 46){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
            }
            if(this.dataLengthWeekly == 45){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
            }
            if(this.dataLengthWeekly == 44){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
            }
            if(this.dataLengthWeekly == 43){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
            }
            if(this.dataLengthWeekly == 42){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
            }
            if(this.dataLengthWeekly == 41){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
            }
            if(this.dataLengthWeekly == 40){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
            }
            if(this.dataLengthWeekly == 39){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
            }
            if(this.dataLengthWeekly == 38){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
            }
            if(this.dataLengthWeekly == 37){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
            }
            if(this.dataLengthWeekly == 36){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
            }
            if(this.dataLengthWeekly == 35){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
            }
            if(this.dataLengthWeekly == 34){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
            }
            if(this.dataLengthWeekly == 33){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
            }
            if(this.dataLengthWeekly == 32){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
            }
            if(this.dataLengthWeekly == 31){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
            }
            if(this.dataLengthWeekly == 30){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
            }
            if(this.dataLengthWeekly == 29){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
            }
            if(this.dataLengthWeekly == 28){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
            }
            if(this.dataLengthWeekly == 27){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
            }
            if(this.dataLengthWeekly == 26){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
            }
            if(this.dataLengthWeekly == 25){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
            }
            if(this.dataLengthWeekly == 24){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
            }
            if(this.dataLengthWeekly == 23){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
            }
            if(this.dataLengthWeekly == 22){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
            }
            if(this.dataLengthWeekly == 21){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
            }
            if(this.dataLengthWeekly == 20){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
            }
            if(this.dataLengthWeekly == 19){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
            }
            if(this.dataLengthWeekly == 18){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
            }
            if(this.dataLengthWeekly == 17){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
            }
            if(this.dataLengthWeekly == 16){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
            }
            if(this.dataLengthWeekly == 15){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
            }
            if(this.dataLengthWeekly == 14){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
            }
            if(this.dataLengthWeekly == 13){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
            }
            if(this.dataLengthWeekly == 12){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
            }
            if(this.dataLengthWeekly == 11){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
            }
            if(this.dataLengthWeekly == 10){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
            }
            if(this.dataLengthWeekly == 9){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
            }
            if(this.dataLengthWeekly == 8){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
            }
            if(this.dataLengthWeekly == 7){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
            }
            if(this.dataLengthWeekly == 6){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
            }
            if(this.dataLengthWeekly == 5){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
            }
            if(this.dataLengthWeekly == 4){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
            }
            if(this.dataLengthWeekly == 3){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
            }
            if(this.dataLengthWeekly == 2){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
            }
            if(this.dataLengthWeekly == 1){
                dataWeekly1 = this.dataWeekly[0].studentCount;
            }

            var dataWeeklyCompleted1 = 0, dataWeeklyCompleted2 = 0, dataWeeklyCompleted3 = 0, dataWeeklyCompleted4 = 0, dataWeeklyCompleted5 = 0, dataWeeklyCompleted6 = 0, dataWeeklyCompleted7 = 0, dataWeeklyCompleted8 = 0, dataWeeklyCompleted9 = 0, dataWeeklyCompleted10 = 0,
            dataWeeklyCompleted11 = 0, dataWeeklyCompleted12 = 0, dataWeeklyCompleted13 = 0, dataWeeklyCompleted14 = 0, dataWeeklyCompleted15 = 0, dataWeeklyCompleted16 = 0, dataWeeklyCompleted17 = 0, dataWeeklyCompleted18 = 0, dataWeeklyCompleted19 = 0, dataWeeklyCompleted20 = 0,
            dataWeeklyCompleted21 = 0, dataWeeklyCompleted22 = 0, dataWeeklyCompleted23 = 0, dataWeeklyCompleted24 = 0, dataWeeklyCompleted25 = 0, dataWeeklyCompleted26 = 0, dataWeeklyCompleted27 = 0, dataWeeklyCompleted28 = 0, dataWeeklyCompleted29 = 0, dataWeeklyCompleted30 = 0,
            dataWeeklyCompleted31 = 0, dataWeeklyCompleted32 = 0, dataWeeklyCompleted33 = 0, dataWeeklyCompleted34 = 0, dataWeeklyCompleted35 = 0, dataWeeklyCompleted36 = 0, dataWeeklyCompleted37 = 0, dataWeeklyCompleted38 = 0, dataWeeklyCompleted39 = 0, dataWeeklyCompleted40 = 0,
            dataWeeklyCompleted41 = 0, dataWeeklyCompleted42 = 0, dataWeeklyCompleted43 = 0, dataWeeklyCompleted44 = 0, dataWeeklyCompleted45 = 0, dataWeeklyCompleted46 = 0, dataWeeklyCompleted47 = 0, dataWeeklyCompleted48 = 0, dataWeeklyCompleted49 = 0, dataWeeklyCompleted50 = 0,
            dataWeeklyCompleted51 = 0, dataWeeklyCompleted52 = 0;

            if(this.dataLengthWeeklyCompleted == 52){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
                dataWeeklyCompleted50 = this.dataWeeklyCompleted[49].studentCount;
                dataWeeklyCompleted51 = this.dataWeeklyCompleted[50].studentCount;
                dataWeeklyCompleted52 = this.dataWeeklyCompleted[51].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 51){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
                dataWeeklyCompleted50 = this.dataWeeklyCompleted[49].studentCount;
                dataWeeklyCompleted51 = this.dataWeeklyCompleted[50].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 50){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
                dataWeeklyCompleted50 = this.dataWeeklyCompleted[49].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 49){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 48){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 47){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 46){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 45){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 44){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 43){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 42){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 41){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 40){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 39){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 38){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 37){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 36){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 35){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 34){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 33){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 32){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 31){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 30){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 29){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 28){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 27){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 26){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 25){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 24){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 23){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 22){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 21){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 20){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 19){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 18){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 17){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 16){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 15){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 14){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 13){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 12){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 11){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 10){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 9){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 8){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 7){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 6){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 5){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 4){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 3){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 2){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 1){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
            }  

            this.barData4 = {
                labels: [labelWeekly1, labelWeekly2, labelWeekly3, labelWeekly4, labelWeekly5, labelWeekly6, labelWeekly7, labelWeekly8, labelWeekly9, labelDaily10,
                    labelWeekly11, labelWeekly12, labelWeekly13, labelWeekly14, labelWeekly15, labelWeekly16, labelWeekly17, labelWeekly18, labelWeekly19, labelWeekly20,
                    labelWeekly21, labelWeekly22, labelWeekly23, labelWeekly24, labelWeekly25, labelWeekly26, labelWeekly27, labelWeekly28, labelWeekly29, labelWeekly30,  
                    labelWeekly31, labelWeekly32, labelWeekly33, labelWeekly34, labelWeekly35, labelWeekly36, labelWeekly37, labelWeekly38, labelWeekly39, labelDaily40,
                    labelWeekly41, labelWeekly42, labelWeekly43, labelWeekly44, labelWeekly45, labelWeekly46, labelWeekly47, labelWeekly48, labelWeekly49, labelDaily50,
                    labelWeekly51, labelWeekly51],
                datasets: [
                    {
                        label: 'Approved Students',
                        fill: false,
                        backgroundColor: "rgb(57, 255, 20)",                          
                        borderColor: "rgb(57, 255, 20)", 
                        data: [dataWeekly1, dataWeekly2, dataWeekly3, dataWeekly4, dataWeekly5, dataWeekly6, dataWeekly7, dataWeekly8, dataWeekly9, dataWeekly10,
                            dataWeekly11, dataWeekly12, dataWeekly13, dataWeekly14, dataWeekly15, dataWeekly16, dataWeekly17, dataWeekly18, dataWeekly19, dataWeekly20,
                            dataWeekly21, dataWeekly22, dataWeekly23, dataWeekly24, dataWeekly25, dataWeekly26, dataWeekly27, dataWeekly28, dataWeekly29, dataWeekly30,
                            dataWeekly31, dataWeekly32, dataWeekly33, dataWeekly34, dataWeekly35, dataWeekly36, dataWeekly37, dataWeekly38, dataWeekly39, dataWeekly40,
                            dataWeekly41, dataWeekly42, dataWeekly43, dataWeekly44, dataWeekly45, dataWeekly46, dataWeekly47, dataWeekly48, dataWeekly49, dataWeekly50,
                            dataWeekly51, dataWeekly52]
                    },
                    {
                        label: 'Completed',
                        fill: false,
                        backgroundColor: "rgb(106, 13, 173)",
                        borderColor: "rgb(106, 13, 173)",
                        data: [dataWeeklyCompleted1, dataWeeklyCompleted2, dataWeeklyCompleted3, dataWeeklyCompleted4, dataWeeklyCompleted5, dataWeeklyCompleted6, dataWeeklyCompleted7, dataWeeklyCompleted8, dataWeeklyCompleted9, dataWeeklyCompleted10,
                            dataWeeklyCompleted11, dataWeeklyCompleted12, dataWeeklyCompleted13, dataWeeklyCompleted14, dataWeeklyCompleted15, dataWeeklyCompleted16, dataWeeklyCompleted17, dataWeeklyCompleted18, dataWeeklyCompleted19, dataWeeklyCompleted20,
                            dataWeeklyCompleted21, dataWeeklyCompleted22, dataWeeklyCompleted23, dataWeeklyCompleted24, dataWeeklyCompleted25, dataWeeklyCompleted26, dataWeeklyCompleted27, dataWeeklyCompleted28, dataWeeklyCompleted29, dataWeeklyCompleted30,
                            dataWeeklyCompleted31, dataWeeklyCompleted32, dataWeeklyCompleted33, dataWeeklyCompleted34, dataWeeklyCompleted35, dataWeeklyCompleted36, dataWeeklyCompleted37, dataWeeklyCompleted38, dataWeeklyCompleted39, dataWeeklyCompleted40,
                            dataWeeklyCompleted41, dataWeeklyCompleted42, dataWeeklyCompleted43, dataWeeklyCompleted44, dataWeeklyCompleted45, dataWeeklyCompleted46, dataWeeklyCompleted47, dataWeeklyCompleted48, dataWeeklyCompleted49, dataWeeklyCompleted50,
                            dataWeeklyCompleted51, dataWeeklyCompleted52]
                    }                   
                ]
            };
        });
    }

    loadDashboardWeeklyByDs(){
        this.currentDate = new Date();
        this.nYear =  moment(new Date(this.currentDate)).format("YYYY");

        this.cmsService.getDashboardDataWeeklyCompletedByDs(this.nYear, this.dsProfileId).then((dataCompleted:any) => {
            if (dataCompleted.result.length > 0){
                this.dataWeeklyCompleted = dataCompleted.result;       
                this.dataLengthWeeklyCompleted = dataCompleted.result.length;
            }
            else{
                this.dataWeeklyCompleted = {id:1,nYear:this.nYear,nMonth:1,nWeek:this.nWeek,nDay:1,studentCount:0};
                this.dataLengthWeeklyCompleted = 1;
                //console.log(this.dataMonthlyCompleted);
            }  
        });

        this.cmsService.getDashboardDataWeeklyByDs(this.nYear, this.dsProfileId).then((response:any) => {
            this.dataWeekly = response.result;
            this.dataLengthWeekly = response.result.length;
            //console.log(this.dataWeekly);
            var labelWeekly1 = 1, labelWeekly2 = 2, labelWeekly3 = 3, labelWeekly4 = 4, labelWeekly5 = 5, labelWeekly6 = 6, labelWeekly7 = 7, labelWeekly8 = 8, labelWeekly9 = 9, labelDaily10 = 10,
            labelWeekly11 = 11, labelWeekly12 = 12, labelWeekly13 = 13, labelWeekly14 = 14, labelWeekly15 = 15, labelWeekly16 = 16, labelWeekly17 = 17, labelWeekly18 = 18, labelWeekly19 = 19, labelWeekly20 = 20,
            labelWeekly21 = 21, labelWeekly22 = 22, labelWeekly23 = 23, labelWeekly24 = 24, labelWeekly25 = 25, labelWeekly26 = 26, labelWeekly27 = 27, labelWeekly28 = 28, labelWeekly29 = 29, labelWeekly30 = 30,  
            labelWeekly31 = 31, labelWeekly32 = 32, labelWeekly33 = 33, labelWeekly34 = 34, labelWeekly35 = 35, labelWeekly36 = 36, labelWeekly37 = 37, labelWeekly38 = 38, labelWeekly39 = 39, labelDaily40 = 40,
            labelWeekly41 = 41, labelWeekly42 = 42, labelWeekly43 = 43, labelWeekly44 = 44, labelWeekly45 = 45, labelWeekly46 = 46, labelWeekly47 = 47, labelWeekly48 = 48, labelWeekly49 = 49, labelDaily50 = 50,
            labelWeekly51 = 51, labelWeekly51 = 52;

            var dataWeekly1 = 0, dataWeekly2 = 0, dataWeekly3 = 0, dataWeekly4 = 0, dataWeekly5 = 0, dataWeekly6 = 0, dataWeekly7 = 0, dataWeekly8 = 0, dataWeekly9 = 0, dataWeekly10 = 0,
            dataWeekly11 = 0, dataWeekly12 = 0, dataWeekly13 = 0, dataWeekly14 = 0, dataWeekly15 = 0, dataWeekly16 = 0, dataWeekly17 = 0, dataWeekly18 = 0, dataWeekly19 = 0, dataWeekly20 = 0,
            dataWeekly21 = 0, dataWeekly22 = 0, dataWeekly23 = 0, dataWeekly24 = 0, dataWeekly25 = 0, dataWeekly26 = 0, dataWeekly27 = 0, dataWeekly28 = 0, dataWeekly29 = 0, dataWeekly30 = 0,
            dataWeekly31 = 0, dataWeekly32 = 0, dataWeekly33 = 0, dataWeekly34 = 0, dataWeekly35 = 0, dataWeekly36 = 0, dataWeekly37 = 0, dataWeekly38 = 0, dataWeekly39 = 0, dataWeekly40 = 0,
            dataWeekly41 = 0, dataWeekly42 = 0, dataWeekly43 = 0, dataWeekly44 = 0, dataWeekly45 = 0, dataWeekly46 = 0, dataWeekly47 = 0, dataWeekly48 = 0, dataWeekly49 = 0, dataWeekly50 = 0,
            dataWeekly51 = 0, dataWeekly52 = 0;

            if(this.dataLengthWeekly == 52){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
                dataWeekly50 = this.dataWeekly[49].studentCount;
                dataWeekly51 = this.dataWeekly[50].studentCount;
                dataWeekly52 = this.dataWeekly[51].studentCount;
            }
            if(this.dataLengthWeekly == 51){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
                dataWeekly50 = this.dataWeekly[49].studentCount;
                dataWeekly51 = this.dataWeekly[50].studentCount;
            }
            if(this.dataLengthWeekly == 50){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
                dataWeekly50 = this.dataWeekly[49].studentCount;
            }
            if(this.dataLengthWeekly == 49){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
            }
            if(this.dataLengthWeekly == 48){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
            }
            if(this.dataLengthWeekly == 47){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
            }
            if(this.dataLengthWeekly == 46){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
            }
            if(this.dataLengthWeekly == 45){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
            }
            if(this.dataLengthWeekly == 44){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
            }
            if(this.dataLengthWeekly == 43){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
            }
            if(this.dataLengthWeekly == 42){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
            }
            if(this.dataLengthWeekly == 41){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
            }
            if(this.dataLengthWeekly == 40){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
            }
            if(this.dataLengthWeekly == 39){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
            }
            if(this.dataLengthWeekly == 38){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
            }
            if(this.dataLengthWeekly == 37){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
            }
            if(this.dataLengthWeekly == 36){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
            }
            if(this.dataLengthWeekly == 35){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
            }
            if(this.dataLengthWeekly == 34){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
            }
            if(this.dataLengthWeekly == 33){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
            }
            if(this.dataLengthWeekly == 32){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
            }
            if(this.dataLengthWeekly == 31){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
            }
            if(this.dataLengthWeekly == 30){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
            }
            if(this.dataLengthWeekly == 29){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
            }
            if(this.dataLengthWeekly == 28){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
            }
            if(this.dataLengthWeekly == 27){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
            }
            if(this.dataLengthWeekly == 26){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
            }
            if(this.dataLengthWeekly == 25){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
            }
            if(this.dataLengthWeekly == 24){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
            }
            if(this.dataLengthWeekly == 23){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
            }
            if(this.dataLengthWeekly == 22){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
            }
            if(this.dataLengthWeekly == 21){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
            }
            if(this.dataLengthWeekly == 20){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
            }
            if(this.dataLengthWeekly == 19){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
            }
            if(this.dataLengthWeekly == 18){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
            }
            if(this.dataLengthWeekly == 17){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
            }
            if(this.dataLengthWeekly == 16){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
            }
            if(this.dataLengthWeekly == 15){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
            }
            if(this.dataLengthWeekly == 14){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
            }
            if(this.dataLengthWeekly == 13){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
            }
            if(this.dataLengthWeekly == 12){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
            }
            if(this.dataLengthWeekly == 11){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
            }
            if(this.dataLengthWeekly == 10){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
            }
            if(this.dataLengthWeekly == 9){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
            }
            if(this.dataLengthWeekly == 8){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
            }
            if(this.dataLengthWeekly == 7){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
            }
            if(this.dataLengthWeekly == 6){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
            }
            if(this.dataLengthWeekly == 5){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
            }
            if(this.dataLengthWeekly == 4){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
            }
            if(this.dataLengthWeekly == 3){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
            }
            if(this.dataLengthWeekly == 2){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
            }
            if(this.dataLengthWeekly == 1){
                dataWeekly1 = this.dataWeekly[0].studentCount;
            }

            var dataWeeklyCompleted1 = 0, dataWeeklyCompleted2 = 0, dataWeeklyCompleted3 = 0, dataWeeklyCompleted4 = 0, dataWeeklyCompleted5 = 0, dataWeeklyCompleted6 = 0, dataWeeklyCompleted7 = 0, dataWeeklyCompleted8 = 0, dataWeeklyCompleted9 = 0, dataWeeklyCompleted10 = 0,
            dataWeeklyCompleted11 = 0, dataWeeklyCompleted12 = 0, dataWeeklyCompleted13 = 0, dataWeeklyCompleted14 = 0, dataWeeklyCompleted15 = 0, dataWeeklyCompleted16 = 0, dataWeeklyCompleted17 = 0, dataWeeklyCompleted18 = 0, dataWeeklyCompleted19 = 0, dataWeeklyCompleted20 = 0,
            dataWeeklyCompleted21 = 0, dataWeeklyCompleted22 = 0, dataWeeklyCompleted23 = 0, dataWeeklyCompleted24 = 0, dataWeeklyCompleted25 = 0, dataWeeklyCompleted26 = 0, dataWeeklyCompleted27 = 0, dataWeeklyCompleted28 = 0, dataWeeklyCompleted29 = 0, dataWeeklyCompleted30 = 0,
            dataWeeklyCompleted31 = 0, dataWeeklyCompleted32 = 0, dataWeeklyCompleted33 = 0, dataWeeklyCompleted34 = 0, dataWeeklyCompleted35 = 0, dataWeeklyCompleted36 = 0, dataWeeklyCompleted37 = 0, dataWeeklyCompleted38 = 0, dataWeeklyCompleted39 = 0, dataWeeklyCompleted40 = 0,
            dataWeeklyCompleted41 = 0, dataWeeklyCompleted42 = 0, dataWeeklyCompleted43 = 0, dataWeeklyCompleted44 = 0, dataWeeklyCompleted45 = 0, dataWeeklyCompleted46 = 0, dataWeeklyCompleted47 = 0, dataWeeklyCompleted48 = 0, dataWeeklyCompleted49 = 0, dataWeeklyCompleted50 = 0,
            dataWeeklyCompleted51 = 0, dataWeeklyCompleted52 = 0;

            if(this.dataLengthWeeklyCompleted == 52){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
                dataWeeklyCompleted50 = this.dataWeeklyCompleted[49].studentCount;
                dataWeeklyCompleted51 = this.dataWeeklyCompleted[50].studentCount;
                dataWeeklyCompleted52 = this.dataWeeklyCompleted[51].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 51){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
                dataWeeklyCompleted50 = this.dataWeeklyCompleted[49].studentCount;
                dataWeeklyCompleted51 = this.dataWeeklyCompleted[50].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 50){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
                dataWeeklyCompleted50 = this.dataWeeklyCompleted[49].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 49){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 48){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 47){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 46){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 45){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 44){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 43){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 42){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 41){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 40){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 39){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 38){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 37){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 36){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 35){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 34){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 33){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 32){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 31){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 30){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 29){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 28){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 27){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 26){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 25){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 24){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 23){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 22){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 21){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 20){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 19){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 18){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 17){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 16){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 15){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 14){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 13){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 12){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 11){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 10){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 9){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 8){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 7){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 6){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 5){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 4){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 3){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 2){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 1){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
            }  

        this.barData4 = {
                labels: [labelWeekly1, labelWeekly2, labelWeekly3, labelWeekly4, labelWeekly5, labelWeekly6, labelWeekly7, labelWeekly8, labelWeekly9, labelDaily10,
                    labelWeekly11, labelWeekly12, labelWeekly13, labelWeekly14, labelWeekly15, labelWeekly16, labelWeekly17, labelWeekly18, labelWeekly19, labelWeekly20,
                    labelWeekly21, labelWeekly22, labelWeekly23, labelWeekly24, labelWeekly25, labelWeekly26, labelWeekly27, labelWeekly28, labelWeekly29, labelWeekly30,  
                    labelWeekly31, labelWeekly32, labelWeekly33, labelWeekly34, labelWeekly35, labelWeekly36, labelWeekly37, labelWeekly38, labelWeekly39, labelDaily40,
                    labelWeekly41, labelWeekly42, labelWeekly43, labelWeekly44, labelWeekly45, labelWeekly46, labelWeekly47, labelWeekly48, labelWeekly49, labelDaily50,
                    labelWeekly51, labelWeekly51],
                datasets: [
                    {
                        label: 'Approved Students',
                        fill: false,
                        backgroundColor: "rgb(57, 255, 20)",                          
                        borderColor: "rgb(57, 255, 20)", 
                        data: [dataWeekly1, dataWeekly2, dataWeekly3, dataWeekly4, dataWeekly5, dataWeekly6, dataWeekly7, dataWeekly8, dataWeekly9, dataWeekly10,
                            dataWeekly11, dataWeekly12, dataWeekly13, dataWeekly14, dataWeekly15, dataWeekly16, dataWeekly17, dataWeekly18, dataWeekly19, dataWeekly20,
                            dataWeekly21, dataWeekly22, dataWeekly23, dataWeekly24, dataWeekly25, dataWeekly26, dataWeekly27, dataWeekly28, dataWeekly29, dataWeekly30,
                            dataWeekly31, dataWeekly32, dataWeekly33, dataWeekly34, dataWeekly35, dataWeekly36, dataWeekly37, dataWeekly38, dataWeekly39, dataWeekly40,
                            dataWeekly41, dataWeekly42, dataWeekly43, dataWeekly44, dataWeekly45, dataWeekly46, dataWeekly47, dataWeekly48, dataWeekly49, dataWeekly50,
                            dataWeekly51, dataWeekly52]
                    },
                    {
                        label: 'Completed',
                        fill: false,
                        backgroundColor: "rgb(106, 13, 173)",
                        borderColor: "rgb(106, 13, 173)",
                        data: [dataWeeklyCompleted1, dataWeeklyCompleted2, dataWeeklyCompleted3, dataWeeklyCompleted4, dataWeeklyCompleted5, dataWeeklyCompleted6, dataWeeklyCompleted7, dataWeeklyCompleted8, dataWeeklyCompleted9, dataWeeklyCompleted10,
                            dataWeeklyCompleted11, dataWeeklyCompleted12, dataWeeklyCompleted13, dataWeeklyCompleted14, dataWeeklyCompleted15, dataWeeklyCompleted16, dataWeeklyCompleted17, dataWeeklyCompleted18, dataWeeklyCompleted19, dataWeeklyCompleted20,
                            dataWeeklyCompleted21, dataWeeklyCompleted22, dataWeeklyCompleted23, dataWeeklyCompleted24, dataWeeklyCompleted25, dataWeeklyCompleted26, dataWeeklyCompleted27, dataWeeklyCompleted28, dataWeeklyCompleted29, dataWeeklyCompleted30,
                            dataWeeklyCompleted31, dataWeeklyCompleted32, dataWeeklyCompleted33, dataWeeklyCompleted34, dataWeeklyCompleted35, dataWeeklyCompleted36, dataWeeklyCompleted37, dataWeeklyCompleted38, dataWeeklyCompleted39, dataWeeklyCompleted40,
                            dataWeeklyCompleted41, dataWeeklyCompleted42, dataWeeklyCompleted43, dataWeeklyCompleted44, dataWeeklyCompleted45, dataWeeklyCompleted46, dataWeeklyCompleted47, dataWeeklyCompleted48, dataWeeklyCompleted49, dataWeeklyCompleted50,
                            dataWeeklyCompleted51, dataWeeklyCompleted52]
                    }                   
                ]
            };
        });
    }

    loadDashboardWeeklyByBranch(branchCode){
        this.currentDate = new Date();
        this.nYear =  moment(new Date(this.currentDate)).format("YYYY");

        this.cmsService.getDashboardDataWeeklyCompletedByBranch(this.nYear, branchCode).then((dataCompleted:any) => {
            if (dataCompleted.result.length > 0){
                this.dataWeeklyCompleted = dataCompleted.result;       
                this.dataLengthWeeklyCompleted = dataCompleted.result.length;        
            }
            else{
                this.dataWeeklyCompleted = {id:1,nYear:this.nYear,nMonth:1,nWeek:this.nWeek,nDay:1,studentCount:0};
                this.dataLengthWeeklyCompleted = 1;
                //console.log(this.dataMonthlyCompleted);
            }  
        });

        this.cmsService.getDashboardDataWeeklyByBranch(this.nYear, branchCode).then((response:any) => {
            this.dataWeekly = response.result;
            this.dataLengthWeekly = response.result.length;  

            //console.log(this.dataWeekly);
            var labelWeekly1 = 1, labelWeekly2 = 2, labelWeekly3 = 3, labelWeekly4 = 4, labelWeekly5 = 5, labelWeekly6 = 6, labelWeekly7 = 7, labelWeekly8 = 8, labelWeekly9 = 9, labelDaily10 = 10,
            labelWeekly11 = 11, labelWeekly12 = 12, labelWeekly13 = 13, labelWeekly14 = 14, labelWeekly15 = 15, labelWeekly16 = 16, labelWeekly17 = 17, labelWeekly18 = 18, labelWeekly19 = 19, labelWeekly20 = 20,
            labelWeekly21 = 21, labelWeekly22 = 22, labelWeekly23 = 23, labelWeekly24 = 24, labelWeekly25 = 25, labelWeekly26 = 26, labelWeekly27 = 27, labelWeekly28 = 28, labelWeekly29 = 29, labelWeekly30 = 30,  
            labelWeekly31 = 31, labelWeekly32 = 32, labelWeekly33 = 33, labelWeekly34 = 34, labelWeekly35 = 35, labelWeekly36 = 36, labelWeekly37 = 37, labelWeekly38 = 38, labelWeekly39 = 39, labelDaily40 = 40,
            labelWeekly41 = 41, labelWeekly42 = 42, labelWeekly43 = 43, labelWeekly44 = 44, labelWeekly45 = 45, labelWeekly46 = 46, labelWeekly47 = 47, labelWeekly48 = 48, labelWeekly49 = 49, labelDaily50 = 50,
            labelWeekly51 = 51, labelWeekly51 = 52;

            var dataWeekly1 = 0, dataWeekly2 = 0, dataWeekly3 = 0, dataWeekly4 = 0, dataWeekly5 = 0, dataWeekly6 = 0, dataWeekly7 = 0, dataWeekly8 = 0, dataWeekly9 = 0, dataWeekly10 = 0,
            dataWeekly11 = 0, dataWeekly12 = 0, dataWeekly13 = 0, dataWeekly14 = 0, dataWeekly15 = 0, dataWeekly16 = 0, dataWeekly17 = 0, dataWeekly18 = 0, dataWeekly19 = 0, dataWeekly20 = 0,
            dataWeekly21 = 0, dataWeekly22 = 0, dataWeekly23 = 0, dataWeekly24 = 0, dataWeekly25 = 0, dataWeekly26 = 0, dataWeekly27 = 0, dataWeekly28 = 0, dataWeekly29 = 0, dataWeekly30 = 0,
            dataWeekly31 = 0, dataWeekly32 = 0, dataWeekly33 = 0, dataWeekly34 = 0, dataWeekly35 = 0, dataWeekly36 = 0, dataWeekly37 = 0, dataWeekly38 = 0, dataWeekly39 = 0, dataWeekly40 = 0,
            dataWeekly41 = 0, dataWeekly42 = 0, dataWeekly43 = 0, dataWeekly44 = 0, dataWeekly45 = 0, dataWeekly46 = 0, dataWeekly47 = 0, dataWeekly48 = 0, dataWeekly49 = 0, dataWeekly50 = 0,
            dataWeekly51 = 0, dataWeekly52 = 0;

            if(this.dataLengthWeekly == 52){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
                dataWeekly50 = this.dataWeekly[49].studentCount;
                dataWeekly51 = this.dataWeekly[50].studentCount;
                dataWeekly52 = this.dataWeekly[51].studentCount;
            }
            if(this.dataLengthWeekly == 51){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
                dataWeekly50 = this.dataWeekly[49].studentCount;
                dataWeekly51 = this.dataWeekly[50].studentCount;
            }
            if(this.dataLengthWeekly == 50){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
                dataWeekly50 = this.dataWeekly[49].studentCount;
            }
            if(this.dataLengthWeekly == 49){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
            }
            if(this.dataLengthWeekly == 48){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
            }
            if(this.dataLengthWeekly == 47){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
            }
            if(this.dataLengthWeekly == 46){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
            }
            if(this.dataLengthWeekly == 45){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
            }
            if(this.dataLengthWeekly == 44){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
            }
            if(this.dataLengthWeekly == 43){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
            }
            if(this.dataLengthWeekly == 42){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
            }
            if(this.dataLengthWeekly == 41){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
            }
            if(this.dataLengthWeekly == 40){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
            }
            if(this.dataLengthWeekly == 39){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
            }
            if(this.dataLengthWeekly == 38){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
            }
            if(this.dataLengthWeekly == 37){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
            }
            if(this.dataLengthWeekly == 36){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
            }
            if(this.dataLengthWeekly == 35){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
            }
            if(this.dataLengthWeekly == 34){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
            }
            if(this.dataLengthWeekly == 33){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
            }
            if(this.dataLengthWeekly == 32){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
            }
            if(this.dataLengthWeekly == 31){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
            }
            if(this.dataLengthWeekly == 30){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
            }
            if(this.dataLengthWeekly == 29){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
            }
            if(this.dataLengthWeekly == 28){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
            }
            if(this.dataLengthWeekly == 27){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
            }
            if(this.dataLengthWeekly == 26){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
            }
            if(this.dataLengthWeekly == 25){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
            }
            if(this.dataLengthWeekly == 24){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
            }
            if(this.dataLengthWeekly == 23){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
            }
            if(this.dataLengthWeekly == 22){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
            }
            if(this.dataLengthWeekly == 21){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
            }
            if(this.dataLengthWeekly == 20){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
            }
            if(this.dataLengthWeekly == 19){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
            }
            if(this.dataLengthWeekly == 18){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
            }
            if(this.dataLengthWeekly == 17){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
            }
            if(this.dataLengthWeekly == 16){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
            }
            if(this.dataLengthWeekly == 15){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
            }
            if(this.dataLengthWeekly == 14){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
            }
            if(this.dataLengthWeekly == 13){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
            }
            if(this.dataLengthWeekly == 12){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
            }
            if(this.dataLengthWeekly == 11){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
            }
            if(this.dataLengthWeekly == 10){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
            }
            if(this.dataLengthWeekly == 9){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
            }
            if(this.dataLengthWeekly == 8){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
            }
            if(this.dataLengthWeekly == 7){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
            }
            if(this.dataLengthWeekly == 6){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
            }
            if(this.dataLengthWeekly == 5){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
            }
            if(this.dataLengthWeekly == 4){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
            }
            if(this.dataLengthWeekly == 3){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
            }
            if(this.dataLengthWeekly == 2){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
            }
            if(this.dataLengthWeekly == 1){
                dataWeekly1 = this.dataWeekly[0].studentCount;
            }

            var dataWeeklyCompleted1 = 0, dataWeeklyCompleted2 = 0, dataWeeklyCompleted3 = 0, dataWeeklyCompleted4 = 0, dataWeeklyCompleted5 = 0, dataWeeklyCompleted6 = 0, dataWeeklyCompleted7 = 0, dataWeeklyCompleted8 = 0, dataWeeklyCompleted9 = 0, dataWeeklyCompleted10 = 0,
            dataWeeklyCompleted11 = 0, dataWeeklyCompleted12 = 0, dataWeeklyCompleted13 = 0, dataWeeklyCompleted14 = 0, dataWeeklyCompleted15 = 0, dataWeeklyCompleted16 = 0, dataWeeklyCompleted17 = 0, dataWeeklyCompleted18 = 0, dataWeeklyCompleted19 = 0, dataWeeklyCompleted20 = 0,
            dataWeeklyCompleted21 = 0, dataWeeklyCompleted22 = 0, dataWeeklyCompleted23 = 0, dataWeeklyCompleted24 = 0, dataWeeklyCompleted25 = 0, dataWeeklyCompleted26 = 0, dataWeeklyCompleted27 = 0, dataWeeklyCompleted28 = 0, dataWeeklyCompleted29 = 0, dataWeeklyCompleted30 = 0,
            dataWeeklyCompleted31 = 0, dataWeeklyCompleted32 = 0, dataWeeklyCompleted33 = 0, dataWeeklyCompleted34 = 0, dataWeeklyCompleted35 = 0, dataWeeklyCompleted36 = 0, dataWeeklyCompleted37 = 0, dataWeeklyCompleted38 = 0, dataWeeklyCompleted39 = 0, dataWeeklyCompleted40 = 0,
            dataWeeklyCompleted41 = 0, dataWeeklyCompleted42 = 0, dataWeeklyCompleted43 = 0, dataWeeklyCompleted44 = 0, dataWeeklyCompleted45 = 0, dataWeeklyCompleted46 = 0, dataWeeklyCompleted47 = 0, dataWeeklyCompleted48 = 0, dataWeeklyCompleted49 = 0, dataWeeklyCompleted50 = 0,
            dataWeeklyCompleted51 = 0, dataWeeklyCompleted52 = 0;

            if(this.dataLengthWeeklyCompleted == 52){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
                dataWeeklyCompleted50 = this.dataWeeklyCompleted[49].studentCount;
                dataWeeklyCompleted51 = this.dataWeeklyCompleted[50].studentCount;
                dataWeeklyCompleted52 = this.dataWeeklyCompleted[51].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 51){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
                dataWeeklyCompleted50 = this.dataWeeklyCompleted[49].studentCount;
                dataWeeklyCompleted51 = this.dataWeeklyCompleted[50].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 50){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
                dataWeeklyCompleted50 = this.dataWeeklyCompleted[49].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 49){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 48){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 47){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 46){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 45){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 44){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 43){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 42){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 41){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 40){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 39){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 38){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 37){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 36){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 35){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 34){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 33){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 32){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 31){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 30){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 29){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 28){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 27){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 26){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 25){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 24){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 23){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 22){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 21){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 20){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 19){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 18){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 17){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 16){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 15){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 14){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 13){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 12){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 11){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 10){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 9){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 8){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 7){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 6){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 5){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 4){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 3){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 2){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 1){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
            }  

            this.barData4 = {
                labels: [labelWeekly1, labelWeekly2, labelWeekly3, labelWeekly4, labelWeekly5, labelWeekly6, labelWeekly7, labelWeekly8, labelWeekly9, labelDaily10,
                    labelWeekly11, labelWeekly12, labelWeekly13, labelWeekly14, labelWeekly15, labelWeekly16, labelWeekly17, labelWeekly18, labelWeekly19, labelWeekly20,
                    labelWeekly21, labelWeekly22, labelWeekly23, labelWeekly24, labelWeekly25, labelWeekly26, labelWeekly27, labelWeekly28, labelWeekly29, labelWeekly30,  
                    labelWeekly31, labelWeekly32, labelWeekly33, labelWeekly34, labelWeekly35, labelWeekly36, labelWeekly37, labelWeekly38, labelWeekly39, labelDaily40,
                    labelWeekly41, labelWeekly42, labelWeekly43, labelWeekly44, labelWeekly45, labelWeekly46, labelWeekly47, labelWeekly48, labelWeekly49, labelDaily50,
                    labelWeekly51, labelWeekly51],
                datasets: [
                    {
                        label: 'Approved Students',
                        fill: false,
                        backgroundColor: "rgb(57, 255, 20)",                          
                        borderColor: "rgb(57, 255, 20)", 
                        data: [dataWeekly1, dataWeekly2, dataWeekly3, dataWeekly4, dataWeekly5, dataWeekly6, dataWeekly7, dataWeekly8, dataWeekly9, dataWeekly10,
                            dataWeekly11, dataWeekly12, dataWeekly13, dataWeekly14, dataWeekly15, dataWeekly16, dataWeekly17, dataWeekly18, dataWeekly19, dataWeekly20,
                            dataWeekly21, dataWeekly22, dataWeekly23, dataWeekly24, dataWeekly25, dataWeekly26, dataWeekly27, dataWeekly28, dataWeekly29, dataWeekly30,
                            dataWeekly31, dataWeekly32, dataWeekly33, dataWeekly34, dataWeekly35, dataWeekly36, dataWeekly37, dataWeekly38, dataWeekly39, dataWeekly40,
                            dataWeekly41, dataWeekly42, dataWeekly43, dataWeekly44, dataWeekly45, dataWeekly46, dataWeekly47, dataWeekly48, dataWeekly49, dataWeekly50,
                            dataWeekly51, dataWeekly52]
                    },
                    {
                        label: 'Completed',
                        fill: false,
                        backgroundColor: "rgb(106, 13, 173)",
                        borderColor: "rgb(106, 13, 173)",
                        data: [dataWeeklyCompleted1, dataWeeklyCompleted2, dataWeeklyCompleted3, dataWeeklyCompleted4, dataWeeklyCompleted5, dataWeeklyCompleted6, dataWeeklyCompleted7, dataWeeklyCompleted8, dataWeeklyCompleted9, dataWeeklyCompleted10,
                            dataWeeklyCompleted11, dataWeeklyCompleted12, dataWeeklyCompleted13, dataWeeklyCompleted14, dataWeeklyCompleted15, dataWeeklyCompleted16, dataWeeklyCompleted17, dataWeeklyCompleted18, dataWeeklyCompleted19, dataWeeklyCompleted20,
                            dataWeeklyCompleted21, dataWeeklyCompleted22, dataWeeklyCompleted23, dataWeeklyCompleted24, dataWeeklyCompleted25, dataWeeklyCompleted26, dataWeeklyCompleted27, dataWeeklyCompleted28, dataWeeklyCompleted29, dataWeeklyCompleted30,
                            dataWeeklyCompleted31, dataWeeklyCompleted32, dataWeeklyCompleted33, dataWeeklyCompleted34, dataWeeklyCompleted35, dataWeeklyCompleted36, dataWeeklyCompleted37, dataWeeklyCompleted38, dataWeeklyCompleted39, dataWeeklyCompleted40,
                            dataWeeklyCompleted41, dataWeeklyCompleted42, dataWeeklyCompleted43, dataWeeklyCompleted44, dataWeeklyCompleted45, dataWeeklyCompleted46, dataWeeklyCompleted47, dataWeeklyCompleted48, dataWeeklyCompleted49, dataWeeklyCompleted50,
                            dataWeeklyCompleted51, dataWeeklyCompleted52]
                    }                   
                ]
            };
        });
    }

    loadDashboardWeeklyByRegion(region){
        this.currentDate = new Date();
        this.nYear =  moment(new Date(this.currentDate)).format("YYYY");

        this.cmsService.getDashboardDataWeeklyCompletedByRegion(this.nYear, region).then((dataCompleted:any) => {
            if (dataCompleted.result.length > 0){
                this.dataWeeklyCompleted = dataCompleted.result;     
                this.dataLengthWeeklyCompleted = dataCompleted.result.length;           
            }
            else{
                this.dataWeeklyCompleted = {id:1,nYear:this.nYear,nMonth:1,nWeek:this.nWeek,nDay:1,studentCount:0};
                this.dataLengthWeeklyCompleted = 1;
                //console.log(this.dataMonthlyCompleted);
            }  
        });

        this.cmsService.getDashboardDataWeeklyByRegion(this.nYear, region).then((response:any) => {
            //console.log("Region - Weekly")
            this.dataWeekly = response.result;
            this.dataLengthWeekly = response.result.length;
            
            var labelWeekly1 = 1, labelWeekly2 = 2, labelWeekly3 = 3, labelWeekly4 = 4, labelWeekly5 = 5, labelWeekly6 = 6, labelWeekly7 = 7, labelWeekly8 = 8, labelWeekly9 = 9, labelDaily10 = 10,
            labelWeekly11 = 11, labelWeekly12 = 12, labelWeekly13 = 13, labelWeekly14 = 14, labelWeekly15 = 15, labelWeekly16 = 16, labelWeekly17 = 17, labelWeekly18 = 18, labelWeekly19 = 19, labelWeekly20 = 20,
            labelWeekly21 = 21, labelWeekly22 = 22, labelWeekly23 = 23, labelWeekly24 = 24, labelWeekly25 = 25, labelWeekly26 = 26, labelWeekly27 = 27, labelWeekly28 = 28, labelWeekly29 = 29, labelWeekly30 = 30,  
            labelWeekly31 = 31, labelWeekly32 = 32, labelWeekly33 = 33, labelWeekly34 = 34, labelWeekly35 = 35, labelWeekly36 = 36, labelWeekly37 = 37, labelWeekly38 = 38, labelWeekly39 = 39, labelDaily40 = 40,
            labelWeekly41 = 41, labelWeekly42 = 42, labelWeekly43 = 43, labelWeekly44 = 44, labelWeekly45 = 45, labelWeekly46 = 46, labelWeekly47 = 47, labelWeekly48 = 48, labelWeekly49 = 49, labelDaily50 = 50,
            labelWeekly51 = 51, labelWeekly51 = 52;

            var dataWeekly1 = 0, dataWeekly2 = 0, dataWeekly3 = 0, dataWeekly4 = 0, dataWeekly5 = 0, dataWeekly6 = 0, dataWeekly7 = 0, dataWeekly8 = 0, dataWeekly9 = 0, dataWeekly10 = 0,
            dataWeekly11 = 0, dataWeekly12 = 0, dataWeekly13 = 0, dataWeekly14 = 0, dataWeekly15 = 0, dataWeekly16 = 0, dataWeekly17 = 0, dataWeekly18 = 0, dataWeekly19 = 0, dataWeekly20 = 0,
            dataWeekly21 = 0, dataWeekly22 = 0, dataWeekly23 = 0, dataWeekly24 = 0, dataWeekly25 = 0, dataWeekly26 = 0, dataWeekly27 = 0, dataWeekly28 = 0, dataWeekly29 = 0, dataWeekly30 = 0,
            dataWeekly31 = 0, dataWeekly32 = 0, dataWeekly33 = 0, dataWeekly34 = 0, dataWeekly35 = 0, dataWeekly36 = 0, dataWeekly37 = 0, dataWeekly38 = 0, dataWeekly39 = 0, dataWeekly40 = 0,
            dataWeekly41 = 0, dataWeekly42 = 0, dataWeekly43 = 0, dataWeekly44 = 0, dataWeekly45 = 0, dataWeekly46 = 0, dataWeekly47 = 0, dataWeekly48 = 0, dataWeekly49 = 0, dataWeekly50 = 0,
            dataWeekly51 = 0, dataWeekly52 = 0;

            if(this.dataLengthWeekly == 52){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
                dataWeekly50 = this.dataWeekly[49].studentCount;
                dataWeekly51 = this.dataWeekly[50].studentCount;
                dataWeekly52 = this.dataWeekly[51].studentCount;
            }
            if(this.dataLengthWeekly == 51){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
                dataWeekly50 = this.dataWeekly[49].studentCount;
                dataWeekly51 = this.dataWeekly[50].studentCount;
            }
            if(this.dataLengthWeekly == 50){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
                dataWeekly50 = this.dataWeekly[49].studentCount;
            }
            if(this.dataLengthWeekly == 49){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
                dataWeekly49 = this.dataWeekly[48].studentCount;
            }
            if(this.dataLengthWeekly == 48){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
                dataWeekly48 = this.dataWeekly[47].studentCount;
            }
            if(this.dataLengthWeekly == 47){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
                dataWeekly47 = this.dataWeekly[46].studentCount;
            }
            if(this.dataLengthWeekly == 46){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
                dataWeekly46 = this.dataWeekly[45].studentCount;
            }
            if(this.dataLengthWeekly == 45){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
                dataWeekly45 = this.dataWeekly[44].studentCount;
            }
            if(this.dataLengthWeekly == 44){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
                dataWeekly44 = this.dataWeekly[43].studentCount;
            }
            if(this.dataLengthWeekly == 43){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
                dataWeekly43 = this.dataWeekly[42].studentCount;
            }
            if(this.dataLengthWeekly == 42){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
                dataWeekly42 = this.dataWeekly[41].studentCount;
            }
            if(this.dataLengthWeekly == 41){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
                dataWeekly41 = this.dataWeekly[40].studentCount;
            }
            if(this.dataLengthWeekly == 40){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
                dataWeekly40 = this.dataWeekly[39].studentCount;
            }
            if(this.dataLengthWeekly == 39){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
                dataWeekly39 = this.dataWeekly[38].studentCount;
            }
            if(this.dataLengthWeekly == 38){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
                dataWeekly38 = this.dataWeekly[37].studentCount;
            }
            if(this.dataLengthWeekly == 37){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
                dataWeekly37 = this.dataWeekly[36].studentCount;
            }
            if(this.dataLengthWeekly == 36){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
                dataWeekly36 = this.dataWeekly[35].studentCount;
            }
            if(this.dataLengthWeekly == 35){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
                dataWeekly35 = this.dataWeekly[34].studentCount;
            }
            if(this.dataLengthWeekly == 34){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
                dataWeekly34 = this.dataWeekly[33].studentCount;
            }
            if(this.dataLengthWeekly == 33){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
                dataWeekly33 = this.dataWeekly[32].studentCount;
            }
            if(this.dataLengthWeekly == 32){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
                dataWeekly32 = this.dataWeekly[31].studentCount;
            }
            if(this.dataLengthWeekly == 31){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
                dataWeekly31 = this.dataWeekly[30].studentCount;
            }
            if(this.dataLengthWeekly == 30){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
                dataWeekly30 = this.dataWeekly[29].studentCount;
            }
            if(this.dataLengthWeekly == 29){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
                dataWeekly29 = this.dataWeekly[28].studentCount;
            }
            if(this.dataLengthWeekly == 28){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
                dataWeekly28 = this.dataWeekly[27].studentCount;
            }
            if(this.dataLengthWeekly == 27){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
                dataWeekly27 = this.dataWeekly[26].studentCount;
            }
            if(this.dataLengthWeekly == 26){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
                dataWeekly26 = this.dataWeekly[25].studentCount;
            }
            if(this.dataLengthWeekly == 25){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
                dataWeekly25 = this.dataWeekly[24].studentCount;
            }
            if(this.dataLengthWeekly == 24){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
                dataWeekly24 = this.dataWeekly[23].studentCount;
            }
            if(this.dataLengthWeekly == 23){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
                dataWeekly23 = this.dataWeekly[22].studentCount;
            }
            if(this.dataLengthWeekly == 22){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
                dataWeekly22 = this.dataWeekly[21].studentCount;
            }
            if(this.dataLengthWeekly == 21){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
                dataWeekly21 = this.dataWeekly[20].studentCount;
            }
            if(this.dataLengthWeekly == 20){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
                dataWeekly20 = this.dataWeekly[19].studentCount;
            }
            if(this.dataLengthWeekly == 19){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
                dataWeekly19 = this.dataWeekly[18].studentCount;
            }
            if(this.dataLengthWeekly == 18){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
                dataWeekly18 = this.dataWeekly[17].studentCount;
            }
            if(this.dataLengthWeekly == 17){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
                dataWeekly17 = this.dataWeekly[16].studentCount;
            }
            if(this.dataLengthWeekly == 16){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
                dataWeekly16 = this.dataWeekly[15].studentCount;
            }
            if(this.dataLengthWeekly == 15){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
                dataWeekly15 = this.dataWeekly[14].studentCount;
            }
            if(this.dataLengthWeekly == 14){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
                dataWeekly14 = this.dataWeekly[13].studentCount;
            }
            if(this.dataLengthWeekly == 13){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
                dataWeekly13 = this.dataWeekly[12].studentCount;
            }
            if(this.dataLengthWeekly == 12){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
                dataWeekly12 = this.dataWeekly[11].studentCount;
            }
            if(this.dataLengthWeekly == 11){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
                dataWeekly11 = this.dataWeekly[10].studentCount;
            }
            if(this.dataLengthWeekly == 10){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
                dataWeekly10 = this.dataWeekly[9].studentCount;
            }
            if(this.dataLengthWeekly == 9){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
                dataWeekly9 = this.dataWeekly[8].studentCount;
            }
            if(this.dataLengthWeekly == 8){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
                dataWeekly8 = this.dataWeekly[7].studentCount;
            }
            if(this.dataLengthWeekly == 7){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
                dataWeekly7 = this.dataWeekly[6].studentCount;
            }
            if(this.dataLengthWeekly == 6){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
                dataWeekly6 = this.dataWeekly[5].studentCount;
            }
            if(this.dataLengthWeekly == 5){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
                dataWeekly5 = this.dataWeekly[4].studentCount;
            }
            if(this.dataLengthWeekly == 4){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
                dataWeekly4 = this.dataWeekly[3].studentCount;
            }
            if(this.dataLengthWeekly == 3){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
                dataWeekly3 = this.dataWeekly[2].studentCount;
            }
            if(this.dataLengthWeekly == 2){
                dataWeekly1 = this.dataWeekly[0].studentCount;
                dataWeekly2 = this.dataWeekly[1].studentCount;
            }
            if(this.dataLengthWeekly == 1){
                dataWeekly1 = this.dataWeekly[0].studentCount;
            }

            var dataWeeklyCompleted1 = 0, dataWeeklyCompleted2 = 0, dataWeeklyCompleted3 = 0, dataWeeklyCompleted4 = 0, dataWeeklyCompleted5 = 0, dataWeeklyCompleted6 = 0, dataWeeklyCompleted7 = 0, dataWeeklyCompleted8 = 0, dataWeeklyCompleted9 = 0, dataWeeklyCompleted10 = 0,
            dataWeeklyCompleted11 = 0, dataWeeklyCompleted12 = 0, dataWeeklyCompleted13 = 0, dataWeeklyCompleted14 = 0, dataWeeklyCompleted15 = 0, dataWeeklyCompleted16 = 0, dataWeeklyCompleted17 = 0, dataWeeklyCompleted18 = 0, dataWeeklyCompleted19 = 0, dataWeeklyCompleted20 = 0,
            dataWeeklyCompleted21 = 0, dataWeeklyCompleted22 = 0, dataWeeklyCompleted23 = 0, dataWeeklyCompleted24 = 0, dataWeeklyCompleted25 = 0, dataWeeklyCompleted26 = 0, dataWeeklyCompleted27 = 0, dataWeeklyCompleted28 = 0, dataWeeklyCompleted29 = 0, dataWeeklyCompleted30 = 0,
            dataWeeklyCompleted31 = 0, dataWeeklyCompleted32 = 0, dataWeeklyCompleted33 = 0, dataWeeklyCompleted34 = 0, dataWeeklyCompleted35 = 0, dataWeeklyCompleted36 = 0, dataWeeklyCompleted37 = 0, dataWeeklyCompleted38 = 0, dataWeeklyCompleted39 = 0, dataWeeklyCompleted40 = 0,
            dataWeeklyCompleted41 = 0, dataWeeklyCompleted42 = 0, dataWeeklyCompleted43 = 0, dataWeeklyCompleted44 = 0, dataWeeklyCompleted45 = 0, dataWeeklyCompleted46 = 0, dataWeeklyCompleted47 = 0, dataWeeklyCompleted48 = 0, dataWeeklyCompleted49 = 0, dataWeeklyCompleted50 = 0,
            dataWeeklyCompleted51 = 0, dataWeeklyCompleted52 = 0;

            if(this.dataLengthWeeklyCompleted == 52){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
                dataWeeklyCompleted50 = this.dataWeeklyCompleted[49].studentCount;
                dataWeeklyCompleted51 = this.dataWeeklyCompleted[50].studentCount;
                dataWeeklyCompleted52 = this.dataWeeklyCompleted[51].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 51){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
                dataWeeklyCompleted50 = this.dataWeeklyCompleted[49].studentCount;
                dataWeeklyCompleted51 = this.dataWeeklyCompleted[50].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 50){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
                dataWeeklyCompleted50 = this.dataWeeklyCompleted[49].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 49){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
                dataWeeklyCompleted49 = this.dataWeeklyCompleted[48].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 48){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
                dataWeeklyCompleted48 = this.dataWeeklyCompleted[47].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 47){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
                dataWeeklyCompleted47 = this.dataWeeklyCompleted[46].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 46){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
                dataWeeklyCompleted46 = this.dataWeeklyCompleted[45].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 45){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
                dataWeeklyCompleted45 = this.dataWeeklyCompleted[44].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 44){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
                dataWeeklyCompleted44 = this.dataWeeklyCompleted[43].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 43){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
                dataWeeklyCompleted43 = this.dataWeeklyCompleted[42].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 42){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
                dataWeeklyCompleted42 = this.dataWeeklyCompleted[41].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 41){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
                dataWeeklyCompleted41 = this.dataWeeklyCompleted[40].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 40){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 39){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
                dataWeeklyCompleted40 = this.dataWeeklyCompleted[39].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 38){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
                dataWeeklyCompleted38 = this.dataWeeklyCompleted[37].studentCount;
                dataWeeklyCompleted39 = this.dataWeeklyCompleted[38].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 37){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
                dataWeeklyCompleted37 = this.dataWeeklyCompleted[36].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 36){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
                dataWeeklyCompleted36 = this.dataWeeklyCompleted[35].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 35){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
                dataWeeklyCompleted35 = this.dataWeeklyCompleted[34].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 34){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
                dataWeeklyCompleted34 = this.dataWeeklyCompleted[33].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 33){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
                dataWeeklyCompleted33 = this.dataWeeklyCompleted[32].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 32){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
                dataWeeklyCompleted32 = this.dataWeeklyCompleted[31].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 31){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
                dataWeeklyCompleted31 = this.dataWeeklyCompleted[30].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 30){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
                dataWeeklyCompleted30 = this.dataWeeklyCompleted[29].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 29){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
                dataWeeklyCompleted29 = this.dataWeeklyCompleted[28].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 28){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
                dataWeeklyCompleted28 = this.dataWeeklyCompleted[27].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 27){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
                dataWeeklyCompleted27 = this.dataWeeklyCompleted[26].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 26){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
                dataWeeklyCompleted26 = this.dataWeeklyCompleted[25].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 25){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
                dataWeeklyCompleted25 = this.dataWeeklyCompleted[24].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 24){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
                dataWeeklyCompleted24 = this.dataWeeklyCompleted[23].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 23){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
                dataWeeklyCompleted23 = this.dataWeeklyCompleted[22].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 22){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
                dataWeeklyCompleted22 = this.dataWeeklyCompleted[21].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 21){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
                dataWeeklyCompleted21 = this.dataWeeklyCompleted[20].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 20){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
                dataWeeklyCompleted20 = this.dataWeeklyCompleted[19].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 19){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
                dataWeeklyCompleted19 = this.dataWeeklyCompleted[18].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 18){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
                dataWeeklyCompleted18 = this.dataWeeklyCompleted[17].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 17){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
                dataWeeklyCompleted17 = this.dataWeeklyCompleted[16].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 16){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
                dataWeeklyCompleted16 = this.dataWeeklyCompleted[15].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 15){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
                dataWeeklyCompleted15 = this.dataWeeklyCompleted[14].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 14){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
                dataWeeklyCompleted14 = this.dataWeeklyCompleted[13].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 13){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
                dataWeeklyCompleted13 = this.dataWeeklyCompleted[12].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 12){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
                dataWeeklyCompleted12 = this.dataWeeklyCompleted[11].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 11){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
                dataWeeklyCompleted11 = this.dataWeeklyCompleted[10].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 10){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
                dataWeeklyCompleted10 = this.dataWeeklyCompleted[9].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 9){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
                dataWeeklyCompleted9 = this.dataWeeklyCompleted[8].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 8){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
                dataWeeklyCompleted8 = this.dataWeeklyCompleted[7].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 7){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
                dataWeeklyCompleted7 = this.dataWeeklyCompleted[6].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 6){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
                dataWeeklyCompleted6 = this.dataWeeklyCompleted[5].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 5){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
                dataWeeklyCompleted5 = this.dataWeeklyCompleted[4].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 4){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
                dataWeeklyCompleted4 = this.dataWeeklyCompleted[3].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 3){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
                dataWeeklyCompleted3 = this.dataWeeklyCompleted[2].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 2){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
                dataWeeklyCompleted2 = this.dataWeeklyCompleted[1].studentCount;
            }
            if(this.dataLengthWeeklyCompleted == 1){
                dataWeeklyCompleted1 = this.dataWeeklyCompleted[0].studentCount;
            }          
            //console.log(this.dataWeekly);
            this.barData4 = {
                labels: [labelWeekly1, labelWeekly2, labelWeekly3, labelWeekly4, labelWeekly5, labelWeekly6, labelWeekly7, labelWeekly8, labelWeekly9, labelDaily10,
                    labelWeekly11, labelWeekly12, labelWeekly13, labelWeekly14, labelWeekly15, labelWeekly16, labelWeekly17, labelWeekly18, labelWeekly19, labelWeekly20,
                    labelWeekly21, labelWeekly22, labelWeekly23, labelWeekly24, labelWeekly25, labelWeekly26, labelWeekly27, labelWeekly28, labelWeekly29, labelWeekly30,  
                    labelWeekly31, labelWeekly32, labelWeekly33, labelWeekly34, labelWeekly35, labelWeekly36, labelWeekly37, labelWeekly38, labelWeekly39, labelDaily40,
                    labelWeekly41, labelWeekly42, labelWeekly43, labelWeekly44, labelWeekly45, labelWeekly46, labelWeekly47, labelWeekly48, labelWeekly49, labelDaily50,
                    labelWeekly51, labelWeekly51],
                datasets: [
                    {
                        label: 'Approved Students',
                        fill: false,
                        backgroundColor: "rgb(57, 255, 20)",                          
                        borderColor: "rgb(57, 255, 20)", 
                        data: [dataWeekly1, dataWeekly2, dataWeekly3, dataWeekly4, dataWeekly5, dataWeekly6, dataWeekly7, dataWeekly8, dataWeekly9, dataWeekly10,
                            dataWeekly11, dataWeekly12, dataWeekly13, dataWeekly14, dataWeekly15, dataWeekly16, dataWeekly17, dataWeekly18, dataWeekly19, dataWeekly20,
                            dataWeekly21, dataWeekly22, dataWeekly23, dataWeekly24, dataWeekly25, dataWeekly26, dataWeekly27, dataWeekly28, dataWeekly29, dataWeekly30,
                            dataWeekly31, dataWeekly32, dataWeekly33, dataWeekly34, dataWeekly35, dataWeekly36, dataWeekly37, dataWeekly38, dataWeekly39, dataWeekly40,
                            dataWeekly41, dataWeekly42, dataWeekly43, dataWeekly44, dataWeekly45, dataWeekly46, dataWeekly47, dataWeekly48, dataWeekly49, dataWeekly50,
                            dataWeekly51, dataWeekly52]
                    },
                    {
                        label: 'Completed',
                        fill: false,
                        backgroundColor: "rgb(106, 13, 173)",
                        borderColor: "rgb(106, 13, 173)",
                        data: [dataWeeklyCompleted1, dataWeeklyCompleted2, dataWeeklyCompleted3, dataWeeklyCompleted4, dataWeeklyCompleted5, dataWeeklyCompleted6, dataWeeklyCompleted7, dataWeeklyCompleted8, dataWeeklyCompleted9, dataWeeklyCompleted10,
                            dataWeeklyCompleted11, dataWeeklyCompleted12, dataWeeklyCompleted13, dataWeeklyCompleted14, dataWeeklyCompleted15, dataWeeklyCompleted16, dataWeeklyCompleted17, dataWeeklyCompleted18, dataWeeklyCompleted19, dataWeeklyCompleted20,
                            dataWeeklyCompleted21, dataWeeklyCompleted22, dataWeeklyCompleted23, dataWeeklyCompleted24, dataWeeklyCompleted25, dataWeeklyCompleted26, dataWeeklyCompleted27, dataWeeklyCompleted28, dataWeeklyCompleted29, dataWeeklyCompleted30,
                            dataWeeklyCompleted31, dataWeeklyCompleted32, dataWeeklyCompleted33, dataWeeklyCompleted34, dataWeeklyCompleted35, dataWeeklyCompleted36, dataWeeklyCompleted37, dataWeeklyCompleted38, dataWeeklyCompleted39, dataWeeklyCompleted40,
                            dataWeeklyCompleted41, dataWeeklyCompleted42, dataWeeklyCompleted43, dataWeeklyCompleted44, dataWeeklyCompleted45, dataWeeklyCompleted46, dataWeeklyCompleted47, dataWeeklyCompleted48, dataWeeklyCompleted49, dataWeeklyCompleted50,
                            dataWeeklyCompleted51, dataWeeklyCompleted52]
                    }                   
                ]
            };
        });
    }

    loadDashboard() {
        this.cmsService.getDashboardDataByAdmin().then((response:any) => {
            this.data = response.result[0];

            this.totalStudent = formatNumber(Number(this.data.studentTotalCount), 'en-US', '1.0-0');
            this.totalApproved = formatNumber(Number(this.data.studentTotalPaymentCountApproved), 'en-US', '1.0-0');
            this.totalOngoing = formatNumber(Number(this.data.studentOngoingCount), 'en-US', '1.0-0');
            this.totalPending = formatNumber(Number(this.data.studentTotalPaymentCountPending), 'en-US', '1.0-0');

            //console.log(this.data);
            this.students =[
                {
                    course: "TDC",
                    count:  formatNumber(Number(this.data.totalCreditsSp), 'en-US', '1.0-0'),
                },
                {
                    course: "CDE",
                    count: formatNumber(Number(this.data.totalCreditsNp+this.data.totalCreditsPr), 'en-US', '1.0-0'),
                }
            ];

            this.bills = [
                {
                    name: "REGISTRATION FEE",
                    amount: formatNumber(Number(this.data.dsTotalPaymentPendingRegistration), 'en-US', '1.0-0'),
                },
                {
                    name: "SECURITY BOND",
                    amount: formatNumber(Number(this.data.dsTotalPaymentPendingBond), 'en-US', '1.0-0'),
                },
                {
                    name: "BILLING",
                    amount: formatNumber(Number(this.data.dsTotalPaymentPendingBilling), 'en-US', '1.0-0'),
                    
                },
            ];
        
             this.pieData = {
                labels: ["TDC - " + formatNumber(Number(this.data.studentCountByCourseSp), 'en-US', '1.0-0'), 
                "CDE - " + formatNumber(Number(this.data.studentCountByCoursePr+this.data.studentCountByCourseNp), 'en-US', '1.0-0'), 
                "No Course - " + formatNumber(Number(this.data.studentCountByCourseNone), 'en-US', '1.0-0')],
                datasets: [
                    {
                        data: [ 
                        this.data.studentCountByCourseSp, 
                        this.data.studentCountByCoursePr+this.data.studentCountByCourseNp,                         
                        this.data.studentCountByCourseNone],
                        backgroundColor: [
                            "rgb(54, 162, 235)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 88, 84)",
                        ],
                    },
                ],
            };          
        });
    }

    loadDashboardByDs() {
        this.cmsService.getDashboardDataByDS(this.dsProfileId).then((response:any) => {
            this.data = response.result[0];
            //console.log(this.data);
            this.totalStudent = formatNumber(Number(this.data.studentTotalCount), 'en-US', '1.0-0');
            this.totalApproved = formatNumber(Number(this.data.studentTotalPaymentCountApproved), 'en-US', '1.0-0');
            this.totalOngoing = formatNumber(Number(this.data.studentOngoingCount), 'en-US', '1.0-0');
            this.totalPending = formatNumber(Number(this.data.studentTotalPaymentCountPending), 'en-US', '1.0-0');

            this.students =[
                {
                    course: "TDC",
                    count:  formatNumber(Number(this.data.totalCreditsSp), 'en-US', '1.0-0'),
                },
                {
                    course: "CDE",
                    count: formatNumber(Number(this.data.totalCreditsNp+this.data.totalCreditsPr), 'en-US', '1.0-0'),
                }
            ];

            this.bills = [
                {
                    name: "REGISTRATION FEE",
                    amount: formatNumber(Number(this.data.dsTotalPaymentPendingRegistration), 'en-US', '1.0-0'),
                },
                {
                    name: "SECURITY BOND",
                    amount: formatNumber(Number(this.data.dsTotalPaymentPendingBond), 'en-US', '1.0-0'),
                },
                {
                    name: "BILLING",
                    amount: formatNumber(Number(this.data.dsTotalPaymentPendingBilling), 'en-US', '1.0-0'),
                    
                },
            ];
        
            this.pieData = {
                labels: ["TDC - " + formatNumber(Number(this.data.studentCountByCourseSp), 'en-US', '1.0-0'), 
                "CDE - " + formatNumber(Number(this.data.studentCountByCoursePr+this.data.studentCountByCourseNp), 'en-US', '1.0-0'), 
                "No Course - " + formatNumber(Number(this.data.studentCountByCourseNone), 'en-US', '1.0-0')],
                datasets: [
                    {
                        data: [ 
                        this.data.studentCountByCourseSp, 
                        this.data.studentCountByCoursePr+this.data.studentCountByCourseNp,                         
                        this.data.studentCountByCourseNone],
                        backgroundColor: [
                            "rgb(54, 162, 235)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 88, 84)",
                        ],
                    },
                ],
            };          
        });
    }

    loadDashboardByBranch(branchCode) {
        this.cmsService.getDashboardDataByBranch(branchCode).then((response:any) => {
            this.data = response.result[0];
            //console.log(this.data);
            this.totalStudent = formatNumber(Number(this.data.studentTotalCount), 'en-US', '1.0-0');
            this.totalApproved = formatNumber(Number(this.data.studentTotalPaymentCountApproved), 'en-US', '1.0-0');
            this.totalOngoing = formatNumber(Number(this.data.studentOngoingCount), 'en-US', '1.0-0');
            this.totalPending = formatNumber(Number(this.data.studentTotalPaymentCountPending), 'en-US', '1.0-0');

            this.students =[
                {
                    course: "TDC",
                    count:  formatNumber(Number(this.data.totalCreditsSp), 'en-US', '1.0-0'),
                },
                {
                    course: "CDE",
                    count: formatNumber(Number(this.data.totalCreditsNp+this.data.totalCreditsPr), 'en-US', '1.0-0'),
                }
            ];

            this.bills = [
                {
                    name: "REGISTRATION FEE",
                    amount: formatNumber(Number(this.data.dsTotalPaymentPendingRegistration), 'en-US', '1.0-0'),
                },
                {
                    name: "SECURITY BOND",
                    amount: formatNumber(Number(this.data.dsTotalPaymentPendingBond), 'en-US', '1.0-0'),
                },
                {
                    name: "BILLING",
                    amount: formatNumber(Number(this.data.dsTotalPaymentPendingBilling), 'en-US', '1.0-0'),
                    
                },
            ];
        
            this.pieData = {
                labels: ["TDC - " + formatNumber(Number(this.data.studentCountByCourseSp), 'en-US', '1.0-0'), 
                "CDE - " + formatNumber(Number(this.data.studentCountByCoursePr+this.data.studentCountByCourseNp), 'en-US', '1.0-0'), 
                "No Course - " + formatNumber(Number(this.data.studentCountByCourseNone), 'en-US', '1.0-0')],
                datasets: [
                    {
                        data: [ 
                        this.data.studentCountByCourseSp, 
                        this.data.studentCountByCoursePr+this.data.studentCountByCourseNp,                         
                        this.data.studentCountByCourseNone],
                        backgroundColor: [
                            "rgb(54, 162, 235)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 88, 84)",
                        ],
                    },
                ],
            };          
        });
    }

    loadDashboardByRegion(region) {
        this.cmsService.getDashboardDataByRegion(region).then((response:any) => {
            this.data = response.result[0];
            //console.log(this.data );

            this.totalStudent = formatNumber(Number(this.data.studentTotalCount), 'en-US', '1.0-0');
            this.totalApproved = formatNumber(Number(this.data.studentTotalPaymentCountApproved), 'en-US', '1.0-0');
            this.totalOngoing = formatNumber(Number(this.data.studentOngoingCount), 'en-US', '1.0-0');
            this.totalPending = formatNumber(Number(this.data.studentTotalPaymentCountPending), 'en-US', '1.0-0');

            this.students =[
                {
                    course: "TDC",
                    count:  formatNumber(Number(this.data.totalCreditsSp), 'en-US', '1.0-0'),
                },
                {
                    course: "CDE",
                    count: formatNumber(Number(this.data.totalCreditsNp+this.data.totalCreditsPr), 'en-US', '1.0-0'),
                }
            ];

            this.bills = [
                {
                    name: "REGISTRATION FEE",
                    amount: formatNumber(Number(this.data.dsTotalPaymentPendingRegistration), 'en-US', '1.0-0'),
                },
                {
                    name: "SECURITY BOND",
                    amount: formatNumber(Number(this.data.dsTotalPaymentPendingBond), 'en-US', '1.0-0'),
                },
                {
                    name: "BILLING",
                    amount: formatNumber(Number(this.data.dsTotalPaymentPendingBilling), 'en-US', '1.0-0'),
                    
                },
            ];
        
            this.pieData = {
                labels: ["TDC - " + formatNumber(Number(this.data.studentCountByCourseSp), 'en-US', '1.0-0'), 
                "CDE - " + formatNumber(Number(this.data.studentCountByCoursePr+this.data.studentCountByCourseNp), 'en-US', '1.0-0'), 
                "No Course - " + formatNumber(Number(this.data.studentCountByCourseNone), 'en-US', '1.0-0')],
                datasets: [
                    {
                        data: [ 
                        this.data.studentCountByCourseSp, 
                        this.data.studentCountByCoursePr+this.data.studentCountByCourseNp,                         
                        this.data.studentCountByCourseNone],
                        backgroundColor: [
                            "rgb(54, 162, 235)",
                            "rgb(255, 99, 132)",
                            "rgb(255, 88, 84)",
                        ],
                    },
                ],
            };         
        });
    }
}
