import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-billing-ds-account",
    templateUrl: "./billing-ds-account.component.html",
    styleUrls: ["./billing-ds-account.component.scss"],
})
export class BillingDsAccountComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
