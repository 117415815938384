import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { StorageService } from "../saferoadsph/services/storage.service";
import { UsersService } from "../saferoadsph/services/users.service";

@Component({
    selector: "app-accessdenied",
    templateUrl: "./app.accessdenied.component.html",
})
export class AppAccessdeniedComponent implements OnInit {
    verificationCode;
    errors = [];
    email;
    successMsg;
    constructor(
        private userService: UsersService,
        private router: Router,
        private storageService: StorageService
    ) {}

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.storageService.get("email").then((res) => {
            this.email = res;
        });
    }

    confirm() {
        this.errors = [];
        this.userService
            .verifyAccount(this.verificationCode)
            .then((res: any) => {
                if (res.statusCode == 404) {
                    this.errors = res.errorMessage;
                }
                if (res.statusCode == 200) {
                    this.router.navigate(["/"]);
                }
            })
            .catch((err) => {});
    }

    resendCode() {
        this.userService
            .resendCode(this.email)
            .then((res: any) => {
                //console.log(res);
                if (res.statusCode == 200) {
                    this.successMsg = "Code has beeen sent to : " + this.email;
                }
            })
            .catch((err) => {});
    }

    logout() {
        this.storageService.clear();
    }
}
