import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ChangePassword, Login, TransferStudentAccount, User, UserArchived, UserDelete, UserEmail, UserName } from "../interfaces/User";
import { dateParam } from "../interfaces/Report";

@Injectable({
    providedIn: "root",
})
export class UsersService {
    constructor(private httpClient: HttpClient) {}

    loadHeader() {
        return {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: localStorage.getItem("token"),
          }),
        };
      }

    loginHeader(login: Login) {
        var encrpytion = btoa(login.email + ":" + login.password);
        return {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Basic ${encrpytion}`,
            }),
        };
    }

    login(login: Login) {
        return this.httpClient
            .post(
                environment.paymentServiceURL + "/login",
                {},
                this.loginHeader(login)
            )
            .toPromise();
    }

    register(user: User) {
        return this.httpClient
            .post(
                environment.userServiceURL + "/safe-road-users/register",
                user
            )
            .toPromise();
    }

    forgotPassword(user: User) {
        return this.httpClient
            .post(
                environment.userServiceURL + "/safe-road-users/register",
                user
            )
            .toPromise();
    }

    updateProfile(user: User) {
        return this.httpClient
            .put(
                environment.userServiceURL + "/safe-road-users/edit-profile",
                user
            )
            .toPromise();
    }

    verifyAccount(code) {
        return this.httpClient
            .get(
                environment.userServiceURL +
                    "/safe-road-users/email-confirmation?code=" +
                    code
            )
            .toPromise();
    }

    resendCode(email) {
        return this.httpClient
            .get(
                environment.userServiceURL +
                    "/sysAdmin/email/resend-confirmation?email=" +
                    email
            )
            .toPromise();
    }

    resetpasswordByDrivingSchool(obj) {
        return this.httpClient
            .post(
                environment.userServiceURL +
                    "/safe-road-users/forgotPasswordByEmail",
                obj
            )
            .toPromise();
    }

    resetPassword(obj) {
        return this.httpClient
            .post(
                environment.userServiceURL + "/sysAdmin/email/password-reset",
                obj
            )
            .toPromise();
    }

    changePassword(password: ChangePassword) {
        return this.httpClient
            .post(
                environment.userServiceURL + "/sysAdmin/email/change-password",
                password
            )
            .toPromise();
    }

    getProfile() {
        return this.httpClient
            .get(environment.userServiceURL + "/safe-road-users/profile")
            .toPromise();
    }

    getStudentProfile(studentId) {
        return this.httpClient
            .get(
                environment.userServiceURL +
                    "/driving-school/view-student-profile/" +
                    studentId
            )
            .toPromise();
    }

    // Admin
    getAllStudents() {
        return this.httpClient
            .get(
                environment.userServiceURL +
                    "/admin/student-list?drivingSchoolId=0"
            )
            .toPromise();
    }

    getAdminAllSchools() {
        return this.httpClient
            .get(
                environment.userServiceURL +
                    "/admin/ds-list-all"
            )
            .toPromise();
    }

    getAllDrivingSchools() {
        return this.httpClient
            .get(
                environment.userServiceURL +
                    "/driving-school/driving-school-list"
            )
            .toPromise();
    }

    // Driving School
    getDrivingSchoolProfile() {
        return this.httpClient
            .get(environment.userServiceURL + "/driving-school/view-profile")
            .toPromise();
    }

    getDrivingSchoolProfileId(dsProfileId) {
        return this.httpClient
            .get(environment.userServiceURL + "/driving-school/view-profile?drivingSchoolId="+dsProfileId)
            .toPromise();
    }

    getAllStudentsByDrivingSchool() {
        return this.httpClient
            .get(environment.reportServiceURL + "/reports/student-list")
            .toPromise();
    }

    getAllStudentsByDrivingSchoolAdmin() {
        return this.httpClient
            .get(environment.reportServiceURL + "/reports/student-list-admin")
            .toPromise();
    }

    
    getAllStudentsByDrivingSchoolCreatedDate(obj: dateParam) {
        return this.httpClient
            .post(environment.reportServiceURL + "/reports/student-list-created-date", 
            obj
            )
            .toPromise();
    }

    getAllStudentsByDrivingSchoolAdminCreatedDate(obj: dateParam) {
        return this.httpClient
            .post(environment.reportServiceURL + "/reports/student-list-admin-created-date", 
            obj
            )
            .toPromise();
    }

    getAllStudentsByDrivingSchoolApprovedDate(obj: dateParam) {
        return this.httpClient
            .post(environment.reportServiceURL + "/reports/student-list-approved-date", 
            obj
            )
            .toPromise();
    }

    getAllStudentsByDrivingSchoolAdminApprovedDate(obj: dateParam) {
        return this.httpClient
            .post(environment.reportServiceURL + "/reports/student-list-admin-approved-date", 
            obj
            )
            .toPromise();
    }

    getAllStudentsByDrivingSchoolPaymentDate(obj: dateParam) {
        return this.httpClient
            .post(environment.reportServiceURL + "/reports/student-list-payment-date", 
            obj
            )
            .toPromise();
    }

    getAllStudentsByDrivingSchoolAdminPaymentDate(obj: dateParam) {
        return this.httpClient
            .post(environment.reportServiceURL + "/reports/student-list-admin-payment-date", 
            obj
            )
            .toPromise();
    }

    getAllStudentsNewAccountsByDrivingSchool() {
        return this.httpClient
            .get(environment.reportServiceURL + "/reports/student-list-new-account")
            .toPromise();
    }

    
    getAllStudentsNewAccountsByAdmin() {
        return this.httpClient
            .get(environment.reportServiceURL + "/reports/student-list-new-account-admin")
            .toPromise();
    }

    getAllStudentsEnrolledByDrivingSchool() {
        return this.httpClient
            .get(environment.reportServiceURL + "/reports/student-list-enrolled")
            .toPromise();
    }

    getAllStudentsEnrolledByDrivingSchoolCreatedDate(obj: dateParam) {
        return this.httpClient
            .post(environment.reportServiceURL + "/reports/student-list-enrolled-created-date",
            obj
            )
            .toPromise();
    }

    getAllStudentsEnrolledByDrivingSchoolApprovedDate(obj: dateParam) {
        return this.httpClient
            .post(environment.reportServiceURL + "/reports/student-list-enrolled-approved-date",
            obj           
            )
            .toPromise();
    }

    getAllStudentsEnrolledByDrivingSchoolPaymentDate(obj: dateParam) {
        return this.httpClient
            .post(environment.reportServiceURL + "/reports/student-list-enrolled-payment-date",
            obj
            )
            .toPromise();
    }

    editStudentName(userName: UserName) {
        return this.httpClient
            .post(environment.reportServiceURL + "/reports/update-student-name",
            userName
            )
            .toPromise();
    }

    editStudentEmail(userEmail: UserEmail) {
        return this.httpClient
            .post(environment.reportServiceURL + "/reports/update-student-email",
            userEmail
            )
            .toPromise();
    }

    transferStudentAccount(transferStudent: TransferStudentAccount) {
        return this.httpClient
            .post(environment.reportServiceURL + "/reports/transfer-student-account",
            transferStudent
            )
            .toPromise();
    }

    deleteStudentAccount(userDelete: UserDelete) {
        return this.httpClient
            .post(environment.reportServiceURL + "/reports/delete-student-account",
            userDelete
            )
            .toPromise();
    }

   archivedStudentAccount(userArchived: UserArchived) {
        return this.httpClient
            .post(environment.reportServiceURL + "/reports/archived-student-account",
            userArchived
            )
            .toPromise();
    }

    reverseArchivedStudentAccount(userArchived: UserArchived) {
        return this.httpClient
            .post(environment.reportServiceURL + "/reports/reverse-archived-student-account",
            userArchived
            )
            .toPromise();
    }

    getDuplicateStudentAccount() {
        return this.httpClient
            .get(environment.reportServiceURL + "/reports/duplicate-student-account")
            .toPromise();
    }

    getAllUsersByDrivingSchool() {
        return this.httpClient
            .get(environment.userServiceURL + "/driving-school/user-list")
            .toPromise();
    }

    getAllDrivingSchoolByCustomId(customeId) {
        return this.httpClient
            .get(environment.userServiceURL + "/driving-school/driving-school-list-customId/"+customeId)
            .toPromise();
    }

    editStudentProfile(studentId, user: User) {
        return this.httpClient
            .put(
                environment.userServiceURL +
                    "/driving-school/edit-student-user/" +
                    studentId,
                user
            )
            .toPromise();
    }

    
    editStudentProfileId(studentId, user: User) {
        return this.httpClient
            .put(
                environment.userServiceURL +
                    "/driving-school/edit-student-profile/" +
                    studentId,
                user
            )
            .toPromise();
    }

    addStudent(user: User) {
        return this.httpClient
            .post(
                environment.userServiceURL + "/driving-school/add-student-user",
                user
            )
            .toPromise();
    }

    uploadStudentPhoto(formData: FormData) {
        return this.httpClient.post(
            environment.userServiceURL +
                "/driving-school/update-student-user-with-photo-only",
            formData
        );
    }

    uploadCompanyLogo(formData: FormData) {
        return this.httpClient.post(
            environment.userServiceURL + "/upload-photo/company-logo",
            formData
        );
    }

    updateDrivingSchoolProfile(school) {
        return this.httpClient
            .put(
                environment.userServiceURL + "/driving-school/edit-profile",
                school
            )
            .toPromise();
    }

    updateDrivingSchoolProfileByDSCode(school) {
        return this.httpClient
            .put(
                environment.userServiceURL + "/driving-school/edit-profile?drivingSchoolId="+school.id,
                school
            )
            .toPromise();
    }

    deleteStudentProfile(studentId) {
        return this.httpClient
            .delete(
                environment.userServiceURL +
                    "/driving-school/delete-student-user/" +
                    studentId
            )
            .toPromise();
    }

    uploadSchoolDocs(formData: FormData) {
        return this.httpClient
            .post(environment.userServiceURL + "/upload-docs", formData)
            .toPromise();
    }

    uploadFormDocs(formData: FormData) {
        return this.httpClient
            .post(environment.userServiceURL + "/uploadDocumentForms", formData)
            .toPromise();
    }

    getSchoolDocs(dsProfileId) {
        return this.httpClient
            .get(
                environment.userServiceURL +
                    "/upload-docs/getDSDocuments/" +
                    dsProfileId
            )
            .toPromise();
    }

    getFormDocs() {
        return this.httpClient
            .get(
                environment.userServiceURL +
                    "/document-forms/getDocumentForms/" 
            )
            .toPromise();
    }

    deleteDocs(docId) {
        return this.httpClient
            .delete(
                environment.userServiceURL +
                    "/upload-docs/deleteDSDocument/" +
                    docId
            )
            .toPromise();
    }

    approveDocs(formData: FormData, docId) {
        
        return this.httpClient
            .put(
                environment.userServiceURL +
                    "/upload-docs/approveDSDocument/" +
                    docId
                    ,formData)
            .toPromise();
    }

    deleteFormDocs(docId) {
        return this.httpClient
            .delete(
                environment.userServiceURL +
                    "/document-forms/deleteDocumentForms/" +
                    docId
                    )
            .toPromise();
    }

    getStudentFaceCapturedLogs(studentId) {
        return this.httpClient
            .get(
                environment.userServiceURL +
                    "/safe-road-users/get-student-face-recognition-images?studentId=" +
                    studentId
            )
            .toPromise();
    }

    getCountries() {
        return this.httpClient.get<any>('assets/data/countries.json')
            .toPromise()
    }
}
