import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-driving-school-branch',
  templateUrl: './driving-school-branch.component.html',
  styleUrls: ['./driving-school-branch.component.scss']
})
export class DrivingSchoolBranchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
