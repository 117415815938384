import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class StorageService {
    constructor() {}

    public set(key: string, value: any) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    }

    public get(key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }

    public clear() {
        return Promise.resolve().then(function () {
            return localStorage.clear();
        });
    }
}
