import {
    Component,
    AfterViewInit,
    OnDestroy,
    ViewChild,
    Renderer2,
    OnInit,
} from "@angular/core";
import {
    trigger,
    state,
    style,
    transition,
    animate,
} from "@angular/animations";
import { PrimeNGConfig, MessageService } from "primeng/api";
import { AppComponent } from "./app.component";
import { StorageService } from "./saferoadsph/services/storage.service";
import * as moment from 'moment';
import { WebcamImage } from "ngx-webcam";
import { UsersService } from './saferoadsph/services/users.service';

@Component({
    selector: "app-main",
    templateUrl: "./app.main.component.html",
    providers: [MessageService],
    animations: [
        trigger("submenu", [
            state(
                "hidden",
                style({
                    height: "0px",
                })
            ),
            state(
                "visible",
                style({
                    height: "*",
                })
            ),
            transition(
                "visible => hidden",
                animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")
            ),
            transition(
                "hidden => visible",
                animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")
            ),
        ]),
    ],
})
export class AppMainComponent implements AfterViewInit, OnDestroy, OnInit {
    public menuInactiveDesktop: boolean;

    public menuActiveMobile: boolean;

    public profileActive: boolean;

    public topMenuActive: boolean;

    public topMenuLeaving: boolean;

    documentClickListener: () => void;

    menuClick: boolean;

    topMenuButtonClick: boolean;

    configActive: boolean;

    configClick: boolean;

    name;

    userType;

    photo: string = "assets/layout/images/profile.png";

    companyName;

    player;

    refCode;

    imageSrc;
    imageFile;
    uploadingPhoto = false;
    webcamImage: WebcamImage = null;
    noPrivate: any;
    pictureDialog: boolean;

    constructor(
        public renderer: Renderer2,
        private primengConfig: PrimeNGConfig,
        public app: AppComponent,
        private storageService: StorageService,
        private userService: UsersService,
        private messageService: MessageService
    ) {}

    ngOnInit() {
        this.primengConfig.ripple = true;
    }

    ngAfterViewInit() {
        // hides the overlay menu and top menu if outside is clicked
        this.documentClickListener = this.renderer.listen(
            "body",
            "click",
            (event) => {
                if (!this.isDesktop()) {
                    if (!this.menuClick) {
                        this.menuActiveMobile = false;
                    }

                    if (!this.topMenuButtonClick) {
                        this.hideTopMenu();
                    }
                } else {
                    if (!this.menuClick && this.isOVerlay()) {
                        this.menuInactiveDesktop = true;
                    }
                }

                if (this.configActive && !this.configClick) {
                    this.configActive = false;
                }

                this.configClick = false;
                this.menuClick = false;
                this.topMenuButtonClick = false;
            }
        );

        this.storageService.get("name").then((res) => {
            this.name = res;
        });
        this.storageService.get("profile_photo").then((res) => {
            this.photo = res;
        });

        this.storageService.get("userType").then((res) => {
            this.userType = res;
        });

        this.storageService.get("companyName").then((res) => {
            this.companyName = res;
        });

        this.storageService.get("player").then((res) => {
            this.player = res;
        });

        this.storageService.get("referralCode").then((res) => {
            this.refCode = res;
        });
    }

    toggleMenu(event: Event) {
        this.menuClick = true;
        if (this.isDesktop()) {
            this.menuInactiveDesktop = !this.menuInactiveDesktop;
            if (this.menuInactiveDesktop) {
                this.menuActiveMobile = false;
            }
        } else {
            this.menuActiveMobile = !this.menuActiveMobile;
            if (this.menuActiveMobile) {
                this.menuInactiveDesktop = false;
            }
        }

        if (this.topMenuActive) {
            this.hideTopMenu();
        }

        event.preventDefault();
    }

    toggleProfile(event: Event) {
        this.profileActive = !this.profileActive;
        event.preventDefault();
    }

    toggleTopMenu(event: Event) {
        this.topMenuButtonClick = true;
        this.menuActiveMobile = false;

        if (this.topMenuActive) {
            this.hideTopMenu();
        } else {
            this.topMenuActive = true;
        }

        event.preventDefault();
    }

    hideTopMenu() {
        this.topMenuLeaving = true;
        setTimeout(() => {
            this.topMenuActive = false;
            this.topMenuLeaving = false;
        }, 500);
    }

    onMenuClick() {
        this.menuClick = true;
    }

    onRippleChange(event) {
        this.app.ripple = event.checked;
        this.primengConfig.ripple = event.checked;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    isStatic() {
        return this.app.menuMode === "static";
    }

    isOVerlay() {
        return this.app.menuMode === "overlay";
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    onSearchClick() {
        this.topMenuButtonClick = true;
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }
    }

    uploadPhoto() {
        this.pictureDialog = true;
        this.noPrivate = null;
        this.webcamImage = null;
        this.imageSrc = null;
    }

    submitPhoto() {
        var img;
        this.uploadingPhoto = true;
        if (this.noPrivate != null) {
            const imageName = moment(new Date()).format("YYYYMMDDHms") + ".png";
            const imageBlob = this.dataURItoBlob(this.noPrivate.imageAsBase64);
            const imageFile = new File([imageBlob], imageName, {
                type: "image/png",
            });
            img = imageFile;
        } else {
            img = this.imageFile[0];
        }

        const formData = new FormData();
        formData.append("studentUserId", this.player.toString());
        formData.append("file", img);

        this.userService
            .uploadStudentPhoto(formData)
            .toPromise()
            .then((res) => {
                this.uploadingPhoto = false;
                this.messageService.add({
                    severity: "success",
                    summary: "Successful",
                    detail: "Photo has been Uploaded.",
                    life: 3000,
                });
                this.pictureDialog = false;
                window.location.reload();
            })
            .catch((err) => {
                this.uploadingPhoto = false;
            });
    }

    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: "image/png" });
        return blob;
    }

    onFileChange(event) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            this.imageFile = event.target.files;
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.imageSrc = reader.result as string;
                this.webcamImage = null;
                this.noPrivate = null;
            };
        }
    }

    handleImage(webcamImage: WebcamImage) {
        this.webcamImage = webcamImage;
        this.noPrivate = webcamImage;
    }
    // Get Address Settings
    retake() {
        this.webcamImage = null;
        this.noPrivate = null;
    }
}
