import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import {
    LocationStrategy,
    HashLocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";

import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { BadgeModule } from "primeng/badge";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { CarouselModule } from "primeng/carousel";
import { CascadeSelectModule } from "primeng/cascadeselect";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { ChipModule } from "primeng/chip";
import { ChipsModule } from "primeng/chips";
import { CodeHighlighterModule } from "primeng/codehighlighter";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ColorPickerModule } from "primeng/colorpicker";
import { ContextMenuModule } from "primeng/contextmenu";
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { FieldsetModule } from "primeng/fieldset";
import { FileUploadModule } from "primeng/fileupload";
import { FullCalendarModule } from "primeng/fullcalendar";
import { GalleriaModule } from "primeng/galleria";
import { InplaceModule } from "primeng/inplace";
import { InputNumberModule } from "primeng/inputnumber";
import { InputMaskModule } from "primeng/inputmask";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { KnobModule } from "primeng/knob";
import { LightboxModule } from "primeng/lightbox";
import { ListboxModule } from "primeng/listbox";
import { MegaMenuModule } from "primeng/megamenu";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { MultiSelectModule } from "primeng/multiselect";
import { OrderListModule } from "primeng/orderlist";
import { OrganizationChartModule } from "primeng/organizationchart";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { PanelMenuModule } from "primeng/panelmenu";
import { PasswordModule } from "primeng/password";
import { PickListModule } from "primeng/picklist";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { RatingModule } from "primeng/rating";
import { RippleModule } from "primeng/ripple";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ScrollTopModule } from "primeng/scrolltop";
import { SelectButtonModule } from "primeng/selectbutton";
import { SidebarModule } from "primeng/sidebar";
import { SkeletonModule } from "primeng/skeleton";
import { SlideMenuModule } from "primeng/slidemenu";
import { SliderModule } from "primeng/slider";
import { SplitButtonModule } from "primeng/splitbutton";
import { SplitterModule } from "primeng/splitter";
import { StepsModule } from "primeng/steps";
import { TabMenuModule } from "primeng/tabmenu";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { TagModule } from "primeng/tag";
import { TerminalModule } from "primeng/terminal";
import { TieredMenuModule } from "primeng/tieredmenu";
import { TimelineModule } from "primeng/timeline";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { TreeModule } from "primeng/tree";
import { TreeTableModule } from "primeng/treetable";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { WebcamModule } from "ngx-webcam";
import { BlockUIModule } from "primeng/blockui";

import { AppCodeModule } from "./app.code.component";
import { AppComponent } from "./app.component";
import { AppMainComponent } from "./app.main.component";
import { AppTopBarComponent } from "./app.topbar.component";
import { AppConfigComponent } from "./app.config.component";
import { AppMenuComponent } from "./app.menu.component";
import { AppMenuitemComponent } from "./app.menuitem.component";
import { DashboardDemoComponent } from "./demo/view/dashboarddemo.component";
import { FormLayoutDemoComponent } from "./demo/view/formlayoutdemo.component";
import { FloatLabelDemoComponent } from "./demo/view/floatlabeldemo.component";
import { InvalidStateDemoComponent } from "./demo/view/invalidstatedemo.component";
import { InputDemoComponent } from "./demo/view/inputdemo.component";
import { ButtonDemoComponent } from "./demo/view/buttondemo.component";
import { TableDemoComponent } from "./demo/view/tabledemo.component";
import { ListDemoComponent } from "./demo/view/listdemo.component";
import { TreeDemoComponent } from "./demo/view/treedemo.component";
import { PanelsDemoComponent } from "./demo/view/panelsdemo.component";
import { OverlaysDemoComponent } from "./demo/view/overlaysdemo.component";
import { MediaDemoComponent } from "./demo/view/mediademo.component";
import { MenusDemoComponent } from "./demo/view/menusdemo.component";
import { MessagesDemoComponent } from "./demo/view/messagesdemo.component";
import { MiscDemoComponent } from "./demo/view/miscdemo.component";
import { EmptyDemoComponent } from "./demo/view/emptydemo.component";
import { ChartsDemoComponent } from "./demo/view/chartsdemo.component";
import { FileDemoComponent } from "./demo/view/filedemo.component";
import { DocumentationComponent } from "./demo/view/documentation.component";
import { DisplayComponent } from "./utilities/display.component";
import { ElevationComponent } from "./utilities/elevation.component";
import { FlexboxComponent } from "./utilities/flexbox.component";
import { GridComponent } from "./utilities/grid.component";
import { IconsComponent } from "./utilities/icons.component";
import { WidgetsComponent } from "./utilities/widgets.component";
import { SpacingComponent } from "./utilities/spacing.component";
import { TypographyComponent } from "./utilities/typography.component";
import { TextComponent } from "./utilities/text.component";
import { AppCrudComponent } from "./pages/app.crud.component";
import { AppCalendarComponent } from "./pages/app.calendar.component";
import { AppInvoiceComponent } from "./pages/app.invoice.component";
import { AppHelpComponent } from "./pages/app.help.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppTimelineDemoComponent } from "./pages/app.timelinedemo.component";
import { AppAccessdeniedComponent } from "./pages/app.accessdenied.component";
import { AppLoginComponent } from "./pages/app.login.component";
import { EditorModule } from "primeng/editor";

import { CountryService } from "./demo/service/countryservice";
import { CustomerService } from "./demo/service/customerservice";
import { EventService } from "./demo/service/eventservice";
import { IconService } from "./demo/service/iconservice";
import { NodeService } from "./demo/service/nodeservice";
import { PhotoService } from "./demo/service/photoservice";
import { ProductService } from "./demo/service/productservice";
import { BreadcrumbService } from "./app.breadcrumb.service";
import { MenuService } from "./app.menu.service";
import { DashboardPageComponent } from "./saferoadsph/pages/dashboard-page/dashboard-page.component";
import { SchoolsListComponent } from "./saferoadsph/pages/schools/schools-list/schools-list.component";
import { InterceptorInterceptor } from "./saferoadsph/interceptors/interceptor.interceptor";
import { StudentsListComponent } from "./saferoadsph/pages/students/students-list/students-list.component";
import { CoursesListComponent } from "./saferoadsph/pages/courses/courses-list/courses-list.component";
import { BillingDsAccountComponent } from "./saferoadsph/pages/billing/billing-ds-account/billing-ds-account.component";
import { BillingDsStudentComponent } from "./saferoadsph/pages/billing/billing-ds-student/billing-ds-student.component";
import { BillingInvoicesComponent } from "./saferoadsph/pages/billing/billing-invoices/billing-invoices.component";
import { StudentsComponent } from "./saferoadsph/schools/students/students.component";
import { StudentsPaymentComponent } from "./saferoadsph/schools/students-payment/students-payment.component";
import { CoursePaymentMatrixComponent } from "./saferoadsph/schools/course-payment-matrix/course-payment-matrix.component";
import { PaymentOptionsComponent } from "./saferoadsph/schools/payment-options/payment-options.component";
import { VerifyStudentPaymentsComponent } from "./saferoadsph/schools/verify-student-payments/verify-student-payments.component";
import { PaymentSubscriptionComponent } from "./saferoadsph/schools/payment-subscription/payment-subscription.component";
import { PaymentPrepaidComponent } from "./saferoadsph/schools/payment-prepaid/payment-prepaid.component";
import { PaymentStatusComponent } from "./saferoadsph/schools/payment-status/payment-status.component";
import { PaymentBillsComponent } from "./saferoadsph/schools/payment-bills/payment-bills.component";
import { DocumentsComponent } from "./saferoadsph/schools/documents/documents.component";
import { PaymentReceiptsComponent } from "./saferoadsph/schools/payment-receipts/payment-receipts.component";
import { AppRegisterComponent } from "./pages/app.register.component";
import { CameraComponent } from "./saferoadsph/components/camera/camera.component";
import { BillingDsReceiptComponent } from "./saferoadsph/pages/billing/billing-ds-receipt/billing-ds-receipt.component";
import { ProfileComponent } from "./saferoadsph/shared/profile/profile.component";
import { ChangePasswordComponent } from "./saferoadsph/shared/change-password/change-password.component";
import { DrivingSchoolComponent } from "./saferoadsph/schools/driving-school/driving-school.component";
import { BillingCourseFeeComponent } from "./saferoadsph/pages/billing/billing-course-fee/billing-course-fee.component";
import { BillingPaymentMethodComponent } from "./saferoadsph/pages/billing/billing-payment-method/billing-payment-method.component";
import { UsersComponent } from "./saferoadsph/schools/users/users.component";
import { CourseModalComponent } from "./saferoadsph/pages/modals/course-modal/course-modal.component";
import { ModuleModalComponent } from "./saferoadsph/pages/modals/module-modal/module-modal.component";
import { TopicModalComponent } from "./saferoadsph/pages/modals/topic-modal/topic-modal.component";
import { QuestionsComponent } from "./saferoadsph/pages/questions/questions.component";
import { StripTagsPipe } from "./pipes/strip-tags.pipe";
import { BillingPaymentTypeComponent } from "./saferoadsph/pages/billing/billing-payment-type/billing-payment-type.component";
import { CmsComponent } from "./saferoadsph/pages/cms/cms.component";
import { ReportsStudentStatusListComponent } from './saferoadsph/schools/reports-student-status-list/reports-student-status-list.component';
import { DocumentsFormsComponent } from './saferoadsph/schools/documents-forms/documents-forms.component';
import { DocumentApprovalComponent } from './saferoadsph/pages/document-approval/document-approval.component';
import { ReportSchoolListComponent } from './saferoadsph/pages/reports/report-school-list/report-school-list.component';
import { ReportPaymentListComponent } from './saferoadsph/pages/reports/report-payment-list/report-payment-list.component';
import { ReportSummaryComponent } from './saferoadsph/pages/reports/report-summary/report-summary.component';
import { QRCodeModule } from 'angular2-qrcode';
import { StudentApprovedPaymentsComponent } from './saferoadsph/schools/student-approved-payments/student-approved-payments.component';
import { StudentNewAccountComponent } from './saferoadsph/schools/student-new-account/student-new-account.component';
import { StudentNewEnrolledComponent } from './saferoadsph/schools/student-new-enrolled/student-new-enrolled.component';
import { TutorialPageComponent } from "./saferoadsph/schools/tutorials/tutorial-page/tutorial-page.component";
import { DrivingSchoolsAdminComponent } from './saferoadsph/admin/driving-schools-admin/driving-schools-admin.component';
import { StudentsAdminComponent } from './saferoadsph/admin/students-admin/students-admin.component';
import { StudentsRegionComponent } from './saferoadsph/region/students-region/students-region.component';
import { DrivingSchoolsRegionComponent } from './saferoadsph/region/driving-schools-region/driving-schools-region.component';
import { DrivingSchoolBranchComponent } from './saferoadsph/branch/driving-school-branch/driving-school-branch.component';
import { StudentsBranchComponent } from './saferoadsph/branch/students-branch/students-branch.component';
import { PaymentVerificationBranchComponent } from './saferoadsph/branch/payments/payment-verification-branch/payment-verification-branch.component';
import { PaymentReceiptsBranchComponent } from './saferoadsph/branch/payments/payment-receipts-branch/payment-receipts-branch.component';
import { PaymentBillingBranchComponent } from './saferoadsph/branch/payments/payment-billing-branch/payment-billing-branch.component';
import { StudentsAdminArchivingComponent } from "./saferoadsph/admin/students-admin-archiving/students-admin-archiving.component";

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        ChipModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TagModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        WebcamModule,
        ProgressSpinnerModule,
        AppCodeModule,
        BlockUIModule,
        EditorModule,
        QRCodeModule,
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppTopBarComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        DashboardDemoComponent,
        FormLayoutDemoComponent,
        FloatLabelDemoComponent,
        InvalidStateDemoComponent,
        InputDemoComponent,
        ButtonDemoComponent,
        TableDemoComponent,
        ListDemoComponent,
        TreeDemoComponent,
        PanelsDemoComponent,
        OverlaysDemoComponent,
        MediaDemoComponent,
        MenusDemoComponent,
        MessagesDemoComponent,
        MessagesDemoComponent,
        MiscDemoComponent,
        ChartsDemoComponent,
        EmptyDemoComponent,
        FileDemoComponent,
        DocumentationComponent,
        DisplayComponent,
        ElevationComponent,
        FlexboxComponent,
        GridComponent,
        IconsComponent,
        WidgetsComponent,
        SpacingComponent,
        TypographyComponent,
        TextComponent,
        AppCrudComponent,
        AppCalendarComponent,
        AppLoginComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppTimelineDemoComponent,
        AppAccessdeniedComponent,
        DashboardPageComponent,
        SchoolsListComponent,
        StudentsListComponent,
        CoursesListComponent,
        BillingDsAccountComponent,
        BillingDsStudentComponent,
        BillingInvoicesComponent,
        StudentsComponent,
        StudentsPaymentComponent,
        CoursePaymentMatrixComponent,
        PaymentOptionsComponent,
        VerifyStudentPaymentsComponent,
        PaymentSubscriptionComponent,
        PaymentPrepaidComponent,
        PaymentStatusComponent,
        PaymentBillsComponent,
        DocumentsComponent,
        PaymentReceiptsComponent,
        AppRegisterComponent,
        CameraComponent,
        BillingDsReceiptComponent,
        ProfileComponent,
        ChangePasswordComponent,
        DrivingSchoolComponent,
        BillingCourseFeeComponent,
        BillingPaymentMethodComponent,
        UsersComponent,
        CourseModalComponent,
        ModuleModalComponent,
        TopicModalComponent,
        QuestionsComponent,
        StripTagsPipe,
        BillingPaymentTypeComponent,
        CmsComponent,
        ReportsStudentStatusListComponent,
        DocumentsFormsComponent,
        DocumentApprovalComponent,
        ReportSchoolListComponent,
        ReportPaymentListComponent,
        ReportSummaryComponent,
        StudentApprovedPaymentsComponent,
        StudentNewAccountComponent,
        StudentNewEnrolledComponent,
        TutorialPageComponent,
        DrivingSchoolsAdminComponent,
        StudentsAdminComponent,
        StudentsRegionComponent,
        DrivingSchoolsRegionComponent,
        DrivingSchoolBranchComponent,
        StudentsBranchComponent,
        PaymentVerificationBranchComponent,
        PaymentReceiptsBranchComponent,
        PaymentBillingBranchComponent,
        StudentsAdminArchivingComponent,
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorInterceptor,
            multi: true,
        },
        CountryService,
        CustomerService,
        EventService,
        IconService,
        NodeService,
        PhotoService,
        ProductService,
        MenuService,
        BreadcrumbService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
