import { Component, OnInit } from "@angular/core";
import { StorageService } from "./saferoadsph/services/storage.service";

@Component({
    selector: "app-menu",
    template: `
        <div class="menu">
            <ul class="layout-menu">
                <li
                    app-menuitem
                    *ngFor="let item of model; let i = index"
                    [item]="item"
                    [index]="i"
                    [root]="true"
                ></li>

<!--                 <li>
                    <a href="https://m.me/169840018487411" target="_blank"
                        ><i class="pi pi-fw pi-comment"></i
                        ><span>Chat Support</span></a
                    >
                </li> -->

                <li>
                    <a href="https://m.me/61563619108037" target="_blank"
                        ><i class="pi pi-fw pi-comment"></i
                        ><span>Chat Support</span></a
                    >
                </li>

                <li>
                    <a href="#" (click)="logout()"
                        ><i class="fa fa-sign-out"></i> Logout</a
                    >
                </li>
            </ul>
        </div>
    `,
})


export class AppMenuComponent implements OnInit {
    accountValidated;
    model: any[];
    super = [
        {
            label: "Dashboard",
            icon: "pi pi-fw pi-home",
            routerLink: ["/"]
        },
       /*  {
            label: "CMS",
            icon: "fa fa-pencil-square-o",
            routerLink: ["/admin/cms"],
        },
        {
            label: "Courses",
            icon: "fa fa-book",
            routerLink: ["/admin/courses"],
        },
        {
            label: "Questionaire",
            icon: "fa fa-question-circle",
            routerLink: ["/admin/questionaire"],
        }, */
        {
            label: "Driving Schools",
            icon: "fa fa-car",
            //routerLink: ["/admin/schools"],
            routerLink: ["/admin/driving-schools-admin"],
        },
        {
            label: "Students",
            icon: "fa fa-graduation-cap",
            //routerLink: ["/admin/students"],
            routerLink: ["/admin/students-admin"],
        },
        {
            label: "Payments",
            icon: "fa fa-credit-card",
            routerLink: ["/admin/billing"],
            items: [
                {
                    label: "Student Registration",

                    routerLink: ["/admin/billing/ds-student"],
                },
                {
                    label: "Invoice",

                    routerLink: ["/admin/billing/invoices"],
                },
                {
                    label: "Receipt",

                    routerLink: ["/admin/billing/receipt"],
                },
                {
                    label: "Payment Options",

                    routerLink: ["/admin/billing/payment-method"],
                },
                {
                    label: "Course Fees",

                    routerLink: ["/admin/billing/course-fees"],
                },
                {
                    label: "Payment Types",

                    routerLink: ["/admin/billing/payment-type"],
                },
            ],
        },

        {
            label: "Students for Archiving",
            icon: "fa fa-users",
            //routerLink: ["/admin/students"],
            routerLink: ["/admin/students-admin-archiving"],
        },

        /*{
            label: "Users",
            icon: "fa fa-user-secret",
            routerLink: ["/uikit"],
            items: [
                {
                    label: "List",

                    routerLink: ["/uikit/formlayout"],
                },
                {
                    label: "Add",

                    routerLink: ["/uikit/formlayout"],
                },
            ],
        },*/
        /* {
            label: "Reports",
            icon: "fa fa-bar-chart",
            routerLink: ["/admin/reports"],
            items: [
                {
                    label: "Students List Report", */
                    //routerLink: ["/admin/reports/student-list"],
               /*      routerLink: ["/admin/students-admin"],
                }, */
               /*  {
                    label: "Payment List Report",

                    routerLink: ["/admin/reports/payment-list"],
                },
                {
                    label: "Summary Report",

                    routerLink: ["/admin/reports/summary"],
                }, */
     /*        ],
        }, */
        {
            label: "Documents",
            icon: "fa fa-bar-chart",
            routerLink: ["/uikit"],
            items: [
                {
                    label: "Approve Documents",

                    routerLink: ["/admin/document-approval"],
                },
/*                 {
                    label: "Download Forms",

                    routerLink: ["/uikit/formlayout"],
                }, */
            ],
        },
       /* {
            label: "Utilities",
            icon: "fa fa-wrench",
            routerLink: ["/uikit"],
            items: [
                {
                    label: "Pages",

                    routerLink: ["/uikit/formlayout"],
                },
                {
                    label: "Blocks",

                    routerLink: ["/uikit/formlayout"],
                },
            ],
        }, */
    ];

    school = [
        {
            label: "Dashboard",
            icon: "pi pi-fw pi-home",
            routerLink: ["/"]
        },
        {
            label: "Tutorials",
            icon: "pi-fw fa fa-info-circle",
            routerLink: ["/school/tutorials/tutorial-page"],
        },

        {
            label: "Account Plan",
            icon: "pi-fw fa fa-user-plus",
            routerLink: ["/school/account-plan"],
            items: [
                {
                    label: "Subscription",
                    routerLink: ["/school/account-plan/subscription"],
                },
                {
                    label: "Prepaid",
                    routerLink: ["/school/account-plan/prepaid"],
                },
                {
                    label: "Status",
                    routerLink: ["/school/account-plan/status"],
                },
            ],
        },

        {
            label: "Documents",
            icon: "pi-fw fa fa-book",
            routerLink: ["/school/documents"],
        },  

        {
            label: "Students",
            icon: "pi-fw fa fa-graduation-cap",
            // routerLink: ["/school/students"],
              items: [
                {
                    label: "New Accounts",
                    routerLink: ["/school/students-new-accounts"],
                },
                {
                    label: "Enrolled",
                    routerLink: ["/school/students-enrolled"],
                },       
                /* {
                    label: "All",
                    routerLink: ["/school/students"],
                },     */        
            ],  
        },


        {
            label: "Payments",
            icon: "pi-fw fa fa-credit-card",
            routerLink: ["/school/payments"],
            items: [
/*                 {
                    label: "Student Payment",
                    routerLink: ["/school/payments/students-payment"],
                }, */
                {
                    label: "Payment Verifications",
                    routerLink: ["/school/payments/verify-student-payments"],
                },
                {
                    label: "Approved Payments",
                    routerLink: ["/school/payments/student-approved-payments"],
                },
                {
                    label: "Bills (Subscription)",
                    routerLink: ["/school/payments/bills"],
                },
                {
                    label: "Receipts",
                    routerLink: ["/school/payments/receipts"],
                },

                {
                    label: "Course Payment Matrix",
                    routerLink: ["/school/payments/course-payment-matrix"],
                },
                {
                    label: "Payment Options",
                    routerLink: ["/school/payments/payment-options"],
                },
            ],
        },

        {
            label: "Admin Users",
            icon: "pi-fw fa fa-graduation-cap",
            routerLink: ["/school/users"],
        },
        
        {
            label: "Reports",
            icon: "pi-fw fa fa-bar-chart",
            routerLink: ["/school/reports"],
            items: [
                {
                    label: "Student Status List",
                    routerLink: ["/school/reports/student-status-list"],
                },
            ],
        },


/*         {
            label: "Download Forms",
            icon: "pi-fw fa fa-book",
            routerLink: ["/school/documents-forms"],
        }, */
      
        // {
        //     label: "Report",
        //     icon: "pi-fw fa fa-line-chart",
        //     routerLink: ["/report"],
        // },
    ];

    schoolRegistration = [
        {
            label: "Dashboard",
            icon: "pi pi-fw pi-home",
            routerLink: ["/"]
        },
    ]

    schoolAdmin = [
        {
            label: "Dashboard",
            icon: "pi pi-fw pi-home",
            routerLink: ["/"]
        },       
         {
            label: "Driving Schools",
            icon: "fa fa-car",
            routerLink: ["/admin/schools"],
        },
        {
            label: "Students",
            icon: "fa fa-graduation-cap",
            routerLink: ["/admin/students"],
        },   
        {
            label: "Reports",
            icon: "pi-fw fa fa-bar-chart",
            routerLink: ["/school/reports"],
            items: [
                {
                    label: "Student Status List",
                    routerLink: ["/school/reports/student-status-list"],
                },
            ],
        },       
    ];

    admin = [
        {
            label: "Dashboard",
            icon: "pi pi-fw pi-home",
            routerLink: ["/"]
        },
        {
            label: "Driving Schools",
            icon: "fa fa-car",
            routerLink: ["/admin/driving-schools-admin"],
        },
        {
            label: "Students",
            icon: "fa fa-graduation-cap",
            routerLink: ["/admin/students-admin"],
        },
    ];

    lto = [
         {
                    label: "Dashboard",
                    icon: "pi pi-fw pi-home",
                    routerLink: ["/"]
                },   
                {
                    label: "Driving Schools",
                    icon: "fa fa-car",
                    routerLink: ["/region/driving-schools-region"],
                },
                {
                    label: "Students",
                    icon: "fa fa-graduation-cap",
                    routerLink: ["/region/students-region"],
                },      
               /*  {
                    label: "Reports",
                    icon: "pi-fw fa fa-bar-chart",
                    routerLink: ["/school/reports"],
                    items: [
                        {
                            label: "Students List Report",
        
                            routerLink: ["/admin/reports/student-list"],
                        },
                    ],
                }, */
            ];

    constructor(private storageService: StorageService) {}

    ngOnInit() {
        this.loadAccountValidated();
        this.storageService.get("userType").then((res) => {
            if (res == "ADMIN") {
                this.model = this.admin;
            } else if(res == "SCHOOL") {
                this.model = this.school;
            }
            else if(res == "SCHOOL-REGISTRATION") {
                this.model = this.schoolRegistration;
            }
            else if(res == "SCHOOL-ADMIN") {
                this.model = this.schoolAdmin;
            }
            else if(res == "LTO") {
                this.model = this.lto;
            }
            else if(res == "SUPER") {
                this.model = this.super;
            }
        });
    }

    loadAccountValidated() {
        this.storageService.get('accountValidated').then((response:any) => {
            this.accountValidated = response;
        })
    }

    logout() {
        this.storageService.clear().then((res) => {
            location.reload();
        });
    }
}
