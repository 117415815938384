import { Component } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { filter } from 'rxjs/operators';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: []
})
export class AppComponent {
    menuMode = 'static';

    lightMenu = false;

    inputStyle = 'outlined';

    ripple: boolean;
    router: any;

    constructor(private primengConfig: PrimeNGConfig) {
    }

    ngOnInit() {
        this.setUpAnalytics;
        this.primengConfig.ripple = true;
        this.ripple = true;
    }

    setUpAnalytics() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                gtag('config', 'G-2LSQWR0DNH',
                    {
                        page_path: event.urlAfterRedirects
                    }
                );
            });
    }

}
