import { Component, OnInit } from "@angular/core";
import { QRCodeModule } from "angular2-qrcode";
import { MessageService } from "primeng/api";
import { Address, School } from "src/app/saferoadsph/interfaces/User";
import { CmsService } from "src/app/saferoadsph/services/cms.service";
import { UsersService } from "src/app/saferoadsph/services/users.service";
import { ValidationService } from "src/app/saferoadsph/services/validation.service";
import { environment } from "src/environments/environment";
import _ from "lodash";

@Component({
  selector: 'app-driving-schools-admin',
  templateUrl: './driving-schools-admin.component.html',
  styleUrls: ['./driving-schools-admin.component.scss']
})
export class DrivingSchoolsAdminComponent implements OnInit {
  selectedSchool;
  school;
  schools;
  loading: boolean;
  dsDialog: boolean;

  selectedDSProfileId;

  dsSchool: School = new School();
  address: Address = new Address();
  submitted: Boolean = false;
  regions;
  provinces;
  cities;
  qrCode;
  branchQRCode;
  companyName;
  fullAddress;
  contactDetails1;
  contactDetails2;
  logo;

  transMsg;
  transMsgError: Boolean = false;
  transMsgSuccess: Boolean = false;

  subscriptionOption = [
      { label: "SUBSCRIPTION", value: "SUBSCRIPTION" },
      { label: "PREPAID", value: "PREPAID" },
  ];

  selectOptions=[
    {label: "EMPTY",value: null},
    {label: "YES",value:"YES"},
    {label: "NO",value:"NO"}
];

  constructor(private userService: UsersService,
        private validationService: ValidationService,
        private cmsService: CmsService,
        private messageService: MessageService,
        private QRCode: QRCodeModule) { }


        ngOnInit(): void {
          this.getAllDrivingSchools();
      }
  
      getAllDrivingSchools() {
          this.loading = true;
          this.userService.getAllDrivingSchools().then((res: any) => {
              this.schools = res.result;
              this.loading = false;
              //console.log(this.schools);
          });
      }
  
      editRecord(selectedSchool:any) {
          //this.loadAddressOfStudent(record);
          //record.birthDate = new Date(this.convertDate(record.birthDate));
          this.dsDialog = true;
          //console.log(selectedSchool);
          this.getAllRegions();
          this.loadSchoolProfile(selectedSchool.id);
          this.selectedDSProfileId = selectedSchool.id;
          //this.state = "edit";
          //this.submitted = false;
          //this.isDisabled = true;
      }
  
      cancel() {
          this.dsDialog = false; 
          this.getAllDrivingSchools();
          this.transMsgError = false;
          this.transMsgSuccess = false;
      }
  
      loadSchoolProfile(dsProfileId:any) {
          this.dsSchool = null;
          this.userService
              .getDrivingSchoolProfileId(dsProfileId)
              .then((response: any) => {
                  this.dsSchool = response.result[0];
                  //console.log(response.result[0]);
                  //console.log(this.dsSchool);
                  this.loadAddressOfProfile(this.dsSchool);
                  this.address = this.dsSchool.address;
                  this.qrCode = environment.qrCodeCreateAccountURL + this.dsSchool.referralCode;
                  this.branchQRCode = environment.branchQRCodeCreateAccountURL +this.dsSchool.customId;
                  this.companyName = this.dsSchool.companyName.toUpperCase();
                  this.fullAddress = this.dsSchool.fullAddress;
                  this.contactDetails1 ="Email: "+ this.dsSchool.companyEmail;
                  this.contactDetails2 ="Tel. No.: "+this.dsSchool.telephone +" | Mobile No.: "+this.dsSchool.mobile;
                  this.logo = this.dsSchool.logo;
              })
              .catch((err) => {
                  this.transMsgError = true;
                  this.transMsg = "Error in Loading Data Please Contact Administrator";
  /*                 this.messageService.add({
                      severity: "error",
                      summary: "Error",
                      detail: "Error in Loading Data Please Contact Administrator",
                      life: 3000,
                  }); */
              });
      }
  
      uploading = false;
      uploadCompanyLogo() {
          this.uploading = true;
          const formData = new FormData();
          formData.append("file", this.imgFile);
          this.userService
              .uploadCompanyLogo(formData)
              .toPromise()
              .then((res) => {
                  this.uploading = false;
                  this.imgFile = null;
                  this.loadSchoolProfile(this.selectedDSProfileId);
              })
              .catch((err) => {
                  this.imgFile = null;
                  this.uploading = false;
              });
      }
  
      imgFile;
      onFileChange($e) {
          this.imgFile = $e.target.files[0];
          //console.log(this.imgFile);
      }
  
      save() {
        this.address.latitude = this.dsSchool.latitude;
        this.address.longitude = this.dsSchool.longitude;
          this.dsSchool.address = this.address;
          this.userService.updateDrivingSchoolProfileByDSCode(this.dsSchool).then((res) => {
              this.transMsgSuccess = true;
              this.transMsg = "Driving School Profile Updated.";
  /*             this.messageService.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Driving School Profile Updated.",
                  life: 3000,
              }); */
              //this.loadSchoolProfile(this.selectedDSProfileId);
              this.hideDialog();
          });
      }
  
      getAllRegions() {
          this.cmsService.getRegions().then((res: any) => {
              if (res.statusCode == 200) {
                  this.regions = res.result;
                  this.regions.push({ regionName: null });
                   this.regions = _.map(this.regions, function (value) {
                      return {
                          label: value.regionName ? value.regionName : "",
                          value: value.regionName,
                      };
                  }); 
              } else {
                  this.transMsgError = true;
                  this.transMsg = "Error in Loading Data Please Contact Administrator";
  /*                 this.messageService.add({
                      severity: "error",
                      summary: "Error",
                      detail: "Error in Loading Data Please Contact Administrator",
                      life: 3000,
                  }); */
              }
          });
      }
  
      changeRegion($e) {
          this.cmsService.getProvincesByRegionName($e.value).then((res: any) => {
              if (res.statusCode == 200) {
                  this.provinces = res.result;
                  this.provinces.push({ stateName: null });
                   this.provinces = _.map(this.provinces, function (value) {
                      return {
                          label: value.stateName ? value.stateName : "",
                          value: value.stateName,
                      };
                  }); 
              } else {
                  this.transMsgError = true;
                  this.transMsg = "Error in Loading Data Please Contact Administrator";
  /*                 this.messageService.add({
                      severity: "error",
                      summary: "Error",
                      detail: "Error in Loading Data Please Contact Administrator",
                      life: 3000,
                  }); */
              }
          });
      }
  
      changeProvince($e) {
          this.cmsService.getCitiesByProvinceName($e.value).then((res: any) => {
              if (res.statusCode == 200) {
                  this.cities = res.result;
                  this.cities.push({ cityName: null });
                   this.cities = _.map(this.cities, function (value) {
                      return {
                          label: value.cityName ? value.cityName : "",
                          value: value.cityName,
                      };
                  }); 
              } else {
                  this.transMsgError = true;
                  this.transMsg = "Error in Loading Data Please Contact Administrator";
  /*                 this.messageService.add({
                      severity: "error",
                      summary: "Error",
                      detail: "Error in Loading Data Please Contact Administrator",
                      life: 3000,
                  }); */
              }
          });
      }
  
      validateEmail(email) {
          return this.validationService.validateEmail(email);
      }
  
      loadAddressOfProfile(record) {
          this.cmsService
              .getProvincesByRegionName(record.address.region)
              .then((res: any) => {
                  if (res.statusCode == 200) {
                      this.provinces = res.result;
                       this.provinces = _.map(this.provinces, function (value) {
                          return {
                              label: value.stateName,
                              value: value.stateName,
                          }; 
                      });
                      this.cmsService
                          .getCitiesByProvinceName(record.address.province)
                          .then((res: any) => {
                              if (res.statusCode == 200) {
                                  this.cities = res.result;
                                   this.cities = _.map(
                                      this.cities,
                                      function (value) {
                                          return {
                                              label: value.cityName,
                                              value: value.cityName,
                                          };
                                      }
                                  ); 
                                  record.address.city = record.address.city;
                              }
                          });
                  }
              });
      }
  
      hideDialog() {
          this.dsDialog = false; 
          this.getAllDrivingSchools();
          this.transMsgError = false;
          this.transMsgSuccess = false;
      }

}
