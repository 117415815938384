import { Component, OnInit } from "@angular/core";
import { CourseService } from "../../services/course.service";

@Component({
    selector: "app-questions",
    templateUrl: "./questions.component.html",
    styleUrls: ["./questions.component.scss"],
})
export class QuestionsComponent implements OnInit {
    questions;
    courses;
    topics;
    languages;
    loading: boolean;

    optionYesNo = [
        { id: "N", name: "No" },
        { id: "Y", name: "Yes" },
    ];

    constructor(private courseService: CourseService) {}

    ngOnInit(): void {
        this.getAllQuestionaires();
        this.getCourses();
        this.getTopics();
        this.getLanguages();
    }

    getAllQuestionaires() {
        this.loading = true;
        this.courseService.getAllQuestionaires().then((res: any) => {
            this.questions = res.result[0];
            this.loading = false;
        });
    }

    getCourses() {
        this.courseService.getAllCourses().then((res: any) => {
            //console.log(res);
            this.courses = res.result[0];
           //console.log(res.result[0]);
        });
    }

    getTopics() {
        this.courseService.getAllTopics().then((res: any) => {
            this.topics = res.result[0];
            //console.log(res.result[0]);
        });
    }

    getLanguages() {
        this.courseService.getAllLanguages().then((res: any) => {
            this.languages = res.result[0];
            //console.log(res.result[0]);
        });
    }

    getSize(str) {
        return str.split(",").length;
    }
}
