import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { PaymentMatrix } from "../../interfaces/Payment";
import { PaymentsService } from "../../services/payments.service";
import { StorageService } from "../../services/storage.service";
import { ValidationService } from "../../services/validation.service";
import { FormControl, FormGroup, FormArray, FormBuilder, ValidatorFn,AbstractControl } from '@angular/forms';

@Component({
    selector: "app-course-payment-matrix",
    templateUrl: "./course-payment-matrix.component.html",
    styleUrls: ["./course-payment-matrix.component.scss"],
    providers: [MessageService, ConfirmationService],
})
export class CoursePaymentMatrixComponent implements OnInit {
    recordDialog: boolean;
    records;
    record: PaymentMatrix;
    selectedRecords: PaymentMatrix[];
    submitted: boolean;
    statuses: any[];
    state: string = "new";
    dsProfileId;
    isDisabled = false;
    loading: boolean = true;
    form: FormGroup;

    courseOptions = [
        {
            label: "15hr TDC - STUDENT PERMIT",
            value: { code: "SP", id: 1, name: "STUDENT PERMIT" },
        },
        {
            label: "5hr COMPREHENSIVE DRIVER'S EDUCATION (CDE)",
            value: { code: "NP", id: 2, name: "NON PROFESSIONAL" },
        }
    ];
    selectedCourse;
    selectedCourseFee;
    courseFees;
    errForm = false;
    accountValidated;

    constructor(
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private paymentService: PaymentsService,
        private storageService: StorageService,
        private validationService: ValidationService
    ) {}

    ngOnInit(): void {
        this.loadAccountValidated();
        this.loadCourseFees();
        this.loadPaymentMatrix();
    }

    loadAccountValidated() {
        this.storageService.get('accountValidated').then((response:any) => {
            this.accountValidated = response;
        })
    }

    loadDsProfile() {
        this.storageService.get("dsProfileId").then((response) => {
            this.dsProfileId = response;
            this.record.dsProfileId = this.dsProfileId;
        });
    }

    loadPaymentMatrix() {
        this.paymentService.getSchoolMatrix().then((response: any) => {
            //console.log(response.result);
            this.records = response.result;
            this.loading = false;
        });
    }

    loadCourseFees() {
        this.paymentService.getCourseFees().then((response: any) => {
            //console.log(response.result);
            this.courseFees = response.result;
        });
    }

    openNew() {
        this.isDisabled = false;
        this.record = new PaymentMatrix();
        this.submitted = false;
        this.recordDialog = true;
        this.state = "new";
        this.record.code = this.courseOptions[0].value.code;
        this.record.courseId = this.courseOptions[0].value.id;
        this.record.name = this.courseOptions[0].value.name;
        this.selectedCourse = this.courseOptions[0];
        this.selectedCourseFee = this.courseFees.filter((fee) => {
            return fee.code == this.selectedCourse.value.code;
        });
        this.selectedCourseFee = this.selectedCourseFee[0].amount;
        this.loadDsProfile();
    }

    editRecord(record: any) {
        //console.log(record);
        this.isDisabled = true;
        this.record = { ...record };
        this.recordDialog = true;
        this.state = "edit";
        this.selectedCourseFee = this.courseFees.filter((fee) => {
            return fee.code == this.record.code;
        });
        this.selectedCourseFee = this.selectedCourseFee[0].amount;
        this.record.totalAmount =
            this.record.dsRegistrationFee + this.selectedCourseFee;
        this.selectedCourse = this.courseOptions.filter((course) => {
            return course.value.name == this.record.name;
        });
        this.selectedCourse = this.selectedCourse[0].value;
    }

    selectCourse() {
        this.record.code = this.selectedCourse.code;
        this.record.courseId = this.selectedCourse.id;
        this.record.name = this.selectedCourse.name;
        this.selectedCourseFee = this.courseFees.filter((fee) => {
            return fee.code == this.record.code;
        });
        this.selectedCourseFee = this.selectedCourseFee[0].amount;
    }

    computeTotalwVat(value) {
        this.record.vatAmount = this.record.dsRegistrationFee * (value / 100);
        if (this.record.vatInclusive == 0) {
            this.record.totalAmount =
                this.record.dsRegistrationFee +
                this.selectedCourseFee -
                this.record.discountAmount +
                this.record.vatAmount;
        } else {
            this.record.totalAmount =
                this.record.dsRegistrationFee +
                this.selectedCourseFee -
                this.record.discountAmount;
        }
    }

    computeTotalwDiscount(value) {
        this.record.discountAmount =
            this.record.dsRegistrationFee * (value / 100);

        if (this.record.vatInclusive == 0) {
            this.record.totalAmount =
                this.record.dsRegistrationFee +
                this.selectedCourseFee -
                this.record.discountAmount +
                this.record.vatAmount;
        } else {
            this.record.totalAmount =
                this.record.dsRegistrationFee +
                this.selectedCourseFee -
                this.record.discountAmount;
        }
    }

    computeTotalVat() {
        this.record.totalAmount =
            this.record.dsRegistrationFee +
            this.selectedCourseFee
    }

    saveProduct() {
        //console.log(this.record);
        //console.log(this.record.id);
        this.submitted = true;
        if (this.validationService.validatePriceMatrix(this.record)) {
           if (this.state =="edit"){
            this.paymentService
            .editSchoolMatrix(this.record,this.record.id)
            .then((response: any) => {
                this.submitted = false;
                this.recordDialog = false;
                this.loadPaymentMatrix();
                this.messageService.add({
                    severity: "success",
                    summary: "Saving was successful.",
                    detail: "",
                    life: 3000,
                });
            })
            .catch((err) => {
                this.submitted = false;
                this.recordDialog = false;
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            });              
           }
           else{
            this.paymentService
                .addSchoolMatrix(this.record)
                .then((response: any) => {
                    this.submitted = false;
                    this.recordDialog = false;
                    this.loadPaymentMatrix();
                    this.messageService.add({
                        severity: "success",
                        summary: "Saving was successful.",
                        detail: "",
                        life: 3000,
                    });
                })
                .catch((err) => {
                    this.submitted = false;
                    this.recordDialog = false;
                    this.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Error in Loading Data Please Contact Administrator",
                        life: 3000,
                    });
                });
            }
        } else {
            this.submitted = false;
            this.errForm = true;
        }
    }

    hideDialog() {
        this.recordDialog = false;
        this.submitted = false;
    }

    deleteRecord(record) {
        this.paymentService
            .deleteSchoolMatrix(record.id)
            .then((response: any) => {
                this.loadPaymentMatrix();
                this.messageService.add({
                    severity: "success",
                    summary: "Saving was successful.",
                    detail: "",
                    life: 3000,
                });
            })
            .catch((err) => {
                this.submitted = false;
                this.recordDialog = false;
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            });            
    }

}
