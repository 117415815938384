import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { PaymentsService } from "src/app/saferoadsph/services/payments.service";

@Component({
    selector: "app-billing-ds-receipt",
    templateUrl: "./billing-ds-receipt.component.html",
    styleUrls: ["./billing-ds-receipt.component.scss"],
    providers: [MessageService, ConfirmationService],
})
export class BillingDsReceiptComponent implements OnInit {
    selectedRecords;
    recordDialog: boolean;
    passwordDialog: boolean;
    pictureDialog: boolean;
    records: any[];
    record: any;
    constructor(private paymentService: PaymentsService) {}

    ngOnInit(): void {
        this.loadAllReceipts();
    }

    loadAllReceipts() {
        this.paymentService.getAllInvoiceHeaders().then((res: any) => {
            this.records = res.result;
            //console.log(this.records)
        });
    }
}
