import { Component, OnInit } from "@angular/core";
import {
    PaymentMatrix,
    SchoolAccountPaymentOption,
    StudentTransaction,
} from "../../interfaces/Payment";
import { PaymentsService } from "../../services/payments.service";
import _ from "lodash";
import { User } from "../../interfaces/User";
import { UsersService } from "../../services/users.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { StorageService } from "../../services/storage.service";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { ValidationService } from "../../services/validation.service";

@Component({
    selector: "app-students-payment",
    templateUrl: "./students-payment.component.html",
    styleUrls: ["./students-payment.component.scss"],
    providers: [ConfirmationService, MessageService],
})
export class StudentsPaymentComponent implements OnInit {
    submitted;
    record;
    recordDialog;
    studentTransaction = new StudentTransaction();

    paymentOptions = [];
    studentOptions: User[];
    courseOptions: PaymentMatrix[];

    selectedPaymentMethod;
    selectedStudent;
    selectedCourse;
    paymentMethodReadOnly;
    fileToUpload;
    schoolProfile;
    credits;
    isError = false;
    errForm = false;
    accountValidated;

    constructor(
        private paymentService: PaymentsService,
        private userService: UsersService,
        private messageService: MessageService,
        private storageService: StorageService,
        private route: ActivatedRoute,
        private validationService: ValidationService
    ) {}

    ngOnInit(): void {
        this.loadAccountValidated();
        this.loadSchoolProfile();
        this.loadPaymentOptions();
        this.loadAllStudents();
    }

    loadAccountValidated() {
        this.storageService.get('accountValidated').then((response:any) => {
            this.accountValidated = response;
        })
    }

    clearInputs() {
        this.studentTransaction = new StudentTransaction();
        this.fileToUpload = null;
    }

    loadSchoolProfile() {
        this.userService.getDrivingSchoolProfile().then((response:any) => {
            this.schoolProfile = response.result[0];
            //console.log(this.schoolProfile);
            
            this.credits = {
                SP: this.schoolProfile.spQuantity,
                NP: this.schoolProfile.npQuantity,
                PR: this.schoolProfile.prQuantity
            }

            this.loadAllPaymentMatrix();
        }).catch(err => {
            this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Error in Loading Data Please Contact Administrator",
                life: 3000,
            });
        });
        // this.storageService.get("credits").then((response: any) => {
        //     this.credits = JSON.parse(response)8667676;
        // });
    }

    loadPaymentOptions() {
        this.paymentService.getSchoolPaymentOption().then((response: any) => {
            this.paymentOptions = response.result;
            this.selectedPaymentMethod = this.paymentOptions[0];
            this.selectPaymentMethod();
            this.paymentOptions = _.map(this.paymentOptions, function (value) {
                return {
                    label: value.name,
                    value: value,
                };
            });
        });
    }

    loadAllStudents() {
        this.userService.getAllStudentsByDrivingSchool().then((res: any) => {
            if (res.statusCode == 200) {
                this.studentOptions = res.result;
                this.selectedStudent = this.studentOptions[0];

                this.selectStudent();
                this.studentOptions = _.map(
                    this.studentOptions,
                    function (value) {
                        return {
                            label: value.fullName,
                            value: value.id,
                        };
                    }
                );

                if (this.route.snapshot.params["id"]) {
                    this.selectedStudent = parseInt(
                        this.route.snapshot.params["id"]
                    );
                }
            }
        });
    }

    loadAllPaymentMatrix() {
        this.paymentService.getSchoolMatrix().then((response: any) => {
            this.courseOptions = response.result;
            //console.log(this.courseOptions)
            this.selectedCourse = this.courseOptions[0];
            this.selectCourse();
            this.courseOptions = _.map(this.courseOptions, function (value) {
                return {
                    label: `${value.name}`,
                    value: value,
                };
            });
        });
    }

    selectStudent() {
        this.studentTransaction.studentId = this.selectedStudent.id;
    }

    selectPaymentMethod() {
        this.studentTransaction.paymentMethodCode =
            this.selectedPaymentMethod.code;
        this.paymentMethodReadOnly = this.selectedPaymentMethod.name;
    }

    selectCourse() {
        this.isError = false;

        this.studentTransaction.courseCode = this.selectedCourse.code;

        if (this.schoolProfile.subscriptionType === 'PREPAID' && this.credits[this.selectedCourse.code] == 0) {
            this.isError = true;
        } else if (this.schoolProfile.subscriptionType === 'SUBSCRIPTION' &&
            moment(this.schoolProfile.subscriptionExpiration).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD")) {
            this.isError = true;
        }
    }

    handleFileInput(file) {
        this.fileToUpload = file.item(0);
    }

    paymentConfirmation(record) {
        this.record = { ...record };
        this.submitted = false;
        if(this.validationService.validateStudentPayment(this.record)) {
            this.recordDialog = true;
            this.errForm = false;
        } else {
            this.recordDialog = false;
            this.errForm = true;
        }
    }

    convertDate(date) {
        return moment(new Date(date)).format("YYYY-MM-DD");
    }

    sendTransaction() {
        this.submitted = true;
        //console.log(this.fileToUpload);
        this.studentTransaction.studentId = this.selectedStudent.id
            ? this.selectedStudent.id
            : this.selectedStudent;
        //console.log(this.studentTransaction);
        //this.studentTransaction.paymentDate =  new Date(this.convertDate(this.record.birthDate)).toDateString();

        const formData = new FormData();
      
        if(this.fileToUpload) {
            formData.append("file", this.fileToUpload);
            formData.append("object", JSON.stringify(this.studentTransaction));
    
            this.paymentService
            .studentPaymentCashTransctionWithAttachment(formData)
            .then((response: any) => {
                this.submitted = false;
                this.recordDialog = false;
                this.clearInputs();
                this.messageService.add({
                    severity: "success",
                    summary: "Payment has been successfully submitted.",
                    detail: "",
                    life: 3000,
                });
            })
            .catch((err) => {
                this.submitted = false;
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            });
        } else {
            formData.append("object", JSON.stringify(this.studentTransaction));
    
            this.paymentService
            .studentPaymentCashTransction(formData)
            .then((response: any) => {
                this.submitted = false;
                this.recordDialog = false;
                this.clearInputs();
                this.messageService.add({
                    severity: "success",
                    summary: "Payment has been successfully submitted.",
                    detail: "",
                    life: 3000,
                });
            })
            .catch((err) => {
                this.submitted = false;
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            });
        }

    }

    hideDialog() {
        this.recordDialog = false;
        this.submitted = false;
    }
}
