import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { MessageService } from "primeng/api";
import { User } from "src/app/saferoadsph/interfaces/User";
import { CmsService } from "src/app/saferoadsph/services/cms.service";
import { UsersService } from "src/app/saferoadsph/services/users.service";
import { ValidationService } from "src/app/saferoadsph/services/validation.service";
import _ from "lodash";

@Component({
    selector: "app-students-list",
    templateUrl: "./students-list.component.html",
    styleUrls: ["./students-list.component.scss"],
})
export class StudentsListComponent implements OnInit {
    record: User = null;
    selectedStudent;
    students;
    loading: boolean;

    studentDialog: boolean;
    submitted: boolean;
    submitting = false;
    birthDate=null;
    studentId=0;
    state;

    genderOptions = [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
    ];

    constructor(private userService: UsersService,
        private messageService: MessageService,
        private cmsService: CmsService,
        private validationService: ValidationService) {}

    ngOnInit(): void {
        this.getAllStudents();     
    }

    editRecord (selectedStudent: any){       
        this.studentId = selectedStudent.id  
        this.getStudent(this.studentId);          
        this.state = 'edit';         
    }

    convertDate(date) {
        return moment(new Date(date)).format("YYYY-MM-DD");
    }

    getAllStudents() {
        this.loading = true;
        this.userService.getAllStudents().then((res: any) => {
            //console.log(res.result)
            this.students = res.result;
            this.loading = false;
        });
    }

    getStudent(id: any) {
        this.loading = true;       
        this.userService.getStudentProfile(id).then((res: any) => {
            this.record = res.result[0];
            if (this.record.birthDate) {
                this.birthDate = new Date(this.convertDate(this.record.birthDate));
                this.record.birthDate = this.birthDate;
            }
            this.getAllRegions();
            this.loadAddressOfStudent(this.record);
            //console.log(this.record)
            this.studentDialog = true; 
            this.loading = false;            
        });
    }

    saveRecord() {
        //console.log(this.record);
        this.submitted = true; // para sa validation
        var transformRecord: User = this.record;
        //transformRecord.birthDate = new Date(this.convertDate(this.record.birthDate)).toDateString();
       
        if (
            this.validationService.validateUser(transformRecord) &&
            this.validationService.validateAddress(transformRecord.address)
        ) {
            this.submitting = true; // para sa loading
           
            this.userService
                .editStudentProfileId(transformRecord.id, transformRecord)
                .then((res) => {
                    //console.log(transformRecord);
                    this.submitting = false;
                    this.messageService.add({
                        severity: "success",
                        summary: "Successful",
                        detail: "Student Saved",
                        life: 3000,
                    });
                    this.hideDialog();
                    this.getAllStudents();
                })
                .catch((err) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Error in Saving Student.",
                        life: 3000,
                    });
                    this.submitting = false;
                });
        }
    }

    hideDialog() {
        this.studentDialog = false;
        this.submitted = false;
        this.state = '';       
    }

    regions: any[];
    provinces: any[];
    cities: any[];

    getAllRegions() {
        this.cmsService.getRegions().then((res: any) => {
            if (res.statusCode == 200) {
                this.regions = res.result;
                this.regions.push({ regionName: null });
                this.regions = _.map(this.regions, function (value) {
                    return {
                        label: value.regionName ? value.regionName : "",
                        value: value.regionName,
                    };
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            }
        });
    }

    changeRegion($e) {
        this.cmsService.getProvincesByRegionName($e.value).then((res: any) => {
            if (res.statusCode == 200) {
                this.provinces = res.result;
                this.provinces.push({ stateName: null });
                this.provinces = _.map(this.provinces, function (value) {
                    return {
                        label: value.stateName ? value.stateName : "",
                        value: value.stateName,
                    };
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            }
        });
    }

    changeProvince($e) {
        this.cmsService.getCitiesByProvinceName($e.value).then((res: any) => {
            if (res.statusCode == 200) {
                this.cities = res.result;
                this.cities.push({ cityName: null });
                this.cities = _.map(this.cities, function (value) {
                    return {
                        label: value.cityName ? value.cityName : "",
                        value: value.cityName,
                    };
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            }
        });
    }

    loadAddressOfStudent(record) {
        this.cmsService
            .getProvincesByRegionName(record.address.region)
            .then((res: any) => {
                if (res.statusCode == 200) {
                    this.provinces = res.result;
                    this.provinces = _.map(this.provinces, function (value) {
                        return {
                            label: value.stateName,
                            value: value.stateName,
                        };
                    });
                    this.cmsService
                        .getCitiesByProvinceName(record.address.province)
                        .then((res: any) => {
                            if (res.statusCode == 200) {
                                this.cities = res.result;
                                this.cities = _.map(
                                    this.cities,
                                    function (value) {
                                        return {
                                            label: value.cityName,
                                            value: value.cityName,
                                        };
                                    }
                                );
                                record.address.city = record.address.city;
                            }
                        });
                }
            });
    }
}
