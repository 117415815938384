import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { AppMainComponent } from "./app.main.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppAccessdeniedComponent } from "./pages/app.accessdenied.component";
import { AppLoginComponent } from "./pages/app.login.component";

import { DashboardPageComponent } from "./saferoadsph/pages/dashboard-page/dashboard-page.component";
import { SchoolsListComponent } from "./saferoadsph/pages/schools/schools-list/schools-list.component";
import { AuthGuard } from "./saferoadsph/guards/auth.guard";
import { LoginGuard } from "./saferoadsph/guards/login.guard";
import { StudentsListComponent } from "./saferoadsph/pages/students/students-list/students-list.component";
import { CoursesListComponent } from "./saferoadsph/pages/courses/courses-list/courses-list.component";
import { BillingDsAccountComponent } from "./saferoadsph/pages/billing/billing-ds-account/billing-ds-account.component";
import { BillingDsStudentComponent } from "./saferoadsph/pages/billing/billing-ds-student/billing-ds-student.component";
import { BillingInvoicesComponent } from "./saferoadsph/pages/billing/billing-invoices/billing-invoices.component";
import { StudentsComponent } from "./saferoadsph/schools/students/students.component";
import { StudentsPaymentComponent } from "./saferoadsph/schools/students-payment/students-payment.component";
import { PaymentOptionsComponent } from "./saferoadsph/schools/payment-options/payment-options.component";
import { CoursePaymentMatrixComponent } from "./saferoadsph/schools/course-payment-matrix/course-payment-matrix.component";
import { VerifyStudentPaymentsComponent } from "./saferoadsph/schools/verify-student-payments/verify-student-payments.component";
import { PaymentSubscriptionComponent } from "./saferoadsph/schools/payment-subscription/payment-subscription.component";
import { PaymentPrepaidComponent } from "./saferoadsph/schools/payment-prepaid/payment-prepaid.component";
import { PaymentStatusComponent } from "./saferoadsph/schools/payment-status/payment-status.component";
import { PaymentBillsComponent } from "./saferoadsph/schools/payment-bills/payment-bills.component";
import { DocumentsComponent } from "./saferoadsph/schools/documents/documents.component";
import { DocumentsFormsComponent } from "./saferoadsph/schools/documents-forms/documents-forms.component";
import { PaymentReceiptsComponent } from "./saferoadsph/schools/payment-receipts/payment-receipts.component";
import { AppRegisterComponent } from "./pages/app.register.component";
import { BillingDsReceiptComponent } from "./saferoadsph/pages/billing/billing-ds-receipt/billing-ds-receipt.component";
import { ProfileComponent } from "./saferoadsph/shared/profile/profile.component";
import { ChangePassword } from "./saferoadsph/interfaces/User";
import { ChangePasswordComponent } from "./saferoadsph/shared/change-password/change-password.component";
import { DrivingSchoolComponent } from "./saferoadsph/schools/driving-school/driving-school.component";
import { BillingPaymentMethodComponent } from "./saferoadsph/pages/billing/billing-payment-method/billing-payment-method.component";
import { BillingCourseFeeComponent } from "./saferoadsph/pages/billing/billing-course-fee/billing-course-fee.component";
import { UsersComponent } from "./saferoadsph/schools/users/users.component";
import { QuestionsComponent } from "./saferoadsph/pages/questions/questions.component";
import { BillingPaymentTypeComponent } from "./saferoadsph/pages/billing/billing-payment-type/billing-payment-type.component";
import { CmsComponent } from "./saferoadsph/pages/cms/cms.component";
import { ReportsStudentStatusListComponent } from "./saferoadsph/schools/reports-student-status-list/reports-student-status-list.component";
import { DocumentApprovalComponent } from "./saferoadsph/pages/document-approval/document-approval.component";
import { ReportSchoolListComponent } from "./saferoadsph/pages/reports/report-school-list/report-school-list.component";
import { ReportPaymentListComponent } from "./saferoadsph/pages/reports/report-payment-list/report-payment-list.component";
import { ReportSummaryComponent } from "./saferoadsph/pages/reports/report-summary/report-summary.component";
import { StudentApprovedPaymentsComponent } from "./saferoadsph/schools/student-approved-payments/student-approved-payments.component";
import { StudentNewAccountComponent } from "./saferoadsph/schools/student-new-account/student-new-account.component";
import { StudentNewEnrolledComponent } from "./saferoadsph/schools/student-new-enrolled/student-new-enrolled.component";
import { TutorialPageComponent } from "./saferoadsph/schools/tutorials/tutorial-page/tutorial-page.component";
import { StudentsAdminComponent } from "./saferoadsph/admin/students-admin/students-admin.component";
import { DrivingSchoolsAdminComponent } from "./saferoadsph/admin/driving-schools-admin/driving-schools-admin.component";
import { StudentsRegionComponent } from "./saferoadsph/region/students-region/students-region.component";
import { DrivingSchoolsRegionComponent } from "./saferoadsph/region/driving-schools-region/driving-schools-region.component";
import { StudentsAdminArchivingComponent } from "./saferoadsph/admin/students-admin-archiving/students-admin-archiving.component";

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: "",
                    component: AppMainComponent,
                    canActivate: [AuthGuard],
                    children: [{ path: "", component: DashboardPageComponent }],
                },

                {
                    path: "region",
                    component: AppMainComponent,
                    canActivate: [AuthGuard],
                    children: [
                        
                            { path: "students-region", component: StudentsRegionComponent },
                            { path: "driving-schools-region", component: DrivingSchoolsRegionComponent },     
                            { path: "profile",component: ProfileComponent},       
                            { path: "password",component: ChangePasswordComponent},               
                    ],     
                },

                {
                    path: "school-admin",
                    component: AppMainComponent,
                    canActivate: [AuthGuard],
                    children: [                       
                            { path: "profile",component: ProfileComponent},    
                            { path: "password",component: ChangePasswordComponent},             
                    ],   
                },

                {
                path: "super",
                component: AppMainComponent,
                canActivate: [AuthGuard],
                children: [
                    { path: "", component: DashboardPageComponent },
                    { path: "profile",component: ProfileComponent},
                    { path: "password",component: ChangePasswordComponent},     
                    ],
                },
                
                {
                    path: "admin",
                    component: AppMainComponent,
                    canActivate: [AuthGuard],
                    children: [
                        { path: "", component: DashboardPageComponent },
                        { path: "schools", component: SchoolsListComponent },
                        { path: "cms", component: CmsComponent },

                        { path: "students", component: StudentsListComponent },
                        { path: "courses", component: CoursesListComponent },
                        {
                            path: "questionaire",
                            component: QuestionsComponent,
                        },

                        {
                            path: "billing/ds-account",
                            component: BillingDsAccountComponent,
                        },
                        {
                            path: "billing/ds-student",
                            component: BillingDsStudentComponent,
                        },
                        {
                            path: "billing/invoices",
                            component: BillingInvoicesComponent,
                        },
                        {
                            path: "billing/receipt",
                            component: BillingDsReceiptComponent,
                        },
                        {
                            path: "billing/course-fees",
                            component: BillingCourseFeeComponent,
                        },
                        {
                            path: "billing/payment-method",
                            component: BillingPaymentMethodComponent,
                        },
                        {
                            path: "billing/payment-type",
                            component: BillingPaymentTypeComponent,
                        },
                        {
                            path: "profile",
                            component: ProfileComponent,
                        },
                        {
                            path: "password",
                            component: ChangePasswordComponent,
                        },
                        {
                            path: "document-approval",
                            component: DocumentApprovalComponent,
                        },
                        {
                            path: "reports/student-list",
                            component: ReportSchoolListComponent,
                        },
                        {
                            path: "reports/payment-list",
                            component: ReportPaymentListComponent,
                        },
                        {
                            path: "reports/summary",
                            component: ReportSummaryComponent,
                        },

                        { path: "students-admin", component: StudentsAdminComponent },
                        { path: "students-admin-archiving", component: StudentsAdminArchivingComponent },
                        { path: "driving-schools-admin", component: DrivingSchoolsAdminComponent },
                    
                    ],
                },
                {
                    path: "school",
                    component: AppMainComponent,
                    canActivate: [AuthGuard],
                    children: [
                        { path: "", component: DashboardPageComponent },
                        { path: "students", component: StudentsComponent },
                        { path: "students-new-accounts", component: StudentNewAccountComponent },
                        { path: "students-enrolled", component: StudentNewEnrolledComponent },
                        { path: "users", component: UsersComponent },
                        { 
                            path: "tutorials/tutorial-page", 
                            component: TutorialPageComponent

                        },
                        {
                            path: "payments/students-payment",
                            component: StudentsPaymentComponent,
                        },
                        {
                            path: "payments/students-payment/:id",
                            component: StudentsPaymentComponent,
                        },
                        {
                            path: "payments/course-payment-matrix",
                            component: CoursePaymentMatrixComponent,
                        },
                        {
                            path: "payments/payment-options",
                            component: PaymentOptionsComponent,
                        },
                        {
                            path: "payments/verify-student-payments",
                            component: VerifyStudentPaymentsComponent,
                        },
                        {
                            path: "payments/student-approved-payments",
                            component: StudentApprovedPaymentsComponent,
                        },
                        {
                            path: "payments/bills",
                            component: PaymentBillsComponent,
                        },
                        {
                            path: "payments/receipts",
                            component: PaymentReceiptsComponent,
                        },
                        {
                            path: "reports/student-status-list",
                            component: ReportsStudentStatusListComponent,
                        },
                        {
                            path: "account-plan/subscription",
                            component: PaymentSubscriptionComponent,
                        },
                        {
                            path: "account-plan/prepaid",
                            component: PaymentPrepaidComponent,
                        },
                        {
                            path: "account-plan/status",
                            component: PaymentStatusComponent,
                        },
                        {
                            path: "documents",
                            component: DocumentsComponent,
                        },

                        {
                            path: "documents-forms",
                            component: DocumentsFormsComponent,
                        },
                        {
                            path: "profile",
                            component: ProfileComponent,
                        },
                        {
                            path: "password",
                            component: ChangePasswordComponent,
                        },
                        {
                            path: "driving-school-profile",
                            component: DrivingSchoolComponent,
                        },
                    ],
                },
                {
                    path: "register",
                    component: AppRegisterComponent,
                    canActivate: [LoginGuard],
                },
                { path: "error", component: AppErrorComponent },
                {
                    path: "access",
                    component: AppAccessdeniedComponent,
                },
                { path: "notfound", component: AppNotfoundComponent },
                {
                    path: "login",
                    component: AppLoginComponent,
                    canActivate: [LoginGuard],
                },
                { path: "**", redirectTo: "/notfound" },
            ],
            { scrollPositionRestoration: "enabled" }
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
