import { Component, OnInit } from "@angular/core";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";

@Component({
    selector: "app-module-modal",
    templateUrl: "./module-modal.component.html",
    styleUrls: ["./module-modal.component.scss"],
})
export class ModuleModalComponent implements OnInit {
    module;
    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig
    ) {}

    ngOnInit(): void {
        this.module = this.config.data.module;
        //console.log(this.module);
    }
}
