import { Component, OnInit } from "@angular/core";
import { CmsService } from "../../services/cms.service";
import { File } from "../../interfaces/Document";
import { ConfirmationService, MessageService } from "primeng/api";
import { UsersService } from "../../services/users.service";
import { StorageService } from "../../services/storage.service";
@Component({
    selector: "app-documents",
    templateUrl: "./documents.component.html",
    styleUrls: ["./documents.component.scss"],
    providers: [MessageService, ConfirmationService],
})
export class DocumentsComponent implements OnInit {
    recordDialog: boolean;
    records: File[];
    record: File;
    selectedRecords: File[];
    submitted: boolean;
    statuses: any[];
    state: string = "new";
    dsProfileId;
    fileToUpload;
    documentName

    constructor(
        private cmsService: CmsService,
        private userService: UsersService,
        private storageService: StorageService,
        private messageService: MessageService
    ) {}

    ngOnInit(): void {
        this.loadSchoolDocs();
    }

    loadSchoolDocs() {
        this.storageService.get("dsProfileId").then((response) => {
            this.dsProfileId = response;
            this.userService
                .getSchoolDocs(this.dsProfileId)
                .then((response: any) => {
                    if (response.statusCode == 200) {
                        this.records = response.result;
                        //console.log(this.records)
                    } else {
                        this.records = [];
                    }
                });
        });
    }

    getUploadedDocuments() {
        this.cmsService.getUploadedDocuments().then((res) => {
            this.records = res;
        });
    }

    openNew() {
        this.record = new File();
        this.submitted = false;
        this.recordDialog = true;
        this.state = "new";
    }

    hideDialog() {
        this.recordDialog = false;
        this.submitted = false;
    }

    deleteRecord(record) {
        this.userService
            .deleteDocs(record.id)
            .then((response: any) => {
                this.loadSchoolDocs();
                this.messageService.add({
                    severity: "success",
                    summary: "Delete record was successful.",
                    detail: "",
                    life: 3000,
                });
            })
            .catch((err) => {
                this.submitted = false;
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            });
    }

    handleFileInput(file) {
        this.fileToUpload = file.item(0);
    }

    uploadDocument() {
        const formData = new FormData();
        formData.append("file", this.fileToUpload);
        formData.append("documentName", this.documentName);
        this.submitted = true;
        this.userService
            .uploadSchoolDocs(formData)
            .then((response: any) => {
                this.submitted = false;
                this.recordDialog = false;
                this.clearInputs();
                this.loadSchoolDocs();
                this.messageService.add({
                    severity: "success",
                    summary: "Upload file was successful.",
                    detail: "",
                    life: 3000,
                });
            })
            .catch((err) => {
                this.submitted = false;
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            });
    }

    clearInputs() {
        this.fileToUpload = null;
    }
}
