import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { Address, School, User } from "../../interfaces/User";
import { CmsService } from "../../services/cms.service";
import _ from "lodash";
import { ThisReceiver } from "@angular/compiler";
import { UsersService } from "../../services/users.service";
import * as moment from "moment";

@Component({
    selector: "app-profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.scss"],
    providers: [MessageService],
})
export class ProfileComponent implements OnInit {
    submitted: Boolean = false;
    registering: Boolean = false;
    user: User = new User();
    school: School = new School();
    address: Address = new Address();
    errors: any[] = [];
    updating = false;

    regions;
    provinces;
    cities;
    genderOption = [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
    ];

    constructor(
        private cmsService: CmsService,
        private messageService: MessageService,
        private userService: UsersService
    ) {}

    ngOnInit(): void {
        this.getAllRegions();
        this.loadMyProfile();
    }

    convertDate(date) {
        return moment(new Date(date)).format("YYYY-MM-DD");
    }

    loadMyProfile() {
        this.user = null;
        this.userService
            .getProfile()
            .then((response: any) => {
                //console.log(response.result[0]);
                this.user = response.result[0];
                //this.user.birthDate =  new Date(this.convertDate(this.user.birthDate)).toDateString();
                //console.log(this.user);
                this.loadAddressOfProfile(this.user);
                this.address = this.user.address;
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            });
    }

    updateProfile() {
        this.updating = true;
        this.user.address = this.address;
        this.userService
            .updateProfile(this.user)
            .then((res) => {
                this.messageService.add({
                    severity: "success",
                    summary: "Success",
                    detail: "Profile has been updated",
                    life: 3000,
                });
                this.updating = false;
                this.loadMyProfile();
            })
            .catch((err) => {
                this.updating = false;
            });
    }

    getAllRegions() {
        this.cmsService.getRegions().then((res: any) => {
            if (res.statusCode == 200) {
                this.regions = res.result;
                this.regions.push({ regionName: null });
                this.regions = _.map(this.regions, function (value) {
                    return {
                        label: value.regionName ? value.regionName : "",
                        value: value.regionName,
                    };
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            }
        });
    }

    changeRegion($e) {
        this.cmsService.getProvincesByRegionName($e.value).then((res: any) => {
            if (res.statusCode == 200) {
                this.provinces = res.result;
                this.provinces.push({ stateName: null });
                this.provinces = _.map(this.provinces, function (value) {
                    return {
                        label: value.stateName ? value.stateName : "",
                        value: value.stateName,
                    };
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            }
        });
    }

    changeProvince($e) {
        this.cmsService.getCitiesByProvinceName($e.value).then((res: any) => {
            if (res.statusCode == 200) {
                this.cities = res.result;
                this.cities.push({ cityName: null });
                this.cities = _.map(this.cities, function (value) {
                    return {
                        label: value.cityName ? value.cityName : "",
                        value: value.cityName,
                    };
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Loading Data Please Contact Administrator",
                    life: 3000,
                });
            }
        });
    }

    loadAddressOfProfile(record) {
        this.cmsService
            .getProvincesByRegionName(record.address.region)
            .then((res: any) => {
                if (res.statusCode == 200) {
                    this.provinces = res.result;
                    this.provinces = _.map(this.provinces, function (value) {
                        return {
                            label: value.stateName,
                            value: value.stateName,
                        };
                    });
                    this.cmsService
                        .getCitiesByProvinceName(record.address.province)
                        .then((res: any) => {
                            if (res.statusCode == 200) {
                                this.cities = res.result;
                                this.cities = _.map(
                                    this.cities,
                                    function (value) {
                                        return {
                                            label: value.cityName,
                                            value: value.cityName,
                                        };
                                    }
                                );
                                record.address.city = record.address.city;
                            }
                        });
                }
            });
    }
}
