import { Component, OnInit } from '@angular/core';
import { dateParam, StudentStatusReport } from "../../interfaces/Report";
import { ReportsService } from "../../services/reports.service";
import { StorageService } from '../../services/storage.service';
import _ from "lodash";
import * as FileSaver from "file-saver";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Address, TransferStudentAccount, User, UserArchived, UserDelete, UserEmail, UserName } from "../../interfaces/User";
import { WebcamImage } from "ngx-webcam";
import { UsersService } from "../../services/users.service";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { CmsService } from "../../services/cms.service";
import { ValidationService } from "../../services/validation.service";
import { Router } from "@angular/router";
import { CourseService } from "../../services/course.service";
import { PaymentsService } from "../../services/payments.service";
import * as moment from "moment";
import jsPDF from "jspdf";
import { environment } from "src/environments/environment";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-students-admin-archiving',
  templateUrl: './students-admin-archiving.component.html',
  styleUrls: ['./students-admin-archiving.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class StudentsAdminArchivingComponent implements OnInit {
  pdfObj;
  fileDownloaded;
  profileImg;
  dsProfileImg;
  headerBG;

  checkImg;
  certificate;
  studentCert;
  courses;
  studentCourse;
  studentCourseDetails;
  studentCourseId;
  studentCourseName;
  studentCourseDescription;
  //issueCert : IssueCert;
  studentProfile: any;
  driverLicense = "";
  driverLicenseLabel;
  birthDate;
  gender;
  dsCompanyName;
  dsAddress;
  dsAccreditationNo;
  dsContacts;
  studentFullName;


  loading: boolean = true;
  recordDialog: boolean;
  updateRecordDialog: boolean;
  updateStudentNameRecordDialog: boolean;
  paymentDialog: boolean;
  passwordDialog: boolean;
  pictureDialog: boolean;
  records: User[];
  record: User;
  updateRecord: User;
  selectedRecords: User[];
  submitted: boolean;
  statuses: any[];
  state: string = "new";
  webcamImage: WebcamImage = null;
  noPrivate: any;
  invalidEmail = false;

  cols: any[];
  exportColumns: any[];
  selectedGender;
  selectedUser;
  isDisabled = false;
  submitting = false;
  existingEmail = false;
  resetting = false;
  imageSrc;
  imageFile;
  uploadingPhoto = false;
  accountValidated;

  genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  userTypeOptions = [
    { label: "Student Permit", value: "STUDENT" },
    { label: "Non Professional", value: "NON_PRO" },
    { label: "Professional", value: "PRO" },
  ];

  paramFieldOptions =[
    { label: "APPROVED DATE", value: "validationDate" },
    { label: "PAYMENT DATE", value: "paymentDate" },
    { label: "CREATED DATE", value: "createdDate" },
    { label: "NEW STUDENT ACCOUNTS", value: "new" },
  ];

  selectedParams;

  dateFrom;
  dateTo;
  currentDate;
  paramDates: dateParam;

  paymentDetails;
  paymentStatus;
  validationStatus;
  paymentCourseName;
  show = false;
  paramSelection;

  constructor(private userService: UsersService,
      private messageService: MessageService,
      private confirmationService: ConfirmationService,
      private cmsService: CmsService,
      private validationService: ValidationService,
      private router: Router,
      private http: HttpClient,
      private courseService: CourseService,
      private storageService: StorageService,
      private paymentService: PaymentsService) { }

     
      ngOnInit(): void {

        this.getAllRegions();
  
        this.currentDate = new Date();
        this.dateFrom = new Date(new Date().setDate(new Date().getDate()-30));
        this.dateTo = new Date(new Date().setDate(new Date().getDate()));
        this.selectedParams = "validationDate";
        const dateParams = {dateFrom:this.convertDate(this.dateFrom),dateTo:this.convertDate(this.dateTo)}
        this.paramSelection = "APPROVED DATE ("+this.convertDate(this.dateFrom)+" to "+this.convertDate(this.dateTo)+")"
        this.getAllStudentsApprovedDate(dateParams);
      }    
  
      getLast7Days(){
        this.currentDate = new Date();
        this.dateFrom = new Date(new Date().setDate(new Date().getDate()-7));
        this.dateTo = new Date(new Date().setDate(new Date().getDate()));
        //console.log( this.dateFrom);
        //console.log( this.dateTo);
        //console.log(this.selectedParams);
        const dateParams = {dateFrom:this.convertDate(this.dateFrom),dateTo:this.convertDate(this.dateTo)}
  
        if (this.selectedParams=="validationDate"){
          this.paramSelection = "APPROVED DATE ("+this.convertDate(this.dateFrom)+" to "+this.convertDate(this.dateTo)+")"
          this.getAllStudentsApprovedDate(dateParams);
        }
        else if (this.selectedParams=="createdDate"){
          this.paramSelection = "CREATED DATE ("+this.convertDate(this.dateFrom)+" to "+this.convertDate(this.dateTo)+")"
          this.getAllStudentsCreatedDate(dateParams);
        }
        else if(this.selectedParams=="paymentDate"){
          this.paramSelection = "PAYMENT DATE ("+this.convertDate(this.dateFrom)+" to "+this.convertDate(this.dateTo)+")"
          this.getAllStudentsPaymentDate(dateParams);
        }
        else if(this.selectedParams=="new"){
          this.paramSelection = "NEW STUDENT ACCOUNTS"
          this.getAllStudentsNewAccountAdmin()
        }
        else{
          this.paramSelection = "ALL"
          this.getAllStudents();
        } 
  
      }
  
      getLast30Days(){
        this.currentDate = new Date();
        this.dateFrom = new Date(new Date().setDate(new Date().getDate()-30));
        this.dateTo = new Date(new Date().setDate(new Date().getDate()));
        //console.log( this.dateFrom);
        //console.log( this.dateTo);
        //console.log(this.selectedParams);
        const dateParams = {dateFrom:this.convertDate(this.dateFrom),dateTo:this.convertDate(this.dateTo)}
  
        if (this.selectedParams=="validationDate"){
          this.paramSelection = "APPROVED DATE ("+this.convertDate(this.dateFrom)+" to "+this.convertDate(this.dateTo)+")"
          this.getAllStudentsApprovedDate(dateParams);
        }
        else if (this.selectedParams=="createdDate"){
          this.paramSelection = "CREATED DATE ("+this.convertDate(this.dateFrom)+" to "+this.convertDate(this.dateTo)+")"
          this.getAllStudentsCreatedDate(dateParams);
        }
        else if(this.selectedParams=="paymentDate"){
          this.paramSelection = "PAYMENT DATE ("+this.convertDate(this.dateFrom)+" to "+this.convertDate(this.dateTo)+")"
          this.getAllStudentsPaymentDate(dateParams);
        }
        else if(this.selectedParams=="new"){
          this.paramSelection = "NEW STUDENT ACCOUNTS"
          this.getAllStudentsNewAccountAdmin()
        }
        else{
          this.paramSelection = "ALL"
          this.getAllStudents();
        }      
      }
  
      getAll(){
        this.paramSelection = "ALL"
        this.getAllStudents();
      }

      showDateParam: boolean = true;
      onchangeParam(){
       if (this.selectedParams=="new") {
        this.showDateParam = false;
       }
       else {
        this.showDateParam = true;
       }
      }
  
      viewReport(){
        //console.log( this.dateFrom);
        //console.log( this.dateTo);
        //console.log(this.selectedParams);
        const dateParams = {dateFrom:this.convertDate(this.dateFrom),dateTo:this.convertDate(this.dateTo)}
  
        if (this.selectedParams=="validationDate"){
          this.paramSelection = "APPROVED DATE ("+this.convertDate(this.dateFrom)+" to "+this.convertDate(this.dateTo)+")"
          this.getAllStudentsApprovedDate(dateParams);
        }
        else if (this.selectedParams=="createdDate"){
          this.paramSelection = "CREATED DATE ("+this.convertDate(this.dateFrom)+" to "+this.convertDate(this.dateTo)+")"
          this.getAllStudentsCreatedDate(dateParams);
        }
        else if(this.selectedParams=="paymentDate"){
          this.paramSelection = "PAYMENT DATE ("+this.convertDate(this.dateFrom)+" to "+this.convertDate(this.dateTo)+")"
          this.getAllStudentsPaymentDate(dateParams);
        }
        else if(this.selectedParams=="new"){
          this.paramSelection = "NEW STUDENT ACCOUNTS"
          this.getAllStudentsNewAccountAdmin()
        }
        else{
          this.paramSelection = "ALL"
          this.getAllStudents();
        } 
      }
    
      loadAccountValidated() {
        this.storageService.get('accountValidated').then((response: any) => {
          this.accountValidated = response;
        })
      }
    
      getAllStudents() {
        this.loading = true
        this.userService.getAllStudentsByDrivingSchoolAdmin().then((res: any) => {
          if (res.statusCode == 200) {
            this.records = res.result;
            this.records = this.records.filter((res: any) => res.archived == null);
            this.records = this.records.filter((res: any) => res.durationDays >= 30);
            this.records = this.records.filter((res: any) => res.paymentStatus =='APPROVED');
            //console.log(this.records);
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Data Please Contact Administrator",
              life: 3000,
            });
          }       
        })
        .catch((err) => {
          this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Student List.",
              life: 3000,
          });
        });
        this.loading = false;
      }

      getAllStudentsNewAccountAdmin() {
        this.loading = true
        this.userService.getAllStudentsNewAccountsByAdmin().then((res: any) => {
          if (res.statusCode == 200) {
            this.records = res.result;
            this.records = this.records.filter((res: any) => res.archived == null);
            this.records = this.records.filter((res: any) => res.durationDays >= 30);
            this.records = this.records.filter((res: any) => res.paymentStatus =='APPROVED');
            //console.log(this.records);
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Data Please Contact Administrator",
              life: 3000,
            });
          }       
        })
        .catch((err) => {
          this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Student List.",
              life: 3000,
          });
        });
        this.loading = false;
      }
  
      getAllStudentsCreatedDate(dateParams) {
        this.loading = true
        this.userService.getAllStudentsByDrivingSchoolAdminCreatedDate(dateParams).then((res: any) => {
          if (res.statusCode == 200) {
            this.records = res.result;
            this.records = this.records.filter((res: any) => res.archived == null);
            this.records = this.records.filter((res: any) => res.durationDays >= 30);
            this.records = this.records.filter((res: any) => res.paymentStatus =='APPROVED');
            //console.log(this.records);
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Data Please Contact Administrator",
              life: 3000,
            });
          }       
        })      
        .catch((err) => {
          this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Student List.",
              life: 3000,
          });
        });
        this.loading = false;
      }
  
      
      getAllStudentsApprovedDate(dateParams) {
        this.loading = true
        this.userService.getAllStudentsByDrivingSchoolAdminApprovedDate(dateParams).then((res: any) => {
          if (res.statusCode == 200) {
            this.records = res.result;
            this.records = this.records.filter((res: any) => res.archived == null);
            this.records = this.records.filter((res: any) => res.durationDays >= 30);
            this.records = this.records.filter((res: any) => res.paymentStatus =='APPROVED');
            //console.log(this.records);
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Data Please Contact Administrator",
              life: 3000,
            });
          }       
        })      
        .catch((err) => {
          this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Student List.",
              life: 3000,
          });
        });
        this.loading = false;
      }
  
      getAllStudentsPaymentDate(dateParams) {
        this.loading = true
        this.userService.getAllStudentsByDrivingSchoolAdminPaymentDate(dateParams).then((res: any) => {
          if (res.statusCode == 200) {
            this.records = res.result;
            this.records = this.records.filter((res: any) => res.archived == null);
            this.records = this.records.filter((res: any) => res.durationDays >= 30);
            this.records = this.records.filter((res: any) => res.paymentStatus =='APPROVED');
            //console.log(this.records);
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Data Please Contact Administrator",
              life: 3000,
            });
          }       
        })      
        .catch((err) => {
          this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Student List.",
              life: 3000,
          });
        });
        this.loading = false;
      }

      paymentRecord: any;
      getStudentPaymentTransactionsAdmin(id) {
        this.loading = true
        this.paymentService.getStudentPaymentTransactionsAdmin(id).then((res: any) => {
          if (res.statusCode == 200) {
            this.paymentRecord = res.result[0];
            //console.log(this.paymentRecord);
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Data Please Contact Administrator",
              life: 3000,
            });
          }       
        })      
        .catch((err) => {
          this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Student Payment.",
              life: 3000,
          });
        });
        this.loading = false;
      }
  
      courseProgessRecords;
      courseName;
      moduleName;
      getStudentsProgess(studentId) {
        this.courseService.getStudentCourseDetailsProgess(studentId).then((res: any) => {
          if (res.statusCode == 200) {
            this.courseProgessRecords = res.result;
  
            this.courseName = _.groupBy(res.result[0], "courseName");
            this.courseName = this.generateTable(this.courseName,"courseName");
  
            this.moduleName = _.groupBy(res.result[0], "moduleCode");
            this.moduleName = this.generateTable(this.moduleName,"moduleCode");   
  
            //console.log(this.courseProgessRecords);
            
            //console.log(this.courseName);
            //console.log(this.moduleName);          
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Data Please Contact Administrator",
              life: 3000,
            });
          }
          this.loading = false;
        });
      }
  
      clear(){
        this.studentQuizzes=null;
        this.showQuiz = false;
      }
      
      getTopicName(topic){      
        return topic.topicCode +" - "+ topic.topicDescription + " - " + topic.status;     
      }
      studentQuizzes;
      showQuiz: boolean;
      getStudentQuizzesDetails (profileId,courseId, topicId){
        this.courseService.getStudentQuizzes(profileId,courseId, topicId).then((res: any) => {
          if (res.statusCode == 200) {          
            this.studentQuizzes = res.result[0];
            //console.log(this.studentQuizzes);
             if (this.studentQuizzes.length>0){
              this.showQuiz = true;
            }
          }
          else{
            this.showQuiz = false;
          }
  
        });
      }
  
      generateTable(obj,fieldName) {
        var data: any[] = [];
        Object.entries(obj).forEach(([key, value]) => {
         data.push( {
            name : key,
            data : value
         });
        });   
        return _.orderBy(data, ["id"], ["asc"]);
      }
    
      convertDate(date) {
        return moment(new Date(date)).format("YYYY-MM-DD");
    }
    
      openNew() {
        this.submitted = false;
        this.recordDialog = true;
        this.state = "new";
        this.isDisabled = false;
        this.record = {};
        this.record.address = {};
    
        // Address
        this.provinces = [];
        this.cities = [];
        this.record.address.province = null;
        this.record.address.city = null;
    
        // Initial Value
        this.record.gender = "Male";
        this.record.userType = "STUDENT";
      }
    
      editRecord(record: any) {
        //this.updateRecord = null;
        this.updateRecord = record;
        this.updateRecord.birthDate = null;
        this.userService.getStudentProfile(record.id).then((res: any) => {
          res.result[0].birthDate = new Date(this.convertDate(res.result[0].birthDate));
          this.updateRecord = res.result[0];
          this.updateRecord.address = this.setAddress(res.result[0].address);
        })
        .catch((err) => {
          this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Student.",
              life: 3000,
          });
          this.submitted = false;
      });
        this.editloadAddressOfStudent(this.updateRecord);
        this.updateRecordDialog = true;
        this.state = "edit";
        this.submitted = false;
        this.isDisabled = true;
    }
    
    payment(record: any) {
        this.paymentService.getStudentPaymentTransactions(record.id).then((pay: any) => {
            this.paymentDetails = pay.result[0];
            if ( pay.result[0] =="No transaction found for this student"){                
                this.router.navigate([
                    "/school/payments/students-payment/" + record.id,
                ]);
                }
            else {
                this.paymentDialog = true;
                this.paymentStatus = this.paymentDetails.paymentStatus;
                this.validationStatus= this.paymentDetails.validationStatus;
                this.paymentCourseName = this.paymentDetails.courseName;
            }
        });
    }
    
    validateEMail(email) {
        return this.validationService.validateEmail(email);
    }
    
    validatePassword(record) {
        return record.password == record.confirmPassword;
    }
    
    validateAddress(address) {
      if (address.block == null || address.block == "") {
          return false;
      } else if (address.street == null || address.street == "") {
          return false;
      } else if (address.barangay == null || address.barangay == "") {
          return false;
      } else if (address.region == null || address.region == "") {
          return false;
      } else if (address.province == null || address.province == "") {
          return false;
      } else if (address.city == null || address.city == "") {
          return false;
      } else if (address.postalCode == null || address.postalCode == "") {
          return false;
      } else {
          return true;
      }
    }
    
    setAddress(recAdd) {
      let add = new Address();
      add.block =  recAdd.block;
      add.street =  recAdd.street;
      add.barangay =  recAdd.barangay;
      add.region =  recAdd.region;
      add.province =  recAdd.province;
      add.city =  recAdd.city;
      add.postalCode =  recAdd.postalCode;
      return add;
    }
    
    addRecord() {
        this.submitted = true; // para sa validation
        this.record.address = this.setAddress(this.record.address);
        var transformRecord: User = this.record; // transformation kung meron customized value
        //transformRecord.birthDate = new Date(this.convertDate(this.record.birthDate)).toDateString();
        //console.log(transformRecord);
        if (
            this.validationService.validateUser(transformRecord) &&
            this.validationService.validateAddress(transformRecord.address) &&
            this.validatePassword(transformRecord) &&
            this.validateEMail(transformRecord.email)
        ) {
            this.submitting = true; // para sa loading
            this.userService
                .addStudent(transformRecord)
                .then((res: any) => {
                    if (res.statusCode == 409) {
                        // email is already exists
                        this.existingEmail = true;
                        this.submitting = false;
                    }
    
                    if (res.statusCode == 200) {
                        this.messageService.add({
                            severity: "success",
                            summary: "Successful",
                            detail: "Student Saved",
                            life: 3000,
                        });
                        this.hideDialog();
                        this.getAllStudents();
                        this.submitting = false;
                    }
                })
                .catch((err) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Error in Saving Student.",
                        life: 3000,
                    });
                    this.submitting = false;
                });
        } else {
            this.submitting = false;
        }
    }
    
    saveRecord() {
        //console.log(this.record);
        this.submitted = true; // para sa validation
        this.updateRecord.address = this.setAddress(this.updateRecord.address);
        var transformRecord: User = this.updateRecord;
        transformRecord.birthDate = moment(new Date(this.convertDate(this.updateRecord.birthDate))).format("YYYY-MM-DD"); 
        //console.log(transformRecord);
        if (
            this.validationService.validateUser(transformRecord) &&
            this.validationService.validateAddress(transformRecord.address)      
        ) {
            this.submitting = true; // para sa loading
            this.userService
                .editStudentProfile(transformRecord.id, transformRecord)
                .then((res) => {
                    this.submitting = false;
                    this.messageService.add({
                        severity: "success",
                        summary: "Successful",
                        detail: "Student Saved",
                        life: 3000,
                    });
                    this.updateRecord.birthDate=  null; 
                    this.hideDialog();
                    this.getAllStudents();
                })
                .catch((err) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Error in Saving Student.",
                        life: 3000,
                    });
                    this.submitting = false;
                });
        }
    }
    
    resetPassword(record) {
        this.resetting = true;
        this.userService
            .resetpasswordByDrivingSchool({ email: record.email })
            .then((res) => {
                this.messageService.add({
                    severity: "success",
                    summary: "Password has been succesfully Reset",
                    detail: "New Password has been sent to : " + record.email,
                    life: 5000,
                });
                this.resetting = false;
            })
            .catch((err) => {
                this.resetting = false;
            });
    }
    
    editPassword(record: any) {
        this.record = { ...record };
        this.passwordDialog = true;
        this.state = "password";
    }
    uploadPhoto(record: any) {
        this.record = { ...record };
        this.pictureDialog = true;
        this.state = "photo";
        this.noPrivate = null;
        this.webcamImage = null;
        this.imageSrc = null;
    }
    
    submitPhoto() {
        var img;
        this.uploadingPhoto = true;
        if (this.noPrivate != null) {
            const imageName = moment(new Date()).format("YYYYMMDDHms") + ".png";
            const imageBlob = this.dataURItoBlob(this.noPrivate.imageAsBase64);
            const imageFile = new File([imageBlob], imageName, {
                type: "image/png",
            });
            img = imageFile;
        } else {
            img = this.imageFile[0];
        }
    
        const formData = new FormData();
        formData.append("studentUserId", this.record.id.toString());
        formData.append("file", img);
    
        this.userService
            .uploadStudentPhoto(formData)
            .toPromise()
            .then((res) => {
                this.getAllStudents();
                this.uploadingPhoto = false;
                this.messageService.add({
                    severity: "success",
                    summary: "Successful",
                    detail: "Photo has been Uploaded.",
                    life: 3000,
                });
                this.pictureDialog = false;
            })
            .catch((err) => {
                this.uploadingPhoto = false;
            });
    }
    
    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: "image/png" });
        return blob;
    }
    
    onFileChange(event) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            this.imageFile = event.target.files;
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.imageSrc = reader.result as string;
                this.webcamImage = null;
                this.noPrivate = null;
            };
        }
    }
    
    updateStudentName(record: any){
      this.confirmationService.confirm({
        message: "Do you want to update this student name? ( " + record.fullName + " )",
        header: "Confirm",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
    
          this.submitted = true; 
          var transformRecord: UserName = record;
          transformRecord.studentId = record.id;
          transformRecord.email = record.email;
          transformRecord.lastName =record.lastName;
          transformRecord.firstName = record.firstName;
          transformRecord.middleName = record.middleName;
          transformRecord.extensionName = record.extensionName;
          this.submitting = true; 
          this.userService
            .editStudentName(transformRecord)
            .then((res) => {
              this.submitting = false;
              this.messageService.add({
                severity: "success",
                summary: "Successful",
                detail: "Student Saved",
                life: 3000,
              });
              this.hideDialog();
              this.getAllStudents();
            });
        },
    });
    }
    
    saveStudentName(record: any) {
      this.updateRecord = record;
      this.updateRecord.birthDate = null;
      this.userService.getStudentProfile(record.id).then((res: any) => {
        res.result[0].birthDate = new Date(this.convertDate(res.result[0].birthDate));
        this.updateRecord = res.result[0];
        this.updateRecord.address = this.setAddress(res.result[0].address);
      })
      .catch((err) => {
        this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Error in Loading Student.",
            life: 3000,
        });
        this.submitted = false;
    });
      this.editloadAddressOfStudent(this.updateRecord);
      this.updateStudentNameRecordDialog = true;
      this.state = "edit";
      this.submitted = false;
      this.isDisabled = true;
    }
    
    deleteRecordDialog : boolean;
    deletedRecord;
    deletingRecord(record: any) {
      this.deletedRecord = record;
      this.deleteRecordDialog = true;
      this.state = "delete";
      this.submitted = false;
      this.isDisabled = true;
    }
    
    deleteRecord(record: any) {
        this.confirmationService.confirm({
            message: "Are you sure you want to delete this student record? "+
            "( Id No.: " + record.id + " | Student Name: " + record.fullName + " | Email Account: " + record.email + " )."+
            " Please take note this action is irreversible.",
            header: "Confirm",
            icon: "pi pi-exclamation-triangle",
            accept: () => {         
                  this.records = this.records.filter(
                    (val) => val.id !== record.id
                );
                this.record = {};
                this.submitted = true; 
                var rec: UserDelete = record;
                rec.studentId = record.id;
                this.submitting = true; 
                this.userService
                  .deleteStudentAccount(rec)
                  .then((res) => {
                    this.submitting = false;
                    this.messageService.add({
                        severity: "success",
                        summary: "Successful",
                        detail: "Student Deleted",
                        life: 3000,
                    }); 
                    this.hideDialog();
                    this.getAllStudents();
                  });      
            },
        });
    }
    
    changeEmailDialog : boolean;
    oldEmail;
    changingEmail(record: any) {
      this.oldEmail = record.email;
      this.updateRecord = record;
      this.changeEmailDialog = true;
      this.state = "edit";
      this.submitted = false;
      this.isDisabled = true;
    }
    
    changeEmail(record: any) {
      this.confirmationService.confirm({
        message: "Are you sure you want to change the email account of this student? "+
        "( Id No.: " + record.id + " | Student Name: " + record.fullName + " | Old Email Account: " + this.oldEmail + " to New Email Account "+record.email +").",
        header: "Confirm",
        icon: "pi pi-exclamation-triangle",
        accept: () => {         
                this.submitted = true; 
                var rec: UserEmail = record;
                rec.studentId = record.id;
                rec.oldEmail = this.oldEmail;
                rec.newEmail = record.email;
                this.submitting = true; 
                this.userService
                  .editStudentEmail(rec)
                  .then((res) => {
                    this.submitting = false;
                    this.messageService.add({
                        severity: "success",
                        summary: "Successful",
                        detail: "Student Email Account Changed",
                        life: 3000,
                    }); 
                    this.hideDialog();
                    this.getAllStudents();
                  });       
        },
    });
    }
    
    downloadDialog : boolean;
    downloadingCertificate(record: any) {
      this.record = record;
      this.downloadDialog = true;
      this.submitted = false;
      this.isDisabled = true;
      
    }
    
    downloadCertificate(record: any) {
      this.fileDownloaded = false;
      this.record = record;
      this.loadCertificate(record.courseId, record.id);
      this.submitted = true;
      this.isDisabled = false;
    }
    
    progressDialog : boolean;
    showingProgress(record: any) {
      this.record = record;
      this.getStudentQuizzesDetails (record.id,1, 2);
      this.getStudentsProgess(record.id);
      this.progressDialog = true;
    }

    viewStudentInfoDialog : boolean;
    viewStudentInfo(record: any){
      this.viewStudentInfoDialog = true;
      this.record = record;
      this.record.birthDate = this.convertDate(this.record.birthDate);
      //console.log(this.record);
      this.getStudentPaymentTransactionsAdmin(this.record.id);
      //console.log(this.paymentRecord);
    }

    confirm2(event: Event) {
      this.confirmationService.confirm({
          key: 'confirm2',
          target: event.target,
          message: 'This process will be slow as you are loading all the records. Are you sure that you want to proceed?',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.getAll();
          },
          reject: () => {
              //this.messageService.add({severity: 'error', summary: 'Rejected', detail: 'You have rejected'});
          }
      });
  }

  dsBranchOptions:any[];
  selectedDSProfile:any[];

  transferStudent(record){
    this.studentTransferDialog = true;
    this.record = record;
    this.getAllDsBranch(record.ds_custom_id);
    //console.log(this.record);
  }

  multiBranchDS:boolean;
  getAllDsBranch(customId) {
    this.userService.getAllDrivingSchoolByCustomId(customId).then((res: any) => {
      if (res.statusCode == 200) {
        this.dsBranchOptions = res.result;
        if (this.dsBranchOptions.length > 1){
          this.multiBranchDS =true;
        }
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
    });
  }
  studentTransferDialog: boolean;
  transferSelectedDSDialog:boolean;
  transferDsRecord:any;
  transferStudentRecord:any;
  transferBranch(branch, transferStudentRecord){
    this.transferSelectedDSDialog = true;
    //console.log(branch);
    this.transferDsRecord = branch;
    this.transferStudentRecord = transferStudentRecord;
  }

  transferStudentAccount: TransferStudentAccount;

  confirmTransfer() {
    this.transferStudentAccount = {
      email: this.transferStudentRecord.email,
      dsProfileId: this.transferDsRecord.id
    };
    this.transferSelectedDSDialog = false;
    this.studentTransferDialog = false;
    this.userService.transferStudentAccount(this.transferStudentAccount).then((res: any) => {
      if (res.statusCode == 200) {
        this.getAllStudents();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Sucessfull transfer of student account to this branch : " + this.transferDsRecord.companyName,
          life: 3000,
        });
      }
      else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
    });
  }

  cancelTransfer(){
    this.transferSelectedDSDialog = false;
  }
    
    
      hideDialog() {
        this.recordDialog = false;
        this.updateRecordDialog = false;
        this.updateStudentNameRecordDialog = false;
        this.submitted = false;
        this.passwordDialog =false;
        this.deleteRecordDialog =false;
        this.downloadDialog =false;
        this.progressDialog = false;
        this.changeEmailDialog = false;
        this.viewStudentInfoDialog =false;
        this.studentTransferDialog=false;
      }
     
      exportExcel() {
        import("xlsx").then((xlsx) => {
          const worksheet = xlsx.utils.json_to_sheet(this.records);
          const workbook = {
            Sheets: { data: worksheet },
            SheetNames: ["data"],
          };
          const excelBuffer: any = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          this.saveAsExcelFile(excelBuffer, "students");
        });
      }
    
      saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE,
        });
        FileSaver.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    
      // latest snapshot
    
      handleImage(webcamImage: WebcamImage) {
        this.webcamImage = webcamImage;
        this.noPrivate = webcamImage;
      }
      // Get Address Settings
      retake() {
        this.webcamImage = null;
        this.noPrivate = null;
      }
      regions: any[];
      provinces: any[];
      cities: any[];
    
      getAllRegions() {
        this.cmsService.getRegions().then((res: any) => {
          if (res.statusCode == 200) {
            this.regions = res.result;
            this.regions.push({ regionName: null });
            this.regions = _.map(this.regions, function (value) {
              return {
                label: value.regionName ? value.regionName : "",
                value: value.regionName,
              };
            });
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Data Please Contact Administrator",
              life: 3000,
            });
          }
        });
      }
    
      changeRegion($e) {
        this.cmsService.getProvincesByRegionName($e.value).then((res: any) => {
          if (res.statusCode == 200) {
            this.provinces = res.result;
            this.provinces.push({ stateName: null });
            this.provinces = _.map(this.provinces, function (value) {
              return {
                label: value.stateName ? value.stateName : "",
                value: value.stateName,
              };
            }); 
            this.record.address.province = null;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Data Please Contact Administrator",
              life: 3000,
            });
          }
        });
      }
    
      changeProvince($e) {
        this.cmsService.getCitiesByProvinceName($e.value).then((res: any) => {
          if (res.statusCode == 200) {
            this.cities = res.result;
            this.cities.push({ cityName: null });
            this.cities = _.map(this.cities, function (value) {
              return {
                label: value.cityName ? value.cityName : "",
                value: value.cityName,
              };
            });
            this.record.address.city = null;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Error in Loading Data Please Contact Administrator",
              life: 3000,
            });
          }
        });
      }
    
      loadAddressOfStudent(record) {
        this.cmsService
          .getProvincesByRegionName(record.region)
          .then((res: any) => {
            if (res.statusCode == 200) {
              this.provinces = res.result;
              this.provinces = _.map(this.provinces, function (value) {
                return {
                  label: value.stateName,
                  value: value.stateName,
                };
              });
              this.cmsService
                .getCitiesByProvinceName(record.province)
                .then((res: any) => {
                  if (res.statusCode == 200) {
                    this.cities = res.result;
                    this.cities = _.map(
                      this.cities,
                      function (value) {
                        return {
                          label: value.cityName,
                          value: value.cityName,
                        };
                      }
                    );
                    record.city = record.city;
                  }
                });
            }
          });
      }
    
      editloadAddressOfStudent(record) {
        this.cmsService
          .getProvincesByRegionName(record.region)
          .then((res: any) => {
            if (res.statusCode == 200) {
              this.provinces = res.result;
              this.provinces = _.map(this.provinces, function (value) {
                return {
                  label: value.stateName,
                  value: value.stateName,
                };
              });
              this.cmsService
                .getCitiesByProvinceName(record.province)
                .then((res: any) => {
                  if (res.statusCode == 200) {
                    this.cities = res.result;
                    this.cities = _.map(
                      this.cities,
                      function (value) {
                        return {
                          label: value.cityName,
                          value: value.cityName,
                        };
                      }
                    );
                    record.city = record.city;
                  }
                });
            }
          });
      }
    
      showPass() {
        if(!this.show) {
          this.show = true
        }else {
          this.show = false;
        }
      }
  
      getBlobForHeaderBG() {
        this.http
          .get('assets/img/cert/header.png', {
            responseType: 'blob',
          })
          .subscribe((res) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              this.headerBG = reader.result;
            };
            reader.readAsDataURL(res);
          });
      }
    
      getBlobForNoPhoto() {
        this.http
          .get('assets/img/cert/nophoto.jpg', {
            responseType: 'blob',
          })
          .subscribe((res) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              this.profileImg = reader.result;
            };
            reader.readAsDataURL(res);
          });
      }
    
      getBlobForDSNoPhoto() {
        this.http
          .get('assets/img/cert/nophoto.jpg', {
            responseType: 'blob',
          })
          .subscribe((res) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              this.dsProfileImg = reader.result;
            };
            reader.readAsDataURL(res);
          });
      }
    
      getProfileImage(profileImg) {
        fetch(profileImg)
          .then((res) => res.blob())
          .then((res) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              this.profileImg = reader.result;
            };
            reader.readAsDataURL(res);
          })
          .catch((err) => {
            this.getBlobForNoPhoto();
          });
      }
    
      getDSProfileImage(profileImg) {
        fetch(profileImg)
          .then((res) => res.blob())
          .then((res) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              this.dsProfileImg = reader.result;
            };
            reader.readAsDataURL(res);
          })
          .catch((err) => {
            this.getBlobForDSNoPhoto();
          });
      }
  
      getStudentProfile(studentId) {
        this.userService.getStudentProfile(studentId) .then((profile: any) => {
          this.studentProfile = profile.result[0];
          if ( this.studentProfile != null){
          if (this.studentProfile.extensionName == null || this.studentProfile.extensionName == '') { 
            this.studentFullName = this.studentProfile.lastName +", "+ this.studentProfile.firstName +" "+ this.studentProfile.middleName;
          }
          else{
            this.studentFullName = this.studentProfile.lastName +", "+ this.studentProfile.firstName +" "+ this.studentProfile.middleName+" "+ this.studentProfile.extensionName;
          }
          //this.gender = this.studentProfile.gender;
          this.birthDate = this.studentProfile.birthDate; 
        }     
          });
      }
  
      dsProfile;
      getDSProfile(dsProfileId) {
        this.userService.getDrivingSchoolProfileId(dsProfileId) .then((profile: any) => {
          this.dsProfile = profile.result[0];
          });
      }
  
      note1;
      note2;

      loadCertificate(courseId, studentId) {
        this.getBlobForHeaderBG();
        this.courseService
          .getStudentCertificate(courseId, studentId)
          .then((res: any) => {
            if ((res.statusCode = 200)) {
              this.certificate = res.result[0];
              //console.log(this.certificate);
              this.dsCompanyName = this.certificate.drivingSchoolProfile.companyName;
              this.dsAddress = 
              this.certificate.drivingSchoolProfile.dsBlock + " "+
              this.certificate.drivingSchoolProfile.dsStreet +" "+
              this.certificate.drivingSchoolProfile.dsBarangay +"\n"+
              this.certificate.drivingSchoolProfile.dsCity +", "+this.certificate.drivingSchoolProfile.dsProvince+" "+this.certificate.drivingSchoolProfile.dsPostalCode;
              this.dsContacts = "Email: "+ this.certificate.drivingSchoolProfile.companyEmail +" | Mobile No.: " + this.certificate.drivingSchoolProfile.dsMobile 
              +" | Tel. No.: " + this.certificate.drivingSchoolProfile.telephone;
              this.dsAccreditationNo = this.certificate.drivingSchoolProfile.ltoAccreditationNo;
    
              if (this.certificate.studentProfile.extensionName == null || this.certificate.studentProfile.extensionName == '') { 
                this.studentFullName = this.certificate.studentProfile.lastName +", "+ this.certificate.studentProfile.firstName +" "+ this.certificate.studentProfile.middleName;
              }
              else{
                this.studentFullName = this.certificate.studentProfile.lastName +", "+ this.certificate.studentProfile.firstName +" "+ this.certificate.studentProfile.middleName+" "+ this.certificate.studentProfile.extensionName;
              }
              this.gender = this.certificate.studentProfile.gender;
  
              this.driverLicense = this.certificate.studentProfile.driverLicense;
              if (this.driverLicense == null || this.driverLicense == ""){
                this.driverLicenseLabel = " ";
              }
              else{
                  this.driverLicenseLabel = "Driver License No.";
              }
              //console.log(this.certificate.studentProfile.photo);
              this.profileImg = this.certificate.studentProfile.photo;
              //if (this.certificate.studentProfile.photo){           
                //this.getProfileImage(this.studentProfile.photo);
              //}
              //else{
              //  this.getBlobForNoPhoto();
              //}
  
              //console.log(this.certificate.drivingSchoolProfile.logo);
              this.dsProfileImg = this.certificate.drivingSchoolProfile.logo;
              //if(this.dsProfile.logo){
              //  this.getDSProfileImage(this.certificate.drivingSchoolProfile.logo);
              //}
              //else{
              //  this.getBlobForDSNoPhoto();
              //} 

              if (this.certificate.course.id ==  1){
                this.note1 = "NOT VALID for STUDENT PERMIT application without an accompanying DRIVING SCHOOL certificate.";
                this.note2 = "Please coordinate with your Driving School before going to LTO.";
              }
              else{
                this.note1 = "";
                this.note2 = "";
              }

              this.generatePdf();
            }          
          })
          .catch((err) => {
            this.studentCert= false;
            console.log(err);
          });
      }
  
    getDuration(startDate, endDate, status) {
      if (status =="COMPLETED"){
      var date_future: any = new Date(endDate);
       
        var date_now: any = new Date(startDate);
        var seconds = Math.floor((date_future - date_now) / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);
  
        var hours = hours - days * 24;
        var minutes = minutes - days * 24 * 60 - hours * 60;
        var seconds =
          seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
  
        var obj = {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
  
        var string = '';
  
        if (obj.days != 0) {
          string = string + '' + obj.days + ' Days ';
        }
  
        if (obj.hours != 0) {
          string = string + '' + obj.hours + ' Hours ';
        }
  
        if (obj.minutes != 0) {
          string = string + '' + obj.minutes + ' Minutes ';
        }
  
        if (obj.seconds != 0) {
          string = string + '' + obj.seconds + ' Seconds ';
        }
      }
      return string;
    }
    
      
    getDifferenceDate(quiz) {
      var date_future: any = new Date(quiz.endDate);
  
      if (quiz.status != 'COMPLETED' && quiz.status != 'PENDING') {
        var date_future: any = new Date();
      }
  
      var date_now: any = new Date(quiz.startDate);
  
      var seconds = Math.floor((date_future - date_now) / 1000);
      var minutes = Math.floor(seconds / 60);
      var hours = Math.floor(minutes / 60);
      var days = Math.floor(hours / 24);
  
      var hours = hours - days * 24;
      var minutes = minutes - days * 24 * 60 - hours * 60;
      var seconds =
        seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
  
      var obj = {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
  
      var string = '';
  
      if (obj.days != 0) {
        string = string + '' + obj.days + ' Days ';
      }
  
      if (obj.hours != 0) {
        string = string + '' + obj.hours + ' Hours ';
      }
  
      if (obj.minutes != 0) {
        string = string + '' + obj.minutes + ' Minutes ';
      }
  
      if (obj.seconds != 0) {
        string = string + '' + obj.seconds + ' Seconds ';
      }
  
      return string;
    }
  
    exportPdf() {
      const doc = new jsPDF();
       //doc.autoTable(this.exportColumns, this.records);
    //  var dd =
      //autoTable(doc, { html: "#my-table" });
      //autoTable(doc, { dd });   
      doc.save(this.studentFullName+'.pdf');
      /*import("jspdf").then((jsPDF) => {
          import("jspdf-autotable").then((x) => {
              const doc = new jsPDF.default(0, 0);
              doc.autoTable(this.exportColumns, this.records);
              doc.save("students.pdf");
          });
      });*/
    }
  
    getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
  
        img.onload = () => {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img,0,0);
  
          var dataURL = canvas.toDataURL("image/png");
  
          resolve(dataURL);
        };
  
        img.onerror = error => {
          reject(error);
        };
        img.src = url;
      });
    }
  
  async generatePdf() {
          this.dsProfileImg = await this.getBase64ImageFromURL(this.dsProfileImg);
          this.profileImg = await this.getBase64ImageFromURL(this.profileImg);
         let dd = {
          pageSize: 'LETTER', //pageSize: { width: 612.00, height: 792.00},
          pageMargins: [60.5, 0, 0, 0],
          background: [
            {
              image: this.headerBG,
              width: 612.0,
            },
          ],
          watermark: {
            text: 'saferoads.ph',
            color: '#0038a8',
            opacity: 0.05,
            bold: true,
            italics: false,
          },
          content: [
            [
            {
              image: 'dsLogo',
              alignment: 'left',
              fit: [100, 100],           
              margin: [0, 50, 0, 0],
              //text:' ',
            },
            {
              text: this.dsCompanyName,
              style: 'companyName',
            },
            {
              text: this.dsAddress 
              +'\n' + 
              this.dsContacts,
              style: 'companyDetails',
            },
            {
              text:'LTO Accreditation No.: ' + this.dsAccreditationNo,
              style: 'companyDetails',
            },
            ],
            {
              text: 'COURSE COMPLETION',
              style: 'headerSmall',
            },
            {
              text: 'CERTIFICATE',
              style: 'header',
            },
            {
              text: this.certificate.studentModuleSummary.courseDescription,
              style: 'forLabel',
            },
            { text:' '
            },
            {
              text: 'Student Drivers Information',
              style: 'studentInformationLabel',
            },
    
            {
              layout: 'noBorders', // optional
              table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
    
                widths: [82, 80, 308],
    
                body: [
                  [
                    {
                      rowSpan: 3,
                      fillColor: '#f8f8f8',
                      stack: [
                        {
                          image: 'studentPhoto',
                          alignment: 'center',
                          //width: 100,
                          //height:100,
                          fit: [100, 100],
                          margin: [10, 10, 1, 10],
                          //text:' ',
                        },
                      ],
                    },
                    {
                      text: 'Name',
                      fillColor: '#f8f8f8',
                      margin: [10, 5, 0, 0],
                      style: 'label',
                    },
                    {
                      text: this.studentFullName,               
                      fillColor: '#f8f8f8',
                      margin: [10, 5, 0, 0],
                      style: 'field',
                    },
                  ],
                  [
                    { text: '', fillColor: '#f8f8f8' },
                    { text: 'Address', fillColor: '#f8f8f8', style: 'label' ,margin: [10, 5, 0, 0]},
                    {
                      text: this.certificate.userFullAddress
                        ? this.certificate.userFullAddress
                        : 'N/A',
                      fillColor: '#f8f8f8',
                      margin: [10, 5, 0, 0],
                      style: 'field',
                    },
                  ],
                  [
                    { text: '', fillColor: '#f8f8f8' },
                    {
                      text: 'Gender', fillColor: '#f8f8f8', style: 'label',margin: [10, 5, 0, 0]},
                    {
                      text: this.gender,
                      fillColor: '#f8f8f8',
                      margin: [10, 5, 0, 0],
                      style: 'field',
                    },
                  ],  
                  [
                    { text: '', fillColor: '#f8f8f8' },
                    {
                      text: this.driverLicenseLabel,
                      fillColor: '#f8f8f8',
                      style: 'label', margin: [10, 5, 0, 0]
                    },
                    {
                      text: this.driverLicense
                        ? this.driverLicense
                        : ' ',
                      fillColor: '#f8f8f8',
                      style: 'field',margin: [10, 5, 0, 0]
                    },
                  ],  
                  [
                    { text: '', fillColor: '#f8f8f8' },
                    {
                      text: '',//'Birth Date', 
                      fillColor: '#f8f8f8', style: 'label',margin: [10, 5, 0, 0]},
                    {
                      text: '',//moment(this.birthDate).format('DD-MMM-YYYY'),
                      fillColor: '#f8f8f8',
                      margin: [10, 5, 0, 0],
                      style: 'field',
                    },
                  ],                                       
                ],
              },
            },
    
            {
              text: 'Course Information and Evaluation',
              style: 'studentInformationLabel',
            },
    
            {
              layout: 'noBorders', // optional
              table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
    
                widths: [82, 160, 55, 180],
    
                body: [
                  [
                    {
                      text: 'Course Name',
                      margin: [0, 5, 0, 0],
                      style: 'label',
                    },
                    {
                      text: this.certificate.course.description
                        ? this.certificate.course.description
                        : 'N/A',
                      margin: [0, 5, 0, 0],
                      style: 'field',
                    },
                    {
                      text: 'Purpose',
                      margin: [0, 5, 0, 0],
                      style: 'label',
                    },
                    {
                      text: this.certificate.purpose
                        ? this.certificate.purpose
                        : 'N/A',
                      margin: [0, 5, 0, 0],
                      style: 'field',
                    },
                  ],
                  [
                    {
                      text: 'Date Started \n Total No. of Hrs ',
                      margin: [0, 5, 0, 0],
                      style: 'label',
                    },
                    {
                      text:
                      this.certificate.studentModuleSummary.startDateFormatted +
                        '\n' +
                       this.getDifferenceDate(
                          this.certificate.studentModuleSummary
                       ),
                      margin: [0, 5, 0, 0],
                      style: 'field',
                    },
                    {
                      text: 'Date Ended',
                      margin: [0, 5, 0, 0],
                      style: 'label',
                    },
                    {
                      text: this.certificate.studentModuleSummary.endDateFormatted,
                      margin: [0, 5, 0, 0],
                      style: 'field',
                    },
                  ],
                  [
                    {
                      text: 'Exam Result ',
                      margin: [0, 5, 0, 0],
                      style: 'label',
                    },
                    {
                      text: this.certificate.studentModuleSummary.examResult,
                      margin: [0, 5, 0, 0],
                      style: 'field',
                    },
                    {
                      text: '',
                      margin: [0, 5, 0, 0],
                      style: 'label',
                    },
                    {
                      text: '',
                      margin: [0, 5, 0, 0],
                      style: 'field',
                    },
                  ],
    
                ],
              },
            },
            {
              layout: 'noBorders', // optional
              table: {
                body:[
                  [
                    {
                      text: 'Remarks ',
                      margin: [0, 5, 0, 0],
                      style: 'label',
                    },
                    {
                      text: `${this.certificate.remarks}`,
                      margin: [25, 5, 0, 0],
                      style: 'field',
                    },
                  ],
                  [
                  {
                    text: 'Certificate No.',
                    margin: [0, 5, 0, 0],
                    style: 'label',
                  },
                  {
                    text: this.certificate.certificateNo,
                    margin: [25, 5, 0, 0],
                    style: 'field',
                  },
                  ],
                  [
                    {
                      text: 'Issuance Date',
                      margin: [0, 5, 0, 0],
                      style: 'label',
                    },
                    {
                      text: this.certificate.issuanceDate,
                      margin: [25, 5, 0, 0],
                      style: 'field',
                    },
                  ],                   
                ]
              },
            },
            {
              text: " ",
              style: 'label',
            }, 
            {
              text: this.note1,
              style: 'note',
            },  
            {
              text: this.note2,
              style: 'note',
            },                        
            {
              qr: environment.url 
              + '/qr/' + 
              this.certificate.certificateNo,
              fit: '71',
              margin: [400, 0, 0, 0],
            },      
            {
              text: '© ' + moment(new Date()).format('YYYY')+' saferoads.ph Powered by Reviewmasters Inc. | Email: inquiry@saferoads.ph',
              style: 'footer',
            },
          ],
          images: {
            studentPhoto: this.profileImg,
            dsLogo: this.dsProfileImg
          },
          styles: {
            headerSmall: {
              fontSize: 10,
              bold: true,
              alignment: 'left',
              color: '#333232',
              margin: [0, 15, 0, 0],
            },
            header: {
              fontSize: 18,
              alignment: 'left',
              color: '#0038a8',
              bold: true,
            },
            forLabel: {
              fontSize: 10,
              bold: true,
              alignment: 'left',
              color: '#333232',
              margin: [0, 0, 0, 0],
            },
            studentInformationLabel: {
              fontSize: 9,
              bold: true,
              alignment: 'left',
              color: '#0038a8',
              margin: [0, 3, 0, 11],
            },
            companyName: {fontSize: 9,  bold: true, color: '#333232'},
            companyDetails: {fontSize: 8, color: '#333232'},
            label: { fontSize: 9, color: '#333232' },
            field: { fontSize: 9, bold: true, color: '#000000' },
            note: { fontSize: 9, bold: true, color: '#FF0000' },
            footer: { fontSize: 6, color: '#333232' },
          },
        }; 
        pdfMake.createPdf(dd).download(this.studentFullName+'.pdf');
        //pdfMake.createPdf(dd).open();
        this.fileDownloaded=true;
        //console.log("Done");
        this.downloadDialog = false;
      }

      archiveRecordDialog: boolean;
      archiveRecord;
      archivingRecord(record: any) {
        this.archiveRecord = record;
        this.archiveRecordDialog = true;
        this.state = "archive";
        this.submitted = false;
        this.isDisabled = true;
      }
    
      archivedRecord(record: any) {
        this.confirmationService.confirm({
          message: "Are you sure you want to archive this student record? " +
            "( Id No.: " + record.id + " | Student Name: " + record.fullName + " | Email Account: " + record.email + " ).",       
          header: "Confirm",
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            this.records = this.records.filter(
              (val) => val.id !== record.id
            );
            this.record = {};
            this.submitted = true;
            var rec: UserArchived = record;
            rec.studentId = record.id;
            this.submitting = true;
            this.userService
              .archivedStudentAccount(rec)
              .then((res) => {
                this.submitting = false;
                this.archiveRecordDialog = false;
                this.messageService.add({
                  severity: "success",
                  summary: "Successful",
                  detail: "Student Archived",
                  life: 3000,
                });
                this.hideDialog();
                this.getAllStudents();
              });
          },
        });
      }
    
      reverseArchiveRecordDialog: boolean;
      reverseArchiveRecord;
      reverseArchivingRecord(record: any) {
        this.reverseArchiveRecord = record;
        this.reverseArchiveRecordDialog = true;
        this.state = "reverse-archived";
        this.submitted = false;
        this.isDisabled = true;
      }
    
      reverseArchivedRecord(record: any) {
        this.confirmationService.confirm({
          message: "Are you sure you want to reverse archived this student record? " +
            "( Id No.: " + record.id + " | Student Name: " + record.fullName + " | Email Account: " + record.email + " ).",       
          header: "Confirm",
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            this.records = this.records.filter(
              (val) => val.id !== record.id
            );
            this.record = {};
            this.submitted = true;
            var rec: UserArchived = record;
            rec.studentId = record.id;
            this.submitting = true;
            this.userService
              .reverseArchivedStudentAccount(rec)
              .then((res) => {
                this.submitting = false;
                this.reverseArchiveRecordDialog = false;
                this.messageService.add({
                  severity: "success",
                  summary: "Successful",
                  detail: "Student Reverse Archived",
                  life: 3000,
                });
                this.hideDialog();
                this.getAllStudents();
              });
          },
        });
      }


}
