import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import {
    BillingPayment,
    SchoolAccountPaymentOption,
    SchoolTransaction,
    StudentTransaction,
    ValidatePayment,
    Verification,
} from "../../interfaces/Payment";
import { PaymentsService } from "../../services/payments.service";
import _ from "lodash";
import { StorageService } from "../../services/storage.service";
import * as FileSaver from "file-saver";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";

@Component({
    selector: "app-payment-bills",
    templateUrl: "./payment-bills.component.html",
    styleUrls: ["./payment-bills.component.scss"],
    providers: [MessageService],
})
export class PaymentBillsComponent implements OnInit {
    state = "bills";
    schoolTransaction = new BillingPayment();
    validatePayment = new ValidatePayment();
    recordDialog: boolean;
    records;
    record;
    selectedRecords: Verification[];
    submitted: boolean = false;
    loading: boolean = true;
    statuses: any[];
    courseFees;
    courseFee;

    optionStatus = [
        { label: "PENDING", value: "PENDING" },
        { label: "APPROVED", value: "APPROVED" },
        { label: "INVALID", value: "INVALID" },
    ];

    bills = [];

    students = [];
    paymentOptions: SchoolAccountPaymentOption[] = [];
    fileToUpload;
    paymentMethodReadOnly;
    selectedPaymentMethod;
    errForm = false;
    accountValidated;

    constructor(
        private storageService: StorageService,
        private paymentService: PaymentsService,
        private messageService: MessageService
    ) {}

    ngOnInit(): void {
        this.loadAccountValidated();
        this.loadPaymentTransactions();
        this.loadCourseFees();
        this.loadPaymentOptions();
    }

    loadAccountValidated() {
        this.storageService.get('accountValidated').then((response:any) => {
            this.accountValidated = response;
        })
    }

    loadPaymentTransactions() {
        this.paymentService
            .getSchoolTransactions()            
            .then((response: any) => {                
                this.records = response.result.filter((bill) => {
                    return bill.paymentTypeName !== "BILLING";
                });

                this.bills = response.result.filter((bill) => {
                    return bill.paymentTypeName === "BILLING";
                });
                this.loading = false;
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "success",
                    summary: "Success",
                    detail: "Driving School Profile Updated.",
                    life: 3000,
                });
            });
    }

    loadCourseFees() {
        this.paymentService.getAllCourseFees().then((response: any) => {
            this.courseFees = response.result;            
        });
    }

    getCourseFee(course) {
        var courseFee = this.courseFees.filter((c) => {
            return c.name === course;
        });

        return courseFee[0].amount;
    }

    selectPaymentMethod() {
        this.schoolTransaction.paymentMethodCode =
            this.selectedPaymentMethod.code;
        this.paymentMethodReadOnly = this.selectedPaymentMethod.name;
    }

    loadPaymentOptions() {
        this.paymentService.getAdminPaymentOption().then((response: any) => {
            this.paymentOptions = response.result;
            this.selectedPaymentMethod = this.paymentOptions[0];
            this.selectPaymentMethod();
            this.paymentOptions = _.map(this.paymentOptions, function (value) {
                return {
                    label: value.name,
                    value: value,
                };
            });
        });
    }

    pay(id) {
        this.state = "pay";
        this.schoolTransaction.transactionId = id;
        this.loading = true;
        this.paymentService
            .getAllStudentWithInvoiceHeaders(id)
            .then((response: any) => {
                this.loading = false;
                //console.log(response.result);
                this.students = response.result;
            })
            .catch((err) => {
                this.loading = false;
                this.messageService.add({
                    severity: "success",
                    summary: "Success",
                    detail: "Driving School Profile Updated.",
                    life: 3000,
                });
            });
    }

    sendTransaction() {
        //console.log(this.schoolTransaction);
        this.submitted = true;
        const formData = new FormData();

        if (this.fileToUpload) {
            formData.append("file", this.fileToUpload);
            formData.append("object", JSON.stringify(this.schoolTransaction));

            this.paymentService
                .schoolPaymentBillingTransctionWithAttachment(formData)
                .then((response: any) => {
                    this.state = "bills";
                    this.recordDialog = false;
                    this.clearInputs();
                    this.loadPaymentTransactions();
                    this.submitted = false;
                    this.messageService.add({
                        severity: "success",
                        summary: "Payment has been successfully submitted.",
                        detail: "",
                        life: 3000,
                    });
                })
                .catch((err) => {
                    this.submitted = false;
                    this.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Error in Loading Data Please Contact Administrator",
                        life: 3000,
                    });
                });
        } else {
            formData.append("object", JSON.stringify(this.schoolTransaction));

            this.paymentService
                .schoolPaymentBillingTransction(formData)
                .then((response: any) => {
                    this.state = "bills";
                    this.recordDialog = false;
                    this.clearInputs();
                    this.loadPaymentTransactions();
                    this.submitted = false;
                    this.messageService.add({
                        severity: "success",
                        summary: "Payment has been successfully submitted.",
                        detail: "",
                        life: 3000,
                    });
                })
                .catch((err) => {
                    this.submitted = false;
                    this.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Error in Loading Data Please Contact Administrator",
                        life: 3000,
                    });
                });
        }
    }

    clearInputs() {
        this.schoolTransaction = new BillingPayment();
        this.fileToUpload = null;
    }

    getTotal() {
        var price = 0;
        this.students.forEach((res) => {
            if (res.courseId == "1") {
                price = price + this.courseFees[0].amount;
            }
            if (res.courseId == "2") {
                price = price + this.courseFees[1].amount;
            }
            if (res.courseId == "3") {
                price = price + this.courseFees[2].amount;
            }
        });
        return price;
    }

    hideDialog() {
        this.recordDialog = false;
        this.submitted = false;
    }

    editRecord(record: any) {
        this.record = { ...record };
        //console.log(this.record);
        this.recordDialog = true;
    }

    handleFileInput(file) {
        this.fileToUpload = file.item(0);
    }

    exportExcel() {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(this.records);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, "ds_payments");
        });
    }

    exportExcel2() {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(this.students);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, "ds_payments");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(
            data,
            fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
    }
}
