export class PaymentMatrix {
    id:number;
    name: string;
    dsRegistrationFee?: number = 0;
    totalAmount?: number  = 0;
    vatAmount?: number = 0;
    discountAmount?: number = 0;
    vatPercent: number = 0;
    discountPercent:number = 0;
    courseId:number;
    dsProfileId:number;
    companyName:string;
    code:string;
    vatInclusive;
}

export class SchoolAccountPaymentOption {
    id;
   code;
   name;
   photo;
   icon;
   cms;
   secretKey;
   publicKey;
   script;
   mobile;
   dsProfileId;
}

export class SchoolPaymentTypeOption {
    code: string;
    amount?: number;
    discountAmount?: number;
    discountPercent?: number;
    name?: string;
    totalAmount: number;
    vatAmount?: number;
    vatPercent: number;
}

export class AdminPaymentTypeOption {
    name?: string;
    amount?: number;
    discountAmount?: number;
    discountPercent?: number;
    totalAmount: number;
    vatAmount?: number;
    vatPercent: number;
}

export class Verification {
    id: string;
    student_name?: string;
    payment_amount?: string;
    payment_discount?: string;
    payment_vat?: string;
    payment_type?: string;
    payment_image?: string;
    payment_remarks?: string;
    payment_date?: string;
    validation_status?: string;
    validation_date?: string;
    validation_by?: string;
    validation_remarks?: string;
}


export class SchoolTransaction {
    paymentMethodCode: string;

    paymentTypeCode: string;

    paymentRefNumber: string;

    paymentRemarks: string;

    paymentAttachment;

    subscriptionType: string;

    paymentDate: string;

    spQuantity: number = 0;

    npQuantity: number = 0;

    prQuantity: number = 0;

    paymentDiscountAmount: number =0;
}

export class StudentTransaction {

    studentId:number;

    referralCode:string;

    courseCode: string;

    paymentMethodCode: string;

    paymentRefNumber: string;

    paymentRemarks: string;

    paymentDate: string;
}

export class ValidatePayment {
    studentId;
    transactionId;
    dsProfileId;
    validationRemarks: string;
    validationStatus: string;
}

export class CourseFee {
    id;

    code;

    name;

    amount;

    discountPercent;

    vatPercent;

    vatAmount = 0;

    discountAmount =0;

    totalAmount = 0;
}

export class BillingPayment {
    paymentMethodCode;

    paymentTypeCode;

    paymentRefNumber;

    paymentRemarks;

    paymentDate;

    transactionId;
}


export class BillingType {
     code;

     name;

    amount;

    discountPercent;

    vatPercent;

    vatAmount = 0;

    discountAmount =0;

    totalAmount = 0;

    vatInclusive;
}