export class Course {
    id?: string;
    name?: string;
    code?: string;
    duration?: string;
}

export class Module {
    id?: string;
    code?: string;
    courseDescription?: string;
}

export class Topic {
    id?: string;
    code?: string;
    courseDescription?: string;
    duration?: Number;
    examNoOfQuestions?: Number;
    examPassingScore?: Number;
    examSetCode?: Number;
    examTimer?: Number;
    moduleCode?: string;
    moduleDescription?: string;
    moduleId?: Number;
    moduleName?: string;
    name?: string;
    nextTopicId?: Number;
    quizNoOfQuestions?: Number;
    quizPassingScore?: Number;
    quizSetCode?: String;
    quizTimer?: Number;
    requiredTopicId?: Number;
    seqNo?: Number;
    status?: String;
    withExam?: String;
    withQuiz?: String;
}
