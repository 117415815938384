import { Component, OnInit } from "@angular/core";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { Topic } from "src/app/saferoadsph/interfaces/Course";

@Component({
    selector: "app-topic-modal",
    templateUrl: "./topic-modal.component.html",
    styleUrls: ["./topic-modal.component.scss"],
})
export class TopicModalComponent implements OnInit {
    topic;
    topics = [];
    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig
    ) {}

    ngOnInit(): void {
        var x = new Topic();
        x.id = "0";
        x.name = "None";
        this.topics.push(x);
        this.topic = this.config.data.topic;
        this.topics.push(...this.config.data.topics);

        //console.log(this.topics);
    }
}
