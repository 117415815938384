import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CmsService } from 'src/app/saferoadsph/services/cms.service';
import * as _ from 'lodash';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'app-tutorial-page',
  templateUrl: './tutorial-page.component.html',
  styleUrls: ['./tutorial-page.component.scss']
})
export class TutorialPageComponent implements OnInit {

  constructor(
    private cmsService: CmsService,
    protected _sanitizer: DomSanitizer,
  ) { }
  tutorialRecords =  new Array(1); ;
  ngOnInit(): void {
    this.loadContent();
  }

  safeUrl(url) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  blocks;
  loadContent() {
    this.blocks = null;
    this.cmsService.getContent('Tutorial Page').then((res: any) => {
      var blocks = [];
      _.forEach(res.result, function (value, key) {
        blocks[value.block] = value.content;
      });
      blocks['dashboard-video'] = this.safeUrl(blocks['dashboard-video']);
      this.blocks = blocks;   
      //console.log(this.blocks);
    });
  }

}
