import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { ChangePassword } from "../../interfaces/User";
import { UsersService } from "../../services/users.service";

@Component({
    selector: "app-change-password",
    templateUrl: "./change-password.component.html",
    styleUrls: ["./change-password.component.scss"],
    providers: [MessageService],
})
export class ChangePasswordComponent implements OnInit {
    submitted;
    submitting = false;
    user;
    changePassword: ChangePassword = new ChangePassword();
    confirmPassword;

    constructor(
        private userService: UsersService,
        private messageService: MessageService
    ) {}

    ngOnInit(): void {
        this.changePassword.oldPassword;
    }

    submit() {
        this.submitted = true;

        if (
            !this.changePassword.oldPassword ||
            this.changePassword.oldPassword == ""
        ) {
        } else if (
            !this.changePassword.newPassword ||
            this.changePassword.newPassword == ""
        ) {
        } else if (!this.confirmPassword || this.confirmPassword == "") {
        } else if (this.changePassword.newPassword != this.confirmPassword) {
        } else {
            this.submitting = true;
            this.userService
                .changePassword(this.changePassword)
                .then((res: any) => {
                    if (res.statusCode == 200) {
                        this.messageService.add({
                            severity: "success",
                            summary: "Password Changed",
                            detail: "Password Succesfully Changed.",
                            life: 3000,
                        });
                    } else {
                        this.messageService.add({
                            severity: "error",
                            summary: "Error",
                            detail: "Old password is Incorrect.",
                            life: 3000,
                        });
                    }
                    this.submitting = false;
                })
                .catch((err) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Old password is Incorrect.",
                        life: 3000,
                    });
                    this.submitting = false;
                });
        }
    }
}
