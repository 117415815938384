import { Component } from "@angular/core";
import * as moment from "moment";
import { Login, User } from "../saferoadsph/interfaces/User";
import { StorageService } from "../saferoadsph/services/storage.service";
import { UsersService } from "../saferoadsph/services/users.service";

@Component({
    selector: "app-login",
    templateUrl: "./app.login.component.html",
})
export class AppLoginComponent {
    error;
    email;
    password;
    msgs = [];
    copyright="© "+  moment().add(0, 'years').format('YYYY') + " saferoads.ph Powered by Reviewmasters Inc.";
    show = false;
    constructor(
        private userService: UsersService,
        private storageService: StorageService
    ) {}

    login() {
        this.error = null;
        var login: Login = {
            email: this.email,
            password: this.password,
        };
        this.userService
            .login(login)
            .then((res) => {
                this.storageService.set("email", this.email).then((res) => {
                    location.reload();
                });
            })
            .catch((err) => {
                this.msgs = [];
                this.msgs.push({
                    severity: "error",
                    summary: "Error Message",
                    detail: "Login Failed",
                });
                this.error = true;
            });
    }

    showPass() {
        if(!this.show) {
          this.show = true
        }else {
          this.show = false;
        }
      }
}
