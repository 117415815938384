import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { StorageService } from "../../services/storage.service";
import { UsersService } from "../../services/users.service";
import * as moment from "moment";

@Component({
    selector: "app-payment-status",
    templateUrl: "./payment-status.component.html",
    styleUrls: ["./payment-status.component.scss"],
    providers: [MessageService]
})
export class PaymentStatusComponent implements OnInit {
    credits;
    prepaids = [];
    subscriptionType;

    schoolProfile;

    subscriptions = [];

    constructor(private storageService: StorageService,
                private userService: UsersService,
                private messageService: MessageService
                ) {}

    ngOnInit(): void {
        this.loadSchoolProfile();
        this.loadSubcriptionType();
    }

    loadSchoolProfile() {
        this.userService.getDrivingSchoolProfile().then((response:any) => {
            this.schoolProfile = response.result[0];
            //console.log(this.schoolProfile)
            this.prepaids.push(
                {
                    type: "Student Permit",
                    remaining: this.schoolProfile.spQuantity,
                },
                {
                    type: "Non-Professional",
                    remaining: this.schoolProfile.npQuantity,
                },
                {
                    type: "Professional",
                    remaining: this.schoolProfile.prQuantity,
                },
            )
            this.subscriptions.push(
                {
                    type: "Subscription Plan",
                    date_start: moment(this.schoolProfile.subscriptionExpiration).subtract(1,'years').format("YYYY-MM-DD"),
                    date_end: moment(this.schoolProfile.subscriptionExpiration).format("YYYY-MM-DD"),
                    status:  moment(this.schoolProfile.subscriptionExpiration).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD") ? "INACTIVE" : "ACTIVE",
                }
            )
        }).catch(err => {
            this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Error in Loading Data Please Contact Administrator",
                life: 3000,
            });
        });
    }

    loadSubcriptionType() {
        this.storageService.get('subscriptionType').then((response:any) => {
            this.subscriptionType = response;
        })
    }
}
