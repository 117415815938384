import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { UsersService } from "../../services/users.service";
import * as FileSaver from "file-saver";
import * as moment from "moment";
import _ from "lodash";
import { CmsService } from "../../services/cms.service";
import { Address, TransferStudentAccount, User, UserDelete, UserEmail, UserName } from "../../interfaces/User";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { WebcamImage } from "ngx-webcam";
import { concat, Observable, Subject } from "rxjs";
import { ValidationService } from "../../services/validation.service";
import { Router } from "@angular/router";
import { StorageService } from "../../services/storage.service";
import { PaymentsService } from "../../services/payments.service";

@Component({
  selector: 'app-student-new-account',
  templateUrl: './student-new-account.component.html',
  styleUrls: ['./student-new-account.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class StudentNewAccountComponent implements OnInit {
  loading: boolean = true;
  recordDialog: boolean;
  updateRecordDialog: boolean;
  updateStudentNameRecordDialog: boolean;
  paymentDialog: boolean;
  passwordDialog: boolean;
  pictureDialog: boolean;
  studentTransferDialog: boolean;
  records: User[];
  record: User;
  updateRecord: User;
  selectedRecords: User[];
  submitted: boolean;
  statuses: any[];
  state: string = "new";
  webcamImage: WebcamImage = null;
  noPrivate: any;
  invalidEmail = false;

  cols: any[];
  exportColumns: any[];
  selectedGender;
  selectedUser;
  isDisabled = false;
  submitting = false;
  existingEmail = false;
  resetting = false;
  imageSrc;
  imageFile;
  uploadingPhoto = false;
  accountValidated;

  genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  userTypeOptions = [
    { label: "Student Permit", value: "STUDENT" },
    { label: "Non Professional", value: "NON_PRO" },
    { label: "Professional", value: "PRO" },
  ];

  dsBranchOptions:any[];
  selectedDSProfile:any[];

  paymentDetails;
  paymentStatus;
  validationStatus;
  paymentCourseName;
  show = false;
  constructor(private userService: UsersService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private cmsService: CmsService,
    private validationService: ValidationService,
    private router: Router,
    private storageService: StorageService,
    private paymentService: PaymentsService) { }


  branchCode;
  ngOnInit(): void {
    this.branchCode = localStorage.getItem('branchCode');
    this.loadAccountValidated();
    this.invalidEmail = false;
    this.getAllStudents();
    this.getAllRegions();
    this.getAllDsBranch(this.branchCode);
    // Exporting Records
    this.cols = [
      { field: "fullName", header: "Name" },
      { field: "gender", header: "Gender" },
      { field: "mobile", header: "Mobile" },
      { field: "status", header: "Status" },
    ];

    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  loadAccountValidated() {
    this.storageService.get('accountValidated').then((response: any) => {
      this.accountValidated = response;
    })
  }

  getAllStudents() {
    this.userService.getAllStudentsNewAccountsByDrivingSchool().then((res: any) => {
      if (res.statusCode == 200) {
        this.records = res.result;
        //console.log(this.records);
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
      this.loading = false;
    });
  }

  convertDate(date) {
    return moment(new Date(date)).format("YYYY-MM-DD");
}

  openNew() {
    this.submitted = false;
    this.recordDialog = true;
    this.state = "new";
    this.isDisabled = false;
    this.record = {};
    this.record.address = {};

    // Address
    this.provinces = [];
    this.cities = [];
    this.record.address.province = null;
    this.record.address.city = null;

    // Initial Value
    this.record.gender = "Male";
    this.record.userType = "STUDENT";
  }

  editRecord(record: any) {
    //this.updateRecord = null;
    this.updateRecord = record;
    this.updateRecord.birthDate = null;
    this.userService.getStudentProfile(record.id).then((res: any) => {
      res.result[0].birthDate = new Date(this.convertDate(res.result[0].birthDate));
      this.updateRecord = res.result[0];
      this.updateRecord.address = this.setAddress(res.result[0].address);
    })
    .catch((err) => {
      this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Student.",
          life: 3000,
      });
      this.submitted = false;
  });
    this.editloadAddressOfStudent(this.updateRecord);
    this.updateRecordDialog = true;
    this.state = "edit";
    this.submitted = false;
    this.isDisabled = true;
}

payment(record: any) {
    this.paymentService.getStudentPaymentTransactions(record.id).then((pay: any) => {
        this.paymentDetails = pay.result[0];
        if ( pay.result[0] =="No transaction found for this student"){                
            this.router.navigate([
                "/school/payments/students-payment/" + record.id,
            ]);
            }
        else {
            this.paymentDialog = true;
            this.paymentStatus = this.paymentDetails.paymentStatus;
            this.validationStatus= this.paymentDetails.validationStatus;
            this.paymentCourseName = this.paymentDetails.courseName;
        }
    });
}

validateEMail(email) {
    return this.validationService.validateEmail(email);
}

validatePassword(record) {
    return record.password == record.confirmPassword;
}

validateAddress(address) {
  if (address.block == null || address.block == "") {
      return false;
  } else if (address.street == null || address.street == "") {
      return false;
  } else if (address.barangay == null || address.barangay == "") {
      return false;
  } else if (address.region == null || address.region == "") {
      return false;
  } else if (address.province == null || address.province == "") {
      return false;
  } else if (address.city == null || address.city == "") {
      return false;
  } else if (address.postalCode == null || address.postalCode == "") {
      return false;
  } else {
      return true;
  }
}

setAddress(recAdd) {
  let add = new Address();
  add.block =  recAdd.block;
  add.street =  recAdd.street;
  add.barangay =  recAdd.barangay;
  add.region =  recAdd.region;
  add.province =  recAdd.province;
  add.city =  recAdd.city;
  add.postalCode =  recAdd.postalCode;
  return add;
}

addRecord() {
    this.submitted = true; // para sa validation
    this.record.address = this.setAddress(this.record.address);
    var transformRecord: User = this.record; // transformation kung meron customized value
    //transformRecord.birthDate = new Date(this.convertDate(this.record.birthDate)).toDateString();
    //console.log(transformRecord);
    if (
        this.validationService.validateUser(transformRecord) &&
        this.validationService.validateAddress(transformRecord.address) &&
        this.validatePassword(transformRecord) &&
        this.validateEMail(transformRecord.email)
    ) {
        this.submitting = true; // para sa loading
        this.userService
            .addStudent(transformRecord)
            .then((res: any) => {
                if (res.statusCode == 409) {
                    // email is already exists
                    this.existingEmail = true;
                    this.submitting = false;
                }

                if (res.statusCode == 200) {
                    this.messageService.add({
                        severity: "success",
                        summary: "Successful",
                        detail: "Student Saved",
                        life: 3000,
                    });
                    this.hideDialog();
                    this.getAllStudents();
                    this.submitting = false;
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Saving Student.",
                    life: 3000,
                });
                this.submitting = false;
            });
    } else {
        this.submitting = false;
    }
}

saveRecord() {
    //console.log(this.record);
    this.submitted = true; // para sa validation
    this.updateRecord.address = this.setAddress(this.updateRecord.address);
    var transformRecord: User = this.updateRecord;
    transformRecord.birthDate = moment(new Date(this.convertDate(this.updateRecord.birthDate))).format("YYYY-MM-DD"); 
    //console.log(transformRecord);
    if (
        this.validationService.validateUser(transformRecord) &&
        this.validationService.validateAddress(transformRecord.address)      
    ) {
        this.submitting = true; // para sa loading
        this.userService
            .editStudentProfile(transformRecord.id, transformRecord)
            .then((res) => {
                this.submitting = false;
                this.messageService.add({
                    severity: "success",
                    summary: "Successful",
                    detail: "Student Saved",
                    life: 3000,
                });
                this.updateRecord.birthDate=  null; 
                this.hideDialog();
                this.getAllStudents();
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Error in Saving Student.",
                    life: 3000,
                });
                this.submitting = false;
            });
    }
}

resetPassword(record) {
    this.resetting = true;
    this.userService
        .resetpasswordByDrivingSchool({ email: record.email })
        .then((res) => {
            this.messageService.add({
                severity: "success",
                summary: "Password has been succesfully Reset",
                detail: "New Password has been sent to : " + record.email,
                life: 5000,
            });
            this.resetting = false;
        })
        .catch((err) => {
            this.resetting = false;
        });
}

editPassword(record: any) {
    this.record = { ...record };
    this.passwordDialog = true;
    this.state = "password";
}
uploadPhoto(record: any) {
    this.record = { ...record };
    this.pictureDialog = true;
    this.state = "photo";
    this.noPrivate = null;
    this.webcamImage = null;
    this.imageSrc = null;
}

submitPhoto() {
    var img;
    this.uploadingPhoto = true;
    if (this.noPrivate != null) {
        const imageName = moment(new Date()).format("YYYYMMDDHms") + ".png";
        const imageBlob = this.dataURItoBlob(this.noPrivate.imageAsBase64);
        const imageFile = new File([imageBlob], imageName, {
            type: "image/png",
        });
        img = imageFile;
    } else {
        img = this.imageFile[0];
    }

    const formData = new FormData();
    formData.append("studentUserId", this.record.id.toString());
    formData.append("file", img);

    this.userService
        .uploadStudentPhoto(formData)
        .toPromise()
        .then((res) => {
            this.getAllStudents();
            this.uploadingPhoto = false;
            this.messageService.add({
                severity: "success",
                summary: "Successful",
                detail: "Photo has been Uploaded.",
                life: 3000,
            });
            this.pictureDialog = false;
        })
        .catch((err) => {
            this.uploadingPhoto = false;
        });
}

dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/png" });
    return blob;
}

onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        this.imageFile = event.target.files;
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.imageSrc = reader.result as string;
            this.webcamImage = null;
            this.noPrivate = null;
        };
    }
}

updateStudentName(record: any){
  this.confirmationService.confirm({
    message: "Do you want to update this student name? ( " + record.fullName + " )",
    header: "Confirm",
    icon: "pi pi-exclamation-triangle",
    accept: () => {

      this.submitted = true; 
      var transformRecord: UserName = record;
      transformRecord.studentId = record.id;
      transformRecord.email = record.email;
      transformRecord.lastName =record.lastName;
      transformRecord.firstName = record.firstName;
      transformRecord.middleName = record.middleName;
      transformRecord.extensionName = record.extensionName;
      this.submitting = true; 
      this.userService
        .editStudentName(transformRecord)
        .then((res) => {
          this.submitting = false;
          this.messageService.add({
            severity: "success",
            summary: "Successful",
            detail: "Student Saved",
            life: 3000,
          });
          this.hideDialog();
          this.getAllStudents();
        });
    },
});
}

saveStudentName(record: any) {
  this.updateRecord = record;
  this.updateRecord.birthDate = null;
  this.userService.getStudentProfile(record.id).then((res: any) => {
    res.result[0].birthDate = new Date(this.convertDate(res.result[0].birthDate));
    this.updateRecord = res.result[0];
    this.updateRecord.address = this.setAddress(res.result[0].address);
  })
  .catch((err) => {
    this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Error in Loading Student.",
        life: 3000,
    });
    this.submitted = false;
});
  this.editloadAddressOfStudent(this.updateRecord);
  this.updateStudentNameRecordDialog = true;
  this.state = "edit";
  this.submitted = false;
  this.isDisabled = true;
}

deleteRecordDialog : boolean;
deletedRecord;
deletingRecord(record: any) {
  this.deletedRecord = record;
  this.deleteRecordDialog = true;
  this.state = "delete";
  this.submitted = false;
  this.isDisabled = true;
}

deleteRecord(record: any) {
    this.confirmationService.confirm({
        message: "Are you sure you want to delete this student record? "+
        "( Id No.: " + record.id + " | Student Name: " + record.fullName + " | Email Account: " + record.email + " )."+
        " Please take note this action is irreversible.",
        header: "Confirm",
        icon: "pi pi-exclamation-triangle",
        accept: () => {         
              this.records = this.records.filter(
                (val) => val.id !== record.id
            );
            this.record = {};
            this.submitted = true; 
            var rec: UserDelete = record;
            rec.studentId = record.id;
            this.submitting = true; 
            this.userService
              .deleteStudentAccount(rec)
              .then((res) => {
                this.submitting = false;
                this.messageService.add({
                    severity: "success",
                    summary: "Successful",
                    detail: "Student Deleted",
                    life: 3000,
                }); 
                this.hideDialog();
                this.getAllStudents();
              });      
        },
    });
}

changeEmailDialog : boolean;
oldEmail;
changingEmail(record: any) {
  this.oldEmail = record.email;
  this.updateRecord = record;
  this.changeEmailDialog = true;
  this.state = "edit";
  this.submitted = false;
  this.isDisabled = true;
}

changeEmail(record: any) {
  this.confirmationService.confirm({
    message: "Are you sure you want to change the email account of this student? "+
    "( Id No.: " + record.id + " | Student Name: " + record.fullName + " | Old Email Account: " + this.oldEmail + " to New Email Account "+record.email +").",
    header: "Confirm",
    icon: "pi pi-exclamation-triangle",
    accept: () => {         
            this.submitted = true; 
            var rec: UserEmail = record;
            rec.studentId = record.id;
            rec.oldEmail = this.oldEmail;
            rec.newEmail = record.email;
            this.submitting = true; 
            this.userService
              .editStudentEmail(rec)
              .then((res) => {
                this.submitting = false;
                this.messageService.add({
                    severity: "success",
                    summary: "Successful",
                    detail: "Student Email Account Changed",
                    life: 3000,
                }); 
                this.hideDialog();
                this.getAllStudents();
              });       
    },
});
}

downloadDialog : boolean;
downloadingCertificate(record: any) {
  this.record = record;
  this.downloadDialog = true;
  this.submitted = false;
  this.isDisabled = true;
}

downloadCertificate(record: any) {

}

progressDialog : boolean;
showingProgress(record: any) {
  this.record = record;
  this.progressDialog = true;
  this.submitted = false;
  this.isDisabled = true;
}

viewStudentInfoDialog : boolean;
viewStudentInfo(record: any){
  this.viewStudentInfoDialog = true;
  this.record = record;
  this.record.birthDate = this.convertDate(this.record.birthDate);
  //console.log(this.record);
}

transferStudent(record){
  this.studentTransferDialog = true;
  this.record = record;
  this.getAllDsBranch(record.ds_custom_id);
  //console.log(this.record);
}

  hideDialog() {
    this.recordDialog = false;
    this.updateRecordDialog = false;
    this.updateStudentNameRecordDialog = false;
    this.submitted = false;
    this.passwordDialog =false;
    this.deleteRecordDialog =false;
    this.downloadDialog =false;
    this.progressDialog = false;
    this.changeEmailDialog = false;
    this.viewStudentInfoDialog = false;
    this.studentTransferDialog = false;
    this.transferSelectedDSDialog =false;
  }

  exportPdf() {
    const doc = new jsPDF();
    //doc.autoTable(this.exportColumns, this.records);
    autoTable(doc, { html: "#my-table" });
    doc.save("students.pdf");
    /*import("jspdf").then((jsPDF) => {
        import("jspdf-autotable").then((x) => {
            const doc = new jsPDF.default(0, 0);
            doc.autoTable(this.exportColumns, this.records);
            doc.save("students.pdf");
        });
    });*/
  }

  exportExcel() {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.records);
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ["data"],
      };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "students");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  // latest snapshot

  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    this.noPrivate = webcamImage;
  }
  // Get Address Settings
  retake() {
    this.webcamImage = null;
    this.noPrivate = null;
  }
  regions: any[];
  provinces: any[];
  cities: any[];

  multiBranchDS:boolean;
  getAllDsBranch(customId) {
    this.userService.getAllDrivingSchoolByCustomId(customId).then((res: any) => {
      if (res.statusCode == 200) {
        this.dsBranchOptions = res.result;
        if (this.dsBranchOptions.length > 1){
          this.multiBranchDS =true;
        }
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
    });
  }

  transferSelectedDSDialog:boolean;
  transferDsRecord:any;
  transferStudentRecord:any;
  transferBranch(branch, transferStudentRecord){
    this.transferSelectedDSDialog = true;
    //console.log(branch);
    this.transferDsRecord = branch;
    this.transferStudentRecord = transferStudentRecord;
  }

  transferStudentAccount: TransferStudentAccount;

  confirmTransfer() {
    this.transferStudentAccount = {
      email: this.transferStudentRecord.email,
      dsProfileId: this.transferDsRecord.id
    };
    this.transferSelectedDSDialog = false;
    this.studentTransferDialog = false;
    this.userService.transferStudentAccount(this.transferStudentAccount).then((res: any) => {
      if (res.statusCode == 200) {
        this.getAllStudents();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Sucessfull transfer of student account to this branch : " + this.transferDsRecord.companyName,
          life: 3000,
        });
      }
      else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
    });
  }

  cancelTransfer(){
    this.transferSelectedDSDialog = false;
  }

  getAllRegions() {
    this.cmsService.getRegions().then((res: any) => {
      if (res.statusCode == 200) {
        this.regions = res.result;
        this.regions.push({ regionName: null });
        this.regions = _.map(this.regions, function (value) {
          return {
            label: value.regionName ? value.regionName : "",
            value: value.regionName,
          };
        });
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
    });
  }

  changeRegion($e) {
    this.cmsService.getProvincesByRegionName($e.value).then((res: any) => {
      if (res.statusCode == 200) {
        this.provinces = res.result;
        this.provinces.push({ stateName: null });
        this.provinces = _.map(this.provinces, function (value) {
          return {
            label: value.stateName ? value.stateName : "",
            value: value.stateName,
          };
        }); 
        this.record.address.province = null;
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
    });
  }

  changeProvince($e) {
    this.cmsService.getCitiesByProvinceName($e.value).then((res: any) => {
      if (res.statusCode == 200) {
        this.cities = res.result;
        this.cities.push({ cityName: null });
        this.cities = _.map(this.cities, function (value) {
          return {
            label: value.cityName ? value.cityName : "",
            value: value.cityName,
          };
        });
        this.record.address.city = null;
      } else {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Error in Loading Data Please Contact Administrator",
          life: 3000,
        });
      }
    });
  }

  loadAddressOfStudent(record) {
    this.cmsService
      .getProvincesByRegionName(record.region)
      .then((res: any) => {
        if (res.statusCode == 200) {
          this.provinces = res.result;
          this.provinces = _.map(this.provinces, function (value) {
            return {
              label: value.stateName,
              value: value.stateName,
            };
          });
          this.cmsService
            .getCitiesByProvinceName(record.province)
            .then((res: any) => {
              if (res.statusCode == 200) {
                this.cities = res.result;
                this.cities = _.map(
                  this.cities,
                  function (value) {
                    return {
                      label: value.cityName,
                      value: value.cityName,
                    };
                  }
                );
                record.city = record.city;
              }
            });
        }
      });
  }

  editloadAddressOfStudent(record) {
    this.cmsService
      .getProvincesByRegionName(record.region)
      .then((res: any) => {
        if (res.statusCode == 200) {
          this.provinces = res.result;
          this.provinces = _.map(this.provinces, function (value) {
            return {
              label: value.stateName,
              value: value.stateName,
            };
          });
          this.cmsService
            .getCitiesByProvinceName(record.province)
            .then((res: any) => {
              if (res.statusCode == 200) {
                this.cities = res.result;
                this.cities = _.map(
                  this.cities,
                  function (value) {
                    return {
                      label: value.cityName,
                      value: value.cityName,
                    };
                  }
                );
                record.city = record.city;
              }
            });
        }
      });
  }

  showPass() {
    if(!this.show) {
      this.show = true
    }else {
      this.show = false;
    }
  }

}
